/* import { WINDOW } from '@ng-toolkit/universal'; */
import { Component, OnInit , Inject} from '@angular/core';
import { AppService } from '../../../app.service';
import { NgxSmartModalComponent, NgxSmartModalService } from 'ngx-smart-modal';
import { HttpClient } from '@angular/common/http';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
	public zoom = 16;
	public pin_mapa =  './assets/images/others/icon_orange_mark_map.png';
	public img_waze = './assets/images/others/waze_icon_160x160.png';
	public img_moovit = './assets/images/others/moovit_256x256.png';
	sedes = []
	hover: boolean;
	hover1: boolean;
	hover2: boolean;
	hover3: boolean;
	hover4: boolean;

	constructor(
		/* @Inject(WINDOW) private window: Window, */
		private appService: AppService,
		private modalInfo: NgxSmartModalService,
		private http: HttpClient
	) {
		//this.sedes = appService.Data.sedes
		// this.getSedes();
	}

	ngOnInit() {
		this.sedes = this.appService.Data.sedes
	}

	subscribe() { }

	navegarWaze(sede: any) {
		window.open('https://waze.com/ul?ll=' + sede.lat + ',' + sede.lng + '=10', '_blank');
	}
	getSedes() {
		this.appService.getSedes().subscribe(data => {
			this.sedes = data;
		});
	}
	navegarMoovit(sede: any) {
		window.open('moovit://nearby?lat=' + sede.lat + '&lon=' + sede.lng + ' ');
	}
	irMoovit(sede: any) {
		window.open('https://moovit.com/?to=Ubicaci%C3%B3n%20elegida&tll=' + sede.lat + '_' + sede.lng + '&metroId=762&lang=es');
	}
	verModal (nombreModal) {
		this.modalInfo.getModal(nombreModal).open();
	}
}
