import { Injectable, Output } from '@angular/core';
import { Globals } from '../globals';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { VisorRolutosService } from './../services/visor-rolutos.service';

// notificaciones push
import * as noti from 'push.js';
import { EventEmitter } from 'events';
import { Observable } from 'rxjs';
import { AppService } from '../app.service';
import { Ciudadtcc } from '../models/ciudadtcc';
import { CiudadestccService } from './ciudadestcc.service';

@Injectable({
	providedIn: 'root'
})
export class TccService {
	public liquidacionEnvio: Observable<any>;
	public infoRemesa;
	public ciudadesHabilitadas: Ciudadtcc[];
	public valorFlete: Number = 0

	constructor(
		private globals: Globals,
		private http: HttpClient,
		private visor: VisorRolutosService,
		private appService: AppService,
		private ciudadesTccService: CiudadestccService
	) {
		this.ciudadesTccService.getCiudadesTcc().subscribe(async res => {
			this.ciudadesHabilitadas = await res;
		})
	}
	grabarDespacho4Tcc(id_ciudad_origen: string, id_ciudad_destino: string, direccionDestinatario: string, correoOperario: string, nombresDestinatario: string, apellidosDestinatario: string, identificacionDestinatario: string, telefonoDestinatario?,  valor?, peso?: Number, volumen?: Number, alto?, largo?, ancho?, sucursal?) {
		let objetopost = {
			'id_ciudad_origen' : id_ciudad_origen,
			'id_ciudad_destino' : id_ciudad_destino,
			'valor_mercancia' : valor ? valor : 0,
			'peso_real' : peso ? Number(peso).toFixed(2) : 0,
			'peso_volumen' : volumen ? Number(volumen).toFixed(2) : 0,
			'alto': alto ? Number(alto).toFixed(2) : 0,
			'largo' : largo ? Number(largo).toFixed(2) : 0,
			'ancho' : ancho ? Number(ancho).toFixed(2) : 0,
			'sucursal': sucursal ? sucursal : 'bogota',
			'nombres': nombresDestinatario,
			'apellidos' : apellidosDestinatario,
			'telefono_cliente' : telefonoDestinatario ? telefonoDestinatario : '',
			'direccion_destinatario' : direccionDestinatario,
			'identificacion_destinatario' : parseInt(identificacionDestinatario, 0)
		};
		// console.log(objetopost);
		this.http.post(this.globals.backTcc + 'crear_remesa.php', objetopost).subscribe((res: any) => {
			if (res && res.status == 'ok') {
				this.infoRemesa = res.data;
				let objetoCorreo = {
					'correoDestino' : correoOperario ? correoOperario : 'ieportalweb@iegrupo.co',
					'rotulos' : res.data.IMGRotulos,
					'relaciones' : res.data.IMGRelacionEnvio
				};
				this.http.post(this.globals.backServiciosECommerce + 'notificarRemesaOperario.php', objetoCorreo, {headers : {'Access-Control-Allow-Origin': '*'}}).subscribe((resp: any) => {
					// console.log(resp);
				}, err => {
					this.appService.mensajeSnackBar('Error al enviar correos de confirmación' + err, -1, 'error', 'x');
					console.log('Error al enviar correos de confirmacion', err);
				})
			} else {
				let lemensaje = res ? res.mensaje : 'No hay mensaje';
				this.appService.mensajeSnackBar('Error al crear la remesa. ' + lemensaje , 5000, 'error', 'x');
				this.infoRemesa = res ? res.data : 'no hay data';
				// console.log('status error', res)
			}
		}, err => {
			console.error('ocurrio un error al grabar el despacho', err)
		})
	}
	verRotulos() {
		this.visor.post(this.infoRemesa, this.globals.backTcc + 'verRotulo.php' );
	}
	verRelacionEnvio() {
		this.visor.post(this.infoRemesa, this.globals.backTcc + 'verRelacionEnvio.php' );
	}
	getCodigoDANECiudadTcc(ciudadesHabilitadas: Ciudadtcc[], ciudadABuscar: string) {
		if (ciudadABuscar == 'cali') { ciudadABuscar = 'santiago de cali' }
		return this.ciudadesHabilitadas.find(elemento => this.appService.accent_fold(elemento.NOMBRE_POBLACION.toLowerCase()).indexOf(this.appService.accent_fold(ciudadABuscar.toLowerCase())) === 0).DANE
	}
	getCodigoDANECiudadTccV2(ciudadABuscar: string) {
		if (ciudadABuscar == 'cali') { ciudadABuscar = 'santiago de cali' }
		return this.ciudadesHabilitadas.find(elemento => this.appService.accent_fold(elemento.NOMBRE_POBLACION.toLowerCase()).indexOf(this.appService.accent_fold(ciudadABuscar.toLowerCase())) === 0).DANE
	}
	async consultarFlete (origen, destino, valor, peso, largo, ancho, alto, volumen) {
		let paquetePost = {
			'id_ciudad_origen': this.getCodigoDANECiudadTcc(this.ciudadesHabilitadas, origen),
			'id_ciudad_destino': this.getCodigoDANECiudadTcc(this.ciudadesHabilitadas, destino),
			'valor_mercancia': valor,
			'peso_real': peso,
			'peso_volumen': volumen,
			'alto': alto,
			'largo': largo,
			'ancho': ancho
		};
		await this.http.post(this.globals.backTcc + 'consultar_liquidacion.php', paquetePost).subscribe(async (res: any) => {
			this.valorFlete = parseFloat( await res.data.consultarliquidacion2Result.total.totaldespacho);
		});
	}
}
