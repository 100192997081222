import { Routes, RouterModule, Route } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';

// pages IE
import { SedesComponent } from './pages/sedes/sedes.component';

import { AuthGuard } from './guards/auth.guard';

import { RematesComponent } from './pages/remates/remates.component';
import { LandingProductosComponent } from './pages/landing-productos/landing-productos.component';
import { LandingCampanaIelectricistasComponent } from './pages/landing-campana-ielectricistas/landing-campana-ielectricistas.component';
import { DevopsComponent } from './pages/devops/devops.component';

import { ConstruccionComponent } from './pages/construccion/construccion.component';
import { TelcoComponent } from './pages/telco/telco.component';
import { IluminacionComponent } from './pages/iluminacion/iluminacion.component';
import { ProyectosComponent } from './pages/proyectos/proyectos.component';
import { EquiposHerramientasComponent } from './pages/equipos-herramientas/equipos-herramientas.component';
import { TecnologiaComponent } from './pages/tecnologia/tecnologia.component';
import { SolarComponent } from './pages/solar/solar.component';
import { OilGasComponent } from './pages/oil-gas/oil-gas.component';
import { LandingUnidadesComponent } from './pages/landing-unidades/landing-unidades.component';
import { LandingAliadosComponent } from './pages/landing-aliados/landing-aliados.component';

export const routes: Routes = [
	{
		path: '',
		component: PagesComponent, children: [
			{ path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
			{ path: 'cuenta', loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule), data: { breadcrumb: 'Mi cuenta' }, canActivate: [AuthGuard] },
			{ path: 'marcas', loadChildren: () => import('./pages/brands/brands.module').then(m => m.BrandsModule), data: { breadcrumb: 'Marcas' } },
			{ path: 'acceder', loadChildren: () => import('./pages/sign-in/sign-in.module').then(m => m.SignInModule), data: { breadcrumb: 'Acceder' } },
			{ path: 'compare', loadChildren: () => import('./pages/compare/compare.module').then(m => m.CompareModule), data: { breadcrumb: 'Compare' }, canActivate: [AuthGuard] },
			{ path: 'checkout', loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutModule), data: { breadcrumb: 'Pedido' }, canActivate: [AuthGuard] },
			{ path: 'contacto', loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule), data: { breadcrumb: 'Contacto' } },
			{ path: 'categorias', loadChildren: () => import('./pages/categorias/categorias.module').then(m => m.CategoriasModule), data: { breadcrumb: 'Categorias' } },
            { path: 'catalogos', loadChildren: () => import('./pages/catalogos/catalogos.module').then(m => m.CatalogosModule), data: { breadcrumb: 'Catalogos' } },
			{ path: 'cotizaciones', loadChildren: () => import('./pages/wishlist/wishlist.module').then(m => m.WishlistModule), data: { breadcrumb: 'Cotizaciones' } },
			{ path: 'carrito-de-compras', loadChildren: () => import('./pages/cart/cart.module').then(m => m.CartModule), data: { breadcrumb: 'Carrito de compras' }, canActivate: [AuthGuard] },
			{ path: 'trabaja-con-nosotros', loadChildren: () => import('./pages/trabajemos/trabajemos.module').then(m => m.TrabajemosModule), data: { breadcrumb: 'Trabaja con nosotros' } },
			{ path: 'productos/:inicio/:cantidad', loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsModule), data: { breadcrumb: 'Catálogo'} },
			{ path: 'nosotros', loadChildren: () => import('./pages/nosotros/nosotros.module').then(m => m.NosotrosModule), data: { breadcrumb: 'Nosotros'} },
			{ path: 'destacados', loadChildren: () => import('./pages/destacados/destacados.module').then(m => m.DestacadosModule), data: { breadcrumb: 'Nosotros'} },

			/* { path: 'landings', loadChildren: () => import('./pages/landings/landings.module').then(m => m.LandingsModule), data: { breadcrumb: 'Landings'} }, */

			/* { path: 'registro', component: RegistroComponent, data: { breadcrumb: 'Registro' } }, */
			/* { path: 'blog', component: BlogComponent, data: { breadcrumb: 'Blog' } }, */
			/* { path: 'articulo/:url', component: ArticuloComponent }, */
			/* { path: 'chat', component: ChatComponent, data: { breadcrumb: 'Chat' } }, */
			/* { path: 'quienes-somos', component: QuienesSomosComponent, data: { breadcrumb: 'Quienes somos'} }, */
			/* { path: 'alcance-sgi', component: AlcanceSgiComponent, data: { breadcrumb: 'Alcance del sistema del SGI'} }, */
			/* { path: 'politica-hseq', component: PoliticaHseqComponent, data: { breadcrumb: 'Política integral HSEQ'} }, */
			/* { path: 'terminos-y-condiciones', component: TerminosCondicionesComponent, data: { breadcrumb: 'Términos y condiciones'} }, */
			/* { path: 'politica-datos', component: PoliticaDatosComponent, data: {breadcrumb: 'Política de tratamiento y protección de datos'}}, */
			/* { path: 'politica-cookies', component: PoliticaCookiesComponent, data: {breadcrumb: 'Política de cookies'}}, */
			/* { path: 'consentimiento-datos-personales', component: ConsentimientoDatosComponent, data: {breadcrumb: 'Consentimiento para tratamiento de datos personales'}}, */
			/* { path: 'mediodepago', component: MediosPagoComponent, data: { breadcrumb: 'Medios de Pago'} }, */
			/* { path: 'mercados-con-amor', component: MercadosconamorComponent, data: { breadcrumb: 'Mercados con amor'} }, */
			/* { path: 'calculo-solar', component: CalculadoraSolarComponent, data: { breadcrumb: 'Calculadora solar'}}, */
			/* { path: 'covid-19', component: CovidComponent, data: { breadcrumb: '¿Cómo actuamos en ie grupo ante la pandemia causada por el covid-19?'}}, */
			/* { path: 'linea-etica', component: LineaEticaComponent, data:  { breadcrumb: 'Línea ética'}}, */

			{ path: 'registro', loadChildren: () => import('./pages/registro/registro.module').then(m => m.RegistroModule), data: {breadcrumb: 'Registro'}},
			{ path: 'blog', loadChildren: () => import('./pages/blog/blog.module').then(m => m.BlogModule), data: {breadcrumb: 'Blog'}},
			{ path: 'articulo/:url', loadChildren: () => import('./pages/articulo/articulo.module').then(m => m.ArticuloModule), data: {breadcrumb: 'Articulo'}},
			{ path: 'chat', loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatModule), data: {breadcrumb: 'Chat'}},
			/* { path: 'nosotros', loadChildren: () => import('./pages/quienes-somos/quienes-somos.module').then(m => m.QuienesSomosModule), data: {breadcrumb: 'Nosotros'}}, */
			{ path: 'alcance-sgi', loadChildren: () => import('./pages/alcance-sgi/alcance-sgi.module').then(m => m.AlcanceSgiModule), data: {breadcrumb: 'Alcance del sistema del SGI'}},

			{ path: 'politica-hseq', loadChildren: () => import('./pages/politica-hseq/politica-hseq.module').then(m => m.PoliticaHseqModule), data: {breadcrumb: 'Política integral HSEQ'}},
			{ path: 'politica-pqrs', loadChildren: () => import('./pages/politica-pqrs/politica-pqrs.module').then(m => m.PoliticaPqrsModule), data: {breadcrumb: 'Política PQRS'}},
			{ path: 'politica-datos', loadChildren: () => import('./pages/politica-datos/politica-datos.module').then(m => m.PoliticaDatosModule), data: {breadcrumb: 'Política de tratamiento y protección de datos'}},
			{ path: 'politica-cookies', loadChildren: () => import('./pages/politica-cookies/politica-cookies.module').then(m => m.PoliticaCookiesModule), data: {breadcrumb: 'Política de cookies'}},
			{ path: 'terminos-y-condiciones', loadChildren: () => import('./pages/terminos-condiciones/terminos-condiciones.module').then(m => m.TerminosCondicionesModule), data: {breadcrumb: 'Términos y condiciones'}},
			{ path: 'politica-devolucion-derecho-retractacion', loadChildren: () => import('./pages/politica-devolucion/politica-devolucion.module').then(m => m.PoliticaDevolucionModule), data: {breadcrumb: 'Política de devolución o derecho de retractación'}},

			{ path: 'politicas-laft', loadChildren: () => import('./pages/landing-politicas-laft/landing-politicas-laft.module').then(m => m.LandingPoliticasLaftModule), data: {breadcrumb: 'Políticas LAFT'}},
			{ path: 'politicas-cst/:idioma', loadChildren: () => import('./pages/landing-politicas-cst/landing-politicas-cst.module').then(m => m.LandingPoliticasCstModule), data: {breadcrumb: 'Políticas C/ST'}},

			{ path: 'consentimiento-datos-personales', loadChildren: () => import('./pages/consentimiento-datos/consentimiento-datos.module').then(m => m.ConsentimientoDatosModule), data: {breadcrumb: 'Consentimiento para tratamiento de datos personales'}},
			{ path: 'recaudo-electronico', loadChildren: () => import('./pages/medios-pago/medios-pago.module').then(m => m.MediosPagoModule), data: {breadcrumb: 'Medios de Pago'}},
			{ path: 'mercados-con-amor', loadChildren: () => import('./pages/mercadosconamor/mercadosconamor.module').then(m => m.MercadosconamorModule), data: {breadcrumb: 'Mercados con amor'}},
			{ path: 'calculo-solar', loadChildren: () => import('./pages/calculadora-solar/calculadora-solar.module').then(m => m.CalculadoraSolarModule), data: {breadcrumb: 'Calculadora solar'}},
			{ path: 'covid-19', loadChildren: () => import('./pages/covid/covid.module').then(m => m.CovidModule), data: {breadcrumb: '¿Cómo actuamos en ie grupo ante la pandemia causada por el covid-19?'}},
			{ path: 'linea-etica', loadChildren: () => import('./pages/linea-etica/linea-etica.module').then(m => m.LineaEticaModule), data: {breadcrumb: 'Línea ética'}},

			


			{ path: 'sucursales', loadChildren: () => import('./pages/sucursales/sucursales.module').then(m => m.SucursalesModule), data: {breadcrumb: 'Sucursales'}},

			/* { path: 'conexionesconamor', component: MercadosconamorComponent, data: { breadcrumb: 'Conexiones con amor'} }, */
			{ path: 'remates/:inicio/:cantidad', component: RematesComponent, data: { breadcrumb: 'Remates'} },
			{ path: 'sedes/:id', component: SedesComponent, data: { breadcrumb: 'Sedes'} },
			

			{ path: 'cupon-cliente-preferencial/:id-1/:id-2/:id-3/:id-4/:id-5/:id-6', component: LandingProductosComponent, data: {breadcrumb: 'Productos cliente preferencial'}},

			//landings
			{ path: 'construccion', component: ConstruccionComponent, data:  { breadcrumb: 'Construcción'}},
			{ path: 'telco', component: TelcoComponent, data:  { breadcrumb: 'Telco'}},
			{ path: 'iluminacion', component: IluminacionComponent, data:  { breadcrumb: 'Iluminación'}},
			{ path: 'proyectos', component: ProyectosComponent, data:  { breadcrumb: 'Proyectos'}},
			{ path: 'equipos&herramientas', component: EquiposHerramientasComponent, data:  { breadcrumb: 'Equipos & Herramientas'}},
			{ path: 'tecnologia', component: TecnologiaComponent, data:  { breadcrumb: 'Tecnología'}},
			{ path: 'solar', component: SolarComponent, data:  { breadcrumb: 'Solar'}},
			{ path: 'oil&gas', component: OilGasComponent, data:  { breadcrumb: 'Oil & Gas'}},

			// { path: 'buscador', component: LandingBuscadorComponent, data:  { breadcrumb: 'Tienda Online'}},
			{ path: 'buscador', loadChildren: () => import('./pages/landing-buscador/landing-buscador.module').then(m => m.LandingBuscadorModule), data: {breadcrumb: 'Tienda Online'}},


			{ path: 'unidades-de-negocio', component: LandingUnidadesComponent, data:  { breadcrumb: 'Unidades de negocio'}},
			{ path: 'campañas', loadChildren: () => import('./pages/campanias/campanias.module').then(m=> m.CampaniasModule), data: {breadcrumb: 'Campañas'}},
			{ path: 'aliados', component: LandingAliadosComponent, data:  { breadcrumb: 'Aliados'}},

			{ path: 'ielectricistas', component: LandingCampanaIelectricistasComponent, data: { breadcrumb: 'Campaña Ielectricistas'}},
			/* { path: 'devops', component: DevopsComponent, data: { breadcrumb: 'Campo de juego'}}, */
			
			// proveedores
			{ path: 'registro-agendamiento', loadChildren: () => import('./pages/registro-agendamiento/registro-agendamiento.module').then(m=> m.RegistroAgendamientoModule), data: {breadcrumb: 'Campañas'}},
			




			{ path: '**', redirectTo: ''/*   component: NotFoundComponent  */}
		]
	},
];

export const routing: ModuleWithProviders<Route> = RouterModule.forRoot(routes, {
    // onSameUrlNavigation: 'reload',
    // preloadingStrategy: PreloadAllModules,  // <- comment this line for activate lazy load
    useHash: true,
    initialNavigation: 'enabledBlocking',	
	scrollPositionRestoration: 'enabled',
	anchorScrolling: 'enabled' 
});
