import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Globals } from '../globals';


@Injectable({
	providedIn: 'root'
})
export class PedidosService {
	private pedidosUrl = '';
	public pedidoCreado: any;

	constructor(
		private globals: Globals,
		private http: HttpClient
	) {
		this.pedidosUrl = this.globals.urlApi;
	}

	crearPedido(datos): Observable<any> {
		const url = `${this.pedidosUrl}pedidos`;
		return this.http.put<any[]>(url, datos);
	}

	getPedidos(idsapusuario: string): Observable<any> {
		let opt = {
			cuenta : idsapusuario
		}
		const url = `${this.pedidosUrl}pedidos`;
		return this.http.post<any[]>(url, opt);
	}
	deletePedidos(id): Observable<any> {
		const url = `${this.pedidosUrl}pedidos/${id}`;
		return this.http.delete<any[]>(url);
	}
	updatePedido() {
		const url = `${this.pedidosUrl}pedidos/actualizar`;
		return this.http.put<any>(url, this.pedidoCreado);
	}

	consultarEstadoRemesa(codRemesa): Observable<any> {
		return this.http.post(this.globals.backTcc + 'consultar_estado_envio.php', {id_remesa: parseInt(codRemesa, 0)})
	}
}
