import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-politica-hseq',
	templateUrl: './politica-hseq.component.html',
	styleUrls: ['./politica-hseq.component.scss']
})
export class PoliticaHseqComponent implements OnInit {
	imagenes = {
		politicas: 'assets/images/others/img_politicas.jpg'
	};
	constructor() {
	}

	ngOnInit() {
	}

}
