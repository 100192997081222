import { Pipe, PipeTransform } from '@angular/core';
import { Producto } from '../../models/producto';

@Pipe({
	name: 'filterByMark',
	pure: false
})
export class FilterByMarkPipe implements PipeTransform {
	transform(items: Producto[], id?: number) {
		if (id != undefined) {
			return items.filter((item) => item.idmarca == id);
		} else {
			return items;
		}
	}
}
