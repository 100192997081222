import { Pipe, PipeTransform } from '@angular/core';
// import { Category } from './../../app.models'

@Pipe({
	name: 'filtrarPorCategoria'
})
export class FilterByCategoriaPipe implements PipeTransform {
	transform(items: any[], id?: string) {
		return items.filter(function(producto: any) {
			return producto.categorias.find(function(cate) {
				return cate.id == id;
			});
		});
	}
}
