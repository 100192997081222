import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Sede } from './../models/sede';
import { Globals } from '../globals';


@Injectable({
	providedIn: 'root'
})
export class SedeService {
	private SedesUrl = '';

	constructor(
		private globals: Globals,
		private http: HttpClient
	) {
		this.SedesUrl = this.globals.urlApi;
	}

	getSedes(): Observable<any> {
		const url = `${this.SedesUrl}sedesbodegas`;
		return this.http.get<Sede[]>(url);
	}
}
