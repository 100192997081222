import { Component, ViewChild } from '@angular/core';

@Component({
	selector: 'app-timeline',
	templateUrl: './timeline.component.html',
	styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent {
	pasos = [
		{
			fecha: 1996,
			imagen: '../../assets/images/timeline/v2/1996.png',
			textos: [
				{contenido: 'Nace internacional de',bold: false},
				{contenido: 'eléctricos en la ciudad de',bold: false},
				{contenido: 'IBAGUÉ',bold: true},
			]
		},
		{
			fecha: 1999,
			imagen: '../../assets/images/timeline/v2/1999.png',
			textos: [
				{contenido: 'Se crea',bold: false},
				{contenido: 'Lumieléctricos',bold: true},
				{contenido: 'en la ciudad de Bogotá',bold: false},
			]
		},
		{
			fecha: 2001,
			imagen: '../../assets/images/timeline/v2/2001.png',
			textos: [
				{contenido: 'Se incorpora',bold: false},
				{contenido: 'Eurocircuitos',bold: true},
				{contenido: 'en la ciudad de Medellín',bold: false},
			]
		},
		{
			fecha: '2004-2007',
			imagen: '../../assets/images/timeline/v2/2004-2007.png',
			textos: [
				{contenido: 'Se aperturan las sedes ie® en',bold: false},
				{contenido: 'Manizales, Cali, iluminación',bold: true},
				{contenido: 'total en Neiva y Lumieléctricos',bold: true},
				{contenido: 'en Villavicencio', bold: false}
			]
		},
		{
			fecha: 2007,
			imagen: '../../assets/images/timeline/v2/2007.png',
			textos: [
				{contenido: 'Se incorpora',bold: false},
				{contenido: 'Electroindustrial',bold: true},
				{contenido: 'en Bucaramanga',bold: false},
			]
		},
		{
			fecha: 2011,
			imagen: '../../assets/images/timeline/v2/2011.png',
			textos: [
				{contenido: 'Cambio de imagen',bold: false},
				{contenido: 'corporativa',bold: true},
				{contenido: 'ie grupo',bold: true}
			]
		},
		{
			fecha: '2012',
			imagen: '../../assets/images/timeline/v2/2012.png',
			textos: [
				{contenido: 'Fusión de todas las sociedades del',bold: false},
				{contenido: 'grupo en una sola empresa',bold: false},
				{contenido: 'internacional de',bold: true},
				{contenido: 'eléctricos®',bold: true},
			]
		},
		{
			fecha: '2015',
			imagen: '../../assets/images/timeline/v2/2015.png',
			textos: [
				{contenido: 'Integración',bold: false},
				{contenido: 'casa del bombillo 3',bold: true},
				{contenido: 'en la ciudad de Pereira',bold: false},
			]
		},

		{
			fecha: "2016-2017",
			imagen: '../../assets/images/timeline/v2/2016-2017.png',
			textos: [
				{contenido: 'Se aperturan las sedes ie® en',bold: false},
				{contenido: 'Cartagena, Montería',bold: true},
				{contenido: 'y Cúcuta',bold: true}
			]
		},
		{
			fecha: 2022,
			imagen: '../../assets/images/timeline/v2/2022.png',
			textos: [
				{contenido: 'se abre oficina administrativa',bold: false},
				{contenido: 'y comercial en la ciudad de',bold: false},
				{contenido: 'Barranquilla',bold: true}
			]
		},
		{
			fecha: 2023,
			imagen: '../../assets/images/timeline/v2/2023.png',
			textos: [
				{contenido: 'Expandimos nuestros puntos',bold: false},
				{contenido: 'físicos fuera de Colombia, con',bold: false},
				{contenido: 'la nueva sucursal en',bold: false},
				{contenido: 'Panamá',bold: true},
			]
		}
	]
	pos_actual = 0
	@ViewChild('stepperTimeline', { static: true}) stepperTimeline
	/* @ViewChild('stepperTimelineVertical', { static: true}) stepperTimelineVertical? */
	constructor() { }

	ngAfterViewInit(){
		this.stepperTimeline.selectedIndex = this.pos_actual
		/* this.stepperTimelineVertical.selectedIndex = this.pos_actual */
	}

	onScroll(evento){
		evento.preventDefault()
		console.log("X => ", evento)
	}


}