import { Injectable } from '@angular/core';

declare var google: any;
// import {google} from 'googleapis';
// const google = require('googleapis');

export class Opcion {
	ciudad: string;
	productos: any[];
	distanciaAlDestino: number;
	options: any;
}

@Injectable({
	providedIn: 'root'
})
export class GeocodeService {
	geocoder: any;
	distancer: any;
	distancia: number;
	ciudadMasCercana
	listadoOpcionesCercanas: any[]
	arregloCiudadesDistancias: Opcion[] = [];
	promesas: any = [];
	public misCoordenadas = {
		latitud: 0,
		longitud: 0
	};
	public lat: number;
	public lng: number;
	
	closest

	constructor() {
		// this.initGeocoder();
	}
	private initGeocoder() {
		this.geocoder = google.maps.Geocoder();
		this.distancer = google.maps.DistanceMatrixService();
	}
	
	async getDistancia(origen, destino, objeto: Opcion) {
		destino += ' - Colombia';
		origen += ' - Colombia';
		console.log("google codes warn check updates")
		await new google.maps.DistanceMatrixService().getDistanceMatrix({'origins': [origen], 'destinations': [destino], travelMode: 'DRIVING'}, async (results, status) => {
			objeto.distanciaAlDestino = await results.rows[0].elements[0].distance.value;
			objeto.options = results
		}, erro => {
			console.log('error al calcular la distancia', erro)
		} );
	}
	async getCiudadMasCercana(listado: any[], ciudadDestino, ciudadOrigen): Promise<any> { //TODO: arreglar ciudades distancia
		this.arregloCiudadesDistancias = []
		this.promesas = []
		/* console.log('ciudadDestino', ciudadDestino)
		console.log('listado que llega', listado) */
		for (let llave in listado) {
			if (listado[llave]) {
				let opcion = new Opcion();
				opcion.ciudad = llave;
				opcion.productos = listado[llave];
				this.promesas.push(
					this.getDistancia(llave, ciudadDestino, opcion).then(() => {
						this.arregloCiudadesDistancias.push(opcion)
					})
				)
			}
		}
		return Promise.all(this.promesas).then( () => {
			// console.log("orden")
			let opt = this.arregloCiudadesDistancias.sort(this.ordenDistancia);
			
			return this.arregloCiudadesDistancias.sort(this.ordenDistancia);
		}).catch(razon => {
			console.log('razon', razon)
		})
	}
	ordenarArregloDistancia() {
		this.arregloCiudadesDistancias.sort(this.ordenDistancia);
	}
	ordenDistancia(a: Opcion, b: Opcion) {
		if ( (a.distanciaAlDestino < b.distanciaAlDestino ) ) {
			return -1;
		}
		if ( (a.distanciaAlDestino >= b.distanciaAlDestino ) ) {
			return 1;
		}
		return 0;
	}
	public sleep(ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}
}
