import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppService } from '../../app.service';

import SwiperCore, { SwiperOptions, Autoplay, Pagination, Navigation } from 'swiper';
SwiperCore.use([Navigation, Autoplay, Pagination]);

@Component({
	selector: 'app-sedes-carousel',
	templateUrl: './sedes-carousel.component.html',
	styleUrls: ['./sedes-carousel.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class SedesCarouselComponent implements OnInit {
	config: SwiperOptions = {
		observer: true,
		spaceBetween: 10,
		navigation: true,
		hashNavigation :{
			watchState: true,
		  },
		grabCursor: false,
		preloadImages: true,
		lazy: true,
		speed: 500,
		effect: "slide",
		autoplay: {
			delay: 3000
		},
		loop: true,
		breakpoints: {
			320: {
				slidesPerView: 1
			},
			480: {
				slidesPerView: 1
			},
			740: {
				slidesPerView: 2,
			},
			960: {
				slidesPerView: 4,
			},
			1280: {
				slidesPerView: 5,
			},
			1500: {
				slidesPerView: 6,
			}
		}
	}
	public sedes

	constructor(
		public appService: AppService,
	) {
	}

	ngOnInit(): void {
		this.sedes = this.appService.Data.sedes
	}
	trackByFn(index, item) {
		if (!item) { return null; }
		return index
	}
	onSwiper(swiper) {
		// console.log("cargado ->", swiper);
	}
	onSlideChange() {
		// console.log('slide change');
	}

}
