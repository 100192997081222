import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { HttpClient } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { PedidosService } from './../../../services/pedidos.service';
import { IeAuthService } from '../../../services/ieauth.service';
import { TransaccionService } from '../../../services/transaccion.service';
import { EcollectService } from '../../../services/ecollect.service';
import { Globals } from '../../../globals';
import { TccService } from '../../../services/tcc.service';
import { CiudadestccService } from '../../../services/ciudadestcc.service';
import { AppService } from '../../../app.service';

export interface DialogData {
	pedido: any
}
export interface Cotizaciones {
	id: string;
	datos: any;
	fechacreacion: Date;
	fechavencimiento: Date;
	correonotificacion: string;
	valor: number;
	idsapusuario: string;
	idcomercial: string;
	documento: string;
	comentarios: string;
}

// modal pago cotización
export interface DialogData {
	cliente: any;
	cotizacion: any;
}
@Component({
	selector: 'app-pagocotizacion-modal',
	templateUrl: 'modalPagoCotizacion.html',
	styles: [
		'* { line-height: 1; display:block;}'
	]
})
export class PagoCotizacionComponent {
	formInfoCliente: UntypedFormGroup;
	mensajeError: string = '';
	cliente
	cotizacion
	constructor(
		public dialogRef: MatDialogRef<PagoCotizacionComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData,
		public appService: AppService,
		private formBuilder: UntypedFormBuilder,
		private router: Router,
		private ecollectService: EcollectService,
		public snackBar: MatSnackBar
	) {
		this.cliente = data.cliente
		this.cotizacion = data.cotizacion
		let vlr = this.numberWithCommas(this.cotizacion.valor);
		this.formInfoCliente = this.formBuilder.group({
			'tipoDocumento': ['', Validators.required],
			'documento': ['', Validators.required],
			'email': ['', Validators.required],
			'telefono': ['', Validators.required],
			'direccion': ['', Validators.required],
			'usuarioNombre': ['', Validators.required],
			'total': [{value: vlr, disabled: true}, Validators.required],
		});
	}
	onNoClick(): void {
		this.dialogRef.close(this.formInfoCliente);
	}
	onYesClick(): void {
		this.dialogRef.close(this.formInfoCliente)
	}
	numberWithCommas(x) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}
	pagarCotizacion (datos) {
		console.log('que voy a pagar?', datos)
		console.log('que cotizacion?', this.cotizacion)
		this.ecollectService.pagarCotizacion(this.cotizacion.valor, datos.documento, datos.email, datos.telefono, datos.direccion, datos.usuarioNombre, datos.tipoDocumento, this.cotizacion);
		this.dialogRef.close();
	}
}
// FIN modal pago cotización

@Component({
	selector: 'app-orders',
	templateUrl: './orders.component.html',
	styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {
	usuario
	pedidos = [];
	transacciones = [];
	pedido;
	transaccion;
	options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'};
	idTransaccion: string = ''

	// cotizaciones
	cotizacionesActivas: Cotizaciones[] = [];
	displayedColumns: string[] = ['id', 'fechavencimiento', 'valor', 'comentarios', 'acciones']
	dataSource // = new MatTableDataSource(this.cotizacionesActivas)
	@ViewChild(MatSort) sort: MatSort;

	// variable para crear remesa
	ciudadesTcc;
	RemesaFormat = {
		"ciudadorigen": null,
		"ciudaddestino": null,
		"direccion": null,
		"correo": null,
		"nombreUsuario": null,
		"apellidoUsuario": null,
		"numeroDocumento": null,
		"telefonoUsuario": null,
		"valorMercancia": null,
		"pesoPaquete": null,
		"volumenPaquete": null,
		"altoPaquete": null,
		"largoPaquete": null,
		"anchoPaquete": null,
		"ciudadOrigen": null,
	}

	constructor(
		private pedidosService: PedidosService,
		private ieAuthService: IeAuthService,
		public ngxSmartModalService: NgxSmartModalService,
		public transaccionModal: NgxSmartModalService,
		private transaccionService: TransaccionService,
		private ecollectService: EcollectService,
		private pedidoService: PedidosService,
		private httpClient: HttpClient,
		private globals: Globals,
		private tccService: TccService,
		private ciudadesTccService: CiudadestccService,
		private appService: AppService,
		public modalInfoCliente: MatDialog,
		private router: Router,
		public snackBar: MatSnackBar
	) {
		this.usuario = ieAuthService.getCurrentUser();
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				if ((event.url === '/cuenta/historial')){
					console.log("toy bien")
					let cadena = window.location.href;
					let inicio = cadena.indexOf("?id=");
					let fin = cadena.indexOf("#");
					if(inicio > 0) {
						this.idTransaccion = cadena.substring(inicio + 4, fin);
						if(this.idTransaccion.length > 0){
							this.appService.getUsuarioSAP(this.ieAuthService.usuario.email);
							this.appService.consultarEstadoTransaccion(this.idTransaccion);
							/* this.appService.sleep(5000).then( () =>{
									let a = window.location.href.replace("?id=" + this.idTransaccion, '');
									window.location.href = a
							}) */
						}
					}
				}
			}
		})
	}
	ngOnInit() {
		// this.crearRemesa();
		this.consultarPedidos();
		this.consultarTransacciones();
		this.httpClient.get(this.globals.urlApi + 'transacciones/cuenta/' + this.usuario.id ).subscribe((respuesta: any) => {
			this.transacciones = respuesta as Array<any>;
			this.transacciones.forEach(elemento => {
				if (elemento.TranState == 'OK') {
					this.httpClient.get(this.globals.urlApi + 'transacciones/estado/' + elemento.TicketId).subscribe((pedidoTransaccion: any) => {
						if (pedidoTransaccion.pedido[0]) {
							if ( pedidoTransaccion.pedido[0].datos) {
								// this.pedidos.push(pedidoTransaccion.pedido[0].datos)
								// agregar la bodega
								pedidoTransaccion.pedido[0].datos.infoPedido.forEach(element => {
									element.bodegas = [];
									this.httpClient.get(this.globals.urlApi + 'productos-bodegas/' + element.idsap).subscribe( (infoBodProd: any) => {
										let bodegaStock = {
											'bodega' : null,
											'stock' : 0
										};
										infoBodProd.forEach(element2 => {
											bodegaStock.bodega = element2.bodega;
											bodegaStock.stock = element2.stock;
											element.bodegas.push(bodegaStock);
										});
									})
								});
								// correos
								this.sleep(3000).then(() => {
									// alert('enviar correo porque hay un pedido sin atender su transacción está OK');
									// console.log('la info', pedidoTransaccion.pedido[0].datos);
									if (pedidoTransaccion.pedido[0].atendido == false) {
										this.httpClient.post(this.globals.backEcollect + 'enviarCorreoPedidoAdmin.php', {datos: pedidoTransaccion.pedido[0].datos }).subscribe((res: any) => {
											console.log('Enviando a comercial', res);
										})
									}
									
								})
							}
						}
					})
				}
			})
		})
		this.consultarCotizaciones()
	}
	// var points = [40, 100, 1, 5, 25, 10];
	// points.sort(function(a, b){return a-b});
	consultarPedidos() {
		this.pedidosService.getPedidos(this.ieAuthService.getCurrentUser().id).subscribe((res: any) => {
			this.pedidos = res.pedidos;
			this.pedidos.sort(function(a,b){return b.id-a.id});
			this.pedidos.forEach(pedido => {
				// pedido.createdAt = new Date(pedido.createdAt).toLocaleDateString('co-CO', this.options)
				// console.log('el pedido', pedido)
				if (pedido.remesa == null) {
					if (pedido.estado == '0') {
						this.ecollectService.consultarTransaccion(pedido.transaccion).subscribe((resConsultarTransaccion: any) => {
							pedido.estado = resConsultarTransaccion.data.getTransactionInformationResult.TranState;
							// pedido.createdAt = Date.now();
							this.pedidoService.pedidoCreado = pedido
							console.log('se creó un pedido==>', this.pedidoService.pedidoCreado)
							if (resConsultarTransaccion.data.getTransactionInformationResult.TranState == 'OK' && !pedido.atendido) { // crear remesa con los productos que esten en la ciudad de origen
							}
							this.pedidoService.updatePedido().subscribe(resPed => {
								console.log('actualizando la información...', resPed)
								pedido.estadoRemesa = pedido.estado
							})
						})
					} else {
						if (pedido.estado == 'OK' && !pedido.atendido) {
							let origen = pedido.datos.infoCliente.origen;
							let origenCod = pedido.datos.infoCliente.origenCod; // 'xxxx' // pedido.datos.infoCLiente.origenCod;
							let destinoCod = pedido.datos.infoCliente.destinoCod;
							this.enviarPaquete(pedido.datos, origen, origenCod, destinoCod)
						}
						pedido.estadoRemesa = pedido.estado
					}
				} else {
					this.pedidosService.consultarEstadoRemesa(pedido.remesa).subscribe(respuesta => {
						let ultimo_estado = respuesta.data.remesasrespuesta.RemesaEstados.listaestados.Estado.length - 1;
						pedido.estadoRemesa = respuesta.data.remesasrespuesta.RemesaEstados.listaestados.Estado[ultimo_estado].descripcion;
					})
				}
			});
		}, err => {
			console.log('err', err)
		})
	}
	consultarTransacciones() {
		this.sleep(3000).then(() => {
			this.transaccionService.getTransaccionesCuenta(this.ieAuthService.getCurrentUser().id).subscribe(res => {
				this.transacciones = res;
				this.transacciones.sort((a, b) => (a.createdAt < b.createdAt) ? 1 : (b.createdAt < a.createdAt ? -1 : 0 ) );
				this.ecollectService.consultarTransacciones(this.transacciones).subscribe((variaciones: any) => {
					this.transacciones = variaciones.actualizaciones
					this.transacciones.forEach(transaccion => {
						transaccion.createdAt = new Date(transaccion.createdAt).toLocaleDateString('co-CO'/* , this.options */)
					});
				})
			}, err => {
				console.log('error', err)
			})
		})
	}
	verDetallesPedido(pedido?: any): void {
		this.ngxSmartModalService.setModalData(pedido, 'infoPedido')
		this.ngxSmartModalService.getModal('infoPedido').open()
	}
	borrarPedido(id, event) {
		this.pedidosService.deletePedidos(id).subscribe((res: any) => {
			this.pedidos.splice(event, 1);
		}, err => {
			console.log(err)
		})
	}
	verDetallesTransaccion(transaccion, posicion): void {
		let pedidoAsociado = this.pedidos.find( function(elemento) {
			return elemento.transaccion == transaccion.TicketId ? elemento : null;
		})
		transaccion = this.transacciones[posicion];
		if (pedidoAsociado) {
			transaccion.pedido = pedidoAsociado.id
			transaccion.pkgPedido = pedidoAsociado
		} else {
			transaccion.pedido = null
		}

		this.transaccionModal.setModalData(transaccion, 'infoTransaccion')
		this.transaccionModal.getModal('infoTransaccion').open()
	}
	public sleep = function(ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}
	public crearRemesa(ciudadorigen: string, ciudaddestino: string, direccion: string, correo: string, nombreUsuario: string, apellidoUsuario: string, numeroDocumento: string, telefonoUsuario: string, valorMercancia: number, pesoPaquete: number, volumenPaquete: number, altoPaquete: number, largoPaquete: number, anchoPaquete: number, ciudadOrigen: string) {
		let ciudadesTcc
		this.ciudadesTccService.getCiudadesTcc().subscribe(ciudades => {
			ciudadesTcc = ciudades
		})
		this.tccService.grabarDespacho4Tcc(
			this.getCodigoDANECiudadTcc(ciudadesTcc, ciudadorigen),
			this.getCodigoDANECiudadTcc(ciudadesTcc, ciudaddestino),
			direccion,
			correo,
			nombreUsuario,
			apellidoUsuario,
			numeroDocumento,
			telefonoUsuario,
			valorMercancia,
			pesoPaquete,
			volumenPaquete,
			altoPaquete,
			largoPaquete,
			anchoPaquete,
			ciudadOrigen
		)
	}
	getCodigoDANECiudadTcc(muestra: any[], ciudadABuscar: string) {
		if (ciudadABuscar == 'cali') { ciudadABuscar = 'santiago de cali' }
		return muestra.find(elemento => this.appService.accent_fold(elemento.NOMBRE_POBLACION.toLowerCase()).indexOf(this.appService.accent_fold(ciudadABuscar.toLowerCase())) === 0).DANE
	}
	enviarPaquete(datos: any, origen: string, origenCod, destinoCod) {
		let infoCliente = datos.infoCliente;
		let infoPedido = datos.infoPedido;
		let productosCiudad = datos.productosCiudades[origen];
		this.tccService.grabarDespacho4Tcc(origenCod, destinoCod, infoCliente.direccion, 'ieportalweb@iegrupo.co', infoCliente.firstName, infoCliente.lastName, infoCliente.documento, infoCliente.telefono, infoCliente.liquidacion.totalProductos, productosCiudad.peso, (productosCiudad.volumen * 400), 0, 0 , 0, 'bogota' );
	}
	consultarCotizaciones() {
		this.httpClient.get(this.globals.urlApi + 'cotizaciones-activas-cliente/' + this.ieAuthService.usuario.email ).subscribe((respuesta: Cotizaciones[]) => {
			this.cotizacionesActivas = respuesta
			let sinpagar = []
			this.cotizacionesActivas.forEach((cotizacion: any) => {
				if (cotizacion.estado == 'creado') {
					sinpagar.push(cotizacion)
				} else {
					if (cotizacion.estado != 'notificado') {
						// console.log('preguntar si se realizo el pago de la transaccion alv debo enviar el correo y cambiar el estado', cotizacion);
						this.ecollectService.consultarTransaccion(cotizacion.estado).subscribe(estadoTransaccion => {
							// console.log('el estado de esta transaccion es:  ===>', estadoTransaccion);
							if ( estadoTransaccion.data.getTransactionInformationResult.TranState == 'OK' ) {
								this.httpClient.get(this.globals.urlApi + 'comercial/' + cotizacion.idcomercial).subscribe( (comercial: any) => {
									let datosCorreo = {
										cliente: this.usuario,
										cotizacion: cotizacion,
										transaccion: estadoTransaccion.data.getTransactionInformationResult,
										comercial: comercial
									}
									this.httpClient.post(this.globals.backEcollect + 'enviarCorreoConfirmacionPagoCotizacion.php', {datosCorreo} ).subscribe( (envioCorreo: any) => {
										if (envioCorreo.status === 'ok') {
											// console.log('notificado, actualizar estado de cotización', envioCorreo)
											cotizacion.estado = 'notificado';
											this.httpClient.patch(this.globals.urlApi + 'cotizaciones', cotizacion).subscribe( resActualizacion => {
												console.log('fin del proceso 333', resActualizacion)
											})

										}
									})
								})
							}
						})
					}
				}
			})
			this.dataSource = new MatTableDataSource(sinpagar)
		})
	}
	pagarCotizacion (cotizacion) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.panelClass = 'registro-modal';
		dialogConfig.height = 'auto';
		dialogConfig.data = {
			cliente: this.ieAuthService.usuario,
			cotizacion: cotizacion
		};
		const dialogReg = this.modalInfoCliente.open(PagoCotizacionComponent, dialogConfig);
		dialogReg.afterClosed().subscribe(result => {
		})
	}
}
