import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
// https://www.remotestack.io/angular-seo-tutorial-add-seo-page-title-meta-tags-and-canonical-url/
@Injectable({
  providedIn: 'root'
})
export class CanonicalService {

  constructor(
	  @Inject(DOCUMENT)private dom
  ) { }
  createCanonicalLink(url?: string){
	  let canURL = url == undefined ? this.dom.URL : url;
	  let link : HTMLLinkElement = this.dom.createElement('link');
	  link.setAttribute('rel', 'canonical');
	  this.dom.head.appendChild(link);
	  link.setAttribute('href', canURL);
  }
}
