// https://dev.to/andreilm/dynamic-social-media-tags-with-angular-7-3a5
export class MetaTag {
	name: string;
	value: string;
	isFacebook: boolean;

	constructor (name: string, value: string, isFacebook: boolean) {
		this.name = name;
		this.value = value;
		this.isFacebook = isFacebook;
	}
}
