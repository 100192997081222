import { Component, Input, Output, EventEmitter } from '@angular/core';
import * as XLSX from 'xlsx'

@Component({
	selector: 'app-leer-excel',
	templateUrl: './leer-excel.component.html',
	styleUrls: ['./leer-excel.component.scss']
})
export class LeerExcelComponent {
	@Input('nombre_input') nombre_input?: any = 'archivo_uno';
	@Input('tipos_archivo') tipos_archivo?: any = '.xls, .xlsx';
	@Output() _datos_salida = new EventEmitter<any>();
	dataFromXls: any[] = []

	onchangeInput(event) {
		let archivo: File = event.target.files[0];

		let reader = new FileReader();
		reader.onload = (e: any ) => {
			const data: Uint8Array = new Uint8Array(e.target.result);
			const libro: XLSX.WorkBook = XLSX.read(data, {type: 'array'});
			const hoja: XLSX.WorkSheet = libro.Sheets[libro.SheetNames[1]];
			const jsonData: any[] = XLSX.utils.sheet_to_json(hoja, { header: 1})
			jsonData.forEach(el => {
				if(el.length > 0){
					if(el[0] != null && el[1] != null){
						this.dataFromXls.push({
							numero: el[0],
							mensaje: el[1]
						})
					}
				}
			});
			this.dataFromXls.splice(0,1)
			this._datos_salida.emit(this.dataFromXls)
		}
		reader.readAsArrayBuffer(archivo);
	}

}
