/* import { WINDOW } from '@ng-toolkit/universal'; */
import { Component, OnInit, HostListener, ViewChild, AfterViewInit, Inject, ElementRef } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Settings, AppSettings } from '../app.settings';
import { AppService } from '../app.service';
import { Category } from '../app.models';
import { SidenavMenuService } from '../theme/components/sidenav-menu/sidenav-menu.service';
import { Producto } from '../models/producto';
import { ProductoService } from '../services/producto.service';
import { Sede } from '../models/sede';
import { SedeService } from '../services/sede.service';
import { HttpClient } from '@angular/common/http';
import { UntypedFormControl } from '@angular/forms';
import { noop, Observable, of } from 'rxjs';
import { Globals } from '../globals';
import { BuscadorService } from '../services/buscador.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { MatMenu } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { IeAuthService } from '../services/ieauth.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { emailValidator, matchingPasswords } from '../theme/utils/app-validators';
import { CuentaService } from '../services/cuenta.service';

import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { CookieService } from 'ngx-cookie-service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Marca } from '../models/marca';
import { Overlay } from '@angular/cdk/overlay';
import { SocketIoService } from './../services/socket-io.service';
import { ModalCiudadesComponent } from '../shared/modal-ciudades/modal-ciudades.component';


// dialogo registro
export interface DialogData {
	idCuenta: string,
}
@Component({
	selector: 'app-registro-modal',
	templateUrl: 'registroModal.html',
	styles: [
		'* { line-height: 1; display:block;}'
	],
})
export class DialogoRegistroComponent {
	formRegistro: UntypedFormGroup;
	formSuscripcion: UntypedFormGroup;
	mensajeError: string = '';
	terminosYCondiciones: boolean = false;
	imagen = 'https://logosmarcas.s3.amazonaws.com/0.png'
	link
	constructor(
		public dialogRef: MatDialogRef<DialogoRegistroComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData,
		private http: HttpClient,
		private globals: Globals,
		public appService: AppService,
		private formBuilder: UntypedFormBuilder,
		private snackBar: MatSnackBar,
		private ieAuthService: IeAuthService,
		private modal: NgxSmartModalService,
		private router: Router,
		private cuentaService: CuentaService
	) {
		this.formRegistro = this.formBuilder.group({
			'firstName': ['', Validators.compose([Validators.required, Validators.minLength(3)])],
			'lastName': ['', Validators.compose([Validators.required, Validators.minLength(3)])],
			'email': ['', Validators.compose([Validators.required, emailValidator])],
			'password': ['', Validators.compose([Validators.required, Validators.minLength(8)])],
			'confirmPassword': ['', Validators.required],
			'tyc': ['', Validators.required]
		}, { validator: matchingPasswords('password', 'confirmPassword') });

		this.formSuscripcion = this.formBuilder.group({
			'correo': ['', Validators.compose([emailValidator])]
		});

		this.http.get(this.globals.urlApi + 'homemodal').subscribe((res:any) =>{
			this.imagen = res[0].imagen
			const url = res[0].link.replace(/\%20/g, '-').replace(/\%2B/g, '-').replace(/---/g, '-').toLowerCase();
			this.link = url
			
		})
	}
	
	onNoClick(): void {
		this.dialogRef.close(this.formRegistro);
	}
	onYesClick(): void {
		this.dialogRef.close(this.formRegistro)
	}
	navegarA(url: string) {
		this.router.navigate([url]);
		this.dialogRef.close();
	}
	cerrarModal() {
		this.dialogRef.close({datos: "holo"});
	}
	signInWithFB(): void {
		if (this.terminosYCondiciones == false) {
			this.snackBar.open('Debes aceptar los términos y condiciones.', 'x', { panelClass: 'error', verticalPosition: 'top', duration: 2000 });
		} else {
			/* this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(res => {
				console.log(res)
				if (res.email == undefined) {
					this.snackBar.open('Tu cuenta de Facebook no tiene un correo asociado, usa una cuenta diferente.', 'x', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
					this.authService.signOut();
				}
			}) */
		}
	}
	onformRegistroSubmit(values): void {
		if (values.TyC == false) {
			this.snackBar.open('Debes aceptar los términos y condiciones.', 'x', { panelClass: 'error', verticalPosition: 'bottom', duration: 1000 });
		} else {
			this.cuentaService.isCuenta(values.email).subscribe((res: any) => {
				if (res == 0) {
					let tempUser: any = {
						email: values.email,
						firstName: values.firstName,
						lastName: values.lastName,
						name: values.firstName + " " + values.lastName,
						password: values.password,
						tyc: values.tyc
					}
					this.http.post(this.globals.urlApi + 'cuentas/crear', tempUser).subscribe((res: any) => {
						if (res.id) {
							//this.http.post(this.globals.backServiciosECommerce + 'bienvenida_html/bienvenida_usuario.php',{ 
							this.http.post(this.globals.backServiciosECommerce + 'plantillas_correos/bienvenida/correo_bienvenida.php', {
								correoDestino: values.email,
								ulrDestino: 'http://3.93.146.154',
								nombreUsuario: tempUser.firstName + ' ' + tempUser.lastName 
							}).subscribe((rescorreo: any) => {
								this.dialogRef.close()
							})
							this.snackBar.open('Cuenta registrada satisfactoriamente.', '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
							this.ieAuthService.setUser(res);
							this.router.navigate(['/cuenta']);
						}
					})
				} else {
					this.snackBar.open(values.email + ' ya se encuentra registrado .', '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
				}
			}, err => {
				console.log('error', err)
			});
		}
	}
	onFormSuscripcion(values): void {
		// console.log('suscripcion', values)
		this.http.post(this.globals.urlApi + 'suscriptor', values).subscribe((res: any) => {
			if (res.id) {
				this.http.post(this.globals.backServiciosECommerce + 'bienvenida_html/bienvenida_suscriptor.php', { correoDestino: values.correo, ulrDestino: 'http://3.93.146.154' }).subscribe((rescorreo: any) => {
					this.dialogRef.close()
				})
				this.dialogRef.close();
			}
		})
	}
	aceptarTYC(valor: boolean) {
		this.terminosYCondiciones = !valor;
	}
	abrirModal() {
		this.modal.getModal('modalTerminosCondiciones').open();
	}
}
// fin dialogo registro
//-----------------------------------------------------------------------------------------------------------------------
// hoja lateral
export interface DialogDataLateral {
	idProducto: string,
}
@Component({
	selector: 'app-hoja-lateral',
	templateUrl: 'hojaLateral.html',
	styles: [
		'* { line-height: 1; display:block;}'
	]
})
export class HojaLateralComponent {
	producto: Producto;
	marcaProducto: Marca;
	infolateral: any;
	constructor(
		public dialogRef: MatDialogRef<HojaLateralComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogDataLateral,
		private http: HttpClient,
		private globals: Globals,
		private appService: AppService,
		private router: Router,
	){}

	ngOnInit() {
		this.cargarProducto()
	}
	cargarProducto(){
		// this.sleep(500).then(()=>{
			this.http.get(this.globals.urlApi + 'hojalateral').subscribe( (opt:any)=>{
				if(opt) {
					this.infolateral = opt[0];
					if(this.infolateral.modo) {
						console.log('true', opt[0])
						this.http.get(this.globals.urlApi + 'productos/' + opt[0].sku).subscribe((res:any)=>{
							// console.log("el producto", res[0])
							this.producto = res[0];
							this.appService.marcaService.getMarcaIdsap(this.producto.idmarca).subscribe(respuestaMarca =>{
								this.marcaProducto = respuestaMarca[0];
								// console.log("le marcuqe", this.marcaProducto)
							})
							
						})
					} else {
						// console.log('false', opt[0])
					}
				} else {
					console.log("no se cargó la hoja lateral")
				}
				
				
			})
			
		// })
		
	}
	cerrarModal() {
		this.dialogRef.close();
	}
	sleep = function (ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}
	navegarA(url: string) {
		let cadena = 'productos/1/12/producto/'+this.producto.idsap + '/' + this.producto.url
		this.router.navigate([cadena]);
		this.dialogRef.close();
	}
}
// fin hoja lateral
//---------------------------------------------------------------------------------------------------------------------------
// modal registro
export interface DialogDataRegistro {
	imagen: string,
}
@Component({
	selector: 'app-modal-aux',
	templateUrl: 'modalAux.html',
	styles: [
		'* { line-height: 1;}'
	]
})
export class ModalRegistroComponent {
	producto: Producto;
	marcaProducto: Marca;
	infolateral: any;
	constructor(
		public dialogRef: MatDialogRef<ModalRegistroComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogDataRegistro,
		private router: Router,
	){
		
	}
	ngOnInit() {
	}
	cerrarModal() {
		this.dialogRef.close();
	}
	sleep = function (ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}
	navegarA(url: string) {
		let cadena = 'productos/1/12/producto/'+this.producto.idsap + '/' + this.producto.url
		this.router.navigate([cadena]);
		this.dialogRef.close();
	}
}
// fin modal registro
//------------------------------------------------------------------------------------------------------------------------
// modal recaudo
export interface DialogDataRecaudo {
	data: any,
}
@Component({
	selector: 'app-modal-recaudo',
	templateUrl: 'modalRecaudo.html',
	styles: [
		'* { line-height: 1;}'
	]
})
export class ModalRecaudoComponent {
	producto: Producto;
	marcaProducto: Marca;
	infolateral: any;
	constructor(
		public dialogRef: MatDialogRef<ModalRecaudoComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogDataRecaudo,
		private router: Router,
	){
		
	}
	ngOnInit() {
	}
	cerrarModal() {
		this.navegarA('cuenta/anticipos')
	}
	sleep = function (ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}
	navegarA(url: string) {
		
		this.router.navigate([url]);
		this.dialogRef.close();
	}
}
// fin modal recaudo
//------------------------------------------------------------------------------------------------------------------------
// modal pagos
export interface DialogDataPagos {
	url_redirect: string,
}
@Component({
	selector: 'app-modal-pago',
	templateUrl: 'modalPago.html',
	
})
export class ModalPagosComponent {
	constructor(
		public dialogRef: MatDialogRef<ModalPagosComponent>,
		@Inject(MAT_DIALOG_DATA) public data: string, // DialogDataPagos,
		private router: Router,
	){
		
	}
	ngOnInit() {
	}
	cerrarModal() {
		this.dialogRef.close();
	}
	irPasarela () {
		window.location.href = this.data
	}
	
	navegarA(url: string) {
		let cadena = url
		this.router.navigate([cadena]);
		this.dialogRef.close();
	}
}
// fin modal pagos
// --------------------------------------------------------------------------------------------------------------------
// modal ciudades

// fin modal ciudades

// INICIO
@Component({
	selector: 'app-pages',
	templateUrl: './pages.component.html',
	styleUrls: ['./pages.component.scss'],
	providers: [SidenavMenuService],
	animations: [
		trigger('buscable', [
			state('lupin', style({
				fontWeight: 'bolder',
				display: 'block',
			})),
			state('nolup', style({
				display: 'none',
			})),
			transition('nolup => lupin', [
				animate(100, style({
					transform: 'scale(2)',
					display: 'block'
				}))
			]),
			transition('lupin => nolup', [
				animate(100, style({
					transform: 'scale(1)',
					display: 'block'
				}))
			])
		])
	]
})

export class PagesComponent implements OnInit, AfterViewInit {
	logo = './assets/images/others/logo-ie-header.png';
	// logo = './assets/images/others/logo-ieR-navidad.png';
	public ciudadDelAppService;
	public showBackToTop = false;
	public categories: Category[];
	public categoriasv2: any[] = [];
	public category: Category;
	public sidenavMenuItems: Array<any>;
	public productos: Producto[];
	public sedes: Sede[];
	@ViewChild('sidenav') sidenav: any;
	@ViewChild(MatAutocompleteTrigger) autocomplete: any;
	@ViewChild('bancookie') bannerCookies: any;
	@ViewChild(MatMenu) menu: any;
	// user: SocialUser
	loggedIn: boolean
	veces = 0
	buscador: UntypedFormControl;
	buscadorResponsive: UntypedFormControl;
	opciones: Producto[] = [];
	filteredOptions: Observable<Producto[]>;
	largocadena: number = 0;
	marcas: any;
	leOS = '';
	tecla
	animLupaXs = 'nolup'
	verLayer

	cookieValue = 'UNKNOWN';

	public settings: Settings;
	cargandoProductosBuscador: boolean = false;

	// geolocalizacion
	opcionesCiudad: Array<any>;
	laciudad

	//arreglo para mostrar el modalRegistro
	direccionesModalRegistro = [
		"/",
		"/bogota",
		"/bucaramanga",
		"/cali",
		"/cartagena",
		"/cucuta",
		"/ibague",
		"/manizales",
		"/medellin",
		"/monteria",
		"/mosquera",
		"/neiva",
		"/pereira",
		"/villavicencio",
	];
	arreglo_home_top: any[] = [];

	//@ViewChild('contenedorAppParaFondo', { static: false}) contenedorAppParaFondo: ElementRef;
	fondoApp

	public en_ruta_logistica: boolean= false
	ancho_device 
	@ViewChild('logoToolBar', { static: false }) logoToolBar!: ElementRef;

	constructor(
		/* @Inject(WINDOW) private window: Window, */
		public appSettings: AppSettings,
		public appService: AppService,
		public sidenavMenuService: SidenavMenuService,
		public route: ActivatedRoute,
		public router: Router,
		public productoService: ProductoService,
		public sedeService: SedeService,
		private http: HttpClient,
		private globals: Globals,
		private buscadorService: BuscadorService,
		private modalTerminos: NgxSmartModalService,
		public dialogoRegistro: MatDialog,
		public hojaLateral: MatDialog,
		public hojaAux: MatDialog,
		private ccService: NgcCookieConsentService,
		private cookieService: CookieService,
		private overlay: Overlay,
		private _socketio: SocketIoService
	) {
		
		this.opcionesCiudad = this.appService.puntosVenta
		this.appService.getMisCoordenadas();
		// console.log(window.navigator.platform)
		this.leOS = window.navigator.platform;
		this.settings = this.appSettings.settings;	
	
		/* let regex = this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.hojaAux.closeAll();
				if (this.direccionesModalRegistro.includes(event.url)){
					if (event.url === '/'){
						this.appService.setCiudadActualConPlanoName('bogota');
						this.router.navigate(['/bogota'])
						this.laciudad = this.appService.getCiudadActual()
					}
					regex.unsubscribe();
				}
			}
		}); */
	}
	ngOnInit() {
		this._socketio.getMessage().subscribe((resData:any) => {
			console.log("notificar con socket io", resData)
			this.notificar(resData.mensaje, resData.link)
		})
		this.appService.compartidor.subscribe(datos =>{
			this.appService.setCiudadActualConPlanoName(datos.plano_name)
			this.laciudad = this.appService.ciudadActual
			// console.log("SX", this.ciudad)
		})
		/* (<HTMLDivElement>this.contenedorAppParaFondo.nativeElement).style.setProperty('background-image', this.appService.fondo) */
		this.fondoApp = this.appService.fondo
		/* this.appService.compartidor.subscribe( ciudad =>{
			if (ciudad) {
				this.laciudad = ciudad;
				this.ciudadDelAppService = this.laciudad
				if (this.laciudad){
					if(this.direccionesModalRegistro.includes(this.router.url)){
						this.router.navigate(['/'+this.laciudad.plano_name])
					}
				}
			}
			// else {this.verModalCiudades();}
		}); */
		
		let cadenaTemp = ''
		this.ocultarMenu();
		this.buscador = new UntypedFormControl();
		this.buscadorResponsive = new UntypedFormControl();
		// this.getCategories();
		// this.getCategoriasV2();
		this.sidenavMenuItems = this.sidenavMenuService.getSidenavMenuItems();
		this.buscador.valueChanges.subscribe( async cadenaBuscada => {
			let cadenaaux = this.buscador.value
			await setTimeout( () => {
				this.cargandoProductosBuscador = true;
				this.filteredOptions = null;
				this.largocadena = cadenaBuscada ? cadenaBuscada.length : 0;
				if (this.largocadena > 2 && cadenaaux == cadenaTemp) {
					this.buscarCadena(cadenaBuscada.replace('/', '%2F')).subscribe((productsFound: any[]) => {
						productsFound.forEach(producto => {
							producto['show'] = false
							producto['url'] = producto.url?.split(' ').join('-');
						})
						this.filteredOptions = of(productsFound)
						/* this.filteredOptions.forEach(productoLista => {
							productoLista.forEach(item => {
								productoLista['url'] = item.nombre.replace('/', '%2F')
							})
						}) */

						this.cargandoProductosBuscador = false;
					});
				} else {
					this.cargandoProductosBuscador = false;
				}
			}, 1000);
			cadenaTemp = cadenaBuscada
		});
		this.buscadorResponsive.valueChanges.subscribe(async cadena => {
			this.largocadena = cadena.length;
			if (cadena.length > 2) {
				this.animLupaXs = 'lupin'
				// this.animarLupaXs(true)
			} else {
				this.animLupaXs = 'nolup'
				// this.animarLupaXs(false)
			}
		})
		this.cookieValue = this.cookieService.get('AC');
		if (this.cookieValue == 'IeCommerce') {
			document.getElementsByClassName('cc-window')?.item(0)?.remove()
		}
		/* this.statusChangeSubscription =  */this.ccService.statusChange$.subscribe( (event: any) => {
			// you can use this.ccService.getConfig() to do stuff...
			this.cookieService.set( 'AC', 'IeCommerce' );
		});
		this.ancho_device = window.innerWidth
	}

	/* @HostListener('window:resize', ['$event'])
	public onResize(event): void {
		this.ancho_device = window.innerWidth
		// console.log("# cambio", this.ancho_device)
		if(this.ancho_device < 690){
			// console.log("event", event)
			this.en_ruta_logistica = true
			// console.log("C", this.en_ruta_logistica)
			this.logoToolBar.nativeElement.style.display = 'none';
		} else {
			this.logoToolBar.nativeElement.style.display = 'block';
		}
	} */


	ngAfterViewInit() {
		// this.notificar()
		this.ocultarParaLogistica()
		/* if(
			!this.router.url.includes('logistica') && 
			!this.router.url.includes('administrador') &&
			!this.router.url.includes('picking')
		) {	
			setTimeout(() => {
				this.verHojaLateral();	
			}, 20000);
		} */
		
	}
	ocultarParaLogistica(){
		this.en_ruta_logistica = this.router.url.includes('logistica') ? true : false
	}
	
	notificar (mensaje, link) { //TODO: trabajar en notificaciones push
		if(Notification.permission !== 'granted'){ // si no tiene permisos, hay que pedirlos para enviarlos
			Notification.requestPermission().then(res => {
				if (res === 'granted') {
					let notificacionesc = new Notification("Internacional de Eléctricos",{
						icon:"https://www.iegrupo.co/assets/images/others/ie-logo-mant.png",
						body: mensaje,
						silent: false
					})
					notificacionesc.onclick = () => {
						window.open(link, "_blank")
					}
				}
			}).catch(err => {
				console.log("error =>", err)
			})
		} else {
			let notificacionesc = new Notification("Internacional de Eléctricos",{
				icon:"https://www.iegrupo.co/assets/images/others/ie-logo-mant.png",
				body: mensaje,
				silent: false
			})
			notificacionesc.onclick = () => {
				window.open(link, "_blank")
			}
		}
	}
	ngOnDestroy() {
	}
	sleep = function (ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}
	buscarCadena(cadena: string) {
		const url = `${this.globals.urlApi}v2/productos-buscador/${this.laciudad.plano_name}/${cadena}`; //TODO:activar v2
		/* const url = `${this.globals.urlApi}productos-buscador/${cadena}`; */
		return this.http.get(url);
	}
	private _filter(value: string): Producto[] {
		const filterValue = value.toUpperCase();
		return this.opciones.filter(producto => producto.nombre.indexOf(filterValue) === 0)
	}
	displayFn(producto?: any): any | any {
		return producto ? producto.nombre : undefined;
	}
	/* public getCategories() {
		this.appService.getCategories().subscribe(data => {
			this.categories = data;
			this.category = data[0];
			this.appService.Data.categories = data;
			let estructCategorias: any = []
			this.categories.forEach(item => {
				if (item.id != 0 && item.hasSubCategory && item.parentId == 0) {
					estructCategorias.push(item)
				}
			})
			estructCategorias.forEach(cateppal => {
				if (this.estructurarCategorias(cateppal).length > 0) {
					let temp = []
					temp = this.estructurarCategorias(cateppal)
					cateppal.subs = temp
					cateppal.subs.forEach(catesec => {
						if (this.estructurarCategorias(catesec).length > 0) {
							let temp = []
							temp = this.estructurarCategorias(catesec)
							catesec.subs = temp
							catesec.subs.forEach(cateter => {
								if (this.estructurarCategorias(cateter).length > 0) {
									let temp = []
									temp = this.estructurarCategorias(cateter)
									cateter.subs = temp
									cateter.subs.forEach(catecua => {
										if (this.estructurarCategorias(catecua).length > 0) {
											let temp = []
											temp = this.estructurarCategorias(catecua)
											catecua.subs = temp
										}
									})
								}
							})
						}
					})
				}
			});
			this.appService.Data.categories = estructCategorias
			this.categories = estructCategorias
		});
	} */
	/* public getCategoriasV2 () {
		this.http.get(this.globals.urlApi + 'v2/categorias-padres').subscribe( (resData: any) => {
			this.categoriasv2 = resData
		})
	} */
	public estructurarCategorias(categoria: Category) {
		return this.categories.filter(category => category.parentId == categoria.id)
	}
	public changeCategory(event) {
		if (event.target) {
			this.category = this.categories.filter(category => category.name === event.target.innerText)[0];
		}
		if (window.innerWidth < 960) {
			this.stopClickPropagate(event);
		}
	}
	public remove(product) {
		const index: number = this.appService.Data.cartList.indexOf(product);
		if (index !== -1) {
			this.appService.Data.cartList.splice(index, 1);
			this.appService.Data.totalPrice = this.appService.Data.totalPrice - (product.precioecommerce * product.enCarrito);
			this.appService.Data.totalCartCount = this.appService.Data.totalCartCount - product.enCarrito;
			this.appService.resetProductCartCount(product);
			this.appService.salvarCarrito();
		}
	}
	public clear() {
		localStorage.removeItem('carrito');
		this.appService.Data.cartList.forEach(product => {
			this.appService.resetProductCartCount(product);
		});
		this.appService.Data.cartList.length = 0;
		this.appService.Data.totalPrice = 0;
		this.appService.Data.totalCartCount = 0;
	}
	public changeTheme(theme) {
		this.settings.theme = theme;
	}
	public stopClickPropagate(event: any) {
		event.stopPropagation();
		event.preventDefault();
	}
	public scrollToTop() {
		const scrollDuration = 1500;
		const scrollStep = -window.pageYOffset / (scrollDuration / 20);
		const scrollInterval = setInterval(() => {
			if (window.pageYOffset !== 0) {
				window.scrollBy(0, scrollStep);
			} else {
				clearInterval(scrollInterval);
			}
		}, 10);
		if (window.innerWidth <= 768) {
			setTimeout(() => { window.scrollTo(0, 0); });
		}
	}

	/* @HostListener('window:scroll', ['$event'])
	// NO BORRAR, esto hace que se oculte el menú al hacer scroll
	onWindowScroll($event) {
		let anterior = this.verLayer;
		this.verLayer = window.scrollY;

		const elemento_amarillo = document.getElementById("conjunto-menu")
		elemento_amarillo.className = "";

		if (this.verLayer >  100) {
			elemento_amarillo.classList.add("animate__animated", "animate__slideOutUp", "animate__faster")
			if(this.verLayer < anterior) {
				elemento_amarillo.className = "";
				elemento_amarillo.classList.add("animate__animated", "animate__slideInDown", "animate__faster")
			}
		} else{
			elemento_amarillo.classList.add("animate__animated", "animate__slideInDown", "animate__faster")
		}		
		($event.target.documentElement.scrollTop > 200) ? this.showBackToTop = true : this.showBackToTop = false;
	} */

	@HostListener('window:scroll', ['$event'])
	onWindowScroll($event) {
		this.showBackToTop = ($event.target.documentElement.scrollTop > 300)
		// ($event.target.documentElement.scrollTop > 200) ? this.showBackToTop = true : this.showBackToTop = false;
	}


	public closeSubMenus() {
		if (window.innerWidth < 960) {
			this.sidenavMenuService.closeAllSubMenus();
		}
	}
	irAHome(){
		this.laciudad = this.appService.getCiudadActual()
		// console.log("#", this.laciudad)
		// this.router.navigate([`/${this.laciudad.plano_name}`])
		this.router.navigate([`/`])
	}
	// IE Desarrollo
	WhatsApp() {
		window.open('https://api.whatsapp.com/send?phone=573176488875&text=')
	}
	verProductosResultados() {
		this.filteredOptions.subscribe((res: any) => {
			this.opciones = res;
			this.buscadorService.resultadosBuscador = true;
			this.buscadorService.opcionesResultados = this.opciones;
			
			if(this.router.url.includes('resultados')){
				this.router.navigateByUrl('/blog').then( algo => {
					if(algo){
						this.router.navigate(['/productos/1/12/resultados']);		
					}
				})
				
			} else {
				this.router.navigate(['/productos/1/12/resultados']);
			}
			this.filteredOptions = null;
			this.largocadena = 0;
			this.buscador.setValue('');
			this.cargandoProductosBuscador = false;
		})
	}
	verTerminosEnvioGratis() {
		this.modalTerminos.getModal('infoEnvioGratis').open()
	}
	verTodosEnter(event) {
		if (this.largocadena > 2 && (event.keyCode === 13 || event.key == 'Enter')) {
			this.verProductosResultados();
		}
	}
	verTodosResponsive(event) {
		if (this.largocadena > 2 && (event.keyCode === 13 || event.key == 'Enter')) {
			this.ejecutarBusquedaResponsive();
		}
	}
	ejecutarBusqueda() {
		let cadenaBuscada = this.buscador.value
		this.largocadena = cadenaBuscada.length
		if (cadenaBuscada.length > 2) {
			this.filteredOptions = null;
			this.largocadena = 0;
			this.buscarCadena(cadenaBuscada).subscribe((productsFound: any[]) => {
				productsFound.forEach(prod => {
					prod['show'] = false
				})
				this.filteredOptions = of(productsFound)
			});
		}
	}
	ejecutarBusquedaResponsive() {
		console.log(this.buscadorResponsive.value)
		if (this.buscadorResponsive.value && this.buscadorResponsive.value.length > 2) {
			let cadenaBuscada = this.buscadorResponsive.value;
			this.largocadena = cadenaBuscada.length
			if (cadenaBuscada.length > 2) {
				this.filteredOptions = null;
				this.largocadena = 0;
				this.buscarCadena(cadenaBuscada).subscribe((productsFound: any[]) => {
					console.log("encontré productos =?", productsFound)
					productsFound.forEach(prod => {
						prod['show'] = false
					})
					this.filteredOptions = of(productsFound)
					this.verProductosResultados();
				});
			}
		} else {
			console.log('no hay cadena')
		}
	}
	buscarPalabraEnArregloObjetos(arreglo: any[], palabra: string) {
		return arreglo.find(o => o.nombre.search(palabra))
	}
	scrollOpt(evento) {
		console.log('move', evento)
	}
	ocultarMenu() {
		/* if (window.innerWidth <= 600) {
			let prevScrollPos = window.pageYOffset;
			window.onscroll = function () {
				let currentScrollPos = window.pageYOffset;
				if (prevScrollPos >  currentScrollPos) {
					document.getElementById('conjunto-menu').style.top = "0";
				} else {
					document.getElementById('conjunto-menu').style.top = "-100%";
				}
				prevScrollPos = currentScrollPos;
			}
		} */
	}
	navegarAProducto (producto: any) {
		this.cargandoProductosBuscador = false;
		this.router.navigate(['/productos/1/12/producto/', producto.idsap])
	}
	getposicionBuscador() {
		var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
		var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
		let lupita = w > 600 ? document.getElementById('icono-lupa') : document.getElementById('icono-lupa-xs');
		let posx = lupita.offsetLeft;
		let posy = lupita.offsetTop;
		let img_globito = new Image();
		img_globito.setAttribute('id', 'globito');
		img_globito.style.position = 'fixed';
		img_globito.style.top = posy + 'px';
		img_globito.style.left = posx + 'px';
		img_globito.setAttribute('src', 'https://www.iegrupo.co/pdf/aviso-buscador.png');
		img_globito.style.zIndex = '999';
		document.body.appendChild(img_globito);
	}
	animarLupaXs (estado: boolean) {
		/* if (estado) {
			document.getElementById('icono-lupa-xs').animate([
				{ 'opacity': '1' },
				{ 'opacity': '0.5' }
			], {
				duration: 1000,
				iterations: Infinity
			})
		} else {
			document.getElementById('icono-lupa-xs').animate([
				{ 'opacity': 1 },
				{ 'opacity': 1 }
			], {
				duration: 1000,
				iterations: Infinity
			})
		} */
	}
	verModalRegistro(): void {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = false;
		dialogConfig.autoFocus = true;
		dialogConfig.panelClass = 'registro-modal';
		dialogConfig.height = 'auto';
		const dialogReg = this.dialogoRegistro.open(DialogoRegistroComponent, dialogConfig);
		/* dialogReg.afterClosed().subscribe(result => {
			if(!localStorage.getItem('ciudadActual')){
				this.verModalCiudades();
			}
		}) */
	}
	verHojaLateral(): void {
		/* this.hojaAux.closeAll(); */
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = false;
		dialogConfig.autoFocus = false;
		dialogConfig.hasBackdrop = false;
		dialogConfig.panelClass = 'hoja-lateral';
		dialogConfig.height = 'auto';
		dialogConfig.scrollStrategy = this.overlay.scrollStrategies.noop()
		const dialogReg = this.hojaLateral.open(HojaLateralComponent, dialogConfig);
	}
	
	verModalaux() {
		this.hojaAux.closeAll();
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.panelClass = 'registro-modal';
		dialogConfig.height = 'auto';
		const dialogReg = this.hojaAux.open(ModalRegistroComponent, dialogConfig);
	}
	verModalPagos(url: string) {
		this.hojaAux.closeAll();
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.panelClass = 'pagos-modal';
		dialogConfig.height = 'auto';
		dialogConfig.data =  url
		const dialogReg = this.hojaAux.open(ModalPagosComponent, dialogConfig);
	}
	verModalCiudades(){
		this.appService.verModalCiudades()
		this.laciudad = this.appService.ciudadActual	
	}
	
	
}
