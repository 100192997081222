import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tecnologia',
  templateUrl: './tecnologia.component.html',
  styleUrls: ['./tecnologia.component.scss']
})
export class TecnologiaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  iniciarChatWhatsapp(){
	window.open('https://api.whatsapp.com/send?phone=573176488875&text=')
  }

}
