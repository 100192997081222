import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import moment from 'moment';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
/* import { WINDOW } from '@ng-toolkit/universal'; */

import { Globals } from './../../../../globals';
import { IeAuthService } from '../../../../services/ieauth.service';
import { AppService } from '../../../../app.service';
import { MarcaService } from '../../../../services/marca.service';
import { emailValidator } from './../../../../theme/utils/app-validators';

import { S3, Config } from 'aws-sdk';

// modal producto dummy
export interface DialogData {
	producto: any,
}
@Component({
	selector: 'app-productodummy-modal',
	templateUrl: 'modalProductoDummy.html',
	styles: [
		'* { line-height: 1; display:block;}'
	]
})
export class ProductoDummyComponent {

	formRegistro: UntypedFormGroup;
	mensajeError: string = '';


	constructor(
		public dialogRef: MatDialogRef<ProductoDummyComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData,
		public appService: AppService,
		private formBuilder: UntypedFormBuilder,
	) {
		this.formRegistro = this.formBuilder.group({
			'idsap': ['001-Cotización', Validators.compose([Validators.required, Validators.minLength(3)])],
			'nombre': ['', Validators.compose([Validators.required, Validators.minLength(3)])],
			'marca': ['', Validators.compose([Validators.required, Validators.minLength(3)])],
			'referencia': ['', Validators.compose([Validators.required, Validators.minLength(3)])],
			'cantidad': ['', Validators.required],
			'vlrcont': ['', Validators.compose([Validators.required, Validators.minLength(3)])],
			'dctcont': 0,
			'vlrnego': ['', Validators.compose([Validators.required, Validators.minLength(3)])],
			'dctnego': 0,
			'observaciones': '',
			'tiempoentrega': ['A CONVENIR']
		});
	}
	onNoClick(): void {
		this.dialogRef.close(null);
	}
	onYesClick(): void {
		this.dialogRef.close(this.formRegistro)
	}
	agregarDummy (e) {
		this.data.producto = e
		this.dialogRef.close()
	}
}
// fin modal producto dummy

@Component({
	selector: 'app-cotizaciones',
	templateUrl: './cotizaciones.component.html',
	styleUrls: ['./cotizaciones.component.scss']
})
export class CotizacionesComponent implements OnInit {
	adcnClienteActual
	negociacion = -1
	nombreCliente = '';
	cliente
	comercial
	cotizacion: any[];
	prodNeg
	sku
	producto
	productoElegido
	cantidadProducto: number = 0
	valorProducto: number;
	tipopago: boolean = false
	negociacionActiva
	marcas
	marcaTemp: any
	observacionesProducto = ''
	sedesBodegas: any
	tiempoEntrega: string = ''

	// valores
	subtotal: number = 0;
	flete: number = 0;
	iva: number = 0;
	grantotal: number = 0;

	// fecha actual
	fechaElaboracion = moment().format('L');
	fechaVencimiento = moment().add(8, 'days').format('L');

	// id de la cotizacion guardada
	idcotizacion

	// formulario envio cotizacion
	envioHabilitado: boolean = false;
	formEnvioCot: UntypedFormGroup;
	archivoTemp: FileList;
	archivoCot
	nombreArchivoCot

	// devs
	cantidadDeseada: number = 0;
	agregable: boolean = false;
	elArchivo

	// productos-bodegas
	productosBodegas: any[];
	stockLocal = 0

	@ViewChild('content')content: ElementRef

	constructor(
		/* @Inject(WINDOW) private window: Window, */
		private http: HttpClient,
		private globals: Globals,
		private ieAuthService: IeAuthService,
		private appService: AppService,
		private marcaService: MarcaService,
		public dialogDummy: MatDialog,
		private formBuilder: UntypedFormBuilder,
		private snackBar: MatSnackBar,
		private router: Router,
	) {
		this.comercial = this.ieAuthService.usuario
		this.http.get(`https://iegrupo.bitrix24.com/rest/244/o18hhs0jj095hc3x/user.get.json?EMAIL=${this.ieAuthService.getCurrentUser().email}`).subscribe((res: any) => {
			this.comercial.b24 = res.result[0];
			this.marcaService.getMarcasActivas().subscribe( marcas => {
				this.marcas = marcas;
				this.appService.getSedes().subscribe( resbodegas => {
					this.sedesBodegas = resbodegas;
				})
			})
		})
		// console.log('el comercial', this.comercial)
		if (history.state.data && history.state.data.idsap) {
			this.adcnClienteActual = history.state.data.idsap
			this.cargarCliente(history.state.data.idsap.split("-").pop());
		}
		this.formEnvioCot = this.formBuilder.group({
			'email': [ this.cliente ? this.cliente.idsapusuario : '' , Validators.compose([Validators.required, emailValidator])],
			'comentarios': ['En respuesta a su amable solicitud adjuntamos cotización. Estamos atentos a cualquier duda.', Validators.required],
			/* 'documento': ['', Validators.required], */
		});
	}
	ngOnInit() {
		window.scrollTo(0, 0);
		this.cotizacion = [];
	}

	cargarCliente (adcn) {
		let cadena = 'ADCN-' + adcn;
		this.http.post(this.globals.urlApi + 'usuario-comercial', { idsap: cadena, email: this.comercial.email} ).subscribe( (cliente: any) => {
			if (cliente && cliente[0]) {
				this.negociacion = cliente[0].dias;
				this.nombreCliente = cliente[0].nombre;
				this.cliente = cliente[0];
				this.formEnvioCot.controls.email.setValue(this.cliente.email);
				// this.valorProducto = this.negociacionActiva ? this.prodNeg[this.validarRangoNegociacion(this.negociacion)] : this.prodNeg['contado']
			} else {
				this.negociacion = -1;
				this.nombreCliente = '';
				this.cotizacion = [];
				this.cliente = [];
				this.appService.mensajeSnackBar('No se encontró o no existe el cliente, verifique la información', 3000, 'error', 'x');
			}

		})
	}
	elegirArchivo(e) {
		this.archivoTemp = e.target.files
		this.archivoCot = this.archivoTemp.item(0)
	}
	buscarSku (sku) {
		this.limpiarProducto();
		this.http.post(this.globals.urlApi + 'negociaciones/producto', {sku: sku}).subscribe( (productoNegociacion: any) => {
			if ( productoNegociacion && productoNegociacion[0] ) {
				this.prodNeg = productoNegociacion[0];
				this.http.get(this.globals.urlApi + 'productos/' + this.prodNeg.idsap).subscribe( (resdatProd: any) => {
					this.producto = resdatProd[0];
					this.marcaService.getMarcaIdsap(this.producto.idmarca).subscribe(marca => {
						this.marcaTemp  = marca[0];
						this.http.get(this.globals.urlApi + 'productos-bodegas/' + sku).subscribe((info: any[]) => {
							this.productosBodegas = info;
							this.stockPorCiudad();
						})
					})
				})
			}
		})
	}
	limpiarProducto () {
		this.prodNeg = null;
		this.producto = null;
		this.cantidadProducto = 0;
		this.cantidadDeseada = 0;
	}
	agregarACotizacion () {
		this.productoEnBodegaDeComercial()
	}
	removerDeCotizacion(objeto) {
		// console.log('le remove', objeto)
	}
	agregarStock(stock, e) {
		if (e.shiftKey && (this.cantidadProducto + 10) <= stock ) {
			this.cantidadProducto += 10;
		} else {
			this.cantidadProducto++;
		}
		if (this.cantidadProducto <= stock) {
			this.agregable = true
		} else {
			this.agregable = false
		}
	}
	removerStock(stock, e) {
		if (this.cantidadProducto > 0) {
			if (e.shiftKey && this.cantidadProducto >= 10) {
				this.cantidadProducto -= 10;
			} else {
				this.cantidadProducto--;
			}
		} else {
			this.agregable = false
		}
	}
	validarRangoNegociacion(dias) {
		if (this.negociacion != -1) {
			switch (true) {
				case (dias >= 0 && dias <= 8):
					return 'contado';
				case (dias >= 9 && dias <= 45):
					return 'treinta';
				case (dias >= 46 && dias <= 60):
					return 'sesenta';
				case (dias >= 61 && dias <= 90):
					return 'noventa';
				case (dias >= 90 && dias != 120):
					return 'noventamas';
				case (dias == 120) :
					return 'cientoveinte';
				default:
					break;
			}
		}
	}
	onChangeToogle(e) {
		this.calcularValores(this.cotizacion)
	}
	verProductoElegido ( producto ) {
		this.productoElegido = producto;
		this.buscarSku(producto.idsap);
	}
	getMarca(idmarca) {
		let marcap = this.marcas.filter(marca => marca.idsap == idmarca)
		return marcap;
	}
	calcularValores( arreglo: any[] ) {
		let acumSubNeg = 0;
		let ivaNeg = 0;
		let acumSubCon = 0;
		let ivaCon = 0;

		arreglo.forEach(producto => {
			acumSubNeg += ( producto.vlrnego * producto.cantidad )
			acumSubCon += ( producto.vlrcont * producto.cantidad )
		});

		ivaNeg = this.porcentaje(acumSubNeg + this.flete, 19);
		ivaCon = this.porcentaje(acumSubCon + this.flete, 19);

		if (this.negociacionActiva) {
			this.subtotal = acumSubNeg;
			this.iva = ivaNeg;
		} else {
			this.subtotal = acumSubCon;
			this.iva = ivaCon;
		}
		this.grantotal = this.subtotal + this.iva + this.flete
	}
	porcentaje (valor, porcentaje) {
		return (valor / 100) * porcentaje;
	}
	agregarFlete ( valorFlete: number) {
		this.flete = valorFlete
		this.calcularValores(this.cotizacion)
	}
	verFormularioDummy () {
		let opt: any
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.panelClass = 'registro-modal';
		dialogConfig.height = 'auto';
		dialogConfig.data = {
			producto: opt
		};
		const dialogReg = this.dialogDummy.open(ProductoDummyComponent, dialogConfig);
		dialogReg.afterClosed().subscribe(result => {
			console.log('le result', result)
			if (result) {
				this.cotizacion.push(dialogConfig.data.producto);
			}
			this.calcularValores(this.cotizacion);
		})
	}
	guardarCotizacion () {
		this.http.put(this.globals.urlApi + 'cotizaciones', {
			datos: this.cotizacion,
			fechacreacion: moment(),
			fechavencimiento: moment().add(8, 'days'),
			correonotificacion: this.cliente.email,
			valor: this.grantotal,
			idsapusuario: this.cliente.idsap,
			idcomercial: this.comercial.id,
		}).subscribe((resultado: any) => {
			if (resultado) {
				this.envioHabilitado = true
				this.idcotizacion = resultado.id
				this.nombreArchivoCot = 'Cot_' + resultado.id + String(resultado.idsapusuario).split('-')[1] + resultado.idcomercial + '.pdf';
				this.generarPDF();
			} else {
				this.envioHabilitado = false
				console.log('resultado al agregar cotizacion', resultado)
			}
		});
	}
	enviarCotizacion (e) {
		let datosComplentariosBD = {
			id : this.idcotizacion,
			documento: 'https://iecotizaciones.s3.amazonaws.com/' + this.nombreArchivoCot,
			comentarios: e.comentarios,
			correonotificacion: e.email
		}
		this.http.patch(this.globals.urlApi + 'cotizaciones', datosComplentariosBD).subscribe( (resUpdate: any) => {
			console.log('patch 349', resUpdate)
			this.http.get(this.globals.urlApi + 'cotizaciones/' + this.idcotizacion).subscribe( (resCotizacion: any) => {
				console.log('resCotizacion 351', resCotizacion)
				let datosPlantilla = {
					emailDestino: e.email, // no necesariamente es la del cliente
					comentarios: e.comentarios,
					comercial: this.comercial,
					cliente: this.cliente,
					urlPDF: 'https://iecotizaciones.s3.amazonaws.com/' + this.nombreArchivoCot,
				}
				this.http.post(this.globals.urlApi + 'mensajero-ie', {datosPlantilla}).subscribe( (resapi: any) => {
					if ( resapi.status !== 'error' ) {
						this.snackBar.open('Correo enviado', '×', { panelClass: ['success'], verticalPosition: 'top', duration: 2000 });
						this.cliente = null;
						this.router.navigate(['/cuenta/comercial/historial-comercial']);
					} else {
						this.snackBar.open(resapi.mensaje, '×', { panelClass: ['error'], verticalPosition: 'top', duration: 2000 });
					}
				})
			})
		})
	}
	async subirArchivo () {
		// await this.getPdfFile();
		// this.elArchivo = this.getPdfFile();
		// console.log('learchivo', this.elArchivo);
		let bucket: S3 = new S3({params: {Bucket: 'iecotizaciones'}});
		let config = new  Config({
			accessKeyId: 'AKIAYH47G72QRPRCNL4T',
			secretAccessKey: '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',
			region: 'us-east-1',
			apiVersion: '2006-03-01',
		});

		bucket.config = config
		let params = {
			Bucket: 'iecotizaciones',
			Key:  this.nombreArchivoCot,
			Body: this.elArchivo, // this.archivoCot,
			ACL: 'public-read'
		};

		await bucket.upload(params, function(err, data) {
			if (err) {
				return false;
			}
			return true;
		})
	}
	productoEnBodegaDeComercial () {
		let bodegas = [] // bodegas de las que dispone el comercial en la ciudad
		let sedesComercial = this.sedesBodegas.filter((sede: any) => {
			let ciudadSede = sede.ciudad.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
			let ciudadComercial = this.comercial.b24.PERSONAL_CITY.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
			return ciudadSede == ciudadComercial
		})
		sedesComercial.forEach(sede => {
			sede.bodegas.forEach(bodega => {
				bodegas.push(bodega.idsap)
			});
		});
		this.http.get(this.globals.urlApi + 'producto-bodegas/' + this.producto.idsap).subscribe( (res: any[]) => {
			if (res.length > 0 ) {
				/* console.log('bodegas donde el comercial puede hechar mano ===>', bodegas)
				console.log('bodegas donde hay stock del producto', res);
				console.log('informacion del comercial', this.comercial) */
				for (let index = 0; index < res.length; index++) {
					const element = res[index];
					if (bodegas.includes(element.bodega) && this.cantidadDeseada <= this.stockLocal) {
						this.tiempoEntrega = "INMEDIATO SALVO/VP";
						break;
					} else {
						this.tiempoEntrega = "A CONVENIR"
					}
				}
			}
			let valorContado = this.prodNeg['contado']
			let valorNegociacion = this.prodNeg[this.validarRangoNegociacion(this.negociacion)]
			let prodDeCot = {
				idsap: this.producto.idsap,
				nombre: this.producto.nombre,
				cantidad: this.cantidadProducto,
				marca: this.marcaTemp.nombre,
				referencia: this.producto.reffab,
				vlrcont: valorContado,
				dctcont: 0,
				vlrnego: valorNegociacion,
				dctnego: 0,
				observaciones: this.observacionesProducto,
				tiempoentrega: this.tiempoEntrega
			}
			this.cotizacion.push(prodDeCot);
			this.sku = '';
			this.observacionesProducto = '';
			prodDeCot = null;
			this.calcularValores(this.cotizacion)
			this.limpiarProducto();
		})
	}
	public verificarStock(cant) {
		if (!cant) {
			cant = 0
		}
		this.cantidadProducto = cant
		if (this.producto) {
			if (this.cantidadProducto > this.producto.stock) {
				this.agregable = false
			} else {
				this.agregable = true
			}
		} else {
			console.log('no hay producto para comparar el stock')
		}
	}
	public stockPorCiudad () {
		let bodegas
		let stock = 0;
		bodegas = this.productosBodegas.filter( opcion => opcion.info_bodega.ciudad === this.comercial.ciudad)
		bodegas.forEach(element => {
			stock += element.stock
		});
		this.stockLocal = stock
	}
	generarPDF () {
		let objetoPDF = {
			cliente: this.cliente,
			comercial: this.comercial,
			datos: this.cotizacion,
			fechacreacion: moment(),
			fechavencimiento: moment().add(8, 'days'),
			correonotificacion: this.cliente.email,
			subtotal: this.subtotal,
			flete: this.flete,
			iva: this.iva,
			total: this.grantotal,
			idsapusuario: this.cliente.idsap,
			idcomercial: this.comercial.id,
			idcotizacion: this.idcotizacion,
			filename: this.nombreArchivoCot
		}
		this.http.post(this.globals.backPDF + 'crearpdf.php', {objeto: objetoPDF}).subscribe( (resultado: any) => {
			// aqui hay que subir el archivo creado a S3
			if (resultado.status == 'OK') {
				this.pdf2S3(this.nombreArchivoCot);
			} else {
				this.snackBar.open(resultado.mensaje, '×', { panelClass: 'error', verticalPosition: 'top', duration: 2000 });
			}
		})
	}
	pdf2S3(nombreArchivo) {
		this.http.post(this.globals.backAws + 'subirArchivo.php', {nombreArchivo}).subscribe( (res: any) => {
			if (res.status == 'OK') {
				this.http.patch(this.globals.urlApi + 'cotizaciones', {id: this.idcotizacion, documento: res.data}).subscribe( (resUpdate: any) => {
					console.log('añadido documento', resUpdate)
				})
			}
		});
	}
}
