import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Globals } from '../globals';

@Injectable({
	providedIn: 'root'
})
export class SubirarchivosService {
	url: string;

	constructor(private http: HttpClient, private globals: Globals) {
		this.url = this.globals.backServiciosECommerce + 'cotizaciones/subirArchivo.php'
	}

	subirArchivo(datos : any): Observable<any> {
		return this.http.post(this.url, datos);
	}
}
