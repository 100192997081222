import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Globals } from '../globals';


@Injectable({
	providedIn: 'root'
})
export class UsuarioService {
	constructor(
		private globals: Globals,
		private http: HttpClient
	) {}

	getUsuarioSAP(correo: string): Observable<any> {
		// const url = `${this.usuarioUrl}usuarios/correo=${correo}`;
		const url = `${this.globals.urlApi}v2/clientes/cliente/correo/${correo}`;
		return this.http.get<any[]>(url);
	}
}
