import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Settings, AppSettings } from './app.settings';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AppService } from './app.service';
import { CanonicalService } from './services/canonical.service';
import { TagesService } from './services/tages.service';
import { Overlay } from '@angular/cdk/overlay';

declare var gtag: Function;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	animations: [
		
	]
})
export class AppComponent {
	loading: boolean = false;
	mensajeCarga: string = '';
	public settings: Settings;
	// verEngranes = true
	@ViewChild('recaptcha', {static: true }) recaptchaElement: ElementRef

	favIcon: HTMLLinkElement = document.querySelector('#appIcon');

	constructor(
		public appSettings: AppSettings,
		public router: Router,
		public ngxSmartModalService: NgxSmartModalService,
		public appService: AppService,
		private canonical : CanonicalService,
		private tagesService: TagesService,
		private overlay: Overlay
	) {
		this.setFavIcon()
		this.overlay.scrollStrategies.reposition()
		this.settings = this.appSettings.settings;
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				/* gtag('config', 'UA-139557387-1', {
					'page-path': event.urlAfterRedirects
				}) */
				let cadenaBase = 'https://www.iegrupo.co/#';
				let temp = event.url.split("/")
				let imagen = `https://s3.amazonaws.com/iecommerce2019/${temp[5]}/${temp[5]}.jpg`;
				this.tagesService.setTitle('Internacional de Eléctricos')
				this.tagesService.setSocialMediaTags(
					cadenaBase + event.urlAfterRedirects ,
					'Internacional de Eléctricos',
					'Brindamos asesoría, diseño y suministro para proyectos eléctricos, de telecomunicaciones, seguridad electrónica, control, vigilancia & confort, iluminación, generación y transporte de energía.',
					imagen
				);
			}
		})
	}
	ngOnInit() {
		this.canonical.createCanonicalLink();
		this.mensajeCarga = this.appService.mensajeCarga
	}
	ngAfterViewInit() {
	}
	onActivate(event) {
		let scrollToTop = window.setInterval(() => {
			let pos = window.pageYOffset;
			if (pos > 0) {
				window.scrollTo(0, pos - pos); // how far to scroll on each step
			} else {
				window.clearInterval(scrollToTop);
			}
		}, 1);
		window.scroll(0, 0);
	}

	setFavIcon (){
		let mes = new Date().getMonth() + 1
		switch (mes) {
			case 12:
				this.favIcon.href = '../../assets/images/favicons/favicon-dic.png'
				break;
			default:
				this.favIcon.href = '../../assets/images/favicons/favicon.png'
				break;
		}
	}


	limpiarModalTra() {
		this.ngxSmartModalService.resetModalData('infoTransaccion');
	}
	limpiarModalPed() {
		this.ngxSmartModalService.resetModalData('infoPedido');
	}
	verDetallesPedido(pedido?: any): void {
		this.ngxSmartModalService.getModal('infoTransaccion').close()
		this.ngxSmartModalService.setModalData(pedido, 'infoPedido')
		this.ngxSmartModalService.getModal('infoPedido').open()
	}
	cerrarModal(nombreModal) {
		this.ngxSmartModalService.getModal(nombreModal).close();
	}
}
