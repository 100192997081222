/* import { WINDOW } from '@ng-toolkit/universal'; */
import { Component, Input, Output, EventEmitter , Inject, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { HttpClient } from '@angular/common/http';

import { BuscadorService } from '../../services/buscador.service';

@Component({
	selector: 'app-category-list',
	templateUrl: './category-list.component.html',
	styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent {

	@Input() categories;
	@Input() categoryParentId;
	@Output() change: EventEmitter<any> = new EventEmitter();
	
	mainCategories: any [];
	infoCategory;

	@ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

	constructor(
		/* @Inject(WINDOW) private window: Window, */
		private buscador: BuscadorService,
		private router: Router,
		private http: HttpClient
	) {
		this.mainCategories = this.categories
	}

	public ngDoCheck() {
		if (this.categories && !this.mainCategories) {
			this.mainCategories = this.categories.filter(category => category.parentId == this.categoryParentId);
			this.mainCategories.forEach(categoria => {
				categoria['show'] = false;
			})
		}
	}

	public stopClickPropagate(event: any) {
		if (window.innerWidth < 960) {
			event.stopPropagation();
			event.preventDefault();
		}
	}

	public changeCategory(event) {
		this.change.emit(event);
	}
	public actualizarBuscadorCategoria(idcategoria: number) {
		console.log("idcategoria ==> ", idcategoria)
		
		
		this.buscador.productosPorCategoria(idcategoria);
		this.router.navigateByUrl('/chat', {skipLocationChange: true}).then((paso) => {
			if(paso) {
				this.router.navigate(['/productos/1/12'])
			}
		});
	}
	navegarACategoria (idcategoria){
		// this.http.get()
	}
	impresion () {
		console.log("OXO")
		this.closeMyMenu()
		this.openMyMenu()
	}

	openMyMenu() {
		this.trigger.openMenu();
	  } 
	closeMyMenu() {
		this.trigger.closeMenu();
	  }
	  hostClickHandler(item: string) {
		alert(item);
	  }
	quecono(npi: MatMenu) {
		this.trigger.toggleMenu()
		
		console.log("npi", npi)
	}

}
