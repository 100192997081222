import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BuscadorService } from '../../services/buscador.service';
import { Globals } from '../../globals';
import { AppService } from '../../app.service';

import SwiperCore, { SwiperOptions, Autoplay, Pagination, Navigation } from 'swiper';
SwiperCore.use([Navigation, Autoplay, Pagination]);



@Component({
	selector: 'app-main-carousel',
	templateUrl: './main-carousel.component.html',
	styleUrls: ['./main-carousel.component.scss']
})
export class MainCarouselComponent implements OnInit {
	@Input('slides') slides: Array<any> = [];
	mercados_entregados: number = 0
	banner1
	banner2
	banner3
	ciudad;

	config:SwiperOptions = {
		slidesPerView: 1,
		spaceBetween: 1,
		keyboard: true,
		navigation: true,
		pagination: true,
		grabCursor: true,
		loop: true,
		/* preloadImages: true, */
		lazy: true,
		enabled: true,
		autoplay: {
			delay: 6000,
			disableOnInteraction: false
		},
		speed: 500,
		effect: "slide"
	}

	constructor(
		private router: Router,
		private buscadorService: BuscadorService,
		private http: HttpClient,
		private globals: Globals,
		private appService: AppService
		) {
			
		}

	ngOnInit() {
		this.slides = this.ordenarBanners(this.slides)
		this.getMercadosEntregados()
		this.getBanner1()
		this.getBanner2()
		this.getBanner3()
	}
	
	click(slide) {
		this.buscadorService.objetoBuscadorProductos.categoria = slide.idCategoria;
		this.router.navigate(['/productos', 1, 12]);
	}
	verCategoria (categoria) {
		this.buscadorService.objetoBuscadorProductos.categoria = categoria;
		this.router.navigate(['/productos', 1, 12]);
	}
	verMarca(marca) {
		this.buscadorService.objetoBuscadorProductos.marca = marca;
		this.router.navigate(['/productos', 1, 12]);
	}
	verLink(link) {
		this.router.navigate([link]);
	}
	getMercadosEntregados (){
		this.http.get(this.globals.urlApi + 'mercados-entregados').subscribe((data: any) => {
			this.mercados_entregados = data.entregados
		})
	}
	getBanner1() {
		this.appService.compartidor.subscribe(ciudad => {
			this.http.post(this.globals.urlApi + 'banner', {sys_descripcion: "Home-1", ciudad: ciudad?.plano_name}).subscribe( (res: any) => {
				if (res){
					this.banner1 = res[0]
					/* this.banner1.imagen = 'https://media.giphy.com/media/6901DbEbbm4o0/giphy.gif'
					console.log(" tipo de archivo", this.banner1.imagen.split(/[#?]/)[0].split('.').pop().trim()) */
				}
			}, err =>{
				this.http.get(this.globals.urlApi + 'banner/2').subscribe( (res: any) => {
					if (res){
						this.banner1 = res
					}
				})
			})
		})	
	}
	getBanner2() {
		this.appService.compartidor.subscribe(ciudad => {
			this.http.post(this.globals.urlApi + 'banner', {sys_descripcion: "Home-2", ciudad: ciudad?.plano_name}).subscribe( (res: any) => {
				if (res){
					this.banner2 = res[0]
				}
			}, err =>{
				this.http.get(this.globals.urlApi + 'banner/3').subscribe( (res: any) => {
					if (res){
						this.banner2 = res
					}
				});
			})
		})
	}
	getBanner3() {
		this.appService.compartidor.subscribe(ciudad => {
			this.http.post(this.globals.urlApi + 'banner', {sys_descripcion: "Home-3", ciudad: ciudad?.plano_name}).subscribe( (res: any) => {
				if (res){
					this.banner3 = res[0]
				}
			}, err =>{
				this.http.get(this.globals.urlApi + 'banner/3').subscribe( (res: any) => {
					if (res){
						this.banner3 = res
					}
				})
			});
		})
	}

	evaluarLink(link:string ) {
		if (link.indexOf("https") !== -1) {
			window.open(link, "_blank");
		} else {
			this.router.navigate([link])
		}
	}
	// ordenar banners
	ordenarBanners(arreglo: any[]){
		return arreglo.sort((a, b) =>{
			if(a.orden > b.orden){
				return 1;
			}
			if(a.orden < b.orden){
				return -1;
			}
			return 0;
		})
	}
}
