import { Component, Input, OnInit } from '@angular/core';

import SwiperCore, { SwiperOptions, Autoplay, Pagination, Navigation } from 'swiper';
SwiperCore.use([Navigation, Autoplay, Pagination]);


import { MarcaService } from '../../services/marca.service';
import { Marca } from '../../models/marca';
import { Router } from '@angular/router';
import { BuscadorService  } from '../../services/buscador.service';
import { AppService } from '../../app.service';

@Component({
	selector: 'app-brands-carousel',
	templateUrl: './brands-carousel.component.html',
	styleUrls: ['./brands-carousel.component.scss'],
})
export class BrandsCarouselComponent implements OnInit {
	config:SwiperOptions = {
		observer: true,
		slidesPerView: 6,
		spaceBetween: 15,
		keyboard: true,
		navigation: true,
		pagination: false,
		grabCursor: true,
		loop: true,
		preloadImages: false,
		lazy: true,
		autoplay: {
			delay: 4000,
			disableOnInteraction: false
		},
		speed: 500,
		effect: "slide",
		breakpoints: {
			320: {
				slidesPerView: 1
			},
			480: {
				slidesPerView: 2
			},
			600: {
				slidesPerView: 3,
			},
			960: {
				slidesPerView: 4,
			},
			1280: {
				slidesPerView: 5,
			},
			1500: {
				slidesPerView: 6,
			}
		}
	}
	
	@Input('brands') brands: Array<Marca> = [];

	constructor(private router: Router, private buscador: BuscadorService, private marcaService: MarcaService, appService: AppService) {
	}
	ngOnInit() {

	}
	verProductosMarca(idsapmarca: number) {
		this.buscador.productosPorMarca(idsapmarca)
		console.log(this.buscador)
		this.router.navigate(['productos', 1, 12]);
	}
}
