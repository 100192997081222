import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Globals } from '../../globals';
import SwiperCore, { SwiperOptions, Autoplay, Pagination, Navigation } from 'swiper';
import { Router } from '@angular/router';

SwiperCore.use([Navigation, Autoplay, Pagination]);

@Component({
	selector: 'app-top-carousel',
	templateUrl: './top-carousel.component.html',
	styleUrls: ['./top-carousel.component.scss']
})
export class TopCarouselComponent implements OnInit {
	slides: Array<any> = [];
	
	config: SwiperOptions = {
		slidesPerView: 1,
		spaceBetween: 10,
		keyboard: true,
		navigation: false,
		pagination: false,
		grabCursor: true,
		loop: false,
		preloadImages: true,
		lazy: true,
		autoplay: {
			delay: 6000,
			disableOnInteraction: false
		},
		speed: 500,
		effect: "slide"
	}
	@Input() ciudad: string

	constructor(
		private _router: Router,
		private http: HttpClient,
		private globals: Globals,
		
	){}

	ngOnInit(): void {
		if(!this.ciudad){
			this.ciudad = 'nacional'
		}
		this.getSlides(this.ciudad);
	}

	getSlides (ciudad){
		this.http.post( this.globals.urlApi + 'banners-top-ciudad', {ciudad: ciudad}).subscribe((resData:any)=>{
			this.slides = resData;
			this.slides = this.ordenarBanners(this.slides)
			if(this.slides.length === 0){
				this.getSlides('nacional')
			}
		})
	}
	navegarA(slide){
		if(slide.link_destino) {
			this._router.navigate([slide.link_destino]);
		}
	}

	
	ordenarBanners(arreglo: any[]){
		return arreglo.sort((a, b) =>{
			if(a.orden > b.orden){
				return 1;
			}
			if(a.orden < b.orden){
				return -1;
			}
			return 0;
		})
	}

}
