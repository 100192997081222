export class Articulo {
	id: number;
	titulo: string;
	imagen: string;
	resumen: string;
	contenido: string;
	visitas: number;
	compartido: number;
	home: boolean;
	destacado: boolean;
	link_facebook: string;
	link_instagram: string;
	link_youtube: string;
	link_linkedin: string;
	link_twitter: string;
	autor: string;
	fcreacion: string;
	url: string;
	publicar: boolean;
}
