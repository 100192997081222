import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-equipos-herramientas',
  templateUrl: './equipos-herramientas.component.html',
  styleUrls: ['./equipos-herramientas.component.scss']
})
export class EquiposHerramientasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  iniciarChatWhatsapp(){
	window.open('https://api.whatsapp.com/send?phone=573176488875&text=')
  }

}
