import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilterByIdPipe } from './filter-by-id.pipe';
import { FilterBrandsPipe } from './filter-brands.pipe';
import { BrandSearchPipe } from './brand-search.pipe';
import { FilterByCategoriaPipe } from './filter-by-categoria.pipe';
import { FilterByMarkPipe } from './filter-by-marca.pipe';
import { SafePipe } from './safe.pipe';

// pipes
// import { BodegasCercanas } from './bodegas-cercanas.pipe';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [
		FilterByIdPipe,
		FilterBrandsPipe,
		BrandSearchPipe,
		FilterByCategoriaPipe,
		FilterByMarkPipe,
		SafePipe,
	],
	exports: [
		FilterByIdPipe,
		FilterBrandsPipe,
		BrandSearchPipe,
		FilterByCategoriaPipe,
		FilterByMarkPipe,
		SafePipe
	]
})
export class PipesModule { }
