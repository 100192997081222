import { Component, Inject, OnInit } from '@angular/core';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { BarcodeFormat } from '@zxing/library';
import { BehaviorSubject, fromEvent, merge, Observable, Observer, Subscription } from 'rxjs';
import {map} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Globals } from '../../../globals';
import { IeAuthService } from '../../../services/ieauth.service';
import { SocketIoService } from '../../../services/socket-io.service';
import { AppService } from '../../../app.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExportExcelService } from '../../../services/export-excel.service';
import { Router } from '@angular/router';

// componente dialogo de filtro
/* export interface ObjetoFiltroReportes{
	codigo_almacen: string,
	fecha : Date,
	conteo: number,
}
 */
@Component({
	selector: 'dialogo-filtro-reportes',
	templateUrl: './modal_filtro_reportes.html'
})
export class DialogoFiltroReportes {
	filtroForm: UntypedFormGroup
	bodegas = []
	bodegasCopy = [];
	
	almacenElegidoModal
	mensaje
	titulo_reporte
	public campos_visibles = {
		campo_almacen_habilitado: true,
		campo_fecha_habilitado: true,
		campo_n_conteo_habilitado: true,
	}
	
	constructor(
		public dialogRef:  MatDialogRef<DialogoFiltroReportes>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private readonly formBuilder: UntypedFormBuilder,
		private readonly https: HttpClient,
		private readonly servicioExcel: ExportExcelService,
		private readonly globlas: Globals,
	){
		console.log("la data", this.data)
	}

	test(e){
		console.log(e)
	}
	ngOnInit(){
		this.setTituloReporte(this.data.reporte)
		this.bodegas = this.data.bodegas
		this.bodegasCopy = this.bodegas
		this.bodegas.sort(this.ordenarBodegasAlfaModal)
		this.filtroForm = this.formBuilder.group({
			'reporte_id': [this.data.reporte],
			'almacen': [this.almacenElegidoModal, Validators.required],
			'fecha': [''],
			'conteo': ['']
		});
	}
	cerrarModal() {
		// this.dialogRef.close({datos: this.filtroForm.value});
		this.dialogRef.close();
	}
	descargarReporte(){
		let dataForm = this.filtroForm.value
		
		switch (this.data.reporte) {
			case 1:
				this.https.post(`${this.globlas.urlApi}conteo/reporte-1/info`, {
					bodega: dataForm.almacen,
					fecha: dataForm.fecha,
					conteo: dataForm.conteo
				}).subscribe( (resData: any) => {
					if(resData.status === 'ok'){
						this.mensaje = ''
						this.servicioExcel.exportarReporte1(resData.data, dataForm.conteo, dataForm.fecha, dataForm.almacen);
						this.dialogRef.close();
					} else {
						this.mensaje = resData.mensaje
					}
				})
			break;
			case 2:
				this.https.post(`${this.globlas.urlApi}conteo/reporte-2/info`, {
					bodega: dataForm.almacen,
					fecha: dataForm.fecha,
				}).subscribe( (resData: any) => {
					if(resData.status === 'ok'){
						this.mensaje = ''
						let datos_sinceros = resData.data.filter( (el: any) => {
							if(el.diferencia != 0){
								el.diferencia = Math.abs(el.diferencia)
								return el
							}
						})
						this.servicioExcel.exportarReporte2(datos_sinceros, dataForm.almacen, dataForm.fecha);
						this.dialogRef.close();
					} else {
						this.mensaje = resData.mensaje
					}
				})
			break;
			case 3:
				this.https.post(`${this.globlas.urlApi}conteo/reporte-3/info`, {
					bodega: dataForm.almacen,
					fecha: dataForm.fecha,
				}).subscribe( (resData: any) => {
					if(resData.status === 'ok'){
						this.mensaje = ''
						this.servicioExcel.exportarReporte3(resData.data, dataForm.fecha, dataForm.almacen);
						this.dialogRef.close();
					} else {
						this.mensaje = resData.mensaje
					}
				})
			break;
			case 4:
				this.https.post(`${this.globlas.urlApi}conteo/reporte-4/info`, {
					bodega: dataForm.almacen,
					fecha: dataForm.fecha,
					conteo: dataForm.conteo
				}).subscribe( (resData: any) => {
					if(resData.status === 'ok'){
						this.mensaje = ''
						this.servicioExcel.exportarReporte4(resData.data, dataForm.fecha, dataForm.almacen);
						// this.dialogRef.close();
					} else {
						this.mensaje = "La consulta no arrojó resultados, por favor revise los filtros aplicados" // resData.mensaje
					}
				})
			break;
			case 5:
				this.https.post(`${this.globlas.urlApi}conteo/reporte-5/info`, {
					bodega: dataForm.almacen,
					fecha: dataForm.fecha,
					conteo: dataForm.conteo
				}).subscribe( (resData: any) => {
					if(resData.status === 'ok'){
						this.mensaje = ''
						this.servicioExcel.exportarReporte5(resData.data, dataForm.fecha, dataForm.almacen);
						this.dialogRef.close();
					} else {
						this.mensaje = "La consulta no arrojó resultados, por favor revise los filtros aplicados" // resData.mensaje
					}
				})
			break;
			case 6:
				this.https.post(`${this.globlas.urlApi}conteo/reporte-6/info`, {
					bodega: dataForm.almacen,
					fecha: dataForm.fecha
				}).subscribe( (resData: any) => {
					if(resData.status === 'ok'){
						this.mensaje = ''
						this.servicioExcel.exportarReporte6(resData.data, dataForm.fecha, dataForm.almacen);
						this.dialogRef.close();
					} else {
						this.mensaje = "La consulta no arrojó resultados, por favor revise los filtros aplicados" // resData.mensaje
					}
				})
			break;
			case 7:
				this.https.post(`${this.globlas.urlApi}conteo/reporte-final/info`, {
					bodega: dataForm.almacen,
					fecha: dataForm.fecha
				}).subscribe( (resData: any) => {
					if(resData.status === 'ok'){
						this.mensaje = ''
						this.servicioExcel.exportarReporte7(resData.data, dataForm.fecha, dataForm.almacen);
						this.dialogRef.close();
					} else {
						this.mensaje = "La consulta no arrojó resultados, por favor revise los filtros aplicados" // resData.mensaje
					}
				})
			break;
			default:
				break;
		}
	}
	setTituloReporte (numero){
		if(numero === 1){
			this.titulo_reporte = 'Detalle de Conteo'
			this.campos_visibles.campo_almacen_habilitado = true
			this.campos_visibles.campo_fecha_habilitado = true
			this.campos_visibles.campo_n_conteo_habilitado = true
		}
		if(numero === 2){
			this.titulo_reporte = 'Comparativo entre conteos 1 y 2'
			this.campos_visibles.campo_almacen_habilitado = true
			this.campos_visibles.campo_fecha_habilitado = true
			this.campos_visibles.campo_n_conteo_habilitado = false
		}
		if(numero === 3){
			this.titulo_reporte = 'Comparativo entre conteos 1 y 2 y 3'
			this.campos_visibles.campo_almacen_habilitado = true
			this.campos_visibles.campo_fecha_habilitado = true
			this.campos_visibles.campo_n_conteo_habilitado = false
		}
		if(numero === 4){
			this.titulo_reporte = 'Artículos con stock NO contados'
			this.campos_visibles.campo_almacen_habilitado = true
			this.campos_visibles.campo_fecha_habilitado = true
			this.campos_visibles.campo_n_conteo_habilitado = true
		}
		if(numero === 5){
			this.titulo_reporte = 'Diferencia de existencia vs conteo'
			this.campos_visibles.campo_almacen_habilitado = true
			this.campos_visibles.campo_fecha_habilitado = true
			this.campos_visibles.campo_n_conteo_habilitado = false
		}
		if(numero === 6){
			this.titulo_reporte = 'detalle de conteos 1 2 3 4'
			this.campos_visibles.campo_almacen_habilitado = true
			this.campos_visibles.campo_fecha_habilitado = true
			this.campos_visibles.campo_n_conteo_habilitado = false
		}
		if(numero === 7){
			this.titulo_reporte = 'Informe final'
			this.campos_visibles.campo_almacen_habilitado = true
			this.campos_visibles.campo_fecha_habilitado = true
			this.campos_visibles.campo_n_conteo_habilitado = false
		}
	}
	ordenarBodegasAlfaModal(a,b){
		let fa = a.idsap.toLowerCase(),
        fb = b.idsap.toLowerCase();

		if (fa < fb) {
			return -1;
		}
		if (fa > fb) {
			return 1;
		}
		return 0;
	}
	filtrandoBodegasModal(valor: string){
		this.bodegasCopy = this.bodegas.filter(el => el.idsap.search(valor.toUpperCase()) > -1)
	}
	/* setAlmacenElegeido(event){
		this.almacen_elegido = event
		this.filtroForm.patchValue({
			codigo_almacen : this.almacen_elegido.idsap
		})
	} */
}


@Component({
  selector: 'app-logistica',
  templateUrl: './logistica.component.html',
  styleUrls: ['./logistica.component.scss']
})
export class LogisticaComponent implements OnInit {
	admins = [
		'jahernandez@iegrupo.co',
		'jahernandez@iegrupo.co',
		'bagarcia@iegrupo.co',
		'larios@iegrupo.co'
	]
	soyAdmin: boolean = false

	availableDevices: MediaDeviceInfo[];
	deviceCurrent: MediaDeviceInfo;
	deviceSelected: string;
	usuario

	formatsEnabled: BarcodeFormat[] = [
		BarcodeFormat.CODE_128,
		BarcodeFormat.DATA_MATRIX,
		BarcodeFormat.EAN_13,
		BarcodeFormat.QR_CODE,
	];

	hasDevices: boolean;
	hasPermission: boolean;

	qrResultString: string;

	torchEnabled = false;
	torchAvailable$ = new BehaviorSubject<boolean>(false);
	tryHarder = false;

	letemp

	conteoSelected
	bodegas = [];
	bodegasCopy = [];
	almacenElegido
	conteosActivos = [];
	conteoForm: UntypedFormGroup

	CodigoSapBuscar!: number;
	CantidadContado: number;
	infoSkuConteo
	objetoRegConteo

	sonido = new Audio('https://www.iegrupo.co/assets/sounds/beep-2.wav');
	estadoConexion: boolean = true

	estadoConteo: Observable<any>;
	_estadoConteoSub: boolean = false;

	soyEmpleadoIe: boolean = false

	verBtnBorrarRegistro: boolean = false
	skuBorrar
	idConteoBorrar
	objetoLectorQr!: any;

	constructor(
		private readonly https: HttpClient,
		private readonly globals: Globals,
		private readonly formBuilder: UntypedFormBuilder,
		private readonly ieAuthService: IeAuthService,
		private readonly _socket: SocketIoService,
		private readonly _appService: AppService,
		public dialogo: MatDialog,
		public router: Router,
	) {
		this.usuario = this.ieAuthService.getCurrentUser();
		this.getEmpleadoIe(this.usuario.email)

		this.conteoForm = this.formBuilder.group({
			'almacen': [this.almacenElegido, Validators.required],
			'nconteo': ['', Validators.required],
		})

		this.getBodegas()
		if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
			this.letemp = navigator.mediaDevices
		}
	}

	ngOnInit(): void {
		this.estadoConexion$().subscribe(isOnline =>{
			this.estadoConexion = isOnline
		})
		this.soyAdmin = this.admins.includes(this.usuario.email)
		this.listarConteosActivos()
		this.getDevices()
		
		this._socket.getMessage().subscribe((resData:any) => {
			if(resData.temp){
				this._estadoConteoSub = true
			}
			if(resData.accion === 'terminar-conteo'){
				alert("conteo cerrado")
				this.limpiarDatos()
				this.listarConteosActivos()
			}
		})
		
	}
	tempCode = []
	/* alistarLector(evento) {
		this.tempCode.push(evento.target.value)
		let args = evento.target.value
		console.log("SX", args)

		if(this.tempCode[5]){
			this.objetoLectorQr = this.tempCode[5] // JSON.parse(this.tempCode[5])
			console.log("# ", this.objetoLectorQr)
		}
	} */
	


	estadoConexion$(){
		return merge(
			fromEvent(window, 'offline').pipe(map( () => false)),
			fromEvent(window, 'online').pipe(map( () => true)),
			new Observable((sub: Observer<boolean>) => {
				sub.next(navigator.onLine);
				sub.complete()
			})
		)
	}

	scanSuccessHandler($event: any) {
		this.CodigoSapBuscar =$event;
		this.sonido.play();
	}

	getDevices() {
		navigator.mediaDevices.enumerateDevices().then(rts => {
			this.onCamerasFound(rts)
		})
	}

	clearResult(): void {
		this.qrResultString = null;
	}

	onCamerasFound(devices: MediaDeviceInfo[]): void {
		this.availableDevices = devices;
		this.hasDevices = Boolean(devices && devices.length);
	}

	onCodeResult(resultString: string) {
		this.qrResultString = resultString;
		alert(this.qrResultString)
	}

	onDeviceSelectChange(selected: string) {
		const selectedStr = selected || '';
		if (this.deviceSelected === selectedStr) { return; }
		this.deviceSelected = selectedStr;
		const device = this.availableDevices.find(x => x.deviceId === selected);
		this.deviceCurrent = device || undefined;
	}

	onDeviceChange(device: MediaDeviceInfo) {
		const selectedStr = device?.deviceId || '';
		if (this.deviceSelected === selectedStr) { return; }
		this.deviceSelected = selectedStr;
		this.deviceCurrent = device || undefined;
	}

	openFormatsDialog() {
		const data = {
			formatsEnabled: this.formatsEnabled,
		};

		/* this._dialog
		.open(FormatsDialogComponent, { data })
		.afterClosed()
		.subscribe(x => {
		if (x) {
		this.formatsEnabled = x;
		}
		}); */
	}

	onHasPermission(has: boolean) {
		this.hasPermission = has;
	}

	openInfoDialog() {
		const data = {
			hasDevices: this.hasDevices,
			hasPermission: this.hasPermission,
		};

		// this._dialog.open(AppInfoDialogComponent, { data });
	}

	onTorchCompatible(isCompatible: boolean): void {
		this.torchAvailable$.next(isCompatible || false);
	}

	toggleTorch(): void {
		this.torchEnabled = !this.torchEnabled;
	}

	toggleTryHarder(): void {
		this.tryHarder = !this.tryHarder;
	}

	getBodegas(){
		this.https.get(`${this.globals.urlApi}bodegas`).subscribe( (resbodebas: any) =>{
			this.bodegas = resbodebas;
			this.bodegasCopy = this.bodegas;
			this.bodegas.sort( this.ordenarBodegasAlfa)
		})
	}

	filtrandoBodegas(valor: string){
		this.bodegasCopy = this.bodegas.filter(el => el.idsap.search(valor.toUpperCase()) > -1)
	}

	ordenarBodegasAlfa(a,b){
		let fa = a.idsap.toLowerCase(),
        fb = b.idsap.toLowerCase();

		if (fa < fb) {
			return -1;
		}
		if (fa > fb) {
			return 1;
		}
		return 0;
	}

	crearConteo(valores){
		let objetoCrearConteo = {
			bodega: valores.almacen.idsap,
			nombrebodega: valores.almacen.nombre,
			nconteo: valores.nconteo,
			estado: true,
			tipo_conteo: 'general'
		}
		this.https.post(`${this.globals.urlApi}conteo/crear`, objetoCrearConteo).subscribe( (resData:any) => {
			if(resData.status == "error"){
				alert(resData.mensaje)	
			} else {
				alert("Conteo creado satisfactoriamente")
				this.listarConteosActivos()
			}
		})
	}

	activarConteo(conteo){		
		this.https.post(`${this.globals.urlApi}conteo/activar`, {id: conteo.id}).subscribe( (resData:any) =>{
			alert("Conteo activado")
			/* this._socket.enviarMensaje("message", {
				accion: "terminar-conteo",
				id_conteo: conteo.id
			}) */
			this.listarConteosActivos()
		})
	}

	listarConteosActivos(){
		this.limpiarDatos()
		this.conteosActivos = []
		/* this.https.get(`${this.globals.urlApi}conteo/listar-dia`).subscribe( (resData: any) => {
			if(resData.status === 'ok'){
				this.conteosActivos = resData.data
				this.conteosActivos.map( el =>{
					this.https.get(`${this.globals.urlApi}registro-conteo/progreso/${el.id}`).subscribe( (resAvance: any) =>{
						el.porcentaje = (resAvance.data.avance*100)/el.conteo_esperado;
					})
				})
			}
		}) */
		this.https.get(`${this.globals.urlApi}conteo/listar-rango`).subscribe( (resData: any) => {
			if(resData.status === 'ok'){
				this.conteosActivos = resData.data
				this.conteosActivos.map( el =>{
					this.https.get(`${this.globals.urlApi}registro-conteo/progreso/${el.id}`).subscribe( (resAvance: any) =>{
						el.porcentaje = (resAvance.data.avance*100)/el.conteo_esperado;
					})
				})
			}
		})
	}

	elegirConteo(conteo) {
		if((conteo?.estado == true) || (conteo == null)){
			this.conteoSelected = conteo
		} else {
			alert("Conteo deshabilitado")
		}
	}

	sonar(){
		this.sonido.play();
	}

	getInfoSku(sku) {
		this.https.get(`${this.globals.urlApi}v2/productos/producto/${sku}`).subscribe( (resData: any) =>{
			if(!resData.status) {
				this.infoSkuConteo = resData
				this.skuBorrar = this.infoSkuConteo.idsap
				this.idConteoBorrar = this.conteoSelected.id
				this.verBtnBorrarRegistro = true
			} else {
				alert("SKU no encontrado o no existe.")
				this.limpiarDatos()
			}
		})
	}

	guardarConteo(){
		this.objetoRegConteo = {
			id_conteo: this.conteoSelected.id,
			sku: this.infoSkuConteo.idsap,
			cantidad_conteo: this.CantidadContado,
			mail_contador: this.usuario.email,
			hora_conteo: Date.now()
		}
		
		this.https.post(`${this.globals.urlApi}registro-conteo/crear`, this.objetoRegConteo).subscribe( (resData:any) =>{
			if(resData.status === "error"){
				if(resData.mensaje === 'Ya se registró para este conteo'){
					this.skuBorrar = this.infoSkuConteo.idsap
					this.idConteoBorrar = this.conteoSelected.id
					this.verBtnBorrarRegistro = true
				}
				alert(resData.mensaje)
			} else {
				this.limpiarDatos()
				alert("Conteo guardado satisfactoriamente.");
			}
		})
	}
	
	borrarRegistroConteo(id_conteo, sku) {
		this.https.delete(`${this.globals.urlApi}registro-conteo/borrar/${id_conteo}/${sku}`).subscribe( (resData:any) =>{
			console.log("resData borrar", resData)
			if(resData.status == "ok"){
				alert("Regsitro borrado.")
			} else {
				alert("Error al borrar el registro, intentelo nuevamente mas tarde")
			}
			this.limpiarDatos()
		})
	}

	limpiarDatos(){
		this.objetoRegConteo = null
		this.infoSkuConteo = null
		this.CodigoSapBuscar = null
		this.CantidadContado = null

		this.skuBorrar = null
		this.idConteoBorrar = null
		this.verBtnBorrarRegistro = false
	}

	cerrarConteo(conteo){
		this.https.post(`${this.globals.urlApi}conteo/cerrar`, {id: conteo.id}).subscribe( (resData:any) =>{
			this._socket.enviarMensaje("message", {
				accion: "terminar-conteo",
				id_conteo: conteo.id
			})
			this.listarConteosActivos()
		})
	}

	probarSocket(){
		this._socket.enviarMensaje("message", {
			accion: "probando conexion con socket",
			temp: true
		})
	}

	// reportes
	objeto_filtro_reportes = {
		codigo_almacen: '',
		fecha : new Date(),
		conteo: 0,
	}
	
	verObjetoFiltroReportes(reporte_id){
		this.dialogo.open(DialogoFiltroReportes,{
			width: 'auto',
			data: {
				reporte: reporte_id,
				bodegas: this.bodegas
			},
			disableClose: true
		}).afterClosed().subscribe( resultado => {
			if(resultado){
				console.log("return data ", resultado)
			}
		})
	}
	
	getEmpleadoIe(correo){
		this.https.get(`${this.globals.urlApi}empleadosie/empleado/${correo}`).subscribe( (resData: any) =>{
			if(resData.status == "ok"){
				this.soyEmpleadoIe = true
			} else {
				this.soyEmpleadoIe = false
				this.router.navigate(['/cuenta/dashboard'])
			}
		})
		
	}
}
