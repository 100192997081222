import { Component, Input } from '@angular/core';
import { Globals } from '../../globals';
import { HttpClient } from '@angular/common/http';
import { ProductoService } from '../../services/producto.service';

@Component({
	selector: 'app-listado-productos',
	templateUrl: './listado-productos.component.html',
	styleUrls: ['./listado-productos.component.scss']
})
export class ListadoProductosComponent {
	@Input('idsproductos') idprods: any;
	productos: any[] = []
	totalProductos = 0
	cargando: boolean = false

	constructor(
		private global: Globals,
		private https: HttpClient,
		private productoService: ProductoService
	){
		if(!this.idprods){
			this.cargando = true
			console.log("constructor", this.idprods)
		}
	}

	ngOnInit(){
		this.getDataProductosBySKU(this.idprods)
	}

	getDataProductosBySKU(arreglo){
		this.https.post(`${this.global.urlApi}v2/listado-productos`, {data: {skus: arreglo}}).subscribe( (resData: any) => {
			this.cargando = false
			this.totalProductos = resData.data.totalProductos;
			this.productos = resData.data.productos
			this.productos.map( el => {
				this.productoService.getPrecioProducto(el.idsap).subscribe( res =>{
					el.precio = res.data.precio
					el.descuento = res.data.descuento
					el.precioecommerce = res.data.precioecommerce
				})
			})
		})
	}

}
