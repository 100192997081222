import { Component, ViewEncapsulation, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Data, AppService } from '../../../app.service';
import { Product } from '../../../app.models';
import { Marca } from '../../../models/marca';

import { Producto } from '../../../models/producto';
import { MarcaService } from '../../../services/marca.service';

import SwiperCore, { SwiperOptions, Autoplay, Pagination, Navigation } from 'swiper';
SwiperCore.use([Navigation, Autoplay, Pagination]);


@Component({
	selector: 'app-product-dialog',
	templateUrl: './product-dialog.component.html',
	styleUrls: ['./product-dialog.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ProductDialogComponent implements OnInit {
	config:SwiperOptions = {
		slidesPerView: 1,
		spaceBetween: 0,
		keyboard: true,
		navigation: true,
		pagination: false,
		grabCursor: true,
		loop: false,
		preloadImages: false,
		lazy: true,
		effect: "fade",
		fadeEffect: {
			crossFade: true
		}
	}
	public urlBaseImagen = 'https://s3.amazonaws.com/iecommerce2019/';
	public marcaProducto: Marca;

	constructor(
		public appService: AppService,
		public dialogRef: MatDialogRef<ProductDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public product: Producto,
		private marcaService: MarcaService
	) {
		this.marcaService.getMarcaIdsap(this.product.idmarca).subscribe(marcaProducto => {
			this.marcaProducto = marcaProducto[0];
		})
		this.urlBaseImagen += product.idsap + '/' + product.imagen;
	}

	ngOnInit() { }

	public close(): void {
		this.dialogRef.close();
	}
}
