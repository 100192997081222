import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';

@Component({
	selector: 'app-landing-unidades',
	templateUrl: './landing-unidades.component.html',
	styleUrls: ['./landing-unidades.component.scss']
})
export class LandingUnidadesComponent implements OnInit {
	unidad_actual
	@ViewChild('unidadActual', { static: false }) unidadActual: ElementRef;
	@ViewChild('uniDefectoColor', { static: false }) uniDefectoColor: ElementRef;
	@ViewChild('uniDefectoGris', { static: false }) uniDefectoGris: ElementRef;




	@ViewChild('uenergia', { static: false }) uenergia: ElementRef;
	@ViewChild('uconstru', { static: false }) uconstru: ElementRef;
	@ViewChild('uilumina', { static: false }) uilumina: ElementRef;
	@ViewChild('utelcomu', { static: false }) utelcomu: ElementRef;
	@ViewChild('usosteni', { static: false }) usosteni: ElementRef;
	@ViewChild('uindustr', { static: false }) uindustr: ElementRef;
	@ViewChild('uproyect', { static: false }) uproyect: ElementRef;
	@ViewChild('utecnolo', { static: false }) utecnolo: ElementRef;




	constructor(
		private renderer: Renderer2
	) {
	}

	ngOnInit(): void {
	}
	ngAfterViewInit() {

		this.seleccionarUnidad()
	}

	verUnidad(texto: string, color: string) {
		if (texto != '') {
			this.unidad_actual = texto
			this.renderer.setStyle(this.unidadActual.nativeElement, "color", color);
			if (texto != 'Energía, equipos & herramientas') {
				this.renderer.setStyle(this.uniDefectoGris.nativeElement, "display", 'none');
				this.renderer.setStyle(this.uniDefectoColor.nativeElement, "display", 'block');
			} else {
				this.renderer.setStyle(this.uniDefectoGris.nativeElement, "display", 'block');
				this.renderer.setStyle(this.uniDefectoColor.nativeElement, "display", 'none');
			}
		} else {
			this.unidad_actual = ''
			this.verUnidad('Energía, equipos & herramientas', '#ff8500');

		}
	}

	seleccionarUnidad() {
		this.verUnidad('Energía, equipos & herramientas', '#ff8500');

		/* this.unidad_actual =  'Energía, equipos & herramientas';
		this.renderer.setStyle(this.unidadActual.nativeElement, "color", '#ff8500' ); */
	}

	pintarSvg(event, nombreViewChild){
		const collection = document.querySelectorAll<HTMLElement>('.boton-mobile-nav-unidades')
		for (let i = 0; i < collection.length; i++) {
			collection[i].classList.remove('u-selected')
		}
		event.target.classList.add('u-selected')
		
	


		this.setVisionInicial()
		if(nombreViewChild == 'uenergia'){
			const el = this.uenergia.nativeElement;
			this.renderer.setStyle(el.children[0], "display", 'none');
			this.renderer.setStyle(el.children[1], "display", 'block');
		}
		if(nombreViewChild == 'uconstru'){
			const el = this.uconstru.nativeElement;
			this.renderer.setStyle(el.children[0], "display", 'none');
			this.renderer.setStyle(el.children[1], "display", 'block');
		}
		if(nombreViewChild == 'uilumina'){
			const el = this.uilumina.nativeElement;
			this.renderer.setStyle(el.children[0], "display", 'none');
			this.renderer.setStyle(el.children[1], "display", 'block');
		}
		if(nombreViewChild == 'utelcomu'){
			const el = this.utelcomu.nativeElement;
			this.renderer.setStyle(el.children[0], "display", 'none');
			this.renderer.setStyle(el.children[1], "display", 'block');
		}
		if(nombreViewChild == 'usosteni'){
			const el = this.usosteni.nativeElement;
			this.renderer.setStyle(el.children[0], "display", 'none');
			this.renderer.setStyle(el.children[1], "display", 'block');
		}
		if(nombreViewChild == 'uindustr'){
			const el = this.uindustr.nativeElement;
			this.renderer.setStyle(el.children[0], "display", 'none');
			this.renderer.setStyle(el.children[1], "display", 'block');
		}
		if(nombreViewChild == 'uproyect'){
			const el = this.uproyect.nativeElement;
			this.renderer.setStyle(el.children[0], "display", 'none');
			this.renderer.setStyle(el.children[1], "display", 'block');
		}
		if(nombreViewChild == 'utecnolo'){
			const el = this.utecnolo.nativeElement;
			this.renderer.setStyle(el.children[0], "display", 'none');
			this.renderer.setStyle(el.children[1], "display", 'block');
		}
		

	}
	setVisionInicial(){
		const uenergia = this.uenergia.nativeElement;
			this.renderer.setStyle(uenergia.children[0], "display", 'block');
			this.renderer.setStyle(uenergia.children[1], "display", 'none');
		const uconstru = this.uconstru.nativeElement;
			this.renderer.setStyle(uconstru.children[0], "display", 'block');
			this.renderer.setStyle(uconstru.children[1], "display", 'none');
		const uilumina = this.uilumina.nativeElement;
			this.renderer.setStyle(uilumina.children[0], "display", 'block');
			this.renderer.setStyle(uilumina.children[1], "display", 'none');
		const utelcomu = this.utelcomu.nativeElement;
			this.renderer.setStyle(utelcomu.children[0], "display", 'block');
			this.renderer.setStyle(utelcomu.children[1], "display", 'none');
		const usosteni = this.usosteni.nativeElement;
			this.renderer.setStyle(usosteni.children[0], "display", 'block');
			this.renderer.setStyle(usosteni.children[1], "display", 'none');
		const uindustr = this.uindustr.nativeElement;
			this.renderer.setStyle(uindustr.children[0], "display", 'block');
			this.renderer.setStyle(uindustr.children[1], "display", 'none');
		const uproyect = this.uproyect.nativeElement;
			this.renderer.setStyle(uproyect.children[0], "display", 'block');
			this.renderer.setStyle(uproyect.children[1], "display", 'none');
		const utecnolo = this.utecnolo.nativeElement;
			this.renderer.setStyle(utecnolo.children[0], "display", 'block');
			this.renderer.setStyle(utecnolo.children[1], "display", 'none');

	}

}
