import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ProductDialogComponent } from './product-dialog/product-dialog.component';
import { AppService } from '../../app.service';
import { Producto } from '../../models/producto';

import SwiperCore, { SwiperOptions, Autoplay, Pagination, Navigation } from 'swiper';
import { HttpClient } from '@angular/common/http';
import { ProductoService } from '../../../app/services/producto.service';
SwiperCore.use([Navigation, Autoplay, Pagination]);

@Component({
	selector: 'app-products-carousel',
	templateUrl: './products-carousel.component.html',
	styleUrls: ['./products-carousel.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ProductsCarouselComponent implements OnInit {

	@Input('products') products: Array<Producto> = [];
	
	config:SwiperOptions = {
		observer: true,
		spaceBetween: 15,
		keyboard: true,
		navigation: true,
		pagination: false,
		grabCursor: true,
		/* preloadImages: true, */
		lazy: true,
		speed: 500,
		effect: "slide",
		autoplay: true,
		loop: true,
		breakpoints: {
			320: {
				slidesPerView: 1
			},
			480: {
				slidesPerView: 1
			},
			740: {
				slidesPerView: 2,
			},
			960: {
				slidesPerView: 4,
			},
			1280: {
				slidesPerView: 5,
			},
			1500: {
				slidesPerView: 6,
			}
		}
	};

	constructor(
		public appService: AppService,
		public dialog: MatDialog,
		private router: Router,
		private https: HttpClient,
		private readonly productSvc: ProductoService
	) {}

	ngOnInit() {
		this.products.forEach(producto => {
			producto['show'] = false
			producto['url'] = producto.url != '' ? producto.url.replace(/\s/g, '-').toLowerCase() : '';
			let datapeticion = {
				itemcode: producto.idsap,
				cardcode: "f"
			}
			this.productSvc.getPrecioProducto(producto.idsap).subscribe((precios: any) => {
				producto.precio = precios.data.precio
				producto.descuento = precios.data.descuento
				producto.precioecommerce = precios.data.precioecommerce
			})
		})
	}
	
	public cargarDefecto(producto) {
		producto.errorimg = true;
		producto.imagen = "producto-default.png";
	}

	ngAfterViewInit() {
		
	}

	public openProductDialog(product) {
		const dialogRef = this.dialog.open(ProductDialogComponent, {
			data: product,
			panelClass: 'product-dialog'
		});
		dialogRef.afterClosed().subscribe( product => {
			if (product) {
				this.router.navigate(['/productos', 1, 12, 'producto', product.idsap, product.url]);
				// this.router.navigate(['/products', product.id , product.name]);
			}
		});
	}

	public trackByFn(index, item) {
		if (!item) { return null; }
		return index
	}

	obtenerPrecios (idsap) {
		this.https.get('localhost:3000/api/v2/precios/'+ idsap).subscribe( async (res: any) =>{
			return await res.data[4]
		})
	}
}
