import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class IeAuthService {
	public origenUsuario = new BehaviorSubject(this.getCurrentUser());
	public usuario = this.origenUsuario.asObservable();
	public headers: HttpHeaders = new HttpHeaders({
		"Content-Type": "application/json"
	})

	constructor(private http: HttpClient) { }

	loginUser(email: string, password: string): Observable<any> {
		return this.http.post("", {email, password}, {headers: this.headers} ).pipe(map(data => data))
	}

	setUser(user): void {
		if(user != null){
			let user_string = JSON.stringify(user)
			localStorage.setItem("IeCurrentUser", user_string)
			this.usuario = JSON.parse(user_string)
			this.origenUsuario.next(this.usuario)
		} else {
			this.usuario = null
			this.origenUsuario.next(this.usuario)
		}
		
	}
	setToken(token): void {
		localStorage.setItem("accesToken", token)
	}
	getToken() {
		return localStorage.getItem("accessToken")
	}
	getCurrentUser() {
		let user_string = localStorage.getItem("IeCurrentUser")
		if (user_string !== undefined && user_string !== null ) {
			this.usuario = JSON.parse(user_string)
		} else {
			this.usuario = undefined
		}
		return this.usuario
	}
	logOutUser() {
		this.setUser(null);
		// this.origenUsuario.next(undefined)
		let accesToken = localStorage.getItem('accesToken')
		const url_api = `http://localhost:3000/api/Users/logout?acces_token=${accesToken}`;
		localStorage.removeItem("accessToken");
		localStorage.removeItem("currentUser");
		return this.http.post(url_api, {headers: this.headers});
	}
}
