import { Component, OnInit } from '@angular/core';

import { AppService } from '../../app.service';

@Component({
	selector: 'app-chat',
	templateUrl: './chat.component.html',
	styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {

	constructor(
		private appService: AppService
	) { }

	ngOnInit() {
		// this.appService.PopupCenter('https://iegrupo.bitrix24.com/online/chat?user_lang=la', 'ie chat', 400, 750)
	}
	abrirChat() {
		this.appService.PopupCenter('https://iegrupo.bitrix24.com/online/chat?user_lang=la', 'ie chat', 400, 750)
	}

}
