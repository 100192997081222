import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-banners',
	templateUrl: './banners.component.html',
	styleUrls: ['./banners.component.scss']
})
export class BannersComponent implements OnInit {
	@Input('banners') banners: Array<any> = [];
	fondo = 'assets/images/others/fondo1.png';

	constructor() { }

	ngOnInit() { }

	public getBanner(index) {
		return this.banners[index];
	}

	public getBgImage(index) {
		console.log(this.banners[index]);
		const bgImage = {
			'background-image': index != null ? "url(" + this.banners[index].image + ")" : "url(https://via.placeholder.com/600x400/ff0000/fff/)"
		};
		return bgImage;
	}

}
