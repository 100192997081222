import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
/* import { WINDOW } from '@ng-toolkit/universal'; */
import { AppService } from '../app.service';
import { Globals } from '../globals';
import { IeAuthService } from './ieauth.service';
import { UsuarioService } from './usuario.service';

@Injectable({
	providedIn: 'root'
})
export class WompiService {

	public acceptance_token!: any
	public institucionesFinancieras = [];
	public tipoPersona = [
		{ value: 0, name: "Natural" },
		{ value: 1, name: "Jurídica" }
	];
	public tipoDocumento = [
		{ value: "CC", name: "Cédula de ciudadanía" },
		{ value: "NIT", name: "NIT" }
	]

	appwompi: any
	llaveroWompi = {
		llave_publica: '',
		llave_privada: '',
		llave_eventos: ''
	}
	public infoTransa: any;
	public infoComprador: any;


	constructor(
		//@Inject(WINDOW) entornojs: any,
		public http: HttpClient,
		private globals: Globals,
		private route: ActivatedRoute,
		private router: Router,
		private ieAuthService: IeAuthService,
		private usuarioService: UsuarioService,
		private appService: AppService
		/* private http: HttpClient,
		private globals: Globals,
		private snackBar: MatSnackBar,
		private transaccionService: TransaccionService,
		private ieAuthService: IeAuthService,
		private pedidoService: PedidosService,
		private spinner: NgxSpinnerService */
	) {
		this.appwompi = window // entornojs
		this.obtenerTokenWompi()
	}

	obtenerTokenWompi() {
		// 1 - obtener el acceptance_token
		this.http.get(this.globals.urlWompi + '/merchants/' + this.globals.llaves_wompi.llave_publica).subscribe((resData: any) => {
			this.acceptance_token = resData?.data?.presigned_acceptance?.acceptance_token;
			this.getInstitucionesFinancieras()
		})
	}
	getInstitucionesFinancieras() {
		let headers = new HttpHeaders()
		headers = headers.set('Authorization', 'Bearer ' + this.globals.llaves_wompi.llave_publica)
		this.http.get(this.globals.urlWompi + 'pse/financial_institutions', { headers: headers }).subscribe((resData: any) => {
			this.institucionesFinancieras = resData.data
			//console.log("XXX", this.institucionesFinancieras);
			
		})
	}

	pagarConWompi(cantidad: number , tipo_pago:string, redireccionUrl: string, idSAP: string, ciudad: string, id_pedido_web: string) { // cantidad: number, data_pago: any; id_documento es la id del tipo de documento asociado a una transacción
		try{
			let cantidadFormatoCentavos = cantidad ? cantidad * 100 : 12300000000000;
			let concat_pedido = id_pedido_web ? id_pedido_web : 'idp';
			let concat_idsap = idSAP ? idSAP : 'cardcode';
			
			let fechaOperacion = Date.now();

			//fecha "legible"
			const date= new Date(fechaOperacion);
			// let dateFormat = date.getHours() + ":" + date.getMinutes() + ", "+ date.toDateString();
			let dateFormat = `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}-${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}`;

			var checkout = new this.appwompi.WidgetCheckout({
				currency: 'COP',
				amountInCents: cantidadFormatoCentavos,
				reference: tipo_pago + '*' + dateFormat + '*' + this.ieAuthService.getCurrentUser().email + '*' + concat_idsap + '*' + concat_pedido + '*' + ciudad,
				publicKey: '' + this.globals.llaves_wompi.llave_publica,
				redirectUrl: redireccionUrl // 'http://localhost:4200/#/cuenta/ver-cotizaciones', // Opcional // redireccionUrl // 
				
			});
			checkout.open(function (result) {
				//console.log("RESULTADO", result)
				var transaction = result.transaction
				console.log("XCXCXCXCXC", result)
				console.log('Transaction?: ', transaction)
				// zconsole.log('Transaction object: ', transaction)
				// TODO : ACTUALIZAR EL PEDIDO PARA PONERLE EL ID DE LA TRANSACCION
			})
		}catch(error){
			console.log("error en pagarConWompi ", error)
		}
	}
	pagarCotizacionConWompi(cantidad: number , redireccionUrl: string, referencia: string){
		try{
			let cantidadFormatoCentavos = cantidad ? cantidad * 100 : 12300000000000;

			var checkout = new this.appwompi.WidgetCheckout({
				currency: 'COP',
				amountInCents: cantidadFormatoCentavos,
				reference: this.ieAuthService.getCurrentUser().email + '*' + referencia,
				publicKey: '' + this.globals.llaves_wompi.llave_publica,
				redirectUrl: redireccionUrl
			});
			checkout.open(function (result) {
				var transaction = result.transaction
			})
		}catch(error){
			console.log("error en pagarConWompi ", error)
		}
	}
	consultarTransaccion(id_transaccion: string){
		
		let headers = new HttpHeaders()
		headers = headers.set('Authorization', 'Bearer ' + this.globals.llaves_wompi.llave_publica)

		return this.http.get(this.globals.urlWompi + 'transactions/' + id_transaccion, { headers: headers })
	}
	
	verificarTransaccion(id_transaccion: string) {
		// 112336-1626871490-95468 DECLINADA
		// 112336-1626881505-67908 ERROR
		// 112336-1627315471-96931 APROBADA
		// id_transaccion = '112336-1627315471-96931';
		let headers = new HttpHeaders()
		headers = headers.set('Authorization', 'Bearer ' + this.globals.llaves_wompi.llave_publica)

		this.http.get(this.globals.urlWompi + 'transactions/' + id_transaccion, { headers: headers }).subscribe((resData: any) => {
			this.usuarioService.getUsuarioSAP(this.ieAuthService.getCurrentUser().email).subscribe(res =>{
				this.infoComprador = res ? res[0] : null
				let dataMail = {
					cliente: this.infoComprador,
					transaccion: resData.data
				}
				this.infoTransa = resData.data;
				// data.transaccion = this.infoTransa
	
	
				if(this.infoTransa.status == 'APPROVED') {
					this.http.post('https://www.iegrupo.co/servicios/wompi/enviarCorreoPagoAnticipo2023.php', dataMail).subscribe((resDataMail: any)=> {
						if(resDataMail.status === 'ok'){
							this.appService.mensajeSnackBar("Transacción aprobada!!!", 3000, 'success', 'x');
						}
					})
				}	
			})

			
		})
	}
	verificarTransaccionByReferencia(_referencia: string){
		let headers = new HttpHeaders()
		headers = headers.set('Authorization', 'Bearer ' + this.globals.llaves_wompi.llave_privada)
		this.http.get(`${this.globals.urlWompi}transactions?reference=${_referencia}`, { headers: headers }).subscribe((resData: any) => {
			if(resData.data[0]){
				if(resData.data[0].status === 'APPROVED'){
					let leref = _referencia.split("*")
					let id_doc_cot = leref[1]
					this.http.patch(`${this.globals.urlApi}v2/notificaciones-cotizaciones`, {documento : id_doc_cot}).subscribe( (resDataNotif: any) =>{
						if(resDataNotif.data){
							this.http.post('https://www.iegrupo.co/servicios/wompi/correoPagoCotizacionRecibido.php', {data: {transaction: resData.data[0]}}).subscribe((resDataMail: any)=> {
								if(resDataMail.status === 'ok'){
									this.appService.mensajeSnackBar("Se ha notificado al area correspondiente", 3000, 'success', 'x');
								}
							})
						}
					})
				}
			}		
		})
	}

/* 

112336-1685045860-25815
	consultarTransaccion (transaccion: string): Observable<any> {
		let id_transaccion = transaccion
		let cuerpo = {
			EntityCode : this.entityCode,
			TicketId: id_transaccion
		}
		return this.http.post(this.globals.backEcollect + 'consultar_transaccion.php', {cuerpo});
	}
	consultarTransacciones (transacciones: string[]) {
		let cuerpo = {
			EntityCode: this.entityCode,
			transacciones : transacciones,
			url : this.globals.urlApi,
		}
		return this.http.post(this.globals.backEcollect + 'consultar_transacciones.php', {cuerpo})
	}

	verificarCambioEstadoTransaccion() {
		console.log('verificarCambioEstadoTransaccion');
	}
	generarRef(longitud: number) {
		let text = '';
		var posibles = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+';
		for (let i = 0; i < longitud ; i++) {
			text += posibles.charAt(Math.floor(Math.random() * posibles.length ))
		}
		return text;
	}
	pagar(valor_pago, numero_documento, correo, telefono, direccion, nombre_completo, tipo_documento) {
		this.spinner.show();
		let requestData = {
			valor_pago: valor_pago,
			numero_documento: numero_documento,
			tipo_documento: tipo_documento,
			correo: correo,
			telefono: telefono,
			direccion: direccion,
			nombre_completo: nombre_completo,
			redireccion : window.location.origin + window.location.pathname + '#/cuenta/historial'
		}
		this.http.post(this.globals.backEcollect + 'crear_transaccion.php', {requestData}).subscribe((res: any) => {
			this.spinner.show();
			if (res.status == 'ok') {
				res.data.createTransactionPaymentResult.cuenta = this.ieAuthService.getCurrentUser().id;
				this.transaccionService.registrarTransaccion(res.data.createTransactionPaymentResult).subscribe(respuestaRegistroTransaccion => {
					this.spinner.show();
					if (respuestaRegistroTransaccion.ReturnCode == 'SUCCESS') {
						this.pedidoService.pedidoCreado.transaccion = respuestaRegistroTransaccion.TicketId;
						this.pedidoService.updatePedido().subscribe(resPed => {
							console.log('respuesta al actualizar el pedido', resPed);
							this.spinner.show();
							window.open(res.data.createTransactionPaymentResult.eCollectUrl, '_self');
						})
					} else {
						this.spinner.hide();
						this.snackBar.open(respuestaRegistroTransaccion.ReturnCode, 'RESPUESTA ERROR', {duration: 1000});
					}
				}, err => {
					this.spinner.hide();
					console.log('error al registrar la transaccion', err);
				})
			}
		}, err => {
			this.spinner.hide();
			this.snackBar.open(err, 'RESPUESTA ERROR', {duration: 5000});
		})
	}
	pagarCotizacion (valor_pago, numero_documento, correo, telefono, direccion, nombre_completo, tipo_documento, cotizacion) {
		console.log('cotizacion a pagar', cotizacion)
		this.spinner.show();
		let requestData = {
			valor_pago: valor_pago,
			numero_documento: numero_documento,
			tipo_documento: tipo_documento,
			correo: correo,
			telefono: telefono,
			direccion: direccion,
			nombre_completo: nombre_completo,
			redireccion : window.location.origin + window.location.pathname + '#/cuenta/historial'
		}
		console.log('rqd', requestData)
		this.http.post(this.globals.backEcollect + 'pagar_cotizacion.php', requestData).subscribe( (respuesta: any) => {
			if (respuesta && respuesta.status === 'ok') {
				respuesta.data.createTransactionPaymentResult.cuenta = this.ieAuthService.getCurrentUser().id;

				this.transaccionService.registrarTransaccion(respuesta.data.createTransactionPaymentResult).subscribe(respuestaRegistroTransaccion => {
					this.spinner.show();
					if (respuestaRegistroTransaccion.ReturnCode == 'SUCCESS') {
						cotizacion.estado = respuestaRegistroTransaccion.TicketId; // 'transaccion';
						this.http.patch(this.globals.urlApi + 'cotizaciones', cotizacion).subscribe(res => {
							console.log('actualizacion cotizacion', res)
							window.open(respuesta.data.createTransactionPaymentResult.eCollectUrl, '_self');
						})
					} else {
						this.spinner.hide();
						this.snackBar.open(respuestaRegistroTransaccion.ReturnCode, 'RESPUESTA ERROR', {duration: 5000});
					}
				}, err => {
					this.spinner.hide();
					console.log('error al registrar la transaccion', err);
				})
			}
		})
	} */
}
