import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-hoja-producto',
	templateUrl: './hoja-producto.component.html',
	styleUrls: ['./hoja-producto.component.scss']
})
export class HojaProductoComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
