import { Component, OnInit, HostListener, Inject, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../../globals';
// import {S3, Config} from 'aws-sdk';
import {default as  Vivus} from 'vivus'

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { SwiperComponent  } from 'swiper/angular';
import SwiperCore, { EffectCoverflow, Navigation, Pagination, Scrollbar, A11y, SwiperOptions, Swiper } from 'swiper';
SwiperCore.use([EffectCoverflow, Navigation, Pagination, Scrollbar, A11y]);

export interface Slide{
	imgSrc: string;
	imgAlt: string;
}

@Component({
	selector: 'app-mercadosconamor',
	templateUrl: './mercadosconamor.component.html',
	styleUrls: ['./mercadosconamor.component.scss']
})
export class MercadosconamorComponent implements OnInit {
	mercados_entregados: number = 0;
	// https://www.npmjs.com/package/ng-image-slider
	arreglo_imagenes: Slide[] = [
		{
			imgSrc: 'assets/images/mercados/carusel/mca-01.png',
			imgAlt: 'imagen uno'
		},
		{
			imgSrc: 'assets/images/mercados/carusel/mca-02.png',
			imgAlt: 'imagen dos'
		},
		{
			imgSrc: 'assets/images/mercados/carusel/mca-03.png',
			imgAlt: 'imagen tres'
		},
		{
			imgSrc: 'assets/images/mercados/carusel/mca-04.png',
			imgAlt: 'imagen cuatro'
		},
		{
			imgSrc: 'assets/images/mercados/carusel/mca-05.png',
			imgAlt: 'imagen cinco'
		},
		{
			imgSrc: 'assets/images/mercados/carusel/mca-06.png',
			imgAlt: 'imagen seis'
		},
		{
			imgSrc: 'assets/images/mercados/carusel/mca-07.png',
			imgAlt: 'imagen siete'
		},
		{
			imgSrc: 'assets/images/mercados/carusel/mca-08.png',
			imgAlt: 'imagen ocho'
		},
		{
			imgSrc: 'assets/images/mercados/carusel/mca-09.png',
			imgAlt: 'imagen nueve'
		},
	];
	selectedIndex = 0



	// https://github.com/maxwellito/vivus?tab=readme-ov-file
	@ViewChild('polaroid', { read: ElementRef, static: true }) polaroidEl: ElementRef;
	@ViewChild('wave1', { read: ElementRef, static: true }) wave1: ElementRef;
	@ViewChild('wave2', { read: ElementRef, static: true }) wave2: ElementRef;
	animado: boolean = false
	animado1: boolean = false
	animado2: boolean = false

	constructor(
		/* @Inject(WINDOW) private window: Window, */
		private http: HttpClient,
		private globals: Globals,
		private _sanitizer: DomSanitizer

	) {}
	
	@HostListener('window:scroll', ['$event'])
	onWindowScroll() {
		let isVisible = this.isElementInViewport(this.polaroidEl.nativeElement)
		let isVisible1 = this.isElementInViewport(this.wave1.nativeElement)
		let isVisible2 = this.isElementInViewport(this.wave2.nativeElement)
		if(isVisible){
			if(!this.animado){
				this.animado = true
				this.iniciarAnimacion(this.polaroidEl.nativeElement)
			}
		}
		if(isVisible1){
			if(!this.animado1){
				this.animado1 = true
				this.iniciarAnimacion(this.wave1.nativeElement)
			}
		}
		if(isVisible2){
			if(!this.animado2){
				this.animado2 = true
				this.iniciarAnimacion(this.wave2.nativeElement)
			}
		}
		
	  // this.checkVisibility();
	}

	ngOnInit(){
		this.http.get(this.globals.urlApi + 'mercados-entregados').subscribe((data: any) => {
			this.mercados_entregados = data.entregados
		})
	}
	ngAfterViewInit(): void {
		//Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
		//Add 'implements AfterViewInit' to the class.
		
	}

	getVideoIframe () {
		/* var video, results;
		 if (url === null){
			 return '';
		 }
		 results = url.match('[\\?&]v=([^&#]*)');
		 video = (results === null) ? url : results[1]; */
		 return this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/NryaomLzPYQ');
		 // return this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/watch?v=NryaomLzPYQ&feature=youtu.be&ab_channel=ieGrupo');
	}

	isElementInViewport(el: HTMLElement): boolean {
		const rect = el.getBoundingClientRect();
		return (
		  rect.top >= 0 &&
		  rect.left >= 0 &&
		  rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
		  rect.right <= (window.innerWidth || document.documentElement.clientWidth)
		);
	  }

	iniciarAnimacion(queanimar){
		new Vivus(queanimar, {
			type: 'scenario-sync',
			duration: 100,
			forceRender: false,
      		pathTimingFunction: Vivus.LINEAR,
			animTimingFunction: Vivus.EASE_OUT,
		}, (algo)=>{
			
			// console.log("algo => ", algo)
		})
	}

	anterior(i: number){
		if(this.selectedIndex > 0){
			this.selectedIndex = i - 1;
		}
	}

	siguiente(i: number){
		if(this.selectedIndex < this.arreglo_imagenes.length -1){
			this.selectedIndex = i + 1;
		}
	}
	setIndex(i:number){
		this.selectedIndex = i
	}

	

}
