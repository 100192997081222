import { Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';


@Directive({
	selector: '[appOnViewPort]'
})
export class OnViewPortDirective implements OnDestroy {
	private observer: IntersectionObserver;

	@Output() intersectionChanged = new EventEmitter<boolean>();

	constructor(
		private elementRef: ElementRef
	) {
		this.observer = new IntersectionObserver(entries => {
			entries.forEach(entry => {
				if(entry.isIntersecting){
					console.log("en viewport")
					this.intersectionChanged.emit(true)
				} else {
					this.intersectionChanged.emit(false)
				}
			})
		})
		this.observer.observe(this.elementRef.nativeElement)
	}

	ngOnDestroy(): void {
		this.observer.disconnect();	
	}

}
