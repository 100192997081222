/* import { WINDOW } from '@ng-toolkit/universal'; */
import { Component, OnInit, ViewChild , Inject, ViewEncapsulation, ElementRef} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductZoomComponent } from './product-zoom/product-zoom.component';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Title } from '@angular/platform-browser';

import { Meta } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

import { AppService } from '../../../app.service';
import { MarcaService } from '../../../services/marca.service';
import { ProductoService } from '../../../services/producto.service';
import { IeAuthService } from '../../../services/ieauth.service';
import { TagesService } from '../../../services/tages.service';
import { Producto } from '../../../models/producto';
import { Marca } from '../../../models/marca';
import { Globals } from '../../../globals';

import KeenSlider, { KeenSliderInstance, KeenSliderPlugin} from 'keen-slider';

import SwiperCore, { SwiperOptions, Autoplay, Pagination, Navigation } from 'swiper';
SwiperCore.use([Navigation, Autoplay, Pagination]);

// splash para productos con cupon
export interface DataSplash {
	idProducto: string,
}


function ThumbnailPlugin(main: KeenSliderInstance): KeenSliderPlugin {
	return (slider) => {
	  function removeActive() {
		slider.slides.forEach((slide) => {
		  slide.classList.remove("active")
		})
	  }
	  function addActive(idx: number) {
		slider.slides[idx].classList.add("active")
	  }
  
	  function addClickEvents() {
		slider.slides.forEach((slide, idx) => {
		  slide?.addEventListener("click", () => {
			main.moveToIdx(idx)
		  })
		})
	  }
  
	  slider.on("created", () => {
		addActive(slider.track.details.rel)
		addClickEvents()
		main.on("animationStarted", (main) => {
		  removeActive()
		  const next = main.animator.targetIdx || 0
		  addActive(main.track.absToRel(next))
		  slider.moveToIdx(Math.min(slider.track.details.maxIdx, next))
		})
	  })
	}
  }


@Component({
	selector: 'app-splash-producto',
	templateUrl: 'productModal.html'
})
export class SplashProductoComponent {
	idSplash
	constructor(
		public splash: MatDialogRef<SplashProductoComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DataSplash,
	) {
		this.idSplash = this.data;
	}
	onNoClick(): void {
		this.splash.close();
	}
}
// fin splash para productos con cupon


@Component({
	selector: 'app-product',
	templateUrl: './product.component.html',
	styleUrls: ['./product.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ProductComponent implements OnInit {
	@ViewChild('zoomViewer', {static: false}) zoomViewer;

	recargarControlesStock: boolean = false
	
	config:SwiperOptions = {
		observer: true,
		slidesPerView: 4,
		spaceBetween: 10,
		keyboard: true,
		navigation: true,
		pagination: false,
		loop: true,
		preloadImages: false,
		lazy: true,
		breakpoints: {
			320: {
				slidesPerView: 1
			},
			480: {
				slidesPerView: 2
			},
			600: {
				slidesPerView: 3,
			}
		}
	};
	public tipoUsuario: any;
	public product: any;
	public image: any;
	public zoomImage: any;
	// private sub: any;
	public form: UntypedFormGroup;
	public relatedProducts: Array<Producto>;
	public imagen: string = 'https://s3.amazonaws.com/iecommerce2019/';
	public marcaProducto: Marca;

	public imgAwsS3: string = '';
	public listado_imagenes = [];
	infoProducto: boolean = true
	// productos_cuponera = ['121103104058', '121100105061', '137104103039', '121103104056', '121103104053', ];
	productos_cuponera = [];
	public label_nombre = '';
	public descripcion_formateada = [];
	public banners = []
	categoriasDeProducto: any[] = []
    productos_relacionados_validados: any[] = []

	//dev
	dev: boolean = true;

	// @ViewChild("sliderRef") sliderRef: ElementRef<HTMLElement>
	// @ViewChild("thumbnailRef") thumbnailRef: ElementRef<HTMLElement>
  
	slider: KeenSliderInstance = null
	thumbnailSlider: KeenSliderInstance = null

	criterio_relacionados:string = ''
	productos_relacionados = []


	constructor(
		/* @Inject(WINDOW) private window: Window, */
		public appService: AppService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		public dialog: MatDialog,
		public formBuilder: UntypedFormBuilder,
		public productoService: ProductoService,
		private modalTerminos: NgxSmartModalService,
		private marcaService: MarcaService,
		private ieAuthService: IeAuthService,
		public modalSplashProducto: MatDialog,
		private metaService: Meta,
		private http : HttpClient,
		private globals: Globals,
		private tagesService: TagesService,
		private readonly productSvc: ProductoService,
		private readonly tituloPage: Title
	) {
		
	}
	ngOnInit() {
		this.tipoUsuario = this.ieAuthService.getCurrentUser() ? this.ieAuthService.getCurrentUser().idTipoCuenta : 2;
		this.appService.mensajeCarga = 'Cargando información...';
		this.activatedRoute.params.subscribe(params => {
			this.product = null
			let dataparams = params
			// this.productoService.getProducto(dataparams.id).subscribe((data: any) => {
			this.productoService.getV3Producto(dataparams.id).subscribe((data: any) => {
				this.listado_imagenes = [];
				
				if (data.status === 'ok') {
			
					this.actualizarControles()
					
					this.product = data.data;  //TODO: cambiar data[0] a data para version 2
					this.getProductosRelacionados(this.product?.marca)
					this.productoService.getCategoriasProductoV2(this.product);
					
					this.getPrecio(this.product)  //TODO: activar para version 2
					this.getNuevasCategorias(this.product)

					if(this.product.descripcion != null){
						this.formatearDescripcion(this.product.descripcion)
					}
					let stock = 0
					this.label_nombre = '';
					this.product.nombre = this.product.descripcion_producto.toLowerCase()
					this.label_nombre = this.product.nombre;
					this.tituloPage.setTitle(this.label_nombre)
					
					let ciudad = this.appService.ciudadActual.plano_name;
					

					////////////// actualizar
					/* if(data?.original?.ManBtchNum === 'Y'){
						this.http.get(`${this.globals.urlApi}v3/cortas-prducto-ciudad/${ciudad}/${this.product.sku}`).subscribe( (resDataCortas: any) =>{
							let cants = resDataCortas.data.map(el => parseInt(el.Stock))
							this.product.stock = Math.max(...cants)
							
						})
					} else {
						this.http.get(`${this.globals.urlApi}v3/stock-de-ciudad/${ciudad}/${this.product.sku}`).subscribe( (resDataStock: any) =>{
							if(resDataStock.data[0]){
								this.product.stock = resDataStock.data[0].stock
							} else{
								this.product.stock = stock
							}
						})
					} */
					////////////// FIN actualizar
					
					if(data.data.lotes == "Y"){
						this.http.get(`${this.globals.urlApi}v3/cortas-prducto-ciudad/${ciudad}/${this.product.sku}`).subscribe( (resDataCortas: any) =>{
                            console.log("resDataCortas", resDataCortas)
							let cants = resDataCortas.data.map(el => parseInt(el.Stock))
                            console.log("cants", cants)
                            if(cants.length > 0){
                                this.product.stock = Math.max(...cants)
                            } else {
                                this.product.stock = 0
                            }
							console.log(" this.product.stock ", this.product.stock)
						})
					} else {
						this.http.get(`${this.globals.urlApi}v3/stock-de-ciudad/${ciudad}/${this.product.sku}`).subscribe( (resDataStock: any) =>{
							if(resDataStock.data[0]){
								this.product.stock = resDataStock.data[0].stock
							} else{
								this.product.stock = stock
							}
						})
					}

					/// desarrollo de la actualización

					/// FIN desarrollo de la actualización

					if (this.productos_cuponera.includes(this.product.idsap)) {
						this.verSplash(this.product.idsap);
					}
					this.imagen = `https://s3.amazonaws.com/iecommerce2019/${this.product.sku}/${this.product.sku}.jpg`;
					this.listado_imagenes.push(this.imagen)
					switch (this.product.idsap) {
						case '144125100023':
							this.product.precioecommerce = 157080;
							break;
						case '144125100024':
							this.product.precioecommerce = 125664;
							break;
						case '144125100022':
							this.product.precioecommerce = 141372;
							break;
						case '144125100025':
							this.product.precioecommerce = 329868;
							break;
						case '144125100018':
							this.product.precioecommerce = 141372;
							break;
						/* case '106105102018':
							this.product.precioecommerce = 124500;
							break; */
						default:
							break;
					};
					
					
					if (this.tipoUsuario != 1 && this.product.remate == true) {
						this.verTerminos();
					}
					
					/* this.marcaService.getV2MarcaIdsap(this.product?.codmarca).subscribe((marcaProducto: any) => {
						this.marcaProducto = marcaProducto.data// [0];
					
						this.getBannersMarca(this.marcaProducto)
					}) */
					this.getBannersMarca(parseInt(this.product.cod_marca))
					
					this.product.url = this.product.url.replace(/ /g, '-' ).toLowerCase()

					this.tagesService.setTitle(this.product.nombre)
					this.tagesService.setSocialMediaTags(this.product.url, this.product.nombre, this.product.nombre, this.imagen)
					
					this.addTag()
				} else {
					this.infoProducto = false;
				}
			})
		});
	}
	ngAfterViewInit() {
		/* this.slider = new KeenSlider(this.sliderRef.nativeElement)
		this.thumbnailSlider = new KeenSlider(
		  this.thumbnailRef.nativeElement,
		  {
			initial: 0,
			slides: {
			  perView: 4,
			  spacing: 10,
			},
		  },
		  [ThumbnailPlugin(this.slider)]
		) */
		
	}
	ngOnDestroy () {
		// this.sub.unsubscribe();
	}
	
	addTag(){
		this.metaService.updateTag({ name: 'og:image', content: this.imagen });
	}
	iniciarChatWhatsapp(){
		window.open('https://api.whatsapp.com/send?phone=573176488875&text=')
	}
	
	seleccionarImagen (posicion) {
		this.imagen = this.listado_imagenes[posicion];
	}
	
	public selectImage(image) {
		this.image = image;
		this.zoomImage = image;
	}
	public onMouseMove(e) {
		console.log("la e", e)
		if (window.innerWidth >= 1280) {
			let image, offsetX, offsetY, x, y, zoomer;
			image = e.currentTarget;
			offsetX = e.offsetX;
			offsetY = e.offsetY;
			x = offsetX / image.offsetWidth * 100;
			y = offsetY / image.offsetHeight * 100;
			zoomer = this.zoomViewer.nativeElement.children[0];
			if (zoomer) {
				zoomer.style.backgroundPosition = x + '% ' + y + '%';
				zoomer.style.display = 'block';
				zoomer.style.height = image.height + 'px';
				zoomer.style.width = image.width + 'px';
			}
		}
	}
	public onMouseLeave(event) {
		console.log("el evento de mouseleave", event)
		// this.zoomViewer.nativeElement.children[0].style.display = 'none';
	}
	public openZoomViewer() {
		this.dialog.open(ProductZoomComponent, {
			data: this.zoomImage,
			panelClass: 'zoom-dialog'
		});
	}
	public onSubmit(values: Object): void {
		if (this.form.valid) {
			// email sent
		}
	}
	public cambiarImagen() {
		// delete this.product.imagen;
		return this.imagen ? this.imagen : `https://logosmarcas.s3.amazonaws.com/0.png`
		// console.log('ops producto-default.jpeg')
	}
	verTerminos() {
		this.modalTerminos.getModal('infoTerminos').open()
	}
	codificar (data): string {
		let opt: string = '';
		var str = data.reduce(function(a, b) { return a + String.fromCharCode(b) }, '');
		opt = "data:image/jpg;base64," + btoa(str).replace(/.{76}(?=.)/g, '$&\n');
		return opt;
	}
	verSplash (id_producto: string) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.data = id_producto;
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.panelClass = 'registro-modal';
		dialogConfig.height = 'auto';
		const dialogReg = this.modalSplashProducto.open(SplashProductoComponent, dialogConfig);
	}
	formatearDescripcion(cadena: string) {
		let temp = cadena.split('@')
		this.descripcion_formateada = temp;
		for(let i = 0; i < this.descripcion_formateada.length ; i++){
			if(this.descripcion_formateada[i] == "") {
				this.descripcion_formateada.splice(i, 1);
			}
		}
	}
	getBannersMarca (cod_marca){
		this.http.get(this.globals.urlApi + 'marca-banners/' + cod_marca).subscribe((resdata:any) =>{
			if(resdata){
				this.banners = resdata;	
			} else {
				this.banners = [];
			}
		})
	}
	getNuevasCategorias (producto) {
		this.http.get(this.globals.urlApi + 'v2/producto-categorias/por-producto/' + producto.sku).subscribe( (resData:any) => {
			let idscates = resData.data.map(el => el.idCategoria)
			this.http.post(this.globals.urlApi + 'v2/categorias-arreglo', {ids:idscates}).subscribe( (segData:any) => {
				this.categoriasDeProducto = segData.data
			})
		})
	}
	getPrecio (producto){
		let datapeticion = {
			itemcode: producto.idsap,
			cardcode: "f"
		}
		this.productSvc.getPrecioProducto(producto.sku).subscribe((precios: any) => {
			
			producto.precio = precios.data.precio
			producto.descuento = precios.data.descuento
			producto.precioecommerce = precios.data.precioecommerce

			this.product.precio = precios.data.precio
			this.product.descuento = precios.data.descuento
			this.product.precioecommerce = precios.data.precioecommerce

			/* if(producto.idsap == '106105102018'){
				producto.precio = 104622
				producto.precioecommerce = 124500
			} */
		});
		/* this.http.post('https://ie-vie.com:3000/api/v2/precio-calc', datapeticion).subscribe( (resData: any) => {
			producto.precio = resData.valor_.precio
			producto.descuento = resData.valor_.descuento
			producto.precioecommerce = resData.valor_.precioecommerce
			if(producto.idsap == '106105102018'){
				producto.precio = 104622
				producto.precioecommerce = 124500
			}
		}) */
		
	}
	actualizarControles(){
		this.recargarControlesStock = !this.recargarControlesStock
	}

	async getProductosRelacionados(criterio:string){ // se traeran 5 productos de la misma marca ?
		const urlv3 = `${this.globals.urlApi}v3/productos/buscador`;
		await this.http.post(urlv3, {criterio: criterio}).subscribe((resDataProductosRelacionados: any)=>{
			if(resDataProductosRelacionados.status == 'ok'){
                let todoslosrelacionados = resDataProductosRelacionados.data
                this.verificarStockProductos(todoslosrelacionados)
			}
		})
		
	}

	async setStockAvailable(){
        this.productos_relacionados_validados = []
		const urlv3 = `${this.globals.urlApi}v3/stock-skus-ciudad`;
		let ciudad = this.appService.ciudadActual.plano_name;
		let arreglo_skus_relacionados = this.productos_relacionados.map( el => el.sku)

		await this.http.post(urlv3, {
			ciudad: ciudad,
			skus: arreglo_skus_relacionados
		}).subscribe(async (resDataStockSkus: any)=>{
			if(resDataStockSkus.status == 'ok'){
				let infoStock = resDataStockSkus.data
				await this.productos_relacionados.map( producto_relacionado => {
					let datatoupdate = infoStock.find(passtock => passtock.sku == producto_relacionado.sku)
					if(datatoupdate){
						producto_relacionado.stock = datatoupdate.stock
					} else {
						producto_relacionado.stock = 0
					}
                    if(producto_relacionado.stock > 0){
                        this.productos_relacionados_validados.push(producto_relacionado)
                    }
				})

			}
		})
        return this.productos_relacionados_validados
	}
    verificarStockProductos (arreglo_productos) {
        let prods_rta = []
        const urlv3 = `${this.globals.urlApi}v3/stock-skus-ciudad`;
        let ciudad = this.appService.ciudadActual.plano_name;
        let arreglo_skus_relacionados = arreglo_productos.map( el => el.sku)
        this.http.post(urlv3, {
			ciudad: ciudad,
			skus: arreglo_skus_relacionados
		}).subscribe(async (resDataStockSkus: any)=>{
			if(resDataStockSkus.status == 'ok'){
				let infoStock = resDataStockSkus.data
                prods_rta = await arreglo_productos.filter( producto_relacionado => {
					let datatoupdate = infoStock.find(passtock => passtock.sku == producto_relacionado.sku);
                    if (datatoupdate){
                        producto_relacionado.stock = datatoupdate.stock
                        this.productoService.getPrecioProducto(producto_relacionado.sku).subscribe((resPrecio: any) =>{
                            producto_relacionado.descuento = resPrecio.data.descuento
                            producto_relacionado.precio = resPrecio.data.precio
                            producto_relacionado.precioecommerce = resPrecio.data.precioecommerce ? resPrecio.data.precioecommerce : 0
                        })
                        return datatoupdate
                    }
                })
                this.productos_relacionados_validados = prods_rta
                this.productos_relacionados_validados = this.getAleatorios(this.productos_relacionados_validados, 5)
                
                return prods_rta
			}
		})        
    }

    getAleatorios(arreglo, cant_aleatorios){
        if (cant_aleatorios > arreglo.length) {
            return []
            throw new Error('El número de elementos a seleccionar es mayor que el tamaño del arreglo');
        }
        let arrCop = [...arreglo];
        arrCop.sort(()=> Math.random()-0.5)
        return arrCop.slice(0,cant_aleatorios)
    }

}
