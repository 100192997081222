export class Producto {
	id: number;
	idsap: string;
	nombre: string;
	bodega: string;
	referencia: string;
	stock: number;
	precio: number;
	descuento: number;
	iva: number;
	precioecommerce: number;
	remate: boolean;
	imagen: string;
	longitud: number;
	alto: number;
	ancho: number;
	volumen: number;
	peso: number;
	fichatecnica: any;
	destacado: boolean;
	promocion: boolean;
	nuevo: boolean;
	activo: boolean
	unidadlongitud: number;
	unidadalto: number;
	unidadancho: number;
	unidadvolumen: number;
	unidadpeso: number;
	idmarca: number;
	createdAt: any;
	updatedAt: any;
	enCarrito: number;
	categorias: any;
	reffab: string;
	descripcion: any;
	url: string;
	codigoie: string;
	show: boolean;
}
