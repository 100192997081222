import { Component, Input } from '@angular/core';
import { SidenavMenuService } from './sidenav-menu.service';
import { FlatTreeControl } from '@angular/cdk/tree';
import { ArrayDataSource } from '@angular/cdk/collections';
import { IeAuthService } from '../../../services/ieauth.service';
import { MatSidenav } from '@angular/material/sidenav';


// https://stackblitz.com/run?file=src%2Fexample%2Fcdk-tree-flat-example.html,src%2Fexample%2Fcdk-tree-flat-example.ts,src%2Findex.html
const ARBOL_MENU: NodoMenu[] = [
	{
		name: 'Nosotros',
		expandable: true,
		level: 0,
	},
	{
		name: 'La compañía',
		expandable: false,
		level: 1,
		link: '/nosotros'
	},{
		name: 'Nuestra misión',
		expandable: false,
		level: 1,
		link: '/nosotros'
	},{
		name: 'Nuestra historia',
		expandable: false,
		level: 1,
		link: '/nosotros'
	},{
		name: 'Sucursales',
		expandable: false,
		level: 0,
		link: '/sucursales'
	},{
		name: 'Unidades de negocio',
		expandable: true,
		level: 0,
	},{
		name: 'Energía, equipos & herramientas',
		expandable: false,
		level: 1,
		link: '/equipos&herramientas'
	},{
		name: 'Construcción',
		expandable: false,
		level: 1,
		link: '/construccion'
	},{
		name: 'Iluminación & control',
		expandable: false,
		level: 1,
		link: '/iluminacion'
	},{
		name: 'Telco & seguridad',
		expandable: false,
		level: 1,
		link: '/telco'
	},{
		name: 'Sostenibilidad',
		expandable: false,
		level: 1,
		link: '/solar'
	},{
		name: 'Industria',
		expandable: false,
		level: 1,
		link:'/oil&gas'
	},{
		name: 'Servicios de ingeniería',
		expandable: false,
		level: 1,
	},{
		name: 'Tecnología',
		expandable: false,
		level: 1,
		link: '/proyectos'
	},{
		name: 'Software',
		expandable: false,
		level: 1,
		link:'/tecnologia'
	},{
		name: 'Campañas',
		expandable: false,
		level: 0,
		link: '/campañas'
	},{
		name: 'Aliados',
		expandable: false,
		level: 0,
		link: '/aliados'
	},{
		name: 'Recaudo electrónico',
		expandable: false,
		level: 0,
		link: 'recaudo-electronico'
	},{
		name: 'Tienda online',
		expandable: true,
		level: 0,
	},{
		name: 'Buscador de productos',
		expandable: false,
		level: 1,
		link: '/buscador'
	},{
		name: 'Cotizaciones',
		expandable: false,
		level: 1,
		link: '/cotizaciones'
	},{
		name: 'Contáctanos',
		expandable: false,
		level: 0,
		link: '/contacto'
	},{
		name: 'Iniciar sesión',
		expandable: false,
		level: 1,
		link: '/acceder'
	}
]

interface NodoMenu {
	expandable: boolean;
	name: string;
	level: number;
	isExpanded?: boolean,
	link?: string
}


@Component({
	selector: 'app-sidenav-menu',
	templateUrl: './sidenav-menu.component.html',
	styleUrls: ['./sidenav-menu.component.scss'],
	providers: [SidenavMenuService],
})
export class SidenavMenuComponent {
	logueado: boolean = false
	
	constructor(
		private _auth: IeAuthService
	){
		this._auth.usuario.subscribe( olo => {
			this.logueado = olo ? true : false
			/* console.log(olo)
			if(olo) */
		})
	}
	ngOnInit(){
		/* console.log(this._auth.getCurrentUser())
		this._auth.usuario.subscribe(a => {
			console.log(a)
		})
		if(this._auth.getCurrentUser()){
			this.logueado = true
		} else {
			this.logueado = false
		} */
		
	}

	//v2
	@Input('sidenav') sidenav: MatSidenav;
	treeControl = new FlatTreeControl<NodoMenu>(
		node => node.level,
		node => node.expandable,
	);
	dataSource = new ArrayDataSource(ARBOL_MENU);
	hasChild = (_: number, node: NodoMenu) => node.expandable

	getParentNode(node: NodoMenu){
		const nodeIndex = ARBOL_MENU.indexOf(node);

		for(let i = nodeIndex - 1; i>= 0  ; i--){
			if(ARBOL_MENU[i].level === node.level -1){
				return ARBOL_MENU[i]
			}
		}
		return null
	}

	shouldRender(node: NodoMenu){
		let parent = this.getParentNode(node);
		while(parent){
			if(!parent.isExpanded){
				return false;
			}
			parent = this.getParentNode(parent)
		}
		return true
	}

	cerrarMenu(){
		this.sidenav.close()
	}
}
