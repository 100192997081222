import { Injectable } from '@angular/core';
import { Globals } from '../globals';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

const httpOptions = {
	headers: new HttpHeaders({ 'Content-Type': 'application/json'})
};

@Injectable({
	providedIn: 'root'
})
export class CuponesService {
	private cuponesUrl =  '';  // URL to web api
	constructor(
		private globals: Globals,
		private http: HttpClient,
	) {
		this.cuponesUrl = this.globals.urlApi + 'cupones';
	}

	crearCupon (cupon): Observable<any> {
		return this.http.post(this.cuponesUrl, cupon, httpOptions);
	}
	getCupones (): Observable<any> {
		return this.http.get(this.cuponesUrl);
	}
	actualizarCupon (cupon) : Observable<any> {
		return this.http.put(this.cuponesUrl + `/actualizar`, cupon, httpOptions);
	}
	deleteCupon (id: number): Observable<any> {
		return this.http.delete(this.cuponesUrl + `/${id}`);
	}
}
