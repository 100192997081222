import { Directive, ElementRef, EventEmitter, Output } from '@angular/core';

@Directive({
	selector: '[appInView]'
})
export class InViewDirective {
	@Output() inView: EventEmitter<any> = new EventEmitter();

	constructor(
		private el: ElementRef
	) { }

	ngOninit(){
	
		const options = {
			root: null,
			rootMargin: '0px',
			threshold: 0.5,
		};
		const observer = new IntersectionObserver(
			([entry]) => {
				if(entry.isIntersecting){
					this.inView.emit();
					observer.unobserve(this.el.nativeElement)
				}
			},
			options
		);
		observer.observe(this.el.nativeElement)
	}

}
