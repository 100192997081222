import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Globals } from '../../globals';
import { BuscadorService } from '../../services/buscador.service';
import { Observable, of } from 'rxjs';
import { AppService } from '../../app.service';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
	selector: 'app-buscador',
	templateUrl: './buscador.component.html',
	styleUrls: ['./buscador.component.scss']
})
export class BuscadorComponent implements OnInit {
	buscador: UntypedFormControl;
	opciones: any[] = [];
	filteredOptions: Observable<any[]>;
	largocadena: number = 0;
	cargandoProductosBuscador: boolean = false;

	laciudad
	criterio_busqueda = '';

	constructor(
		private http: HttpClient,
		private globals: Globals,
		private buscadorService: BuscadorService,
		private appService: AppService,
		private router: Router,
		private activatedRoute: ActivatedRoute
	) { 
		this.laciudad = this.appService.getCiudadActual()
	}

	ngOnInit(): void {
		let cadenaTemp = ''
		this.buscador = new UntypedFormControl();
		this.buscador.valueChanges.subscribe( async cadenaBuscada => {
			let cadenaaux = this.buscador.value
			await setTimeout( () => {
				this.cargandoProductosBuscador = true;
				this.filteredOptions = null;
				this.largocadena = cadenaBuscada ? cadenaBuscada.length : 0;
				if (this.largocadena > 2 && cadenaaux == cadenaTemp) {
					this.buscarCadena(cadenaBuscada.replace('/', '%2F').replace(' ', '%20')).subscribe((productsFound: any) => {
						this.criterio_busqueda = cadenaBuscada ? cadenaBuscada : 'vie';
						productsFound.data.forEach(producto => {
							producto['show'] = false
							producto['url'] = producto.url?.split(' ').join('-');
						})
						this.filteredOptions = of(productsFound.data)
						this.cargandoProductosBuscador = false;
					});
				} else {
					this.cargandoProductosBuscador = false;
				}
			}, 500);
			cadenaTemp = cadenaBuscada
		});
	}

	ejecutarBusqueda() {
		let cadenaBuscada = this.buscador.value
		this.largocadena = cadenaBuscada.length
		if (cadenaBuscada.length > 2) {
			this.filteredOptions = null;
			this.largocadena = 0;
			this.buscarCadena(cadenaBuscada).subscribe((productsFound: any[]) => {
				productsFound.forEach(prod => {
					prod['show'] = false
				})
				this.filteredOptions = of(productsFound)
			});
		}
	}
	buscarCadena(cadena: string) {
		let cadena_normalizada = cadena.replace(new RegExp('%20', 'g'), ' ').replace(new RegExp('%2F', 'g'), '/')
		
		//const url = `${this.globals.urlApi}v2/productos-buscador/${this.laciudad.plano_name}/${cadena}`; //TODO:activar v2
		/* const url = `${this.globals.urlApi}productos-buscador/${cadena}`; */
		//return this.http.get(url);

		const urlv3 = `${this.globals.urlApi}v3/productos/buscador`;
		return this.http.post(urlv3, {criterio: cadena_normalizada})
	}
	verProductosResultados() {
		
		this.criterio_busqueda = this.buscador.value ? this.buscador.value : 'vie';
		this.criterio_busqueda = this.criterio_busqueda.replace(new RegExp('/', 'g'), '%2F')

		//this.router.navigate([`/productos/1/12?criterio=${this.criterio_busqueda}`]);
		

		

		this.filteredOptions.subscribe((res: any) => {
			this.opciones = res;
			this.buscadorService.resultadosBuscador = true;
			this.buscadorService.opcionesResultados = this.opciones;

			const queryParams: Params = {
				criterio: this.criterio_busqueda
			}

			this.router.navigate([`/productos/1/12`], {
				relativeTo: this.activatedRoute,
				queryParams,
				queryParamsHandling: 'merge'
			});
			
			/* if(this.router.url.includes('resultados')){
				this.router.navigateByUrl('/blog').then( algo => {
					if(algo){
						this.router.navigate([`/productos/1/12?criterio=${criterio_busqueda}`]);
					}
				})
				
			} else {
				this.router.navigate([`/productos/1/12?criterio=${criterio_busqueda}`]);
			} */
			this.filteredOptions = null;
			this.largocadena = 0;
			this.buscador.setValue('');
			this.cargandoProductosBuscador = false;
		})
	}
	verTodosEnter(event) {
		if (this.largocadena > 2 && (event.keyCode === 13 || event.key == 'Enter')) {
			this.buscarCadena(event.target.value) 
			this.verProductosResultados();
		}
	}

	selectedItem(producto){
		let datos = [
			{
				item_name: producto.nombre,
				item_id: producto.idsap,
				price: producto.precioecommerce,
				item_brand: producto.idmarca,
				quantity: 1
			}
		]
		this.appService.enviarTag('select_item', datos)
	}

    identify(item){
        return item.sku; 
     }

}
