import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-telco',
  templateUrl: './telco.component.html',
  styleUrls: ['./telco.component.scss']
})
export class TelcoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  iniciarChatWhatsapp(){
	window.open('https://api.whatsapp.com/send?phone=573176488875&text=')
  }

}
