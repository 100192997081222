import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppService } from '../../app.service';
import { Globals } from '../../globals';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
	selector: 'app-linea-etica',
	templateUrl: './linea-etica.component.html',
	styleUrls: ['./linea-etica.component.scss']
})
export class LineaEticaComponent implements OnInit {
	public relacionConEmpresa = [
		"Colaborador",
		"Proveedor",
		"Cliente",
		"Otro"
	];
	public tipoReporte = [
		"Fraude",
		"Robo",
		"Soborno",
		"Conducta inapropiada",
		"Otro"
	]
	@ViewChild('recaptcha', {static: true }) recaptchaElement: ElementRef;
	validado: boolean = false;
	formulario: UntypedFormGroup

	misArchivos:string[] = [];

	enviando: boolean = false;
	enviado: boolean = false;
	cargando_archivos: boolean = false;
	archivos_cargados: boolean = false;

	constructor(
		private _ngxSmartModalService: NgxSmartModalService,
		private http: HttpClient,
		private globals: Globals,
		private formBuilder: UntypedFormBuilder,
		private appService: AppService
	) {
		this.formulario = this.formBuilder.group({
			fecha_evento: ['', [ Validators.required]],
			ciudad: ['', Validators.required],
			nombre_completo: [''],
			email: [''],
			telefono: [''],
			relacion_empresa: [null, Validators.required], // new FormControl(null, Validators.required),
			tipo_reporte: [null, Validators.required], //  new FormControl(null, Validators.required),
			descripcion: ['', Validators.required], // new FormControl('', Validators.required),
			archivos: [],
			tyc: [false, Validators.requiredTrue],
			tdp: [false, Validators.requiredTrue]
		})
	}

	ngOnInit(): void {
	}
	ngAfterViewInit() {
		this.renderReCaptcha()
	}
	verModal(nombreModal: string){
		this._ngxSmartModalService.getModal(nombreModal).open()
	}
	preventDefault($event){
		$event.preventDefault();
	}
	onFileChange($event){
		if($event.target.files.length > 0) {
			for(let i = 0; i < $event.target.files.length; i++){
				this.misArchivos.push($event.target.files[i]);
			}
		}
	}

	public onFormularioSubmit(values: Object): void {
		this.enviando = true;
		this.cargando_archivos = true
		console.log("los datos de envío ==>", values)
		const formData = new FormData();
		for(var i = 0; i < this.misArchivos.length; i++) {
			formData.append("file[]", this.misArchivos[i]);
		}
		
		this.http.post('https://www.iegrupo.co/servicios/linea-etica/upload.php', formData).subscribe((res: any )=>{
			this.cargando_archivos = false;
			this.archivos_cargados = true;
			this.http.post('https://www.iegrupo.co/servicios/linea-etica/enviarCorreoLineaEtica.php',values).subscribe((resMail: any)=>{
				this.enviando = false;
				this.enviado = true;
				this.appService.mensajeSnackBar('Se ha notificado al area correspondiente.', 5000, 'success', 'x')
			}, error => {
				console.log("ERROR!!!!", error)	
			})
		}, error =>{
			console.log("ERROR!!!!", error)
		})

		// console.log("valores formulario", values)
	}

	addRecaptchaScript() {
		window['grecaptchaCallback'] = () => {
			this.renderReCaptcha();
		}
		(function(d, s, id, obj){
			var js, fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) { return;}
			js = d.createElement(s); js.id = id;
			js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
			fjs.parentNode.insertBefore(js, fjs);
		}(document, 'script', 'recaptcha-jssdk', this));
	   
	}
	renderReCaptcha() {
		window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
		  'sitekey' : '6LfAkhoaAAAAABTbyWODa_6m58zqawfcrIhVm_CE',
		  'callback': (response) => {
			  // console.log(response);
			  this.http.post(this.globals.backServiciosECommerce + 'captcha.php', {response}).subscribe((res:any) =>{
				  // console.log("res captcha ==> ", res)
				  	if(res.success === true) {
						
						this.validado = true;
						
					  	setInterval(function(){
							
							  this.validado = false
							
						}, 120000);
				  	}
			  })
		  }
		});
		//this.validado = true
	}

}
