import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../../globals';

@Component({
	selector: 'app-articulo',
	templateUrl: './articulo.component.html',
	styleUrls: ['./articulo.component.scss']
})
export class ArticuloComponent implements OnInit {
	articulo
	hover: boolean;
	hover1: boolean;
	hover2: boolean;
	hover3: boolean;
	hover4: boolean;

	constructor(
		private activatedRoute: ActivatedRoute,
		private http: HttpClient,
		private globals: Globals,
		private router: Router
	) { }

	ngOnInit() {
		// console.log('que onda', this.activatedRoute.snapshot.params);
		// this.activatedRoute.params.subscribe( async parametros => {
		this.http.get(this.globals.urlApi + 'articulo/' + this.activatedRoute.snapshot.params.url).subscribe(res => {
			this.articulo = res
		})
		// });
	}
	goBlog () {
		this.router.navigate(['blog']);
	}

}
