import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Marca } from './../models/marca';
import { Globals } from '../globals';

@Injectable({
	providedIn: 'root'
})
export class MarcaService {
	private MarcasUrl = '';  // URL to web api
	constructor(
		private globals: Globals,
		private http: HttpClient
	) {
		this.MarcasUrl = this.globals.urlApi + 'marcas';
	}

	getMarcas(): Observable<Marca[]> {
		return this.http.get<Marca[]>(this.MarcasUrl);
	}

	getMarcasActivas(): Observable<Marca[]> {
		const url = `${this.MarcasUrl}/activas`;
		return this.http.get<Marca[]>(url);
	}

	getMarca(id: number): Observable<Marca> {
		const url = `${this.MarcasUrl}/${id}`;
		return this.http.get<Marca>(url);
	}

	getMarcaIdsap(id: number): Observable<Marca> { //deprecar
		const url = `${this.MarcasUrl}/marca/${id}`;
		return this.http.get<Marca>(url);
	}
	getV2MarcaIdsap(id: number): Observable<Marca> { 
		const url = `${this.globals.urlApi}v2/marcas/marca/${id}`;
		return this.http.get<Marca>(url);
	}


	getMarcasCategoria(idcategoria): Observable<Marca[]> {
		const url = `${this.MarcasUrl}/categoria/${idcategoria}`;
		return this.http.get<Marca[]>(url);
	}

	/* addMarca(Marca: Marca): Observable<Marca> {
		return this.http.post<Marca>(this.MarcasUrl, Marca, httpOptions);
	}

	deleteMarca(Marca: Marca | number): Observable<Marca> {
		const id = typeof Marca === 'number' ? Marca : Marca.id;
		const url = `${this.MarcasUrl}/${id}`;

		return this.http.delete<Marca>(url, httpOptions);
	}

	updateMarca(Marca: Marca): Observable<any> {
		return this.http.put(this.MarcasUrl, Marca, httpOptions);
	} */
}
