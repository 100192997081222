import { CommonModule } from '@angular/common';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_MENU_SCROLL_STRATEGY, MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input'; 

import { NgxPaginationModule } from 'ngx-pagination';

// maps


import { OverlayContainer, Overlay } from '@angular/cdk/overlay';

import { CustomOverlayContainer } from './theme/utils/custom-overlay-container';
import { menuScrollStrategy } from './theme/utils/scroll-strategy';

import { SharedModule } from './shared/shared.module';
import { routing } from './app.routing';
import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { TopMenuComponent } from './theme/components/top-menu/top-menu.component';
import { MenuComponent } from './theme/components/menu/menu.component';
import { SidenavMenuComponent } from './theme/components/sidenav-menu/sidenav-menu.component';
import { BreadcrumbComponent } from './theme/components/breadcrumb/breadcrumb.component';

import { AppSettings } from './app.settings';
import { AppService } from './app.service';
import { AppInterceptor } from './theme/utils/app-interceptor';
import { OptionsComponent } from './theme/components/options/options.component';
import { FooterComponent } from './theme/components/footer/footer.component';
import { DialogoRegistroComponent } from './pages/pages.component';
import { HojaLateralComponent } from './pages/pages.component';
import { ModalRegistroComponent } from './pages/pages.component';
import { ModalPagosComponent } from './pages/pages.component';
import { ModalLoginComercialComponent } from './theme/components/top-menu/top-menu.component';
import { SplashProductoComponent } from './pages/products/product/product.component';
import { TerminosCondicionesComponent } from './pages/terminos-condiciones/terminos-condiciones.component';
import { PoliticaDatosComponent } from './pages/politica-datos/politica-datos.component';
import { PoliticaCookiesComponent } from './pages/politica-cookies/politica-cookies.component';

// paginas IE
import { AlcanceSgiComponent } from './pages/alcance-sgi/alcance-sgi.component';
import { PoliticaHseqComponent } from './pages/politica-hseq/politica-hseq.component';
import { SedesComponent } from './pages/sedes/sedes.component';
import { DevopsComponent } from './pages/devops/devops.component';

// servicios
import { ProductoService } from './services/producto.service';
import { MarcaService } from './services/marca.service';
import { SedeService } from './services/sede.service';
import { GeocodeService } from './services/geocode.service';
import { IeAuthService } from './services/ieauth.service';
import { VisorRolutosService } from './services/visor-rolutos.service';
import { PedidosService } from './services/pedidos.service';
import { UsuarioService } from './services/usuario.service';
import { CuponesService } from './services/cupones.service';
import { MetafrenzyModule } from 'ngx-metafrenzy';
import { SubirarchivosService } from './services/subirarchivos.service';

// globals
import { Globals } from './globals';

// ngx-smart-modal
import { NgxSmartModalModule, NgxSmartModalService } from 'ngx-smart-modal';
import { MantenimientoComponent } from './pages/mantenimiento/mantenimiento.component';

//ngx-image-zoom
import { NgxImageZoomModule } from 'ngx-image-zoom';


// necesario para usar el datepicker de MAT
const MY_NATIVE_DATE_FORMATS = {
	parse: {
		dateInput: 'LL',
	},
	display: {
		dateInput: 'LL',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
}

// ng-autocomplete
import { NgSelectModule } from '@ng-select/ng-select';

// cookies
import { CookieService } from 'ngx-cookie-service';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { ConsentimientoDatosComponent } from './pages/consentimiento-datos/consentimiento-datos.component';
import { RematesComponent } from './pages/remates/remates.component';
import { LandingProductosComponent } from './pages/landing-productos/landing-productos.component';
import { RegistroComponent } from './pages/registro/registro.component';
import { ProductoDummyComponent } from './pages/account/comercial/cotizaciones/cotizaciones.component';
import { ClienteInfoComponent } from './pages/account/comercial/clientes/clientes.component';
import { ArticuloEditComponent } from './pages/account/admin/articulos/articulos.component';
import { ChatComponent } from './pages/chat/chat.component';
import { PagoCotizacionComponent } from './pages/account/orders/orders.component';
import { MercadosconamorComponent } from './pages/mercadosconamor/mercadosconamor.component';
import { CategoriasComponent } from './pages/categorias/categorias.component';

const cookieConfig: NgcCookieConsentConfig = {
	cookie: {
		"domain": "https://www.iegrupo.co"
	},
	"position": "bottom-left",
	"theme": "edgeless",
	"palette": {
		"popup": {
			"background": "#000000",
			"text": "#ffffff",
			"link": "#ffffff"
		},
		"button": {
			"background": "#f1d600",
			"text": "#000000",
			"border": "transparent"
		}
	},
	layout: 'ban-galletas',
	layouts: {
		"ban-galletas": '{{messagelink}}{{compliance}}'
	},
	elements: {
		messagelink: `
	  <span id="cookieconsent:desc" class="cc-message">
	  	{{message}}
		<a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{href}}" target="_blank">{{link}}</a>
	  </span>
	  `,
	},
	"type": "info",
	"content": {
		"message": "Utilizamos cookies propias y de terceros para mejorar la experiencia del usuario a través de su navegación. Si continúas navegando, aceptas su uso.",
		"dismiss": "Entendido",
		"deny": "Rechazar cookies",
		"link": "Política de cookies",
		"href": "https://www.iegrupo.co/#/politica-cookies",
		"policy": "Cookie Policy"
	}
};

import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';
import { ArticuloComponent } from './pages/articulo/articulo.component';
import { BlogComponent } from './pages/blog/blog.component';
import { NgxUploaderModule } from 'ngx-uploader';
import { CalculadoraSolarComponent } from './pages/calculadora-solar/calculadora-solar.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { LayoutModule } from '@angular/cdk/layout';


import { LandingCampanaIelectricistasComponent } from './pages/landing-campana-ielectricistas/landing-campana-ielectricistas.component';

import { LineaEticaComponent } from './pages/linea-etica/linea-etica.component';
  
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

import { NgxCaptchaModule } from 'ngx-captcha';


//SOCKET IO
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
const configIO: SocketIoConfig = {
	url: 'https://ie-vie.com:3000',
	options: {
		transports: ['websocket']
	}
}


import { ConstruccionComponent } from './pages/construccion/construccion.component';
import { TelcoComponent } from './pages/telco/telco.component';
import { IluminacionComponent } from './pages/iluminacion/iluminacion.component';
import { ProyectosComponent } from './pages/proyectos/proyectos.component';
import { EquiposHerramientasComponent } from './pages/equipos-herramientas/equipos-herramientas.component';
import { TecnologiaComponent } from './pages/tecnologia/tecnologia.component';
import { OilGasComponent } from './pages/oil-gas/oil-gas.component';
import { SolarComponent } from './pages/solar/solar.component';


import { InViewDirective } from './directivas/in-view.directive';
import { GoogleTagManagerModule, GoogleTagManagerService } from 'angular-google-tag-manager';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { DestacadosComponent } from './pages/destacados/destacados.component';

import { CdkTreeModule } from '@angular/cdk/tree';
import { OnViewPortDirective } from './directivas/on-view-port.directive';
import { ScheduleModule, RecurrenceEditorModule } from '@syncfusion/ej2-angular-schedule';




@NgModule({
    imports: [
        NgSelectModule,
        BrowserModule,
        BrowserModule.withServerTransition({ appId: 'iegrupo' }),
        CommonModule,
        TransferHttpCacheModule,
        HttpClientModule,
        BrowserAnimationsModule,
        HttpClientJsonpModule,
        SharedModule,
        routing,
        ReactiveFormsModule,
        FormsModule,
        NgxCaptchaModule,
        MatCheckboxModule,
        NgxSmartModalModule.forRoot(),
        MatButtonModule, MatCardModule, MatToolbarModule, MatMenuModule,
        MatCheckboxModule,
        MatDatepickerModule, MatNativeDateModule, MatDialogModule,
        NgcCookieConsentModule.forRoot(cookieConfig),
        NgxPaginationModule,
        MetafrenzyModule.forRoot(),
        RichTextEditorModule,
        NgxUploaderModule,
        NgImageSliderModule,
        ServiceWorkerModule.register('ngsw-config.js'),
        MatDialogModule,
        LayoutModule,
        SocketIoModule.forRoot(configIO),
        MatAutocompleteModule,
        MatInputModule,
        NgxIntlTelInputModule,
        CdkTreeModule,
        NgxImageZoomModule,
        GoogleTagManagerModule,
        ScheduleModule,
        RecurrenceEditorModule
    ],
    declarations: [
        AppComponent,
        PagesComponent,
        NotFoundComponent,
        TopMenuComponent,
        MenuComponent,
        SidenavMenuComponent,
        BreadcrumbComponent,
        OptionsComponent,
        FooterComponent,
        AlcanceSgiComponent,
        PoliticaHseqComponent,
        SedesComponent,
        MantenimientoComponent,
        DialogoRegistroComponent,
        HojaLateralComponent,
        ModalRegistroComponent,
        ModalPagosComponent,
        ModalLoginComercialComponent,
        SplashProductoComponent,
        TerminosCondicionesComponent,
        PoliticaDatosComponent,
        PoliticaCookiesComponent,
        ConsentimientoDatosComponent,
        RematesComponent,
        LandingProductosComponent,
        RegistroComponent,
        ProductoDummyComponent,
        ChatComponent,
        ClienteInfoComponent,
        PagoCotizacionComponent,
        ArticuloComponent,
        BlogComponent,
        ArticuloEditComponent,
        MercadosconamorComponent,
        CalculadoraSolarComponent,
        CategoriasComponent,
        LandingCampanaIelectricistasComponent,
        DevopsComponent,
        LineaEticaComponent,
        ConstruccionComponent,
        TelcoComponent,
        IluminacionComponent,
        ProyectosComponent,
        EquiposHerramientasComponent,
        TecnologiaComponent,
        OilGasComponent,
        SolarComponent,
        InViewDirective,
        DestacadosComponent,
        OnViewPortDirective
    ],
    providers: [
        AppSettings,
        AppService,
        ProductoService,
        MarcaService,
        SedeService,
        Globals,
        GeocodeService,
        IeAuthService,
        VisorRolutosService,
        PedidosService,
        UsuarioService,
        CuponesService,
        NgxSmartModalService,
        Meta,
        GoogleTagManagerService,
        CookieService,
        SubirarchivosService,
        { provide: OverlayContainer, useClass: CustomOverlayContainer },
        { provide: MAT_MENU_SCROLL_STRATEGY, useFactory: menuScrollStrategy, deps: [Overlay] },
        { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
        { provide: MAT_DATE_FORMATS, useValue: MY_NATIVE_DATE_FORMATS },
        { provide: MAT_DATE_LOCALE, useValue: 'es-CO' },
        {
            provide: SwRegistrationOptions,
            useFactory: () => ({ enabled: environment.production }),
        },
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { displayDefaultIndicatorType: false }
        },
        {
            provide: 'googleTagManagerId', useValue: 'GTM-56J8LQ6'
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
