import { Component, OnInit, Input } from '@angular/core';
import SwiperCore, { SwiperOptions, Autoplay, Pagination, Navigation } from 'swiper';

@Component({
	selector: 'app-galeria-camps',
	templateUrl: './galeria-camps.component.html',
	styleUrls: ['./galeria-camps.component.scss']
})
export class GaleriaCampsComponent implements OnInit {
	@Input('imagenes')
	imagenesg: Array<any> = [];
	config:SwiperOptions = {
		observer: true,
		spaceBetween: 15,
		keyboard: true,
		navigation: true,
		pagination: false,
		grabCursor: true,
		preloadImages: true,
		lazy: true,
		speed: 500,
		effect: "slide",
		autoplay: true,
		loop: true,
		breakpoints: {
			320: {
				slidesPerView: 1
			},
			480: {
				slidesPerView: 1
			},
			740: {
				slidesPerView: 1,
			},
			960: {
				slidesPerView: 4,
			},
			1280: {
				slidesPerView: 4,
			},
			1500: {
				slidesPerView: 4,
			},
			1800: {
				slidesPerView: 4,
			}
		}
	};
	
	constructor() {
	}
	
	ngOnInit(): void {
		// console.log("imagenes", this.imagenesg)
	
	}
	ngAfterViewInit(){
	
	}
	public trackByFn(index, item) {
		if (!item) { return null; }
		return index
	}

}
