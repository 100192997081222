import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
	selector: 'app-terminos-condiciones',
	templateUrl: './terminos-condiciones.component.html',
	styleUrls: ['./terminos-condiciones.component.scss'],
	animations: [
		trigger('fadeInAnimation', [
		  state('void', style({
			opacity: 0
		  })),
		  transition(':enter', [
			animate('500ms ease-in', style({
			  opacity: 1
			}))
		  ]),
		  transition(':leave', [
			animate('0ms ease-out', style({
			  opacity: 0
			}))
		  ])
		])
	  ]
})
export class TerminosCondicionesComponent implements OnInit {
	divActive: string;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
	) {
		this.route.queryParams.subscribe( params => {
			if(params.seccion){
				this.showDiv(params.seccion)
			}

		})
	}

	ngOnInit() {
	}
	
	showDiv(divName: string) {
		this.router.navigate([], {
			relativeTo: this.route,
			fragment: null,
			replaceUrl: true,
			queryParams: {seccion: divName}
		});
		this.divActive = divName;
	}

}
