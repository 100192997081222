import { Router } from '@angular/router';
import { Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import { AppService } from '../../../app.service';
import { Category } from '../../../app.models';
import { MarcaService } from '../../../services/marca.service';
import { BuscadorService } from '../../../services/buscador.service';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
	@Input() categories;
	@Input() marcas;
	ciudad;

	public category: Category;

	@ViewChild('subMenuNosotros', {static: false}) subMenuNosotros: ElementRef
	@ViewChild('subMenuSucursales', {static: false}) subMenuSucursales: ElementRef
	@ViewChild('subMenuUnidadesNegocio', {static: false}) subMenuUnidadesNegocio: ElementRef
	@ViewChild('subMenuTiendaOnline', {static: false}) subMenuTiendaOnline: ElementRef
	@ViewChild('subMenuRecaudo', {static: false}) subMenuRecaudo: ElementRef
	@ViewChild('panelOverlay', {static: false}) panelOverlay: ElementRef

	isOpen:boolean = true

	constructor(
		private appService: AppService,
		private router: Router,
		private marcasService: MarcaService,
		private buscador: BuscadorService,
		private _overlay: Overlay
	) {
		this.appService.compartidor.subscribe(datos =>{
			this.appService.setCiudadActualConPlanoName(datos.plano_name)
			this.ciudad = this.appService.ciudadActual
			// console.log("SX", this.ciudad)
		})
		
	}
	
	ngOnInit() {

		
	}
	ngAfterViewInit() {
	}
	openMegaMenu() {
		let pane = document.getElementsByClassName('cdk-overlay-pane');
		[].forEach.call(pane, function (el) {
			if (el.children.length > 0) {
				if (el.children[0].classList.contains('menu-categorias')) {
					el.classList.add('mega-menu-pane');
				}
			}
		});
	}
	isActive (instruccion: any): boolean {
		return this.router.isActive(this.router.createUrlTree(instruccion), false)
	}
	public estructurarCategorias (categoria: Category) {
		return this.categories.filter(category => category.parentId == categoria.id )
	}
	public actualizarBuscadorMarca(idmarca: number, nombreMarca: string) {
		this.buscador.objetoBuscadorProductos.categoria = null
		this.buscador.productosPorMarca(idmarca);
		this.router.navigate(['/marcas', nombreMarca]);
	}
	public actualizarBuscadorCategoria(idcategoria: number) {
		this.buscador.objetoBuscadorProductos.marca = null
		this.buscador.objetoBuscadorProductos.categoria = idcategoria;
		this.buscador.productosPorCategoria(idcategoria);
		this.router.navigateByUrl('/chat', {skipLocationChange: true}).then((paso) => {
			console.log(paso)
			if(paso){
				this.router.navigate(['/productos/1/12']);
			}
		});
	}
	cargarMarcas(): void {
		if (!this.marcas || this.marcas.length == 0) {
			this.marcasService.getMarcasActivas().subscribe(res => {
				res.forEach(marca => {
					marca['show'] = false
				})
				this.marcas = res;
				this.appService.Data.marcasA = this.marcas;
			});
		}
	}
	mostrarChat() {
		this.appService.PopupCenter('https://iegrupo.bitrix24.com/online/chat?user_lang=la', 'ie chat', 400, 750)
	}
	irATiendaOnline(){ // https://dev.to/ferfox1981/tree-ways-to-scroll-to-an-element-using-angular-1dlj
		document.getElementById("tienda-online")?.scrollIntoView({
			behavior: "smooth",
			block: "start",
			inline: "nearest"
		});
	}
	irAAliados(){
		document.getElementById("aliades")?.scrollIntoView({
			behavior: "smooth",
			block: "start",
			inline: "nearest"
		});
	}
	irACampanias(){
		document.getElementById("campanias")?.scrollIntoView({
			behavior: "smooth",
			block: "start",
			inline: "nearest"
		});
	}
	irAUnegocios(){
		document.getElementById("unegocios")?.scrollIntoView({
			behavior: "smooth",
			block: "start",
			inline: "nearest"
		});
	}
	verModalCiudades(){
		this.appService.verModalCiudades()
		this.ciudad = this.appService.ciudadActual	
	}

	// menus
	abrirSubMenuNosotros(){
		this.subMenuSucursales.nativeElement.classList.remove('activo');
		this.subMenuTiendaOnline.nativeElement.classList.remove('activo');
		this.subMenuUnidadesNegocio.nativeElement.classList.remove('activo');
		this.subMenuRecaudo.nativeElement.classList.remove('activo');

		this.panelOverlay.nativeElement.classList.add('ver-overlay');
		this.subMenuNosotros.nativeElement.classList.add('activo');
	}
	cerrarSubMenuNosotros(){
		/* setTimeout(()=>{ */
			this.panelOverlay.nativeElement.classList.remove('ver-overlay');
			this.subMenuNosotros.nativeElement.classList.remove('activo');
		/* }, 1000) */
	}

	abrirSubMenuSucursales(){
		this.subMenuNosotros.nativeElement.classList.remove('activo');
		this.subMenuTiendaOnline.nativeElement.classList.remove('activo');
		this.subMenuUnidadesNegocio.nativeElement.classList.remove('activo');
		this.subMenuRecaudo.nativeElement.classList.remove('activo');

		this.panelOverlay.nativeElement.classList.add('ver-overlay');
		this.subMenuSucursales.nativeElement.classList.add('activo');
	}
	cerrarSubMenuSucursales(){
		/* setTimeout(()=>{ */
			this.panelOverlay.nativeElement.classList.remove('ver-overlay');
			this.subMenuSucursales.nativeElement.classList.remove('activo');
		/* }, 1000) */
	}

	abrirSubMenuUnidadesNegocio(){
		this.subMenuNosotros.nativeElement.classList.remove('activo');
		this.subMenuSucursales.nativeElement.classList.remove('activo');
		this.subMenuTiendaOnline.nativeElement.classList.remove('activo');
		this.subMenuRecaudo.nativeElement.classList.remove('activo');

		this.panelOverlay.nativeElement.classList.add('ver-overlay');
		this.subMenuUnidadesNegocio.nativeElement.classList.add('activo');
	}
	cerrarSubMenuUnidadesNegocio(){
		/* setTimeout(()=>{ */
			this.panelOverlay.nativeElement.classList.remove('ver-overlay');
			this.subMenuUnidadesNegocio.nativeElement.classList.remove('activo');
		/* }, 1000) */
	}

	abrirSubMenuTiendaOnline(){
		this.subMenuNosotros.nativeElement.classList.remove('activo');
		this.subMenuSucursales.nativeElement.classList.remove('activo');
		this.subMenuUnidadesNegocio.nativeElement.classList.remove('activo');
		this.subMenuRecaudo.nativeElement.classList.remove('activo');

		this.panelOverlay.nativeElement.classList.add('ver-overlay');
		this.subMenuTiendaOnline.nativeElement.classList.add('activo');
	}
	cerrarSubMenuTiendaOnline(){
		/* setTimeout(()=>{ */
			this.panelOverlay.nativeElement.classList.remove('ver-overlay');
			this.subMenuTiendaOnline.nativeElement.classList.remove('activo');
		/* }, 1000) */
	}

	abrirSubMenuRecaudo( evento){
		this.subMenuNosotros.nativeElement.classList.remove('activo');
		this.subMenuSucursales.nativeElement.classList.remove('activo');
		this.subMenuUnidadesNegocio.nativeElement.classList.remove('activo');
		this.subMenuTiendaOnline.nativeElement.classList.remove('activo');

		this.panelOverlay.nativeElement.classList.add('ver-overlay');
		this.subMenuRecaudo.nativeElement.classList.add('activo');
		
	}
	cerrarSubMenuRecaudo(){
		/* setTimeout(()=>{ */
		this.panelOverlay.nativeElement.classList.remove('ver-overlay');
		this.subMenuRecaudo.nativeElement.classList.remove('activo');
		/* }, 1000) */
	}


	cerrarTodos(){
		this.subMenuNosotros.nativeElement.classList.remove('activo');
		this.subMenuSucursales.nativeElement.classList.remove('activo');
		this.subMenuUnidadesNegocio.nativeElement.classList.remove('activo');
		this.subMenuTiendaOnline.nativeElement.classList.remove('activo');
		this.subMenuRecaudo.nativeElement.classList.remove('activo');
		this.panelOverlay.nativeElement.classList.remove('ver-overlay');
	}


}
