import { Component, OnInit , Inject, ViewChild} from '@angular/core';
import { AppService } from '../../../app.service';
import { IeAuthService } from '../../../services/ieauth.service';
import { Router } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { emailValidator } from './../../../theme/utils/app-validators';
import { ComercialService } from '../../../services/comercial.service';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../../../globals';
import { MatMenuTrigger } from '@angular/material/menu';

// Login comerciales
export interface ModalComercialesData {
	idCuenta: string,
}
@Component ({
	selector: 'app-login-comerciales-modal',
	templateUrl: 'loginComercialModal.html'
})
export class ModalLoginComercialComponent {
	formLoginComercial: UntypedFormGroup;
	fotoIe = '';
	constructor (
		public dialogRef: MatDialogRef<ModalLoginComercialComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ModalComercialesData,
		public appService: AppService,
		private formBuilder: UntypedFormBuilder,
		public comercialService: ComercialService
	) {
		this.formLoginComercial = this.formBuilder.group({
			'email': ['', Validators.compose([Validators.required, emailValidator])],
			'password': ['', Validators.compose([Validators.required, Validators.minLength(6)])],
		});

	}
	onNoClick(): void {
		this.dialogRef.close();
	}
	onformLoginComercialSubmit(valor) {
		this.comercialService.loginComercial(valor.email, valor.password);
		this.dialogRef.close();
	}
}
// fin Login comerciales


@Component({
	selector: 'app-top-menu',
	styleUrls: ['./top-menu.component.scss'],
	templateUrl: './top-menu.component.html'
})
export class TopMenuComponent implements OnInit {
	public currencies = ['COP', 'USD'];
	public currency: any;
	public user: any;
	loggedIn: boolean;
	foto = ''
	public flags = [
		{ name: 'Spanish', image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Flag_of_Colombia.svg/640px-Flag_of_Colombia.svg.png' },
		{ name: 'English', image: 'assets/images/flags/gb.svg' },
	];
	public flag: any;

	//@ViewChild('menuLogin') menuLogin: MatMenuTrigger

	constructor(
		public appService: AppService,
		private ieAuthService: IeAuthService,
		private router: Router,
		private modalTerminos: NgxSmartModalService,
		public modalLoginComercial: MatDialog,
		private https: HttpClient,
		private globals: Globals
	) {
		this.ieAuthService.usuario.subscribe(usuario => {
			this.user = usuario;
			if(this.user){
				// this.user.email = this.user.email == 'jahernandez@iegrupo.co' ? 'jahernandez@iegrupo.co' : this.user.email
		
				this.https.get(`${this.globals.urlApi}empleadosie/empleado/${this.user.email}`).subscribe( (resData: any) =>{
					if(resData.status == "ok"){
						this.foto = resData.data.photo
					} else {
						this.foto = ''
					}
				})
			}
		});
	}

	ngOnInit() {
		if(!this.appService.getCiudadActual){
			this.appService.setCiudadActualConPlanoName('bogota')
		}
		this.currency = this.currencies[0];
		this.flag = this.flags[0];
	}
	ngOnDestroy(): void {
		//Called once, before the instance is destroyed.
		//Add 'implements OnDestroy' to the class.
		
		// this.ieAuthService.usuario?.unsubscribe();
	}
	ngAfterViewInit(): void {
		//Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
		//Add 'implements AfterViewInit' to the class.
		/* console.log("El menú se ha cargado");
		
		this.appService.contenido.subscribe( efe =>{
			console.log("ser cambió la ciudad => ", efe);
			(function (w, d, u) {
				var s = d.createElement('script'); s.async = true; s.src = u + '?' + (Date.now() / 60000 | 0);
				var h = d.getElementsByTagName('script')[0]; h.parentNode.insertBefore(s, h);
			})(window, document, 'https://cdn.bitrix24.com/b9590489/crm/site_button/loader_2_ffhp8s.js');
			(function (w, d, u) {
				var s = d.createElement('script'); s.async = true; s.src = u + '?' + (Date.now() / 60000 | 0);
				var h = d.getElementsByTagName('script')[0]; h.parentNode.insertBefore(s, h);
			})(window, document, 'https://cdn.bitrix24.com/b9590489/crm/tag/call.tracker.js');
		}) */
		
		/* (function (w, d, u) {
			var s = d.createElement('script'); s.async = true; s.src = u + '?' + (Date.now() / 60000 | 0);
			var h = d.getElementsByTagName('script')[0]; h.parentNode.insertBefore(s, h);
		})(window, document, 'https://cdn.bitrix24.com/b9590489/crm/site_button/loader_2_ffhp8s.js');
		(function (w, d, u) {
			var s = d.createElement('script'); s.async = true; s.src = u + '?' + (Date.now() / 60000 | 0);
			var h = d.getElementsByTagName('script')[0]; h.parentNode.insertBefore(s, h);
		})(window, document, 'https://cdn.bitrix24.com/b9590489/crm/tag/call.tracker.js'); */
	}

	openMenuUser(menuTrigger: MatMenuTrigger){
		menuTrigger.openMenu();
	}
	closeMenuUser(menuTrigger: MatMenuTrigger){
		menuTrigger.closeMenu();
	}

	public changeCurrency(currency) {
		this.currency = currency;
	}

	public changeLang(flag) {
		this.flag = flag;
	}
	public signInWithFB (): void {
		// this.authService.signIn(FacebookLoginProvider.PROVIDER_ID)
	}
	public signInWithGoogle (): void {
		// this.authService.signIn(GoogleLoginProvider.PROVIDER_ID)
	}
	signOut(): void {
		this.ieAuthService.logOutUser()
		this.foto = ''
		localStorage.removeItem('IeCurrentUser')
		localStorage.removeItem('currentUser')
		localStorage.removeItem('accesToken')
		if (this.loggedIn) {
			// this.authService.signOut();
		}
		this.router.navigate(['/acceder']);
	}
	verTerminos() {
		this.modalTerminos.getModal('infoTerminos').open()
	}
	abrirModalLoginComerciales () {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.panelClass = 'registro-modal';
		dialogConfig.height = 'auto';
		const dialogReg = this.modalLoginComercial.open(ModalLoginComercialComponent, dialogConfig);
	}
	/* desplegarMiMenu(){
		this.menuLogin.openMenu()
	} */
}
