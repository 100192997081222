import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Globals } from '../../globals';

import { SwiperComponent  } from 'swiper/angular';
import SwiperCore, { EffectCoverflow, Navigation, Pagination, Scrollbar, A11y, SwiperOptions, Swiper } from 'swiper';
SwiperCore.use([EffectCoverflow, Navigation, Pagination, Scrollbar, A11y]);

@Component({
	selector: 'app-landing-aliados',
	templateUrl: './landing-aliados.component.html',
	styleUrls: ['./landing-aliados.component.scss']
})
export class LandingAliadosComponent implements OnInit {
	@Input('inHome') inHome

	marcas:any[] = [
		/* {
			nombre: '3m',
			url_logo: '../../assets/images/aliados/3m.png'
		},
		{
			nombre: 'allied',
			url_logo: '../../assets/images/aliados/allied.png'
		},
		{
			nombre: 'centelsa',
			url_logo: '../../assets/images/aliados/centelsa.png'
		},
		{
			nombre: 'conectores',
			url_logo: '../../assets/images/aliados/conectores.png'
		},
		{
			nombre: 'fermax',
			url_logo: '../../assets/images/aliados/fermax.png'
		},
		{
			nombre: 'fuji',
			url_logo: '../../assets/images/aliados/fuji.png'
		},
		{
			nombre: 'gerfor',
			url_logo: '../../assets/images/aliados/gerfor.png'
		},
		{
			nombre: 'legrand',
			url_logo: '../../assets/images/aliados/legrand.png'
		},
		{
			nombre: 'leviton',
			url_logo: '../../assets/images/aliados/leviton.png'
		},
		{
			nombre: 'magnetron',
			url_logo: '../../assets/images/aliados/magnetron.png'
		},
		{
			nombre: 'panduit',
			url_logo: '../../assets/images/aliados/panduit.png'
		},
		{
			nombre: 'pemsa',
			url_logo: '../../assets/images/aliados/pemsa.png'
		},
		{
			nombre: 'schneider',
			url_logo: '../../assets/images/aliados/schneider.png'
		},
		{
			nombre: 'siemens',
			url_logo: '../../assets/images/aliados/siemens.png'
		},
		{
			nombre: 'tercol',
			url_logo: '../../assets/images/aliados/tercol.png'
		} */
	]

	grupos_habilitados = [
		{
			numero: 1,
			nombre: 'CABLES Y ALAMBRES',
			codigo: 109,
			nombre_completo: 'CABLES Y ALAMBRES DE ENERGIA Y COMUNICACION'
		},
		{
			numero: 2,
			nombre: 'TUBERIA Y ACCESORIOS',
			codigo: 141,
			nombre_completo: 'TUBERIA Y ACCESORIOS'
		},
		{
			numero: 3,
			nombre: 'TRANSFORMADORES',
			codigo: 139,
			nombre_completo: 'TRANSFORMADORES'
		},
		{
			numero: 4,
			nombre: 'DIST Y TRANS ENERGIA',
			codigo: 143,
			nombre_completo: 'DISTRIBUCION Y TANSMISION DE ENERGIA'

		},
		{
			numero: 5,
			nombre: 'SISTEM PUESTA/TIERRA',
			codigo: 101,
			nombre_completo: 'SISTEMAS PARA PUESTA A TIERRA'
		},
		{
			numero: 6,
			nombre: 'HERRAJERIA',
			codigo: 120,
			nombre_completo: 'HERRAJES PARA MEDIA Y ALTA TENSION'
		},
		{
			numero: 7,
			nombre: 'ILUMINACION',
			codigo: 121,
			nombre_completo: 'ILUMINACION'
		},
		{
			numero: 8,
			nombre: 'DISP Y APARATOS ELEC',
			codigo: 122,
			nombre_completo: 'DISPOSITIVOS Y APARATOS ELECTRICOS PARA INSTALACIONES ELECTRICAS'
		}
	]

	aliados_ordenados_landing = []
	criterio_ordenamiento = [25, 114, 908, 176, 91, 70, 649, 410, 90, 544, 643, 683, 253, 137, 47]

	categoriasMarcasAliadas = []
	listadoVisibleCategorias = []

	public configSwiperAliados: SwiperOptions = {
		autoplay: true,
		slidesPerView: 2,
		/* centeredSlides: true, */
		loop: true,
		navigation: true,
		breakpoints:{
			390:{
				slidesPerView: 2
			},
			640:{
				slidesPerView: 2
			},
			992:{
				slidesPerView: 2
			},
			1200:{
				slidesPerView: 5
			}
		}
	}

	constructor(
		public http: HttpClient,
		public globals: Globals,
	) {
		this.getMarcasAliadas() 
		
	}

	ngOnInit(): void {
		
	}
	getMarcasAliadas (){
		
		this.http.get(`https://ie-vie.com:3000/api/v2/marcas-aliadas`).subscribe( (resData:any) =>{
			if(resData.data){
				let tempMarcas = [...resData.data]
				// this.marcas.push(...resData.data)
				// console.log("temp ==> ", tempMarcas)
				// console.log("las marcas ==> ", this.marcas)
				this.criterio_ordenamiento.forEach( pos => {
					let foundedBrand = tempMarcas.find((v,i,a)=> v.id_marca === pos)
					// console.log("marca ordenada", foundedBrand.nombre)
					this.marcas.push(foundedBrand)
					//tempMarcas.push()
				})
				this.getCategoriasMarcasAliadas()
			}
			// this.marcas = resData.data
			//this.marcas = this.marcas.slice(0, 15)
			// console.log("le marcas", this.marcas)
		})
	}

	getCategoriasMarcasAliadas(){
		let bodyMarca = this.marcas.map( el => el.nombre?.toUpperCase())
		this.http.post(`https://ie-vie.com:3000/api/v3/categorias-marcas`, {marcas: bodyMarca}).subscribe( (resData: any) => {
			if(resData.status == 'ok'){
				// console.log("categorias", resData)
				this.categoriasMarcasAliadas = resData.data
				this.categoriasMarcasAliadas.forEach( categoriaBD => {
					this.grupos_habilitados.forEach(habilitada => {
						if(categoriaBD.nombre === habilitada.nombre){
							this.listadoVisibleCategorias.push(categoriaBD)
						}
					})
				})
			}
		})

	}

}
