import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-construccion',
  templateUrl: './construccion.component.html',
  styleUrls: ['./construccion.component.scss']
})
export class ConstruccionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  iniciarChatWhatsapp(){
	window.open('https://api.whatsapp.com/send?phone=573176488875&text=')
  }

}
