import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Producto } from './../models/producto';
import { Globals } from '../globals';

const httpOptions = {
	headers: new HttpHeaders({ 'Content-Type': 'application/json'})
};

@Injectable({
	providedIn: 'root'
})
export class ProductoService {
	private ProductosUrl =  '';  // URL to web api
	constructor(
		private globals: Globals,
		private http: HttpClient
	) {
		this.ProductosUrl = this.globals.urlApi + 'productos';
	}

	getProductos(inicio: number, fin: number): Observable<any> {
		const url = `${this.ProductosUrl}/${inicio}/${fin}`;
		return this.http.get<any>(url);
	}

	getProducto(id: number): Observable<Producto> {
		/* const url = `${this.ProductosUrl}/${id}`;
		return this.http.get<Producto>(url); */

		const url = `https://ie-vie.com:3000/api/v2/productos/producto/${id}`;
		return this.http.get<Producto>(url);

	}

	getV3Producto(id: number): Observable<any> {
		const url = `https://ie-vie.com:3000/api/v3/productos/producto/${id}`;
		return this.http.get<any>(url);
	}


	getProductoByNombre(nombre: string): Observable<Producto> {
		const url = `${this.globals.urlApi + 'producto'}/${nombre}`;
		return this.http.get<Producto>(url);
	}
	getProductosMarca(idmarca: number, inicio: number, fin: number): Observable<any> {
		const url = `${this.ProductosUrl}/marca/${idmarca}/${inicio}/${fin}`;
		return this.http.get<Producto>(url);
	}
	getProductosCategoria(idcategoria: number, inicio: number, fin: number, ciudad: string): Observable<any> {
		/* const url = `${this.ProductosUrl}/categoria/${idcategoria}/${inicio}/${fin}`;
		return this.http.get<Producto>(url); */

		const url = `https://ie-vie.com:3000/api/v2/productos/categoria/${idcategoria}/${inicio}/${fin}/${ciudad}`;
		return this.http.get<any>(url);
	}
	getProductosCategoriaMarca (id_categoria: number, id_marca: number, inicio: number, fin: number, ciudad: string): Observable<any> {
		
		/* const url = `${this.ProductosUrl}/categoria/${id_categoria}/marca/${id_marca}/${inicio}/${fin}`;
		return this.http.get<Producto[]>(url); */
				
		const url = `https://ie-vie.com:3000/api/v2/productos/categoria/${id_categoria}/marca/${id_marca}/${inicio}/${fin}/${ciudad}`;
		return this.http.get<any>(url);
	}

	getProductosBodegaInfo(idsap: string) {
		const url = `https://ie-vie.com:3000/api/v2/productos-bodegas/${idsap}`;
		return this.http.get<any>(url);
	}

	addProducto(Producto: Producto): Observable<Producto> {
		return this.http.post<Producto>(this.ProductosUrl, Producto, httpOptions);
	}

	deleteProducto(Producto: Producto | number): Observable<Producto> {
		const id = typeof Producto === 'number' ? Producto : Producto.id;
		const url = `${this.ProductosUrl}/${id}`;

		return this.http.delete<Producto>(url, httpOptions);
	}

	updateProducto(Producto: Producto): Observable<any> {
		return this.http.put(this.ProductosUrl, Producto, httpOptions);
	}
	
	getCategoriasProdcuto (idsapProducto: string) {
		const url = this.globals.urlApi + `producto-categorias/${idsapProducto}`;
		return this.http.get<any>(url);
	}
	getInfoCategoria ( id_categoria: any ) {
		const url = this.globals.urlApi + `categorias/${id_categoria}`;
		return this.http.get<any>(url);
	}
	getCategoriasProductoV2 (producto: any) {
		this.getCategoriasProdcuto(producto.sku).subscribe(res => {
			res.forEach(element => {
				let categorias = []
				this.getInfoCategoria(element.idCategoria).subscribe(info => {
					categorias.push(info)
					producto.categorias =  categorias;
				})
			});
		})
	}

	getProductosRemateHome (): Observable<any> {
		const url = this.ProductosUrl + `-remates/home`;
		return this.http.get<any>(url);
	}
	getProductosRemate (inicio: number, fin: number): Observable<any> {
		const url = this.ProductosUrl + `/remates/${inicio}/${fin}`;
		return this.http.get<any>(url);
	}
	getProductosPromocion (inicio: number, fin: number): Observable<any> {
		const url = this.ProductosUrl + `/promociones/${inicio}/${fin}`;
		return this.http.get<any>(url);
	}
	getProductosPromocionCiudad (ciudad): Observable<any> {
		/* const url = this.ProductosUrl + `-ciudad/promociones`;
		return this.http.post<any>(url, {data: {ciudad}}); */
		const url = `https://ie-vie.com:3000/api/v2/productos/promociones/${ciudad}`;
		return this.http.get<any>(url);
	}

	getPrecioProducto(sku) {
		const url = `https://ie-vie.com:3000/api/v2/productos/precios-publico/${sku}`;
		return this.http.get<any>(url);
	}
}
