import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-politica-datos',
	templateUrl: './politica-datos.component.html',
	styleUrls: ['./politica-datos.component.scss']
})
export class PoliticaDatosComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
