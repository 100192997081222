/* import { WINDOW } from '@ng-toolkit/universal'; */
import { Component, OnInit , Inject } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '../../app.service';
import { HttpClient } from '@angular/common/http';

// import { CalendarOptions } from '@fullcalendar/angular'; // useful for typechecking
import { Globals } from '../../globals';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
	selector: 'app-sedes',
	templateUrl: './sedes.component.html',
	styleUrls: ['./sedes.component.scss']
})
export class SedesComponent implements OnInit {
	sedes = []
	eventos = []
	bannerSede
	bannerSedeCalendario
	public zoom = 16;
	public id_sede;
	public indice = 0;
	public sedeActual: any;
	public pin_mapa =  './assets/images/others/icon_orange_mark_map.png';
	public breakpoint: number = 2;
	public img_waze = './assets/images/others/waze_icon_160x160.png';
	public img_moovit = './assets/images/others/moovit_256x256.png';

	public ieFbApi: any;
	public laLlave: string;
	public estadoSesionFB: boolean = false;
	// public monedaDeAcceso: any;

	public ciudad: any = null;

	formato_evento = {
		backgroundColor: '#ffe100',
		textColor: '#000000',
		borderColor: '#ffe100',
		color: '#ff0000',
	};

	public eventoActual: any;

	// opciones del mapa
	// https://github.com/angular/components
	centro:any

	constructor(
		/* @Inject(WINDOW) private window: Window,
		@Inject(WINDOW) private FB: any, */
		public servicio: AppService,
		private route: ActivatedRoute,
		private location: Location,
		private http: HttpClient,
		private appService: AppService,
		private globals: Globals,
		private gtmService: GoogleTagManagerService
	) {
		this.gtmService.addGtmToDom()
	}
	
	ngOnInit() {
		window.scrollTo(0,0);
		this.getSedes();
		this.breakpoint = (window.innerWidth <= 400) ? 1 : 2;
		//this.checkLoginState();
		this.appService.compartidor.subscribe( ciudad =>{
			if(ciudad){
				this.ciudad = ciudad ? ciudad.plano_name : null ;
				this.getBannerSede(this.ciudad)
				this.getBannerSedeCalendario()
			}
		});
		this.getEventos()
		/* this.ieFbApi.api('/me', (res)=>{
			console.log('eventos', res)
		}) */
	}
	getBannerSede( ciudad: string) {
		this.http.post(this.globals.urlApi + 'banner', {sys_descripcion: "banner-sede", "ciudad": ciudad}).subscribe( (res: any) => {
			if (res){
				this.bannerSede = res[0]
			}
		})
	}
	getBannerSedeCalendario() {
		this.http.post(this.globals.urlApi + 'banner', {sys_descripcion: "banner-sede-calendario", "ciudad": 'nacional'}).subscribe( (res: any) => {
			if (res){
				this.bannerSedeCalendario = res[0]
			}
		})
	}

	getSedes() {
		this.sedes = this.servicio.Data.sedes;
		this.getSedeActual();
		/* let revisacion = setInterval(() => {
			if(this.servicio.Data.sedes.length > 0){
				this.sedes = this.servicio.Data.sedes
				this.getSedeActual();
				clearInterval(revisacion);
			}
		}, 100) */
	}
	navegarWaze(sede: any) {
		window.open('https://waze.com/ul?ll=' + sede.lat + ',' + sede.lng + '=10', '_blank');
	}
	navegarMoovit(sede: any) {
		
		window.open('moovit://nearby?lat=' + sede.lat + '&lon=' + sede.lng + ' ');
	}
	irMoovit(sede: any) {
		
		window.open('https://moovit.com/?to=Ubicaci%C3%B3n%20elegida&tll=' + sede.latitud + '_' + sede.longitud + '&metroId=762&lang=es');
	}
	onResize(event) {
		console.log(event.target.innerWidth)
		this.breakpoint = (event.target.innerWidth <= 400) ? 1 : 2;
	}
	getSedeActual() {
		this.route.paramMap.subscribe( params => {
			this.id_sede = params.get("id");
			console.log("X", this.id_sede)
			this.sedeActual = this.sedes.find(el => el.id === parseInt(this.id_sede))
			if (this.sedeActual) {
				this.centro = {
					lat: this.sedeActual.latitud,
					lng: this.sedeActual.longitud
				}
				this.verSede(this.sedeActual);
			} else {
				console.log("Sede no encontrada...")		
			}
		})
	}
	getItemSede(id) {
		let objSede
		this.sedes.forEach((elemento, index) => {
			if (elemento.id == id) {
				objSede = elemento
				this.indice = index
			}
		})
		return objSede;
	}
	async verSede(sede) {
		
		this.sedeActual = sede
		this.location.replaceState('/sedes/' + sede.id);
		await this.scrollToSede(sede.id);
	}
	async scrollToSede (id) {
		let el = await document.getElementById('sede-' + id);
		el ? el.scrollIntoView({block: 'nearest', behavior: 'smooth', inline: 'start'}) : null;
	}
	consultarFB (){
		// https://developers.facebook.com/docs/javascript/reference/FB.api/
		// get el acces token
		// a9c599b50d4bc71a285947b0cffcef3e
		
		// console.log("consultarFB", this.ieFbApi)

		this.ieFbApi.getLoginStatus( (resData: any)=> {
			// console.log("RESDATA", resData)

			if(resData.status === 'connected') {
				if (resData?.authResponse?.accessToken) {
					this.estadoSesionFB = true;
					this.laLlave = resData.authResponse.accessToken
					console.log("iniciando llamadas...");

					/* this.ieFbApi.api('/me', {fields: 'last_name'}, function(response) {
						console.log(" el me ", response);
					}); */
					// recorrer un array de ids de eventos o Averiguar como listar los eventos con el api de fb
					this.ieFbApi.api('ie.grupo/events'/* , {fields: 'last_name'} */, function(response) { 
						console.log(" el events ", response);
					});
					
					// this.getEventosFb()
				} else {
					/* this.estadoSesionFB = false;
					
					this.ieFbApi.api('/events', (res)=>{
						console.log('algo aca', res)
					}) */
					this.ieFbApi.login(function(response) {
						if (response.authResponse) {
							console.log('Welcome!  Fetching your information.... ');
							this.ieFbApi.api('/me', function(response) {
								   console.log('Good to see you, ' + response.name + '.');
							});
						} else {
						 console.log('User cancelled login or did not fully authorize.');
						}
					},  {
						scope: 'email', 
						return_scopes: true
					});
					// console.log("No conectado al api de fb ie")
				}
			}
		});
		
		
		this.ieFbApi.api("/2329496123996508/events", function(response){
			// console.log("asdasd", response)
			// console.log("los eventos", response)
		})
		
	}
	loguearFB(){
		this.ieFbApi.login(function(response) {
			console.log("WTF despues de login", response)
			if (response.status === 'connected') {
				this.estadoSesionFB = true;
			  // Logged into your webpage and Facebook.
			  this.monedaDeAcceso = response.authResponse.accessToken;
				this.estadoSesionFB = true;
				console.log("MONEDA", this.monedaDeAcceso)
				this.getEventosFb();
			} else {
				console.log("le sino status response", response);

			  // The person is not logged into your webpage or we are unable to tell. 
			  this.estadoSesionFB = false;
			}
		  });
	}
	cerrarSesionFb() {
		this.ieFbApi.logout(function(response) {
			console.log("WTF despues de logout", response)
			// Person is now logged out
			this.estadoSesionFB = false;
		 });
	}
	checkLoginState() {
		this.ieFbApi.getLoginStatus(function(response) {
			console.log("getLoginStatus", response)
			if(response.status === "connected"){
				this.monedaDeAcceso = response.authResponse.accessToken;
				this.estadoSesionFB = true;
			} else {
				this.ieFb
				this.estadoSesionFB = false;
			}
			// this.ieFbApi.statusChangeCallback(response);
		});
	}
	getEventosFb(){
		this.ieFbApi.api("/me", function(response){
			console.log("eventes ? ", response)
			// console.log("los eventos", response)
		})
	}

	// funciones para el calendario
	handleDateClick(args) {
		console.log("Click en dia", args.dateStr)
	}
	getEventos(){
		this.eventos = [];
		let eventosCalendario = []
		this.http.get('https://ie-vie.com:3000/api/eventos-all').subscribe((resData:any) =>{
			if(resData){
				if(resData.status == 'ok'){
					this.eventos = resData.data
					this.eventos.forEach( evento => {
						let objetoParaCalendario = {
							backgroundColor: '#ffe100',
							title: evento.titulo,
							start: evento.fecha_inicio,
							end: evento.fecha_fin,
							description: evento.descripcion,
							id: evento.id,
							link_fb: evento.link_fb,
							como_asistir: evento.como_asistir,
							imagen: evento.url_imagen
							//textColor: '#000000',
							//borderColor: '#ffe100',
							//color: '#ff0000',
							//title: 'Soporte en soluciones eléctricas',
							//start: '2021-06-10T13:10:00',
							//end: '2022-06-15T18:45:00',
							//description: 'Te ayudamos a solucionar las dudas de implementación sobre todo lo relacionado con la electricidad',
							//editable: true, // agrega el puntero
							//id: "evento_permanente",
							//url: "https://www.iegrupo.co/#/ielectricistas",
						}
						eventosCalendario.push(objetoParaCalendario)
					})
					// this.calendarOptions.events = eventosCalendario
				} else {
					this.appService.snackBar.open("Error al cargar los eventos, inténtelo nuevamente", "x", {panelClass: 'error', verticalPosition: 'top', duration: 5000});		
				}
			} else {
				this.appService.snackBar.open("Error al obtener respuesta del api, inténtelo nuevamente", "x", {panelClass: 'error', verticalPosition: 'top', duration: 5000});	
			}
		},
		() => {
			this.appService.snackBar.open("Error de comunicación, inténtelo nuevamente", "x", {panelClass: 'error', verticalPosition: 'top', duration: 5000});
		})
	}

}
