import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { HttpClient } from '@angular/common/http';

import { emailValidator, matchingPasswords, forzarMinusculas } from './../../theme/utils/app-validators';
import { CuentaService } from '../../services/cuenta.service';
import { Globals } from '../../globals';

import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input-gg'

@Component({
	selector: 'app-registro',
	templateUrl: './registro.component.html',
	styleUrls: ['./registro.component.scss']
})
export class RegistroComponent implements OnInit {
	registerForm: UntypedFormGroup;
	terminosYCondiciones: boolean = false;
	soyElectricista: boolean = false;

	@ViewChild('recaptcha', {static: true }) recaptchaElement: ElementRef
	validado: boolean = true

	separateDialCode = true;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  	PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.Colombia, CountryISO.Panama];

	constructor(
		private formBuilder: UntypedFormBuilder,
		private snackBar: MatSnackBar,
		private cuentaService: CuentaService,
		private http: HttpClient,
		private globals: Globals,
		private modal: NgxSmartModalService,
	) { }

	ngOnInit() {
		this.registerForm = this.formBuilder.group({
			'firstName': ['', Validators.compose([Validators.required, Validators.minLength(3)])],
			'lastName': ['', Validators.compose([Validators.required, Validators.minLength(3)])],
			'email': ['', Validators.compose([Validators.required, emailValidator, forzarMinusculas])],
			'phone': ['', Validators.required],
			'password': ['', Validators.compose([Validators.required, Validators.minLength(8)])],
			'confirmPassword': ['', Validators.required],
			'TyC': ['', Validators.required],
			'cptddp': ['', Validators.required],
			'soyElectrico': [false, ],
		}, { validator: matchingPasswords('password', 'confirmPassword') });
	}

	ngAfterViewInit() {
		this.renderReCaptcha()
	}

	public onRegisterFormSubmit(values): void {
		// console.log('formulario de registro --- > ', values)
		let correo:string  = values.email
		if (values.TyC == false) {
			this.snackBar.open('Debes aceptar los términos y condiciones.', 'x', { panelClass: 'error', verticalPosition: 'bottom', duration: 1000 });
		} else {
			this.cuentaService.isCuenta(values.email).subscribe((res: any) => {
				if (res == 0) {
					
					let tempUser: any = {
						email: correo.toLowerCase(),
						firstName: values.firstName,
						lastName: values.lastName,
						name: values.firstName + " " + values.lastName,
						telefono: values.phone.e164Number,
						password: values.password,
						tyc: values.TyC
					}
					if (this.soyElectricista) { // crear registro en los electricistas
						let tempElectrico = {
							email: correo.toLowerCase(),
							firstName: values.firstName,
							lastName: values.lastName,
							telefono: values.phone.e164Number,
							name: values.firstName + " " + values.lastName,
						}
						this.http.post(this.globals.urlApi + 'electricistasie', tempElectrico).subscribe((res:any) => {
							if (res.status == "ok"){
								this.snackBar.open('Electricista creado con éxito', '×', { panelClass: 'success', verticalPosition: 'top', duration: -1 });
							}
						})
					}
					

					this.http.post(this.globals.urlApi + 'cuentas/crear', tempUser).subscribe((res: any) => {
						if (res.id) {
							this.http.post(this.globals.urlApi + 'cuentas/crear-token', { email: values.email.toLowerCase() }).subscribe((tokenizacion: any) => {
								//this.http.post(this.globals.backServiciosECommerce + 'bienvenida_html/bienvenida_usuario.php', {
								this.http.post(this.globals.backServiciosECommerce + 'plantillas_correos/bienvenida/correo_bienvenida.php', {
									correoDestino: correo.toLowerCase(),
									// ulrDestino: 'http://3.93.146.154', // url a la que deberia redirigir al validar con éxito
									nombreUsuario: tempUser.firstName + ' ' + tempUser.lastName,
									linkValidacion: this.globals.urlApi + `activacion-cuenta/${values.email.toLowerCase()}/${tokenizacion.hash}`
								}).subscribe((rescorreo: any) => {
									if (rescorreo.status == 'ok') {
										this.snackBar.open('Cuenta registrada satisfactoriamente. Activa tu cuenta desde el correo que te hemos enviado. (Este puede tardar varios minutos en llegar. Revisa tu carpeta de spam si no ves el mensaje en tu bandeja de entrada principal.)', '×', { panelClass: 'success', verticalPosition: 'top', duration: -1 });
									} else {
										this.snackBar.open('Ha ocurrido un error inesperado, comunícate con nuestros asesores.', '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 2000 });
									}
								})
							});
							// this.ieAuthService.setUser(res);
							// this.router.navigate(['/cuenta']);
						}
					})
				} else {
					this.snackBar.open(correo.toLowerCase() + ' ya se encuentra registrado .', '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
				}
			}, err => {
				console.log('error', err)
			});
		}
	}
	aceptarTYC(valor: boolean) {
		this.terminosYCondiciones = !valor;
	}
	abrirModal() {
		this.modal.getModal('modalTerminosCondiciones').open();
	}
	verTratamientoDatosPersonales() {
		this.modal.getModal('modalConsentimientoDatos').open();
	}
	setSoyElectricista(event) {
		this.soyElectricista = event.checked;
		// console.log("this.soyElectricista", this.soyElectricista)
	}
	addRecaptchaScript() {
		window['grecaptchaCallback'] = () => {
			this.renderReCaptcha();
		}
		(function(d, s, id, obj){
			var js, fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) { return;}
			js = d.createElement(s); js.id = id;
			js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
			fjs.parentNode.insertBefore(js, fjs);
		}(document, 'script', 'recaptcha-jssdk', this));
	   
	}
	renderReCaptcha() {
		window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
		  /* 'sitekey' : '6LfAkhoaAAAAABTbyWODa_6m58zqawfcrIhVm_CE', */
		  'sitekey' : '6LcCPlYoAAAAAMjc6ZEUJtgnlnB8fkaVWtDevU5Q',
		  'callback': (response) => {
			  /* this.http.post(this.globals.backServiciosECommerce + 'captcha.php', {response}).subscribe((res:any) =>{ */
				this.http.post('https://iegrupo.co/servicios/serviciosECommerce/captcha.php', {response}).subscribe((res:any) =>{
				  	if(res.success === true) {
						console.log("X????", res)
						this.validado = true;
					  	setInterval(function(){
							this.validado = false
						}, 120000);
				  	}
			  })
		  }
		});
	}

}
