import { Injectable } from '@angular/core';
import { CanActivate,  Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { IeAuthService } from '../services/ieauth.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {
	constructor(private ieAuthService: IeAuthService, private router: Router) {}
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if (this.ieAuthService.usuario) {
			return true;
		} else {
			this.router.navigate(['/acceder'], {queryParams: { returnUrl: state.url}});
			return false
		}
	}
}
/*
 id app FB : 333656643916901 jhero 396277150988113 ie
 pwd app FB: fbdc82e7723904b94dccd267c013183f
 api_key google : AIzaSyDYjscpzSeP7Eg_4OM43m1PUFPBkluPG4U
*/
