import { Injectable } from '@angular/core';
import { Producto } from '../models/producto';

@Injectable({
	providedIn: 'root'
})
export class BuscadorService {
	public objetoBuscadorProductos = {
		'marca': null,
		'categoria': null,
		'remates': null,
		'destacados': null,
		'promocion': null,
		'nuevo': null
	};
	public resultadosBuscador: boolean;
	public opcionesResultados: Producto[];
	constructor() { }

	productosPorMarca (idsapmarca: number) {
		this.objetoBuscadorProductos.marca = idsapmarca
		this.objetoBuscadorProductos.remates = null
		this.objetoBuscadorProductos.destacados = null
		this.objetoBuscadorProductos.promocion = null
		this.objetoBuscadorProductos.nuevo = null
	}
	productosPorCategoria (idCategoria: number) {
		this.objetoBuscadorProductos.categoria = idCategoria
		this.objetoBuscadorProductos.remates = null
		this.objetoBuscadorProductos.destacados = null
		this.objetoBuscadorProductos.promocion = null
		this.objetoBuscadorProductos.nuevo = null
	}
	productosPorRemates () {
		this.objetoBuscadorProductos.marca = null
		this.objetoBuscadorProductos.categoria = null
		this.objetoBuscadorProductos.remates = true
		this.objetoBuscadorProductos.destacados = null
		this.objetoBuscadorProductos.promocion = null
		this.objetoBuscadorProductos.nuevo = null
	}
	productosPorDestacados () {
		this.objetoBuscadorProductos.marca = null
		this.objetoBuscadorProductos.categoria = null
		this.objetoBuscadorProductos.remates = null
		this.objetoBuscadorProductos.destacados = true
		this.objetoBuscadorProductos.promocion = null
		this.objetoBuscadorProductos.nuevo = null
	}
	productosPorPromocion () {
		this.objetoBuscadorProductos.marca = null
		this.objetoBuscadorProductos.categoria = null
		this.objetoBuscadorProductos.remates = null
		this.objetoBuscadorProductos.destacados = null
		this.objetoBuscadorProductos.promocion = true
		this.objetoBuscadorProductos.nuevo = null
	}
	productosPorNuevo () {
		this.objetoBuscadorProductos.marca = null
		this.objetoBuscadorProductos.categoria = null
		this.objetoBuscadorProductos.remates = null
		this.objetoBuscadorProductos.destacados = null
		this.objetoBuscadorProductos.promocion = null
		this.objetoBuscadorProductos.nuevo = true
	}
}
