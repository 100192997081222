import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-alcance-sgi',
	templateUrl: './alcance-sgi.component.html',
	styleUrls: ['./alcance-sgi.component.scss'],
})
export class AlcanceSgiComponent implements OnInit {
	imagenes = {
		vision: 'assets/images/others/img_vision.jpg',
	};

	constructor() { }

	ngOnInit() {
	}

}
