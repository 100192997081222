import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Producto } from '../../models/producto';
import { ProductoService } from '../../services/producto.service';

@Component({
	selector: 'app-landing-productos',
	templateUrl: './landing-productos.component.html',
	styleUrls: ['./landing-productos.component.scss']
})
export class LandingProductosComponent implements OnInit {
	productos: Producto[] = [];

	constructor(
		private activatedRoute: ActivatedRoute,
		private productoService: ProductoService
	) { }

	ngOnInit() {
		this.activatedRoute.params.subscribe( async parametros => {
			for (let sku in parametros) {
				if (parametros.hasOwnProperty(sku)) {
					let value = parametros[sku];
					await this.productoService.getProducto(value).subscribe( async res => {
						await this.productos.push(res[0])
					})
				}
			}
		});
	}
	ngAfterInit() {
	}

}
