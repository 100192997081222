import { Injectable } from '@angular/core';
import { Globals } from '../globals';
import { HttpClient } from '@angular/common/http';
import { IeAuthService } from './ieauth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
	providedIn: 'root'
})
export class ComercialService {
	urlApiComerciales
	returnUrl

	constructor(
		private globals: Globals,
		private http: HttpClient,
		private ieAuthService: IeAuthService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private snackBar: MatSnackBar
	) {
		this.urlApiComerciales = this.globals.urlApi + 'comerciales';
	}

	loginComercial (correo, clave) {
		this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] || '/';
		this.http.post(this.urlApiComerciales + '/info', {email: correo, password: clave}).subscribe( (response: any) => {
			if (response.status == "error") {
				this.snackBar.open(response.mensaje, 'x', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
			} else {
				if (response && response.npssd) {
					this.http.post(this.globals.backServiciosECommerce + 'correoClaveComercial.php', {email: 'jahernandez@iegrupo.co', clave: response.npssd}).subscribe( (responseCorreo: any) => {
						this.snackBar.open(responseCorreo.mensaje + ' (La clave de acceso se ha actualizado, revisa tu correo institucional).', 'x', { panelClass: 'succes', verticalPosition: 'bottom', duration: 5000 });
					})
				} else {
					response.idTipoCuenta = response.idtipocuenta;
					delete response.idtipocuenta;
					this.ieAuthService.setUser(response);
					if (this.returnUrl == '/') {
						this.router.navigate(['/cuenta/comercial']);
					} else {
						this.router.navigateByUrl(this.returnUrl)
					}
				}
			}
		})
	}
}
