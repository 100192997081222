import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
/* import { WINDOW } from '@ng-toolkit/universal'; */

import { Globals } from '../../../../globals';
import { IeAuthService } from '../../../../services/ieauth.service';
import { AppService } from '../../../../app.service';

export interface DialogData {
	cliente: any,
}
@Component({
	selector: 'app-infocliente-modal',
	templateUrl: 'modalInfoCliente.html',
	styles: [
		'* { line-height: 1; display:block;}'
	]
})
export class ClienteInfoComponent {
	formInfoCliente: UntypedFormGroup;
	mensajeError: string = '';
	cliente
	constructor(
		public dialogRef: MatDialogRef<ClienteInfoComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData,
		public appService: AppService,
		private formBuilder: UntypedFormBuilder,
		private router: Router,
	) {
		this.cliente = data.cliente
		this.formInfoCliente = this.formBuilder.group({
			'idsap': [this.cliente.idsap, ],
			'nombre': [this.cliente.nombre, ],
			'telefono1': [this.cliente.telefono1, ],
			'telefono2': [this.cliente.telefono2, ],
			'celular': [this.cliente.celular, ],
			'email': [this.cliente.email, ],
			'contacto': [this.cliente.contacto, ],
		});
	}
	onNoClick(): void {
		this.dialogRef.close(this.formInfoCliente);
	}
	onYesClick(): void {
		this.dialogRef.close(this.formInfoCliente)
	}
	crearCotizacion( ) {
		this.router.navigate(['cuenta/comercial/cotizaciones'], {state: {data : this.cliente}});
		this.dialogRef.close()
	}
}

// Main
@Component({
	selector: 'app-clientes',
	templateUrl: './clientes.component.html',
	styleUrls: ['./clientes.component.scss']
})
export class ClientesComponent implements OnInit {
	clientes
	cliente
	comercial
	campobusqueda = new UntypedFormControl;
	opcionesFiltradas: Observable<string[]>;

	displayedColumnsClientes: string[] = ['idsap', 'nombre', 'opciones' ];
	dataSourceClientes

	@ViewChild(MatPaginator) paginator: MatPaginator;

	constructor(
		private globals: Globals,
		private http: HttpClient,
		private ieAuthService: IeAuthService,
		private router: Router,
		/* @Inject(WINDOW) private window: Window, */
		public modalInfoCliente: MatDialog
	) {
		this.comercial = this.ieAuthService.usuario;
	}
	ngOnInit() {
		this.http.post(this.globals.urlApi + 'usuarios-comercial', {email: this.comercial.email}).subscribe( (respuesta: any) => {
			window.scrollTo(0, 0);
			this.clientes = respuesta;
			this.dataSourceClientes = new MatTableDataSource(respuesta);
			this.dataSourceClientes.paginator = this.paginator
			this.opcionesFiltradas = this.campobusqueda.valueChanges.pipe(
				startWith(''),
				map(valor => this._filter(valor))
			)
		});
	}
	private _filter(valor: string): string[] {
		const valorFiltrado = valor.toLowerCase();
		return this.clientes.filter( opcion => opcion.idsap.toLowerCase().includes(valorFiltrado))
	}
	cargarUsuario() {
		this.http.get(this.globals.urlApi + 'usuarios/adcn/' + this.campobusqueda.value).subscribe( lecliente => {
			this.cliente = lecliente[0];
		})
	}
	crearCotizacion () {
		this.router.navigate(['cuenta/comercial/cotizaciones'], {state: {data : this.cliente}})
	}
	applyFilterClientes (filterValue: string) {
		this.dataSourceClientes.filter = filterValue.trim().toLowerCase();
	}
	verInfoCliente ( adcn ) {
		this.http.get(this.globals.urlApi + 'usuarios/adcn/' + adcn).subscribe( lecliente => {
			this.cliente = lecliente[0];
			this.verModalInfoCliente();
			// this.window.scrollTo(0, 600);
		})
	}
	verModalInfoCliente () {
		let opt: any
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.panelClass = 'registro-modal';
		dialogConfig.height = 'auto';
		dialogConfig.data = {
			cliente: this.cliente
		};
		const dialogReg = this.modalInfoCliente.open(ClienteInfoComponent, dialogConfig);
		dialogReg.afterClosed().subscribe(result => {
		})
	}
}
