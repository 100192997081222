import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class SocketIoService {
	emitido = false
	
	constructor(private socket: Socket) {

	}

	enviarMensaje(evento: string, data: any) {
		this.socket.emit(evento, data)
	}
	enviarNotificacion(evento: string, data: any){
		if(this.emitido == false){
			this.socket.emit(evento, data)
			this.emitido = true
		}
	}
	getMessage(){
		return this.socket.fromEvent('message').pipe(map((data) => data))
	}

}
