import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Globals } from '../globals';

@Injectable({
	providedIn: 'root'
})
export class TransaccionService {
	private transaccionUrl = '';

	constructor(
		private globals: Globals,
		private http: HttpClient
	) {
		this.transaccionUrl = this.globals.urlApi;
	}

	registrarTransaccion(formulario): Observable<any> {
		const url = `${this.transaccionUrl}transacciones/crear`;
		return this.http.post(url, formulario);
	}
	getTransaccionesCuenta(cuenta): Observable<any> {
		const url = `${this.transaccionUrl}transacciones/cuenta/${cuenta}`;
		return this.http.get(url);
	}
	updateTransaccion(transaccion: any): Observable<any> {
		const url = `${this.transaccionUrl}transacciones`;
		return this.http.put(url, transaccion);
	}
}
