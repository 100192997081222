import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../../../../globals';
import { UploadService } from '../../../../services/upload.service';
import { Articulo } from '../../../../models/articulo';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { AppService } from '../../../../app.service';
import { Router } from '@angular/router';
import { ToolbarService, LinkService, ImageService, HtmlEditorService } from '@syncfusion/ej2-angular-richtexteditor';

export interface DialogData {
	articulo: Articulo
}
@Component({
	selector: 'app-infoarticulo-modal',
	templateUrl: 'modaleditArticulo.html',
	styleUrls: ['articulos.component.scss'],
	providers: [ToolbarService, LinkService, ImageService, HtmlEditorService]
})
export class ArticuloEditComponent {
	formEditArticulo: UntypedFormGroup;
	mensaje_error: string = '';
	articulo: Articulo
	public tools: object = {
		type: 'Expand',
		items: [
			'Bold', 'Italic', 'Underline', 'FontColor', 'BackgroundColor', 'Formats'
		]
	};
	constructor(
		public dialogRef: MatDialogRef<ArticuloEditComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData,
		public appServise: AppService,
		private formBuilder: UntypedFormBuilder,
		private router: Router,
		private http: HttpClient,
		private globals: Globals,
		// private uploadService: UploadService,
		private snackBar: MatSnackBar,
	) {
		this.articulo = data.articulo
		this.formEditArticulo = this.formBuilder.group({
			'titulo': [ this.articulo.titulo , Validators.compose([Validators.required])],
			'resumen': [ this.articulo.resumen , Validators.compose([])],
			'imagen': [ this.articulo.imagen , Validators.compose([Validators.required])],
			'contenido': [ this.articulo.contenido , Validators.compose([Validators.required])],
			'home': [ this.articulo.home , Validators.compose([])],
			'destacado': [ this.articulo.destacado , Validators.compose([])],
			'link_facebook': [ this.articulo.link_facebook , Validators.compose([])],
			'link_instagram': [ this.articulo.link_instagram , Validators.compose([])],
			'link_youtube': [ this.articulo.link_youtube , Validators.compose([])],
			'link_linkedin': [ this.articulo.link_linkedin , Validators.compose([])],
			'link_twitter': [ this.articulo.link_twitter , Validators.compose([])],
			'autor': [ this.articulo.autor , Validators.compose([])],
			'fcreacion': [ this.articulo.fcreacion , Validators.compose([])],
			'url': [ this.articulo.url , Validators.compose([Validators.required, Validators.pattern(`([a-z,-]*)`)])],
			'publicar': [ this.articulo.publicar , Validators.compose([])],
		})
	}
	onNoClick(): void {
		this.dialogRef.close(this.formEditArticulo);
	}
	onYesClick(): void {
		this.dialogRef.close(this.formEditArticulo);
	}
	sinEspacios (event) {
		return (event.keyCode === 32) ? false : true;
	}
	guardarArticuloActualizado (formulario) {
		this.articulo.id = this.articulo.id;
		this.articulo.titulo = formulario.titulo;
		this.articulo.resumen = formulario.resumen;
		this.articulo.imagen = formulario.imagen;
		this.articulo.contenido = formulario.contenido;
		this.articulo.destacado = formulario.destacado;
		this.articulo.home = formulario.home;
		this.articulo.link_facebook = formulario.link_facebook;
		this.articulo.link_instagram = formulario.link_instagram;
		this.articulo.link_linkedin = formulario.link_linkedin;
		this.articulo.link_twitter = formulario.link_twitter;
		this.articulo.link_youtube = formulario.link_youtube;
		this.articulo.autor = formulario.autor;
		this.articulo.fcreacion = formulario.fcreacion;
		this.articulo.publicar = formulario.publicar;
		this.articulo.url = (formulario.url != '') ? formulario.url : null ;


		this.http.patch(this.globals.urlApi + 'articulo', this.articulo).subscribe( (res: any) => {
			if (res && res.msg != 'error') {
				// this.cargarImgPpal();
				this.articulo = null
				this.formEditArticulo.reset();
				this.router.navigate(['cuenta/administrador/articulos'], {state: { data: this.articulo}});
				this.dialogRef.close()
				this.snackBar.open('Artículo actualizado satisfactoriamente.', null, { panelClass: ['success'], verticalPosition: 'top', duration: 2000 })

				// this.urlImagenPpal = 'https://via.placeholder.com/375x150.png/cecece/000000?text=Previsualizaci%C3%B3n'
			} else {
				this.snackBar.open('Error al guardar el artículo.', null, { panelClass: ['error'], verticalPosition: 'top', duration: 2000 })
			}
		});
	}
}

// Main
@Component({
	selector: 'app-articulos',
	templateUrl: './articulos.component.html',
	styleUrls: ['./articulos.component.scss'],
	providers: [
		ToolbarService, LinkService, ImageService, HtmlEditorService
	]
})
export class ArticulosComponent implements OnInit {
	tabActual = 0;
	formCrearArticulo: UntypedFormGroup;
	selectedFiles: FileList
	imgPpalFile
	urlImagenPpal: string = '';
	articulo: Articulo = new Articulo();
	tiemstamp
	public tools: object = {
		type: 'Expand',
		items: [
			'Bold', 'Italic', 'Underline', 'FontColor', 'BackgroundColor', 'Formats'
		]
	};


	// edicion articulos
	displayedColumnsArticulos: string[] = ['id', 'titulo', 'publicar', 'createdAt', 'url', 'opciones'];
	dataSourceArticulos
	articulos: Articulo[] = []


	constructor(
		private formBuilder: UntypedFormBuilder,
		private http: HttpClient,
		private globals: Globals,
		private uploadService: UploadService,
		private snackBar: MatSnackBar,
		public modalEditArticulo: MatDialog
	) {
		this.formCrearArticulo = this.formBuilder.group({
			'titulo': [ '' , Validators.compose([Validators.required])],
			'imagen': [ '' , Validators.compose([Validators.required])],
			'resumen': [ '' , Validators.compose([])],
			'contenido': [ '' , Validators.compose([Validators.required])],
			'home': [ false , Validators.compose([])],
			'destacado': [ false , Validators.compose([])],
			'link_facebook': [ '' , Validators.compose([])],
			'link_instagram': [ '' , Validators.compose([])],
			'link_youtube': [ '' , Validators.compose([])],
			'link_linkedin': [ '' , Validators.compose([])],
			'link_twitter': [ '' , Validators.compose([])],
			'autor': [ '' , Validators.compose([])],
			'fcreacion': [ Date.now() , Validators.compose([])],
			'url': [ '' , Validators.compose([Validators.required, Validators.pattern(`([a-z,-]*)`)])],
			'publicar': [ false , Validators.compose([])],
		});
	}
	ngOnInit() {
		this.urlImagenPpal = 'https://via.placeholder.com/375x150.png/cecece/000000?text=Previsualizaci%C3%B3n'
	}
	sinEspacios (event) {
		return (event.keyCode === 32) ? false : true;
	}
	crearArticulo (formulario) {
		this.cargarImgPpal();
		this.articulo.titulo = formulario.titulo;
		this.articulo.resumen = formulario.resumen;
		this.articulo.imagen = this.urlImagenPpal;
		this.articulo.contenido = formulario.contenido;
		this.articulo.destacado = formulario.destacado;
		this.articulo.home = formulario.home;
		this.articulo.link_facebook = formulario.link_facebook;
		this.articulo.link_instagram = formulario.link_instagram;
		this.articulo.link_linkedin = formulario.link_linkedin;
		this.articulo.link_twitter = formulario.link_twitter;
		this.articulo.link_youtube = formulario.link_youtube;
		this.articulo.autor = formulario.autor;
		this.articulo.fcreacion = formulario.fcreacion;
		this.articulo.publicar = formulario.publicar;
		this.articulo.url = (formulario.url != '') ? formulario.url : null ;

		this.http.put(this.globals.urlApi + 'articulo', this.articulo).subscribe( (res: any) => {
			if (res && res.msg != 'error') {
				// this.cargarImgPpal();
				this.snackBar.open('Artículo guardado satisfactoriamente.', null, { panelClass: ['success'], verticalPosition: 'top', duration: 2000 })
				this.articulo = null
				this.formCrearArticulo.reset();
				this.urlImagenPpal = 'https://via.placeholder.com/375x150.png/cecece/000000?text=Previsualizaci%C3%B3n'
			} else {
				this.snackBar.open('Error al guardar el artículo.', null, { panelClass: ['error'], verticalPosition: 'top', duration: 2000 })
			}
		});
	}
	cargarImgPpal () {
		this.urlImagenPpal = '';
		// event.preventDefault();
		let archivo = this.selectedFiles.item(0);
		this.imgPpalFile = archivo
		this.uploadService.uploadFile(archivo, 'imgarticulos', archivo.name);
		this.urlImagenPpal = 'https://imgarticulos.s3.amazonaws.com/' + archivo.name
	}
	elegirArchivo (event) {
		event.preventDefault();
		this.selectedFiles = event.target.files;
		let lector = new FileReader();
		lector.readAsDataURL(this.selectedFiles.item(0));
		lector.onload = (_event) => {
			this.urlImagenPpal = lector.result.toString()
		}
	}
	removerImg (event) {
		event.preventDefault();
		this.selectedFiles = null
		this.urlImagenPpal = 'https://via.placeholder.com/375x150.png/cecece/000000?text=Previsualizaci%C3%B3n'
	}
	// EDITAR ARTICULOS
	cargarArticulosAll () {
		this.http.get(this.globals.urlApi + 'articulos-all').subscribe( (respuesta: Articulo[]) => {
			this.dataSourceArticulos = new MatTableDataSource(respuesta)
			this.articulos = respuesta
		})
	}
	accionesTabs (e) {
		if (e.index === 1) {
			this.cargarArticulosAll()
		}
	}
	applyFilterArticulos(filterValue: string) {
		this.dataSourceArticulos.filter = filterValue.trim().toLowerCase();
	}
	verModalEditarArticulo (datos) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.panelClass = 'modal-edit-art';
		dialogConfig.height = 'auto';
		dialogConfig.data = {
			articulo: datos // this.articulo
		};
		const dialogRef = this.modalEditArticulo.open(ArticuloEditComponent, dialogConfig);
		dialogRef.afterClosed().subscribe(res => {
		})
	}

}
