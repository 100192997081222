import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Globals } from '../globals';

@Injectable({
	providedIn: 'root'
})
export class CuentaService {
	private cuentaUrl = '';

	constructor(
		private globals: Globals,
		private http: HttpClient
	) {
		this.cuentaUrl = this.globals.urlApi;
	}

	registrarCuenta(formulario): Observable<any> {
		const url = `${this.cuentaUrl}cuentas/crear`;
		let rta;
		this.http.post(url, formulario).subscribe(res => {
			return rta = res
		});
		return rta
	}
	isCuenta(correo) {
		const url = `${this.cuentaUrl}cuentas/verificar/${correo}`;
		return this.http.get<any[]>(url);
	}
	logInCuenta(body) {
		const url = `${this.cuentaUrl}cuentas/info`;
		return this.http.post(url, body);
	}
	getIdCuenta (correo) {
		const url = `${this.cuentaUrl}cuentas/idCuenta`;
		let rta;
		this.http.post(url, {email: correo}).subscribe(res => {
			return rta = res
		});
		return rta
	}
	generarPassword(longitud: number) {
		let password = '';
		let opciones = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%&*-';
		for (let i = 0; i <= longitud ; i++) {
			password += opciones.charAt(Math.floor(Math.random() * opciones.length));
		}
		return password;
	}
}
