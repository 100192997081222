import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
/* import { WINDOW } from '@ng-toolkit/universal'; */
import { AppService } from '../../app.service';
import { Globals } from '../../globals';

@Component({
	selector: 'app-modal-schneider',
	templateUrl: './modal-schneider.component.html',
	styleUrls: ['./modal-schneider.component.scss']
})
export class ModalSchneiderComponent implements OnInit {
	// schneider
	seWidgetWrapper: any
	wrapperInstance: any

	peticiones: any;

	constructor(
		/* @Inject(WINDOW) private window: Window, */
		public dialogRef: MatDialogRef<ModalSchneiderComponent>,
		private appService: AppService,
		private http: HttpClient,
		private globals: Globals,
		private router: Router
	) {
		let olo: any = window.window
		olo ? this.seWidgetWrapper = olo.seWidgetWrapper : null;
		
	}

	ngOnInit(): void {
		this.setConfigWidgetSchnider()
	}
	setConfigWidgetSchnider() {
		let dataset = {
			type: 'hi',
			value: 'SINGLE_ENTRY_POINT'
		};

		let language = 'ES';
		let country = 'COL';
		let project = 'ANY';
		
		// let token = 'Bearer ' + '5BMJ9cIm1Xi9f2r-4Lsuk1UJy6pE9kjR'; // ORIGINAL
		let token = 'Bearer ' + 'bk0at5dsmqjZOk_PRZyV-ffXWfUQU-CV'; // PROD
		// let token = 'Bearer ' + 'g6MJWNOrykqeXQjYFqCTe8FZzw6gatIx'; // DEV
		
		let widgetOptions = {
			'allowCustomToaster': true,
			"enableQuantityConfiguration" : true,
			'buttons': {
				'saveConfiguration': {
					'title': 'Agregar al carrito', // change the button label
					'isVisible': true, // hide the button "add to cart"
					'mdTooltip': 'IEGRUPO'
				}
			},
			
			
		}
		this.wrapperInstance = this.seWidgetWrapper.startWidget(dataset, language, country, project, token, widgetOptions);

		this.wrapperInstance.addToastListener(toastEvent => {
			console.log("addToastListener ", toastEvent)
			//toastEvent.eventType == "warning"
		})
		this.wrapperInstance.addSaveConfigurationListener(eventoSaveConfig => {
			if (eventoSaveConfig.products.length > 1) {
				this.appService.verMensaje("Hay varios productos ", "warning");
			} else {
				let reffab = eventoSaveConfig.products[0].reference;
				let cantidad = eventoSaveConfig.quantity;
				this.peticiones = this.http.get( this.globals.urlApi +'producto-reffab/' + reffab).subscribe((resData: any) =>{
					if(resData.productos[0]) {
						resData.productos[0].enCarrito = cantidad
						this.appService.addToCart(resData.productos[0]);
						this.appService.verMensaje("Productos agregados al carrito de compras, por favor confirma las cantidades que necesitas de cada producto", "success");
					} else {
						// ver modal de cotización
						// this.appService.verModalCotizaciones(eventoSaveConfig.products[0]);

						/*
						this.router.navigate([]).then(result => {
							window.open('https://www.iegrupo.co/#/cotizaciones', '_blank');
						}); */
					}
					this.peticiones.unsubscribe()
				})
			}
		})
	}
	cerrarModal(){
		this.dialogRef.close()
	}

}
