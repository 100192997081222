import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SwiperModule } from 'swiper/angular';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	wheelPropagation: true,
	suppressScrollX: true
};

import { DialogoCuponComponent } from './../pages/checkout/checkout.component';
import { DialogoEnvioComponent } from './../pages/checkout/checkout.component';
import { DialogoPagoCupoComponent } from './../pages/checkout/checkout.component';
import { PipesModule } from '../theme/pipes/pipes.module';
import { RatingComponent } from './rating/rating.component';
import { ControlsComponent } from './controls/controls.component';
import { MainCarouselComponent } from './main-carousel/main-carousel.component';
import { BrandsCarouselComponent } from './brands-carousel/brands-carousel.component';
import { ProductsCarouselComponent } from './products-carousel/products-carousel.component';
import { ProductDialogComponent } from './products-carousel/product-dialog/product-dialog.component';
import { BannersComponent } from './banners/banners.component';
import { CategoryListComponent } from './category-list/category-list.component';
import { CategoriasCarouselComponent } from './categorias-carousel/categorias-carousel.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BuscadorProductosComponent } from './buscador-productos/buscador-productos.component';
import { HojaProductoComponent } from './hoja-producto/hoja-producto.component';
import { CustomSnackbarComponent } from './custom-snackbar/custom-snackbar.component';
import { SubirArchivoComponent } from './subir-archivo/subir-archivo.component';
import { TopCarouselComponent } from './top-carousel/top-carousel.component';
import { ModalSchneiderComponent } from './modal-schneider/modal-schneider.component';
import { SedesCarouselComponent } from './sedes-carousel/sedes-carousel.component';
import { ModalCotizacionComponent } from './modal-cotizacion/modal-cotizacion.component';
import { ModalRecaudoComponent } from '../pages/pages.component';
import { DialogoFiltroReportes } from '../pages/account/logistica/logistica.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Meta } from '@angular/platform-browser';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ModalCiudadesComponent } from './modal-ciudades/modal-ciudades.component';
import { GaleriaCampsComponent } from './galeria-camps/galeria-camps.component';
import { BuscadorComponent } from './buscador/buscador.component';
import { ArticulosCarouselComponent } from './articulos-carousel/articulos-carousel.component';
import { TimelineComponent } from './timeline/timeline.component';
import { LeerExcelComponent } from './leer-excel/leer-excel.component';
import { ListadoProductosComponent } from './listado-productos/listado-productos.component';

import { OverlayModule } from '@angular/cdk/overlay';
import { HorizontalTimeLineComponent } from './horizontal-time-line/horizontal-time-line.component';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        SwiperModule,
        FlexLayoutModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatStepperModule,
        PerfectScrollbarModule,
        PipesModule,
        NgSelectModule,
        FormsModule,
        MatTreeModule,
        ReactiveFormsModule,
        NgxCaptchaModule,
        FormsModule,
        FontAwesomeModule,
        ZXingScannerModule,
        OverlayModule,
        MatFormFieldModule,
        MatChipsModule,
        MatIconModule
    ],
    exports: [
        RouterModule,
        SwiperModule,
        FlexLayoutModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatStepperModule,
        PerfectScrollbarModule,
        PipesModule,
        MatFormFieldModule,
        MatChipsModule,
        MatIconModule,
        RatingComponent,
        ControlsComponent,
        MainCarouselComponent,
        BrandsCarouselComponent,
        ProductsCarouselComponent,
        ProductDialogComponent,
        BannersComponent,
        CategoryListComponent,
        CategoriasCarouselComponent,
        NgSelectModule,
        ReactiveFormsModule,
        FormsModule,
        NgxCaptchaModule,
        MatTreeModule,
        BuscadorProductosComponent,
        HojaProductoComponent,
        SubirArchivoComponent,
        TopCarouselComponent,
        SedesCarouselComponent,
        FontAwesomeModule,
        ZXingScannerModule,
        GaleriaCampsComponent,
        BuscadorComponent,
        ArticulosCarouselComponent,
        TimelineComponent,
        LeerExcelComponent,
        ListadoProductosComponent,
        HorizontalTimeLineComponent
    ],
    declarations: [
        RatingComponent,
        ControlsComponent,
        MainCarouselComponent,
        BrandsCarouselComponent,
        ProductsCarouselComponent,
        ProductDialogComponent,
        BannersComponent,
        CategoryListComponent,
        CategoriasCarouselComponent,
        BuscadorProductosComponent,
        HojaProductoComponent,
        CustomSnackbarComponent,
        SubirArchivoComponent,
        TopCarouselComponent,
        ModalSchneiderComponent,
        TopCarouselComponent,
        ModalCotizacionComponent,
        SedesCarouselComponent,
        ModalRecaudoComponent,
        DialogoFiltroReportes,
        DialogoCuponComponent,
        DialogoEnvioComponent,
        DialogoPagoCupoComponent,
        ModalCiudadesComponent,
        GaleriaCampsComponent,
        BuscadorComponent,
        ArticulosCarouselComponent,
        TimelineComponent,
        LeerExcelComponent,
        ListadoProductosComponent,
        HorizontalTimeLineComponent
    ],
    providers: [
        { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
        Meta
    ]
})
export class SharedModule { }
