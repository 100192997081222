import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

export function emailValidator(control: UntypedFormControl): { [key: string]: any } {
	//https://www.regextester.com/19
	const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
	if (control.value && !emailRegexp.test(control.value)) {
		return { invalidEmail: true };
	}
}
export function forzarMinusculas (control: UntypedFormControl): {[key: string]: any} {
	let regex = /[a-z]/g;
	if (control.value && !regex.test(control.value)) {
		return { invalidLowerCase : true};
	}
}

export function soloEnteros (controla: UntypedFormControl): {[key: string]: any} {
	let regex = /[0-9]/g;
	if (controla.value && regex.test(controla.value)) {
		return {invalidSimbol : true};
	}
}

export function matchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
	return (group: UntypedFormGroup) => {
		const password = group.controls[passwordKey];
		const passwordConfirmation = group.controls[passwordConfirmationKey];
		if (password.value !== passwordConfirmation.value) {
			return passwordConfirmation.setErrors({ mismatchedPasswords: true });
		}
	};
}
export function cadenaURL(control: UntypedFormControl): {[key: string]: any} {
	let regex = /\s/g;
	if (control.value && regex.test(control.value)) {
		return {invalidSimbol : true}
	}
}
