import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BuscadorService } from '../../services/buscador.service';
import { ProductoService } from '../../services/producto.service';

import SwiperCore, { SwiperOptions, Autoplay, Pagination, Navigation } from 'swiper';
SwiperCore.use([Navigation, Autoplay, Pagination]);


@Component({
	selector: 'app-categorias-carousel',
	templateUrl: './categorias-carousel.component.html',
	styleUrls: ['./categorias-carousel.component.scss']
})
export class CategoriasCarouselComponent implements OnInit {
	configCat: SwiperOptions = {
		observer: true,
		slidesPerView: 5,
		spaceBetween: 10,
		keyboard: true,
		navigation: false,
		pagination: false,
		grabCursor: true,
		loop: true,
		preloadImages: false,
		lazy: true,
		autoplay: {
			delay: 5000,
			disableOnInteraction: false
		},
		speed: 500,
		effect: "slide",
		breakpoints: {
			320: {
				slidesPerView: 1
			},
			480: {
				slidesPerView: 1
			},
			740: {
				slidesPerView: 2,
			},
			960: {
				slidesPerView: 3,
			},
			1280: {
				slidesPerView: 4,
			},
			1500: {
				slidesPerView: 5,
			}
		}
	}
	// @Input('categorias') categorias: Array<any> = [];
	origenes: Array<any> = [];

	constructor(private router: Router, private buscador: BuscadorService, private productoService: ProductoService) {
		this.productoService.getInfoCategoria(0).subscribe( (res: any) => {
			this.origenes =  res.subs
			this.origenes.shift()
		})
	}

	ngOnInit() {
	}

	ngAfterViewInit() {
		/* this.configCat = {
			observer: true,
			slidesPerView: 5,
			spaceBetween: 25,
			keyboard: true,
			navigation: true,
			pagination: true,
			grabCursor: true,
			loop: true,
			preloadImages: false,
			lazy: true,
			autoplay: {
				delay: 5000,
				disableOnInteraction: false
			},
			speed: 500,
			effect: "slide",
			breakpoints: {
				320: {
					slidesPerView: 1
				},
				480: {
					slidesPerView: 1
				},
				740: {
					slidesPerView: 2,
				},
				960: {
					slidesPerView: 3,
				},
				1280: {
					slidesPerView: 4,
				},
				1500: {
					slidesPerView: 5,
				}
			}
		} */
	}
	verCategoria (categoria) {
		this.buscador.objetoBuscadorProductos.categoria = categoria;
		this.router.navigate(['/productos', 1, 12]);
	}

}
