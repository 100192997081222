import { Injectable } from '@angular/core';
import { Globals } from '../globals';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Ciudadtcc } from '../models/ciudadtcc';



@Injectable({
	providedIn: 'root'
})
export class CiudadestccService {
	private urlCiudades = '';
	ciudades: Ciudadtcc[];
	constructor(
		private globals: Globals,
		private http: HttpClient
	) {
		this.urlCiudades = this.globals.urlApi + 'ciudadestcc';
		/* this.getCiudadesTcc().subscribe(res => {
			this.ciudades = res
		}, err => {
			console.log(err)
		}) */
	}

	getCiudadesTcc(): Observable<any> {
		const url = `${this.urlCiudades}`;
		return this.http.get<any>(url);
	}




}
