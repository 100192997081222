/* import { WINDOW } from '@ng-toolkit/universal'; */
import { Component, OnInit, ViewChild, Inject, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { ProductoService } from '../../services/producto.service';
import { ProductDialogComponent } from '../../shared/products-carousel/product-dialog/product-dialog.component';
import { BuscadorService } from '../../services/buscador.service';
import { Producto } from '../../models/producto';

@Component({
	selector: 'app-remates',
	templateUrl: './remates.component.html',
	styleUrls: ['./remates.component.scss']
})
export class RematesComponent implements OnInit {
	productos: Producto[] = [];
	totalProductos: number;
	count: number = 12;
	page = 1;

	@ViewChild('sidenav') sidenav: any;
	public sidenavOpen: boolean = true;
	private sub: any;
	public viewType: string = 'grid';
	public viewCol: number = 25;

	constructor(
		/* @Inject(WINDOW) private window: Window, */
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private productoService: ProductoService,
		private buscador: BuscadorService,
		public dialog: MatDialog,
	) { }
	onLoad() {}
	ngOnInit() {
		this.activatedRoute.params.subscribe(params => {
			this.page = params.inicio
			this.count = params.cantidad
		});
		this.getProductosRemates();
		if (window.innerWidth < 960) {
			this.sidenavOpen = false;
		};
		if (window.innerWidth < 1280) {
			this.viewCol = 33.3;
		};
	}
	@HostListener('window:resize')
	public onWindowResize(): void {
		(window.innerWidth < 960) ? this.sidenavOpen = false : this.sidenavOpen = true;
		(window.innerWidth < 1280) ? this.viewCol = 33.3 : this.viewCol = 25;
	}
	// IE
	public getProductosRemates() {
		this.buscador.productosPorRemates();
		this.productoService.getProductosRemate(this.page, this.count).subscribe(res => {
			this.productos = res.productos;
			this.totalProductos = res.totalProductos;
			console.log('letotalProductos', this.totalProductos)
		});
	}
	public onPageChanged(e) {
		this.productos = []
		this.page = e;
		this.getProductosRemates();
		this.activatedRoute.paramMap.subscribe(params => {
			this.router.navigate(['remates/', this.page, this.count]);
		})
	}
	public openProductDialog(product) {
		const dialogRef = this.dialog.open(ProductDialogComponent, {
			data: product,
			panelClass: 'product-dialog'
		});
		dialogRef.afterClosed().subscribe( product => {
			if (product) {
				// this.router.navigate(['/productos', 1, 12, product.idsap]);
				this.router.navigate(['/productos', 1, 12, 'producto', product.idsap, product.url]);
				// this.router.navigate(['/products', product.id , product.name]);
			}
		});
	}

}
