import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
/* import { WINDOW } from '@ng-toolkit/universal'; */
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { Globals } from '../../globals';
import { emailValidator } from '../../theme/utils/app-validators';

export interface ProductoCotizacion {
	nombrepro: string;
	cantidadpro: number;
	marcapro: string;
}

@Component({
	selector: 'app-modal-cotizacion',
	templateUrl: './modal-cotizacion.component.html',
	styleUrls: ['./modal-cotizacion.component.scss']
})
export class ModalCotizacionComponent implements OnInit {
	/* @Inject(WINDOW) private window: Window */
	cotizacionForm: UntypedFormGroup;

	fcCiudad = new UntypedFormControl();
	ciudades: any[];
	ciudadesFiltradas: Observable<any[]>;

	opciones = [];
	desabilitarbtn: boolean = false;
	productosACotizar: Observable<ProductoCotizacion[]>;
	estadoEnvioCotizacion: boolean = false;
	ciudadie: string;

	// captcha
	@ViewChild('recaptcha', { static: false }) recaptchaElement: ElementRef
	validado: boolean = false

	constructor(
		// public appService: AppService,
		private formBuilder: UntypedFormBuilder,
		private http: HttpClient,
		private globals: Globals,
		public dialogRef: MatDialogRef<ModalCotizacionComponent>,
		private modal: NgxSmartModalService,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.cvs2JSON();

	}

	ngOnInit(): void {
		let comment = "REFFAB : " + this.data?.producto?.reference + ". \n";
		comment += "DESCRIPCIÓN: " + this.data?.producto?.description ;
		this.cotizacionForm = this.formBuilder.group({
			nombre: ['', Validators.required],
			empresa: [''],
			email: ['', Validators.compose([Validators.required, emailValidator])],
			telefono: ['', Validators.required],
			id: ['', Validators.required],
			direccion: ['', Validators.required],
			ciudad: ['', Validators.compose([Validators.required, this.autocompleteRequerido])],
			comentarios: [comment],
			cptddp: ['', Validators.required],
		});

		if (this.estadoEnvioCotizacion == false) {
			this.ciudadesFiltradas = this.cotizacionForm.get(['ciudad']).valueChanges.pipe(
				startWith<string | any>(''),
				map(value => typeof value === 'string' ? value : (value.ciudad ? value.ciudad : '')),
				map(ciudad => ciudad ? this._filter(ciudad) : this.ciudades.slice())
			)
		}
	}
	ngAfterViewInit() {
		this.renderReCaptcha()
	}
	displayFn(ciudad?: any): string | any {
		if (ciudad) { this.ciudadie = ciudad } else { this.ciudadie = '' }
		return ciudad ? ciudad.ciudad + '-' + ciudad.departamento : undefined;
	}
	cerrarModal() {
		this.dialogRef.close()
	}
	autocompleteRequerido(control: AbstractControl) {
		// https://onthecode.co.uk/force-selection-angular-material-autocomplete/
		const selection: any = control.value
		if (typeof selection === 'string') {
			return { incorrect: true }
		}
		return null;
	}
	verTratamientoDatosPersonales() {
		this.modal.getModal('modalConsentimientoDatos').open();
	}

	enviarCotizacion(formulario: any, formDirective: FormGroupDirective) {
		this.desabilitarbtn = true;
		if (this.cotizacionForm.valid) {
			if (this.opciones.length > 0) {
				formulario.listado = this.opciones
			}
			this.http.post(this.globals.backServiciosECommerce + 'cotizaciones/enviarCorreoCotizacion.php', formulario).subscribe((res: any) => {
				this.estadoEnvioCotizacion = (res.status == 'ok') ? true : false;
				/* formDirective.resetForm();
				this.cotizacionForm.reset();
				this.opciones = [];
				this.window.scrollTo(0, 0);
				this.productosACotizar = of(this.opciones); */
				this.cerrarModal();
			});
		}
	}
	soloNumeros(event) {
		if (event.key === "," || event.key === "." || event.key === "e" || event.key === '-' || event.key === '+') {
			return false;
		}
	}
	_filter(ciudad: string): any[] {
		const filterValue = ciudad.toLowerCase()
		return this.ciudades.filter(option => this.accent_fold(option.ciudad.toLowerCase()).indexOf(this.accent_fold(filterValue.toLowerCase())) === 0 || this.accent_fold(option.departamento.toLowerCase()).indexOf(this.accent_fold(filterValue.toLowerCase())) === 0);
	}
	public accent_fold(palabra) {
		if (!palabra) { return ''; }
		let ret = '';
		for (let i = 0; i <= palabra.length; i++) {
			ret += this.getAcentos()[palabra.charAt(i)] || palabra.charAt(i);
		}
		return ret;
	}
	public getAcentos() {
		return {
			'A': 'a',
			'ẚ': 'a',
			'Á': 'a',
			'á': 'a',
			'À': 'a',
			'à': 'a',
			'Ă': 'a',
			'ă': 'a',
			'Ắ': 'a',
			'ắ': 'a',
			'Ằ': 'a',
			'ằ': 'a',
			'Ẵ': 'a',
			'ẵ': 'a',
			'Ẳ': 'a',
			'ẳ': 'a',
			'Â': 'a',
			'â': 'a',
			'Ấ': 'a',
			'ấ': 'a',
			'Ầ': 'a',
			'ầ': 'a',
			'Ẫ': 'a',
			'ẫ': 'a',
			'Ẩ': 'a',
			'ẩ': 'a',
			'Ǎ': 'a',
			'ǎ': 'a',
			'Å': 'a',
			'å': 'a',
			'Ǻ': 'a',
			'ǻ': 'a',
			'Ä': 'a',
			'ä': 'a',
			'Ǟ': 'a',
			'ǟ': 'a',
			'Ã': 'a',
			'ã': 'a',
			'Ȧ': 'a',
			'ȧ': 'a',
			'Ǡ': 'a',
			'ǡ': 'a',
			'Ą': 'a',
			'ą': 'a',
			'Ā': 'a',
			'ā': 'a',
			'Ả': 'a',
			'ả': 'a',
			'Ȁ': 'a',
			'ȁ': 'a',
			'Ȃ': 'a',
			'ȃ': 'a',
			'Ạ': 'a',
			'ạ': 'a',
			'Ặ': 'a',
			'ặ': 'a',
			'Ậ': 'a',
			'ậ': 'a',
			'Ḁ': 'a',
			'ḁ': 'a',
			'Ⱥ': 'a',
			'ⱥ': 'a',
			'Ǽ': 'a',
			'ǽ': 'a',
			'Ǣ': 'a',
			'ǣ': 'a',
			'Ḃ': 'b',
			'ḃ': 'b',
			'Ḅ': 'b',
			'ḅ': 'b',
			'Ḇ': 'b',
			'ḇ': 'b',
			'Ƀ': 'b',
			'ƀ': 'b',
			'ᵬ': 'b',
			'Ɓ': 'b',
			'ɓ': 'b',
			'Ƃ': 'b',
			'ƃ': 'b',
			'Ć': 'c',
			'ć': 'c',
			'Ĉ': 'c',
			'ĉ': 'c',
			'Č': 'c',
			'č': 'c',
			'Ċ': 'c',
			'ċ': 'c',
			'Ç': 'c',
			'ç': 'c',
			'Ḉ': 'c',
			'ḉ': 'c',
			'Ȼ': 'c',
			'ȼ': 'c',
			'Ƈ': 'c',
			'ƈ': 'c',
			'ɕ': 'c',
			'Ď': 'd',
			'ď': 'd',
			'Ḋ': 'd',
			'ḋ': 'd',
			'Ḑ': 'd',
			'ḑ': 'd',
			'Ḍ': 'd',
			'ḍ': 'd',
			'Ḓ': 'd',
			'ḓ': 'd',
			'Ḏ': 'd',
			'ḏ': 'd',
			'Đ': 'd',
			'đ': 'd',
			'ᵭ': 'd',
			'Ɖ': 'd',
			'ɖ': 'd',
			'Ɗ': 'd',
			'ɗ': 'd',
			'Ƌ': 'd',
			'ƌ': 'd',
			'ȡ': 'd',
			'ð': 'd',
			'É': 'e',
			'Ə': 'e',
			'Ǝ': 'e',
			'ǝ': 'e',
			'é': 'e',
			'È': 'e',
			'è': 'e',
			'Ĕ': 'e',
			'ĕ': 'e',
			'Ê': 'e',
			'ê': 'e',
			'Ế': 'e',
			'ế': 'e',
			'Ề': 'e',
			'ề': 'e',
			'Ễ': 'e',
			'ễ': 'e',
			'Ể': 'e',
			'ể': 'e',
			'Ě': 'e',
			'ě': 'e',
			'Ë': 'e',
			'ë': 'e',
			'Ẽ': 'e',
			'ẽ': 'e',
			'Ė': 'e',
			'ė': 'e',
			'Ȩ': 'e',
			'ȩ': 'e',
			'Ḝ': 'e',
			'ḝ': 'e',
			'Ę': 'e',
			'ę': 'e',
			'Ē': 'e',
			'ē': 'e',
			'Ḗ': 'e',
			'ḗ': 'e',
			'Ḕ': 'e',
			'ḕ': 'e',
			'Ẻ': 'e',
			'ẻ': 'e',
			'Ȅ': 'e',
			'ȅ': 'e',
			'Ȇ': 'e',
			'ȇ': 'e',
			'Ẹ': 'e',
			'ẹ': 'e',
			'Ệ': 'e',
			'ệ': 'e',
			'Ḙ': 'e',
			'ḙ': 'e',
			'Ḛ': 'e',
			'ḛ': 'e',
			'Ɇ': 'e',
			'ɇ': 'e',
			'ɚ': 'e',
			'ɝ': 'e',
			'Ḟ': 'f',
			'ḟ': 'f',
			'ᵮ': 'f',
			'Ƒ': 'f',
			'ƒ': 'f',
			'Ǵ': 'g',
			'ǵ': 'g',
			'Ğ': 'g',
			'ğ': 'g',
			'Ĝ': 'g',
			'ĝ': 'g',
			'Ǧ': 'g',
			'ǧ': 'g',
			'Ġ': 'g',
			'ġ': 'g',
			'Ģ': 'g',
			'ģ': 'g',
			'Ḡ': 'g',
			'ḡ': 'g',
			'Ǥ': 'g',
			'ǥ': 'g',
			'Ɠ': 'g',
			'ɠ': 'g',
			'Ĥ': 'h',
			'ĥ': 'h',
			'Ȟ': 'h',
			'ȟ': 'h',
			'Ḧ': 'h',
			'ḧ': 'h',
			'Ḣ': 'h',
			'ḣ': 'h',
			'Ḩ': 'h',
			'ḩ': 'h',
			'Ḥ': 'h',
			'ḥ': 'h',
			'Ḫ': 'h',
			'ḫ': 'h',
			'H': 'h',
			'̱': 'h',
			'ẖ': 'h',
			'Ħ': 'h',
			'ħ': 'h',
			'Ⱨ': 'h',
			'ⱨ': 'h',
			'Í': 'i',
			'í': 'i',
			'Ì': 'i',
			'ì': 'i',
			'Ĭ': 'i',
			'ĭ': 'i',
			'Î': 'i',
			'î': 'i',
			'Ǐ': 'i',
			'ǐ': 'i',
			'Ï': 'i',
			'ï': 'i',
			'Ḯ': 'i',
			'ḯ': 'i',
			'Ĩ': 'i',
			'ĩ': 'i',
			'İ': 'i',
			'i': 'i',
			'Į': 'i',
			'į': 'i',
			'Ī': 'i',
			'ī': 'i',
			'Ỉ': 'i',
			'ỉ': 'i',
			'Ȉ': 'i',
			'ȉ': 'i',
			'Ȋ': 'i',
			'ȋ': 'i',
			'Ị': 'i',
			'ị': 'i',
			'Ḭ': 'i',
			'ḭ': 'i',
			'I': 'i',
			'ı': 'i',
			'Ɨ': 'i',
			'ɨ': 'i',
			'Ĵ': 'j',
			'ĵ': 'j',
			'J': 'j',
			'̌': 'j',
			'ǰ': 'j',
			'ȷ': 'j',
			'Ɉ': 'j',
			'ɉ': 'j',
			'ʝ': 'j',
			'ɟ': 'j',
			'ʄ': 'j',
			'Ḱ': 'k',
			'ḱ': 'k',
			'Ǩ': 'k',
			'ǩ': 'k',
			'Ķ': 'k',
			'ķ': 'k',
			'Ḳ': 'k',
			'ḳ': 'k',
			'Ḵ': 'k',
			'ḵ': 'k',
			'Ƙ': 'k',
			'ƙ': 'k',
			'Ⱪ': 'k',
			'ⱪ': 'k',
			'Ĺ': 'a',
			'ĺ': 'l',
			'Ľ': 'l',
			'ľ': 'l',
			'Ļ': 'l',
			'ļ': 'l',
			'Ḷ': 'l',
			'ḷ': 'l',
			'Ḹ': 'l',
			'ḹ': 'l',
			'Ḽ': 'l',
			'ḽ': 'l',
			'Ḻ': 'l',
			'ḻ': 'l',
			'Ł': 'l',
			'ł': 'l',
			'̣': 'l',
			'Ŀ': 'l',
			'ŀ': 'l',
			'Ƚ': 'l',
			'ƚ': 'l',
			'Ⱡ': 'l',
			'ⱡ': 'l',
			'Ɫ': 'l',
			'ɫ': 'l',
			'ɬ': 'l',
			'ɭ': 'l',
			'ȴ': 'l',
			'Ḿ': 'm',
			'ḿ': 'm',
			'Ṁ': 'm',
			'ṁ': 'm',
			'Ṃ': 'm',
			'ṃ': 'm',
			'ɱ': 'm',
			'Ń': 'n',
			'ń': 'n',
			'Ǹ': 'n',
			'ǹ': 'n',
			'Ň': 'n',
			'ň': 'n',
			'Ñ': 'n',
			'ñ': 'n',
			'Ṅ': 'n',
			'ṅ': 'n',
			'Ņ': 'n',
			'ņ': 'n',
			'Ṇ': 'n',
			'ṇ': 'n',
			'Ṋ': 'n',
			'ṋ': 'n',
			'Ṉ': 'n',
			'ṉ': 'n',
			'Ɲ': 'n',
			'ɲ': 'n',
			'Ƞ': 'n',
			'ƞ': 'n',
			'ɳ': 'n',
			'ȵ': 'n',
			'N': 'n',
			'̈': 'n',
			'n': 'n',
			'Ó': 'o',
			'ó': 'o',
			'Ò': 'o',
			'ò': 'o',
			'Ŏ': 'o',
			'ŏ': 'o',
			'Ô': 'o',
			'ô': 'o',
			'Ố': 'o',
			'ố': 'o',
			'Ồ': 'o',
			'ồ': 'o',
			'Ỗ': 'o',
			'ỗ': 'o',
			'Ổ': 'o',
			'ổ': 'o',
			'Ǒ': 'o',
			'ǒ': 'o',
			'Ö': 'o',
			'ö': 'o',
			'Ȫ': 'o',
			'ȫ': 'o',
			'Ő': 'o',
			'ő': 'o',
			'Õ': 'o',
			'õ': 'o',
			'Ṍ': 'o',
			'ṍ': 'o',
			'Ṏ': 'o',
			'ṏ': 'o',
			'Ȭ': 'o',
			'ȭ': 'o',
			'Ȯ': 'o',
			'ȯ': 'o',
			'Ȱ': 'o',
			'ȱ': 'o',
			'Ø': 'o',
			'ø': 'o',
			'Ǿ': 'o',
			'ǿ': 'o',
			'Ǫ': 'o',
			'ǫ': 'o',
			'Ǭ': 'o',
			'ǭ': 'o',
			'Ō': 'o',
			'ō': 'o',
			'Ṓ': 'o',
			'ṓ': 'o',
			'Ṑ': 'o',
			'ṑ': 'o',
			'Ỏ': 'o',
			'ỏ': 'o',
			'Ȍ': 'o',
			'ȍ': 'o',
			'Ȏ': 'o',
			'ȏ': 'o',
			'Ơ': 'o',
			'ơ': 'o',
			'Ớ': 'o',
			'ớ': 'o',
			'Ờ': 'o',
			'ờ': 'o',
			'Ỡ': 'o',
			'ỡ': 'o',
			'Ở': 'o',
			'ở': 'o',
			'Ợ': 'o',
			'ợ': 'o',
			'Ọ': 'o',
			'ọ': 'o',
			'Ộ': 'o',
			'ộ': 'o',
			'Ɵ': 'o',
			'ɵ': 'o',
			'Ṕ': 'p',
			'ṕ': 'p',
			'Ṗ': 'p',
			'ṗ': 'p',
			'Ᵽ': 'p',
			'Ƥ': 'p',
			'ƥ': 'p',
			'P': 'p',
			'̃': 'p',
			'p': 'p',
			'ʠ': 'q',
			'Ɋ': 'q',
			'ɋ': 'q',
			'Ŕ': 'r',
			'ŕ': 'r',
			'Ř': 'r',
			'ř': 'r',
			'Ṙ': 'r',
			'ṙ': 'r',
			'Ŗ': 'r',
			'ŗ': 'r',
			'Ȑ': 'r',
			'ȑ': 'r',
			'Ȓ': 'r',
			'ȓ': 'r',
			'Ṛ': 'r',
			'ṛ': 'r',
			'Ṝ': 'r',
			'ṝ': 'r',
			'Ṟ': 'r',
			'ṟ': 'r',
			'Ɍ': 'r',
			'ɍ': 'r',
			'ᵲ': 'r',
			'ɼ': 'r',
			'Ɽ': 'r',
			'ɽ': 'r',
			'ɾ': 'r',
			'ᵳ': 'r',
			'ß': 's',
			'Ś': 's',
			'ś': 's',
			'Ṥ': 's',
			'ṥ': 's',
			'Ŝ': 's',
			'ŝ': 's',
			'Š': 's',
			'š': 's',
			'Ṧ': 's',
			'ṧ': 's',
			'Ṡ': 's',
			'ṡ': 's',
			'ẛ': 's',
			'Ş': 's',
			'ş': 's',
			'Ṣ': 's',
			'ṣ': 's',
			'Ṩ': 's',
			'ṩ': 's',
			'Ș': 's',
			'ș': 's',
			'ʂ': 's',
			'S': 's',
			'̩': 's',
			's': 's',
			'Þ': 't',
			'þ': 't',
			'Ť': 't',
			'ť': 't',
			'T': 't',
			'ẗ': 't',
			'Ṫ': 't',
			'ṫ': 't',
			'Ţ': 't',
			'ţ': 't',
			'Ṭ': 't',
			'ṭ': 't',
			'Ț': 't',
			'ț': 't',
			'Ṱ': 't',
			'ṱ': 't',
			'Ṯ': 't',
			'ṯ': 't',
			'Ŧ': 't',
			'ŧ': 't',
			'Ⱦ': 't',
			'ⱦ': 't',
			'ᵵ': 't',
			'ƫ': 't',
			'Ƭ': 't',
			'ƭ': 't',
			'Ʈ': 't',
			'ʈ': 't',
			'ȶ': 't',
			'Ú': 'u',
			'ú': 'u',
			'Ù': 'u',
			'ù': 'u',
			'Ŭ': 'u',
			'ŭ': 'u',
			'Û': 'u',
			'û': 'u',
			'Ǔ': 'u',
			'ǔ': 'u',
			'Ů': 'u',
			'ů': 'u',
			'Ü': 'u',
			'ü': 'u',
			'Ǘ': 'u',
			'ǘ': 'u',
			'Ǜ': 'u',
			'ǜ': 'u',
			'Ǚ': 'u',
			'ǚ': 'u',
			'Ǖ': 'u',
			'ǖ': 'u',
			'Ű': 'u',
			'ű': 'u',
			'Ũ': 'u',
			'ũ': 'u',
			'Ṹ': 'u',
			'ṹ': 'u',
			'Ų': 'u',
			'ų': 'u',
			'Ū': 'u',
			'ū': 'u',
			'Ṻ': 'u',
			'ṻ': 'u',
			'Ủ': 'u',
			'ủ': 'u',
			'Ȕ': 'u',
			'ȕ': 'u',
			'Ȗ': 'u',
			'ȗ': 'u',
			'Ư': 'u',
			'ư': 'u',
			'Ứ': 'u',
			'ứ': 'u',
			'Ừ': 'u',
			'ừ': 'u',
			'Ữ': 'u',
			'ữ': 'u',
			'Ử': 'u',
			'ử': 'u',
			'Ự': 'u',
			'ự': 'u',
			'Ụ': 'u',
			'ụ': 'u',
			'Ṳ': 'u',
			'ṳ': 'u',
			'Ṷ': 'u',
			'ṷ': 'u',
			'Ṵ': 'u',
			'ṵ': 'u',
			'Ʉ': 'u',
			'ʉ': 'u',
			'Ṽ': 'v',
			'ṽ': 'v',
			'Ṿ': 'v',
			'ṿ': 'v',
			'Ʋ': 'v',
			'ʋ': 'v',
			'Ẃ': 'w',
			'ẃ': 'w',
			'Ẁ': 'w',
			'ẁ': 'w',
			'Ŵ': 'w',
			'ŵ': 'w',
			'W': 'w',
			'̊': 'w',
			'ẘ': 'w',
			'Ẅ': 'w',
			'ẅ': 'w',
			'Ẇ': 'w',
			'ẇ': 'w',
			'Ẉ': 'w',
			'ẉ': 'w',
			'Ẍ': 'x',
			'ẍ': 'x',
			'Ẋ': 'x',
			'ẋ': 'x',
			'Ý': 'y',
			'ý': 'y',
			'Ỳ': 'y',
			'ỳ': 'y',
			'Ŷ': 'y',
			'ŷ': 'y',
			'Y': 'y',
			'ẙ': 'y',
			'Ÿ': 'y',
			'ÿ': 'y',
			'Ỹ': 'y',
			'ỹ': 'y',
			'Ẏ': 'y',
			'ẏ': 'y',
			'Ȳ': 'y',
			'ȳ': 'y',
			'Ỷ': 'y',
			'ỷ': 'y',
			'Ỵ': 'y',
			'ỵ': 'y',
			'ʏ': 'y',
			'Ɏ': 'y',
			'ɏ': 'y',
			'Ƴ': 'y',
			'ƴ': 'y',
			'Ź': 'z',
			'ź': 'z',
			'Ẑ': 'z',
			'ẑ': 'z',
			'Ž': 'z',
			'ž': 'z',
			'Ż': 'z',
			'ż': 'z',
			'Ẓ': 'z',
			'ẓ': 'z',
			'Ẕ': 'z',
			'ẕ': 'z',
			'Ƶ': 'z',
			'ƶ': 'z',
			'Ȥ': 'z',
			'ȥ': 'z',
			'ʐ': 'z',
			'ʑ': 'z',
			'Ⱬ': 'z',
			'ⱬ': 'z',
			'Ǯ': 'z',
			'ǯ': 'z',
			'ƺ': 'z',
			'２': '2',
			'６': '6',
			'Ｂ': 'B',
			'Ｆ': 'F',
			'Ｊ': 'J',
			'Ｎ': 'N',
			'Ｒ': 'R',
			'Ｖ': 'V',
			'Ｚ': 'Z',
			'ｂ': 'b',
			'ｆ': 'f',
			'ｊ': 'j',
			'ｎ': 'n',
			'ｒ': 'r',
			'ｖ': 'v',
			'ｚ': 'z',
			'１': '1',
			'５': '5',
			'９': '9',
			'Ａ': 'A',
			'Ｅ': 'E',
			'Ｉ': 'I',
			'Ｍ': 'M',
			'Ｑ': 'Q',
			'Ｕ': 'U',
			'Ｙ': 'Y',
			'ａ': 'a',
			'ｅ': 'e',
			'ｉ': 'i',
			'ｍ': 'm',
			'ｑ': 'q',
			'ｕ': 'u',
			'ｙ': 'y',
			'０': '0',
			'４': '4',
			'８': '8',
			'Ｄ': 'D',
			'Ｈ': 'H',
			'Ｌ': 'L',
			'Ｐ': 'P',
			'Ｔ': 'T',
			'Ｘ': 'X',
			'ｄ': 'd',
			'ｈ': 'h',
			'ｌ': 'l',
			'ｐ': 'p',
			'ｔ': 't',
			'ｘ': 'x',
			'３': '3',
			'７': '7',
			'Ｃ': 'C',
			'Ｇ': 'G',
			'Ｋ': 'K',
			'Ｏ': 'O',
			'Ｓ': 'S',
			'Ｗ': 'W',
			'ｃ': 'c',
			'ｇ': 'g',
			'ｋ': 'k',
			'ｏ': 'o',
			'ｓ': 's',
			'ｗ': 'w'
		};
	}
	cvs2JSON() {
		let cadenacsv: string = `1,Región Eje Cafetero - Antioquia,5,Antioquia,5002,Abejorral
			2,Región Centro Oriente,54,Norte de Santander,54003,Abrego
			3,Región Eje Cafetero - Antioquia,5,Antioquia,5004,Abriaquí
			4,Región Llano,50,Meta,50006,Acacias
			5,Región Pacífico,27,Chocó,27006,Acandí
			6,Región Centro Sur,41,Huila,41006,Acevedo
			7,Región Caribe,13,Bolívar,13006,Achí
			8,Región Centro Sur,41,Huila,41013,Agrado
			9,Región Centro Oriente,25,Cundinamarca,25001,Agua de Dios
			10,Región Caribe,20,Cesar,20011,Aguachica
			11,Región Centro Oriente,68,Santander,68013,Aguada
			12,Región Eje Cafetero - Antioquia,17,Caldas,17013,Aguadas
			13,Región Llano,85,Casanare,85010,Aguazul
			14,Región Caribe,20,Cesar,20013,Agustín Codazzi
			15,Región Centro Sur,41,Huila,41016,Aipe
			16,Región Centro Oriente,25,Cundinamarca,25019,Albán
			17,Región Pacífico,52,Nariño,52019,Albán
			18,Región Centro Sur,18,Caquetá,18029,Albania
			19,Región Caribe,44,La Guajira,44035,Albania
			20,Región Centro Oriente,68,Santander,68020,Albania
			21,Región Pacífico,76,Valle del Cauca,76020,Alcalá
			22,Región Pacífico,52,Nariño,52022,Aldana
			23,Región Eje Cafetero - Antioquia,5,Antioquia,5021,Alejandría
			24,Región Caribe,47,Magdalena,47030,Algarrobo
			25,Región Centro Sur,41,Huila,41020,Algeciras
			26,Región Pacífico,19,Cauca,19022,Almaguer
			27,Región Centro Oriente,15,Boyacá,15022,Almeida
			28,Región Centro Sur,73,Tolima,73024,Alpujarra
			29,Región Centro Sur,41,Huila,41026,Altamira
			30,Región Pacífico,27,Chocó,27025,Alto Baudo
			31,Región Caribe,13,Bolívar,13030,Altos del Rosario
			32,Región Centro Sur,73,Tolima,73026,Alvarado
			33,Región Eje Cafetero - Antioquia,5,Antioquia,5030,Amagá
			34,Región Eje Cafetero - Antioquia,5,Antioquia,5031,Amalfi
			35,Región Centro Sur,73,Tolima,73030,Ambalema
			36,Región Centro Oriente,25,Cundinamarca,25035,Anapoima
			37,Región Pacífico,52,Nariño,52036,Ancuyá
			38,Región Pacífico,76,Valle del Cauca,76036,Andalucía
			39,Región Eje Cafetero - Antioquia,5,Antioquia,5034,Andes
			40,Región Eje Cafetero - Antioquia,5,Antioquia,5036,Angelópolis
			41,Región Eje Cafetero - Antioquia,5,Antioquia,5038,Angostura
			42,Región Centro Oriente,25,Cundinamarca,25040,Anolaima
			43,Región Eje Cafetero - Antioquia,5,Antioquia,5040,Anorí
			44,Región Eje Cafetero - Antioquia,17,Caldas,17042,Anserma
			45,Región Pacífico,76,Valle del Cauca,76041,Ansermanuevo
			46,Región Eje Cafetero - Antioquia,5,Antioquia,5044,Anza
			47,Región Centro Sur,73,Tolima,73043,Anzoátegui
			48,Región Eje Cafetero - Antioquia,5,Antioquia,5045,Apartadó
			49,Región Eje Cafetero - Antioquia,66,Risaralda,66045,Apía
			50,Región Centro Oriente,25,Cundinamarca,25599,Apulo
			51,Región Centro Oriente,15,Boyacá,15047,Aquitania
			52,Región Caribe,47,Magdalena,47053,Aracataca
			53,Región Eje Cafetero - Antioquia,17,Caldas,17050,Aranzazu
			54,Región Centro Oriente,68,Santander,68051,Aratoca
			55,Región Llano,81,Arauca,81001,Arauca
			56,Región Llano,81,Arauca,81065,Arauquita
			57,Región Centro Oriente,25,Cundinamarca,25053,Arbeláez
			58,Región Pacífico,52,Nariño,52051,Arboleda
			59,Región Centro Oriente,54,Norte de Santander,54051,Arboledas
			60,Región Eje Cafetero - Antioquia,5,Antioquia,5051,Arboletes
			61,Región Centro Oriente,15,Boyacá,15051,Arcabuco
			62,Región Caribe,13,Bolívar,13042,Arenal
			63,Región Eje Cafetero - Antioquia,5,Antioquia,5055,Argelia
			64,Región Pacífico,19,Cauca,19050,Argelia
			65,Región Pacífico,76,Valle del Cauca,76054,Argelia
			66,Región Caribe,47,Magdalena,47058,Ariguaní
			67,Región Caribe,13,Bolívar,13052,Arjona
			68,Región Eje Cafetero - Antioquia,5,Antioquia,5059,Armenia
			69,Región Eje Cafetero - Antioquia,63,Quindío,63001,Armenia
			70,Región Centro Sur,73,Tolima,73055,Armero
			71,Región Caribe,13,Bolívar,13062,Arroyohondo
			72,Región Caribe,20,Cesar,20032,Astrea
			73,Región Centro Sur,73,Tolima,73067,Ataco
			74,Región Pacífico,27,Chocó,27050,Atrato
			75,Región Caribe,23,Córdoba,23068,Ayapel
			76,Región Pacífico,27,Chocó,27073,Bagadó
			77,Región Pacífico,27,Chocó,27075,Bahía Solano
			78,Región Pacífico,27,Chocó,27077,Bajo Baudó
			79,Región Pacífico,19,Cauca,19075,Balboa
			80,Región Eje Cafetero - Antioquia,66,Risaralda,66075,Balboa
			81,Región Caribe,8,Atlántico,8078,Baranoa
			82,Región Centro Sur,41,Huila,41078,Baraya
			83,Región Pacífico,52,Nariño,52079,Barbacoas
			84,Región Eje Cafetero - Antioquia,5,Antioquia,5079,Barbosa
			85,Región Centro Oriente,68,Santander,68077,Barbosa
			86,Región Centro Oriente,68,Santander,68079,Barichara
			87,Región Llano,50,Meta,50110,Barranca de Upía
			88,Región Centro Oriente,68,Santander,68081,Barrancabermeja
			89,Región Caribe,44,La Guajira,44078,Barrancas
			90,Región Caribe,13,Bolívar,13074,Barranco de Loba
			91,Región Llano,94,Guainía,94343,Barranco Minas
			92,Región Caribe,8,Atlántico,8001,Barranquilla
			93,Región Caribe,20,Cesar,20045,Becerril
			94,Región Eje Cafetero - Antioquia,17,Caldas,17088,Belalcázar
			95,Región Centro Oriente,15,Boyacá,15087,Belén
			96,Región Pacífico,52,Nariño,52083,Belén
			97,Región Pacífico,27,Chocó,27086,Belén de Bajira
			98,Región Centro Sur,18,Caquetá,18094,Belén de Los Andaquies
			99,Región Eje Cafetero - Antioquia,66,Risaralda,66088,Belén de Umbría
			100,Región Eje Cafetero - Antioquia,5,Antioquia,5088,Bello
			101,Región Eje Cafetero - Antioquia,5,Antioquia,5086,Belmira
			102,Región Centro Oriente,25,Cundinamarca,25086,Beltrán
			103,Región Centro Oriente,15,Boyacá,15090,Berbeo
			104,Región Eje Cafetero - Antioquia,5,Antioquia,5091,Betania
			105,Región Centro Oriente,15,Boyacá,15092,Betéitiva
			106,Región Eje Cafetero - Antioquia,5,Antioquia,5093,Betulia
			107,Región Centro Oriente,68,Santander,68092,Betulia
			108,Región Centro Oriente,25,Cundinamarca,25095,Bituima
			109,Región Centro Oriente,15,Boyacá,15097,Boavita
			110,Región Centro Oriente,54,Norte de Santander,54099,Bochalema
			111,Región Centro Oriente,11,Bogotá D.C.,11001,Bogotá D.C.
			112,Región Centro Oriente,25,Cundinamarca,25099,Bojacá
			113,Región Pacífico,27,Chocó,27099,Bojaya
			114,Región Pacífico,19,Cauca,19100,Bolívar
			115,Región Centro Oriente,68,Santander,68101,Bolívar
			116,Región Pacífico,76,Valle del Cauca,76100,Bolívar
			117,Región Caribe,20,Cesar,20060,Bosconia
			118,Región Centro Oriente,15,Boyacá,15104,Boyacá
			119,Región Eje Cafetero - Antioquia,5,Antioquia,5107,Briceño
			120,Región Centro Oriente,15,Boyacá,15106,Briceño
			121,Región Centro Oriente,68,Santander,68001,Bucaramanga
			122,Región Centro Oriente,54,Norte de Santander,54109,Bucarasica
			123,Región Centro Oriente,15,Boyacá,15109,Buena Vista
			124,Región Pacífico,76,Valle del Cauca,76109,Buenaventura
			125,Región Caribe,23,Córdoba,23079,Buenavista
			126,Región Eje Cafetero - Antioquia,63,Quindío,63111,Buenavista
			127,Región Caribe,70,Sucre,70110,Buenavista
			128,Región Pacífico,19,Cauca,19110,Buenos Aires
			129,Región Pacífico,52,Nariño,52110,Buesaco
			130,Región Pacífico,76,Valle del Cauca,76113,Bugalagrande
			131,Región Eje Cafetero - Antioquia,5,Antioquia,5113,Buriticá
			132,Región Centro Oriente,15,Boyacá,15114,Busbanzá
			133,Región Centro Oriente,25,Cundinamarca,25120,Cabrera
			134,Región Centro Oriente,68,Santander,68121,Cabrera
			135,Región Llano,50,Meta,50124,Cabuyaro
			136,Región Llano,94,Guainía,94886,Cacahual
			137,Región Eje Cafetero - Antioquia,5,Antioquia,5120,Cáceres
			138,Región Centro Oriente,25,Cundinamarca,25123,Cachipay
			139,Región Centro Oriente,54,Norte de Santander,54128,Cachirá
			140,Región Centro Oriente,54,Norte de Santander,54125,Cácota
			141,Región Eje Cafetero - Antioquia,5,Antioquia,5125,Caicedo
			142,Región Pacífico,76,Valle del Cauca,76122,Caicedonia
			143,Región Caribe,70,Sucre,70124,Caimito
			144,Región Centro Sur,73,Tolima,73124,Cajamarca
			145,Región Pacífico,19,Cauca,19130,Cajibío
			146,Región Centro Oriente,25,Cundinamarca,25126,Cajicá
			147,Región Caribe,13,Bolívar,13140,Calamar
			148,Región Llano,95,Guaviare,95015,Calamar
			149,Región Eje Cafetero - Antioquia,63,Quindío,63130,Calarcá
			150,Región Eje Cafetero - Antioquia,5,Antioquia,5129,Caldas
			151,Región Centro Oriente,15,Boyacá,15131,Caldas
			152,Región Pacífico,19,Cauca,19137,Caldono
			153,Región Pacífico,76,Valle del Cauca,76001,Cali
			154,Región Centro Oriente,68,Santander,68132,California
			155,Región Pacífico,76,Valle del Cauca,76126,Calima
			156,Región Pacífico,19,Cauca,19142,Caloto
			157,Región Eje Cafetero - Antioquia,5,Antioquia,5134,Campamento
			158,Región Caribe,8,Atlántico,8137,Campo de La Cruz
			159,Región Centro Sur,41,Huila,41132,Campoalegre
			160,Región Centro Oriente,15,Boyacá,15135,Campohermoso
			161,Región Caribe,23,Córdoba,23090,Canalete
			162,Región Caribe,8,Atlántico,8141,Candelaria
			163,Región Pacífico,76,Valle del Cauca,76130,Candelaria
			164,Región Caribe,13,Bolívar,13160,Cantagallo
			165,Región Eje Cafetero - Antioquia,5,Antioquia,5138,Cañasgordas
			166,Región Centro Oriente,25,Cundinamarca,25148,Caparrapí
			167,Región Centro Oriente,68,Santander,68147,Capitanejo
			168,Región Centro Oriente,25,Cundinamarca,25151,Caqueza
			169,Región Eje Cafetero - Antioquia,5,Antioquia,5142,Caracolí
			170,Región Eje Cafetero - Antioquia,5,Antioquia,5145,Caramanta
			171,Región Centro Oriente,68,Santander,68152,Carcasí
			172,Región Eje Cafetero - Antioquia,5,Antioquia,5147,Carepa
			173,Región Centro Sur,73,Tolima,73148,Carmen de Apicala
			174,Región Centro Oriente,25,Cundinamarca,25154,Carmen de Carupa
			175,Región Pacífico,27,Chocó,27150,Carmen del Darien
			176,Región Eje Cafetero - Antioquia,5,Antioquia,5150,Carolina
			177,Región Caribe,13,Bolívar,13001,Cartagena
			178,Región Centro Sur,18,Caquetá,18150,Cartagena del Chairá
			179,Región Pacífico,76,Valle del Cauca,76147,Cartago
			180,Región Llano,97,Vaupés,97161,Caruru
			181,Región Centro Sur,73,Tolima,73152,Casabianca
			182,Región Llano,50,Meta,50150,Castilla la Nueva
			183,Región Eje Cafetero - Antioquia,5,Antioquia,5154,Caucasia
			184,Región Centro Oriente,68,Santander,68160,Cepitá
			185,Región Caribe,23,Córdoba,23162,Cereté
			186,Región Centro Oriente,15,Boyacá,15162,Cerinza
			187,Región Centro Oriente,68,Santander,68162,Cerrito
			188,Región Caribe,47,Magdalena,47161,Cerro San Antonio
			189,Región Pacífico,27,Chocó,27160,Cértegui
			190,Región Pacífico,52,Nariño,52240,Chachagüí
			191,Región Centro Oriente,25,Cundinamarca,25168,Chaguaní
			192,Región Caribe,70,Sucre,70230,Chalán
			193,Región Llano,85,Casanare,85015,Chámeza
			194,Región Centro Sur,73,Tolima,73168,Chaparral
			195,Región Centro Oriente,68,Santander,68167,Charalá
			196,Región Centro Oriente,68,Santander,68169,Charta
			197,Región Centro Oriente,25,Cundinamarca,25175,Chía
			198,Región Eje Cafetero - Antioquia,5,Antioquia,5172,Chigorodó
			199,Región Caribe,23,Córdoba,23168,Chimá
			200,Región Centro Oriente,68,Santander,68176,Chimá
			201,Región Caribe,20,Cesar,20175,Chimichagua
			202,Región Centro Oriente,54,Norte de Santander,54172,Chinácota
			203,Región Centro Oriente,15,Boyacá,15172,Chinavita
			204,Región Eje Cafetero - Antioquia,17,Caldas,17174,Chinchiná
			205,Región Caribe,23,Córdoba,23182,Chinú
			206,Región Centro Oriente,25,Cundinamarca,25178,Chipaque
			207,Región Centro Oriente,68,Santander,68179,Chipatá
			208,Región Centro Oriente,15,Boyacá,15176,Chiquinquirá
			209,Región Centro Oriente,15,Boyacá,15232,Chíquiza
			210,Región Caribe,20,Cesar,20178,Chiriguaná
			211,Región Centro Oriente,15,Boyacá,15180,Chiscas
			212,Región Centro Oriente,15,Boyacá,15183,Chita
			213,Región Centro Oriente,54,Norte de Santander,54174,Chitagá
			214,Región Centro Oriente,15,Boyacá,15185,Chitaraque
			215,Región Centro Oriente,15,Boyacá,15187,Chivatá
			216,Región Caribe,47,Magdalena,47170,Chivolo
			217,Región Centro Oriente,15,Boyacá,15236,Chivor
			218,Región Centro Oriente,25,Cundinamarca,25181,Choachí
			219,Región Centro Oriente,25,Cundinamarca,25183,Chocontá
			220,Región Caribe,13,Bolívar,13188,Cicuco
			221,Región Caribe,47,Magdalena,47189,Ciénaga
			222,Región Caribe,23,Córdoba,23189,Ciénaga de Oro
			223,Región Centro Oriente,15,Boyacá,15189,Ciénega
			224,Región Centro Oriente,68,Santander,68190,Cimitarra
			225,Región Eje Cafetero - Antioquia,63,Quindío,63190,Circasia
			226,Región Eje Cafetero - Antioquia,5,Antioquia,5190,Cisneros
			227,Región Eje Cafetero - Antioquia,5,Antioquia,5101,Ciudad Bolívar
			228,Región Caribe,13,Bolívar,13222,Clemencia
			229,Región Eje Cafetero - Antioquia,5,Antioquia,5197,Cocorná
			230,Región Centro Sur,73,Tolima,73200,Coello
			231,Región Centro Oriente,25,Cundinamarca,25200,Cogua
			232,Región Centro Sur,41,Huila,41206,Colombia
			233,Región Pacífico,52,Nariño,52203,Colón
			234,Región Centro Sur,86,Putumayo,86219,Colón
			235,Región Caribe,70,Sucre,70204,Coloso
			236,Región Centro Oriente,15,Boyacá,15204,Cómbita
			237,Región Eje Cafetero - Antioquia,5,Antioquia,5206,Concepción
			238,Región Centro Oriente,68,Santander,68207,Concepción
			239,Región Eje Cafetero - Antioquia,5,Antioquia,5209,Concordia
			240,Región Caribe,47,Magdalena,47205,Concordia
			241,Región Pacífico,27,Chocó,27205,Condoto
			242,Región Centro Oriente,68,Santander,68209,Confines
			243,Región Pacífico,52,Nariño,52207,Consaca
			244,Región Pacífico,52,Nariño,52210,Contadero
			245,Región Centro Oriente,68,Santander,68211,Contratación
			246,Región Centro Oriente,54,Norte de Santander,54206,Convención
			247,Región Eje Cafetero - Antioquia,5,Antioquia,5212,Copacabana
			248,Región Centro Oriente,15,Boyacá,15212,Coper
			249,Región Caribe,13,Bolívar,13212,Córdoba
			250,Región Pacífico,52,Nariño,52215,Córdoba
			251,Región Eje Cafetero - Antioquia,63,Quindío,63212,Córdoba
			252,Región Pacífico,19,Cauca,19212,Corinto
			253,Región Centro Oriente,68,Santander,68217,Coromoro
			254,Región Caribe,70,Sucre,70215,Corozal
			255,Región Centro Oriente,15,Boyacá,15215,Corrales
			256,Región Centro Oriente,25,Cundinamarca,25214,Cota
			257,Región Caribe,23,Córdoba,23300,Cotorra
			258,Región Centro Oriente,15,Boyacá,15218,Covarachía
			259,Región Caribe,70,Sucre,70221,Coveñas
			260,Región Centro Sur,73,Tolima,73217,Coyaima
			261,Región Llano,81,Arauca,81220,Cravo Norte
			262,Región Pacífico,52,Nariño,52224,Cuaspud
			263,Región Centro Oriente,15,Boyacá,15223,Cubará
			264,Región Llano,50,Meta,50223,Cubarral
			265,Región Centro Oriente,15,Boyacá,15224,Cucaita
			266,Región Centro Oriente,25,Cundinamarca,25224,Cucunubá
			267,Región Centro Oriente,54,Norte de Santander,54001,Cúcuta
			268,Región Centro Oriente,54,Norte de Santander,54223,Cucutilla
			269,Región Centro Oriente,15,Boyacá,15226,Cuítiva
			270,Región Llano,50,Meta,50226,Cumaral
			271,Región Llano,99,Vichada,99773,Cumaribo
			272,Región Pacífico,52,Nariño,52227,Cumbal
			273,Región Pacífico,52,Nariño,52233,Cumbitara
			274,Región Centro Sur,73,Tolima,73226,Cunday
			275,Región Centro Sur,18,Caquetá,18205,Curillo
			276,Región Centro Oriente,68,Santander,68229,Curití
			277,Región Caribe,20,Cesar,20228,Curumaní
			278,Región Eje Cafetero - Antioquia,5,Antioquia,5234,Dabeiba
			279,Región Pacífico,76,Valle del Cauca,76233,Dagua
			280,Región Caribe,44,La Guajira,44090,Dibula
			281,Región Caribe,44,La Guajira,44098,Distracción
			282,Región Centro Sur,73,Tolima,73236,Dolores
			283,Región Eje Cafetero - Antioquia,5,Antioquia,5237,Don Matías
			284,Región Eje Cafetero - Antioquia,66,Risaralda,66170,Dosquebradas
			285,Región Centro Oriente,15,Boyacá,15238,Duitama
			286,Región Centro Oriente,54,Norte de Santander,54239,Durania
			287,Región Eje Cafetero - Antioquia,5,Antioquia,5240,Ebéjico
			288,Región Pacífico,76,Valle del Cauca,76243,El Águila
			289,Región Eje Cafetero - Antioquia,5,Antioquia,5250,El Bagre
			290,Región Caribe,47,Magdalena,47245,El Banco
			291,Región Pacífico,76,Valle del Cauca,76246,El Cairo
			292,Región Llano,50,Meta,50245,El Calvario
			293,Región Pacífico,27,Chocó,27135,El Cantón del San Pablo
			294,Región Centro Oriente,54,Norte de Santander,54245,El Carmen
			295,Región Pacífico,27,Chocó,27245,El Carmen de Atrato
			296,Región Caribe,13,Bolívar,13244,El Carmen de Bolívar
			297,Región Centro Oriente,68,Santander,68235,El Carmen de Chucurí
			298,Región Eje Cafetero - Antioquia,5,Antioquia,5148,El Carmen de Viboral
			299,Región Llano,50,Meta,50251,El Castillo
			300,Región Pacífico,76,Valle del Cauca,76248,El Cerrito
			301,Región Pacífico,52,Nariño,52250,El Charco
			302,Región Centro Oriente,15,Boyacá,15244,El Cocuy
			303,Región Centro Oriente,25,Cundinamarca,25245,El Colegio
			304,Región Caribe,20,Cesar,20238,El Copey
			305,Región Centro Sur,18,Caquetá,18247,El Doncello
			306,Región Llano,50,Meta,50270,El Dorado
			307,Región Pacífico,76,Valle del Cauca,76250,El Dovio
			308,Región Centro Sur,91,Amazonas,91263,El Encanto
			309,Región Centro Oriente,15,Boyacá,15248,El Espino
			310,Región Centro Oriente,68,Santander,68245,El Guacamayo
			311,Región Caribe,13,Bolívar,13248,El Guamo
			312,Región Pacífico,27,Chocó,27250,El Litoral del San Juan
			313,Región Caribe,44,La Guajira,44110,El Molino
			314,Región Caribe,20,Cesar,20250,El Paso
			315,Región Centro Sur,18,Caquetá,18256,El Paujil
			316,Región Pacífico,52,Nariño,52254,El Peñol
			317,Región Caribe,13,Bolívar,13268,El Peñón
			318,Región Centro Oriente,25,Cundinamarca,25258,El Peñón
			319,Región Centro Oriente,68,Santander,68250,El Peñón
			320,Región Caribe,47,Magdalena,47258,El Piñon
			321,Región Centro Oriente,68,Santander,68255,El Playón
			322,Región Caribe,47,Magdalena,47268,El Retén
			323,Región Llano,95,Guaviare,95025,El Retorno
			324,Región Caribe,70,Sucre,70233,El Roble
			325,Región Centro Oriente,25,Cundinamarca,25260,El Rosal
			326,Región Pacífico,52,Nariño,52256,El Rosario
			327,Región Eje Cafetero - Antioquia,5,Antioquia,5697,El Santuario
			328,Región Pacífico,52,Nariño,52258,El Tablón de Gómez
			329,Región Pacífico,19,Cauca,19256,El Tambo
			330,Región Pacífico,52,Nariño,52260,El Tambo
			331,Región Centro Oriente,54,Norte de Santander,54250,El Tarra
			332,Región Centro Oriente,54,Norte de Santander,54261,El Zulia
			333,Región Centro Sur,41,Huila,41244,Elías
			334,Región Centro Oriente,68,Santander,68264,Encino
			335,Región Centro Oriente,68,Santander,68266,Enciso
			336,Región Eje Cafetero - Antioquia,5,Antioquia,5264,Entrerrios
			337,Región Eje Cafetero - Antioquia,5,Antioquia,5266,Envigado
			338,Región Centro Sur,73,Tolima,73268,Espinal
			339,Región Centro Oriente,25,Cundinamarca,25269,Facatativá
			340,Región Centro Sur,73,Tolima,73270,Falan
			341,Región Eje Cafetero - Antioquia,17,Caldas,17272,Filadelfia
			342,Región Eje Cafetero - Antioquia,63,Quindío,63272,Filandia
			343,Región Centro Oriente,15,Boyacá,15272,Firavitoba
			344,Región Centro Sur,73,Tolima,73275,Flandes
			345,Región Centro Sur,18,Caquetá,18001,Florencia
			346,Región Pacífico,19,Cauca,19290,Florencia
			347,Región Centro Oriente,15,Boyacá,15276,Floresta
			348,Región Centro Oriente,68,Santander,68271,Florián
			349,Región Pacífico,76,Valle del Cauca,76275,Florida
			350,Región Centro Oriente,68,Santander,68276,Floridablanca
			351,Región Centro Oriente,25,Cundinamarca,25279,Fomeque
			352,Región Caribe,44,La Guajira,44279,Fonseca
			353,Región Llano,81,Arauca,81300,Fortul
			354,Región Centro Oriente,25,Cundinamarca,25281,Fosca
			355,Región Pacífico,52,Nariño,52520,Francisco Pizarro
			356,Región Eje Cafetero - Antioquia,5,Antioquia,5282,Fredonia
			357,Región Centro Sur,73,Tolima,73283,Fresno
			358,Región Eje Cafetero - Antioquia,5,Antioquia,5284,Frontino
			359,Región Llano,50,Meta,50287,Fuente de Oro
			360,Región Caribe,47,Magdalena,47288,Fundación
			361,Región Pacífico,52,Nariño,52287,Funes
			362,Región Centro Oriente,25,Cundinamarca,25286,Funza
			363,Región Centro Oriente,25,Cundinamarca,25288,Fúquene
			364,Región Centro Oriente,25,Cundinamarca,25290,Fusagasugá
			365,Región Centro Oriente,25,Cundinamarca,25293,Gachala
			366,Región Centro Oriente,25,Cundinamarca,25295,Gachancipá
			367,Región Centro Oriente,15,Boyacá,15293,Gachantivá
			368,Región Centro Oriente,25,Cundinamarca,25297,Gachetá
			369,Región Centro Oriente,68,Santander,68296,Galán
			370,Región Caribe,8,Atlántico,8296,Galapa
			371,Región Caribe,70,Sucre,70235,Galeras
			372,Región Centro Oriente,25,Cundinamarca,25299,Gama
			373,Región Caribe,20,Cesar,20295,Gamarra
			374,Región Centro Oriente,68,Santander,68298,Gambita
			375,Región Centro Oriente,15,Boyacá,15296,Gameza
			376,Región Centro Oriente,15,Boyacá,15299,Garagoa
			377,Región Centro Sur,41,Huila,41298,Garzón
			378,Región Eje Cafetero - Antioquia,63,Quindío,63302,Génova
			379,Región Centro Sur,41,Huila,41306,Gigante
			380,Región Pacífico,76,Valle del Cauca,76306,Ginebra
			381,Región Eje Cafetero - Antioquia,5,Antioquia,5306,Giraldo
			382,Región Centro Oriente,25,Cundinamarca,25307,Girardot
			383,Región Eje Cafetero - Antioquia,5,Antioquia,5308,Girardota
			384,Región Centro Oriente,68,Santander,68307,Girón
			385,Región Eje Cafetero - Antioquia,5,Antioquia,5310,Gómez Plata
			386,Región Caribe,20,Cesar,20310,González
			387,Región Centro Oriente,54,Norte de Santander,54313,Gramalote
			388,Región Eje Cafetero - Antioquia,5,Antioquia,5313,Granada
			389,Región Centro Oriente,25,Cundinamarca,25312,Granada
			390,Región Llano,50,Meta,50313,Granada
			391,Región Centro Oriente,68,Santander,68318,Guaca
			392,Región Centro Oriente,15,Boyacá,15317,Guacamayas
			393,Región Pacífico,76,Valle del Cauca,76318,Guacarí
			394,Región Pacífico,19,Cauca,19300,Guachené
			395,Región Centro Oriente,25,Cundinamarca,25317,Guachetá
			396,Región Pacífico,52,Nariño,52317,Guachucal
			397,Región Pacífico,76,Valle del Cauca,76111,Guadalajara de Buga
			398,Región Eje Cafetero - Antioquia,5,Antioquia,5315,Guadalupe
			399,Región Centro Sur,41,Huila,41319,Guadalupe
			400,Región Centro Oriente,68,Santander,68320,Guadalupe
			401,Región Centro Oriente,25,Cundinamarca,25320,Guaduas
			402,Región Pacífico,52,Nariño,52320,Guaitarilla
			403,Región Pacífico,52,Nariño,52323,Gualmatán
			404,Región Caribe,47,Magdalena,47318,Guamal
			405,Región Llano,50,Meta,50318,Guamal
			406,Región Centro Sur,73,Tolima,73319,Guamo
			407,Región Pacífico,19,Cauca,19318,Guapi
			408,Región Centro Oriente,68,Santander,68322,Guapotá
			409,Región Caribe,70,Sucre,70265,Guaranda
			410,Región Eje Cafetero - Antioquia,5,Antioquia,5318,Guarne
			411,Región Centro Oriente,25,Cundinamarca,25322,Guasca
			412,Región Eje Cafetero - Antioquia,5,Antioquia,5321,Guatapé
			413,Región Centro Oriente,25,Cundinamarca,25324,Guataquí
			414,Región Centro Oriente,25,Cundinamarca,25326,Guatavita
			415,Región Centro Oriente,15,Boyacá,15322,Guateque
			416,Región Eje Cafetero - Antioquia,66,Risaralda,66318,Guática
			417,Región Centro Oriente,68,Santander,68324,Guavatá
			418,Región Centro Oriente,25,Cundinamarca,25328,Guayabal de Siquima
			419,Región Centro Oriente,25,Cundinamarca,25335,Guayabetal
			420,Región Centro Oriente,15,Boyacá,15325,Guayatá
			421,Región Centro Oriente,68,Santander,68327,Güepsa
			422,Región Centro Oriente,15,Boyacá,15332,Güicán
			423,Región Centro Oriente,25,Cundinamarca,25339,Gutiérrez
			424,Región Centro Oriente,54,Norte de Santander,54344,Hacarí
			425,Región Caribe,13,Bolívar,13300,Hatillo de Loba
			426,Región Centro Oriente,68,Santander,68344,Hato
			427,Región Llano,85,Casanare,85125,Hato Corozal
			428,Región Caribe,44,La Guajira,44378,Hatonuevo
			429,Región Eje Cafetero - Antioquia,5,Antioquia,5347,Heliconia
			430,Región Centro Oriente,54,Norte de Santander,54347,Herrán
			431,Región Centro Sur,73,Tolima,73347,Herveo
			432,Región Eje Cafetero - Antioquia,5,Antioquia,5353,Hispania
			433,Región Centro Sur,41,Huila,41349,Hobo
			434,Región Centro Sur,73,Tolima,73349,Honda
			435,Región Centro Sur,73,Tolima,73001,Ibagué
			436,Región Centro Sur,73,Tolima,73352,Icononzo
			437,Región Pacífico,52,Nariño,52352,Iles
			438,Región Pacífico,52,Nariño,52354,Imués
			439,Región Llano,94,Guainía,94001,Inírida
			440,Región Pacífico,19,Cauca,19355,Inzá
			441,Región Pacífico,52,Nariño,52356,Ipiales
			442,Región Centro Sur,41,Huila,41357,Iquira
			443,Región Centro Sur,41,Huila,41359,Isnos
			444,Región Pacífico,27,Chocó,27361,Istmina
			445,Región Eje Cafetero - Antioquia,5,Antioquia,5360,Itagui
			446,Región Eje Cafetero - Antioquia,5,Antioquia,5361,Ituango
			447,Región Centro Oriente,15,Boyacá,15362,Iza
			448,Región Pacífico,19,Cauca,19364,Jambaló
			449,Región Pacífico,76,Valle del Cauca,76364,Jamundí
			450,Región Eje Cafetero - Antioquia,5,Antioquia,5364,Jardín
			451,Región Centro Oriente,15,Boyacá,15367,Jenesano
			452,Región Eje Cafetero - Antioquia,5,Antioquia,5368,Jericó
			453,Región Centro Oriente,15,Boyacá,15368,Jericó
			454,Región Centro Oriente,25,Cundinamarca,25368,Jerusalén
			455,Región Centro Oriente,68,Santander,68368,Jesús María
			456,Región Centro Oriente,68,Santander,68370,Jordán
			457,Región Caribe,8,Atlántico,8372,Juan de Acosta
			458,Región Centro Oriente,25,Cundinamarca,25372,Junín
			459,Región Pacífico,27,Chocó,27372,Juradó
			460,Región Caribe,23,Córdoba,23350,La Apartada
			461,Región Centro Sur,41,Huila,41378,La Argentina
			462,Región Centro Oriente,68,Santander,68377,La Belleza
			463,Región Centro Oriente,25,Cundinamarca,25377,La Calera
			464,Región Centro Oriente,15,Boyacá,15380,La Capilla
			465,Región Eje Cafetero - Antioquia,5,Antioquia,5376,La Ceja
			466,Región Eje Cafetero - Antioquia,66,Risaralda,66383,La Celia
			467,Región Centro Sur,91,Amazonas,91405,La Chorrera
			468,Región Pacífico,52,Nariño,52378,La Cruz
			469,Región Pacífico,76,Valle del Cauca,76377,La Cumbre
			470,Región Eje Cafetero - Antioquia,17,Caldas,17380,La Dorada
			471,Región Centro Oriente,54,Norte de Santander,54385,La Esperanza
			472,Región Eje Cafetero - Antioquia,5,Antioquia,5380,La Estrella
			473,Región Pacífico,52,Nariño,52381,La Florida
			474,Región Caribe,20,Cesar,20383,La Gloria
			475,Región Llano,94,Guainía,94885,La Guadalupe
			476,Región Caribe,20,Cesar,20400,La Jagua de Ibirico
			477,Región Caribe,44,La Guajira,44420,La Jagua del Pilar
			478,Región Pacífico,52,Nariño,52385,La Llanada
			479,Región Llano,50,Meta,50350,La Macarena
			480,Región Eje Cafetero - Antioquia,17,Caldas,17388,La Merced
			481,Región Centro Oriente,25,Cundinamarca,25386,La Mesa
			482,Región Centro Sur,18,Caquetá,18410,La Montañita
			483,Región Centro Oriente,25,Cundinamarca,25394,La Palma
			484,Región Caribe,20,Cesar,20621,La Paz
			485,Región Centro Oriente,68,Santander,68397,La Paz
			486,Región Centro Sur,91,Amazonas,91407,La Pedrera
			487,Región Centro Oriente,25,Cundinamarca,25398,La Peña
			488,Región Eje Cafetero - Antioquia,5,Antioquia,5390,La Pintada
			489,Región Centro Sur,41,Huila,41396,La Plata
			490,Región Centro Oriente,54,Norte de Santander,54398,La Playa
			491,Región Llano,99,Vichada,99524,La Primavera
			492,Región Llano,85,Casanare,85136,La Salina
			493,Región Pacífico,19,Cauca,19392,La Sierra
			494,Región Eje Cafetero - Antioquia,63,Quindío,63401,La Tebaida
			495,Región Pacífico,52,Nariño,52390,La Tola
			496,Región Eje Cafetero - Antioquia,5,Antioquia,5400,La Unión
			497,Región Pacífico,52,Nariño,52399,La Unión
			498,Región Caribe,70,Sucre,70400,La Unión
			499,Región Pacífico,76,Valle del Cauca,76400,La Unión
			500,Región Centro Oriente,15,Boyacá,15403,La Uvita
			501,Región Pacífico,19,Cauca,19397,La Vega
			502,Región Centro Oriente,25,Cundinamarca,25402,La Vega
			503,Región Centro Oriente,15,Boyacá,15401,La Victoria
			504,Región Pacífico,76,Valle del Cauca,76403,La Victoria
			505,Región Centro Sur,91,Amazonas,91430,La Victoria
			506,Región Eje Cafetero - Antioquia,66,Risaralda,66400,La Virginia
			507,Región Centro Oriente,54,Norte de Santander,54377,Labateca
			508,Región Centro Oriente,15,Boyacá,15377,Labranzagrande
			509,Región Centro Oriente,68,Santander,68385,Landázuri
			510,Región Centro Oriente,68,Santander,68406,Lebríja
			511,Región Centro Sur,86,Putumayo,86573,Leguízamo
			512,Región Pacífico,52,Nariño,52405,Leiva
			513,Región Llano,50,Meta,50400,Lejanías
			514,Región Centro Oriente,25,Cundinamarca,25407,Lenguazaque
			515,Región Centro Sur,73,Tolima,73408,Lérida
			516,Región Centro Sur,91,Amazonas,91001,Leticia
			517,Región Centro Sur,73,Tolima,73411,Líbano
			518,Región Eje Cafetero - Antioquia,5,Antioquia,5411,Liborina
			519,Región Pacífico,52,Nariño,52411,Linares
			520,Región Pacífico,27,Chocó,27413,Lloró
			521,Región Pacífico,19,Cauca,19418,López
			522,Región Caribe,23,Córdoba,23417,Lorica
			523,Región Pacífico,52,Nariño,52418,Los Andes
			524,Región Caribe,23,Córdoba,23419,Los Córdobas
			525,Región Caribe,70,Sucre,70418,Los Palmitos
			526,Región Centro Oriente,54,Norte de Santander,54405,Los Patios
			527,Región Centro Oriente,68,Santander,68418,Los Santos
			528,Región Centro Oriente,54,Norte de Santander,54418,Lourdes
			529,Región Caribe,8,Atlántico,8421,Luruaco
			530,Región Centro Oriente,15,Boyacá,15425,Macanal
			531,Región Centro Oriente,68,Santander,68425,Macaravita
			532,Región Eje Cafetero - Antioquia,5,Antioquia,5425,Maceo
			533,Región Centro Oriente,25,Cundinamarca,25426,Macheta
			534,Región Centro Oriente,25,Cundinamarca,25430,Madrid
			535,Región Caribe,13,Bolívar,13430,Magangué
			536,Región Pacífico,52,Nariño,52427,Magüí
			537,Región Caribe,13,Bolívar,13433,Mahates
			538,Región Caribe,44,La Guajira,44430,Maicao
			539,Región Caribe,70,Sucre,70429,Majagual
			540,Región Centro Oriente,68,Santander,68432,Málaga
			541,Región Caribe,8,Atlántico,8433,Malambo
			542,Región Pacífico,52,Nariño,52435,Mallama
			543,Región Caribe,8,Atlántico,8436,Manatí
			544,Región Caribe,20,Cesar,20443,Manaure
			545,Región Caribe,44,La Guajira,44560,Manaure
			546,Región Llano,85,Casanare,85139,Maní
			547,Región Eje Cafetero - Antioquia,17,Caldas,17001,Manizales
			548,Región Centro Oriente,25,Cundinamarca,25436,Manta
			549,Región Eje Cafetero - Antioquia,17,Caldas,17433,Manzanares
			550,Región Llano,50,Meta,50325,Mapiripán
			551,Región Llano,94,Guainía,94663,Mapiripana
			552,Región Caribe,13,Bolívar,13440,Margarita
			553,Región Caribe,13,Bolívar,13442,María la Baja
			554,Región Eje Cafetero - Antioquia,5,Antioquia,5440,Marinilla
			555,Región Centro Oriente,15,Boyacá,15442,Maripí
			556,Región Centro Sur,73,Tolima,73443,Mariquita
			557,Región Eje Cafetero - Antioquia,17,Caldas,17442,Marmato
			558,Región Eje Cafetero - Antioquia,17,Caldas,17444,Marquetalia
			559,Región Eje Cafetero - Antioquia,66,Risaralda,66440,Marsella
			560,Región Eje Cafetero - Antioquia,17,Caldas,17446,Marulanda
			561,Región Centro Oriente,68,Santander,68444,Matanza
			562,Región Eje Cafetero - Antioquia,5,Antioquia,5001,Medellín
			563,Región Centro Oriente,25,Cundinamarca,25438,Medina
			564,Región Pacífico,27,Chocó,27425,Medio Atrato
			565,Región Pacífico,27,Chocó,27430,Medio Baudó
			566,Región Pacífico,27,Chocó,27450,Medio San Juan
			567,Región Centro Sur,73,Tolima,73449,Melgar
			568,Región Pacífico,19,Cauca,19450,Mercaderes
			569,Región Llano,50,Meta,50330,Mesetas
			570,Región Centro Sur,18,Caquetá,18460,Milán
			571,Región Centro Oriente,15,Boyacá,15455,Miraflores
			572,Región Llano,95,Guaviare,95200,Miraflores
			573,Región Pacífico,19,Cauca,19455,Miranda
			574,Región Centro Sur,91,Amazonas,91460,Miriti Paraná
			575,Región Eje Cafetero - Antioquia,66,Risaralda,66456,Mistrató
			576,Región Llano,97,Vaupés,97001,Mitú
			577,Región Centro Sur,86,Putumayo,86001,Mocoa
			578,Región Centro Oriente,68,Santander,68464,Mogotes
			579,Región Centro Oriente,68,Santander,68468,Molagavita
			580,Región Caribe,23,Córdoba,23464,Momil
			581,Región Caribe,13,Bolívar,13468,Mompós
			582,Región Centro Oriente,15,Boyacá,15464,Mongua
			583,Región Centro Oriente,15,Boyacá,15466,Monguí
			584,Región Centro Oriente,15,Boyacá,15469,Moniquirá
			585,Región Eje Cafetero - Antioquia,5,Antioquia,5467,Montebello
			586,Región Caribe,13,Bolívar,13458,Montecristo
			587,Región Caribe,23,Córdoba,23466,Montelíbano
			588,Región Eje Cafetero - Antioquia,63,Quindío,63470,Montenegro
			589,Región Caribe,23,Córdoba,23001,Montería
			590,Región Llano,85,Casanare,85162,Monterrey
			591,Región Caribe,23,Córdoba,23500,Moñitos
			592,Región Caribe,13,Bolívar,13473,Morales
			593,Región Pacífico,19,Cauca,19473,Morales
			594,Región Centro Sur,18,Caquetá,18479,Morelia
			595,Región Llano,94,Guainía,94888,Morichal
			596,Región Caribe,70,Sucre,70473,Morroa
			597,Región Centro Oriente,25,Cundinamarca,25473,Mosquera
			598,Región Pacífico,52,Nariño,52473,Mosquera
			599,Región Centro Oriente,15,Boyacá,15476,Motavita
			600,Región Centro Sur,73,Tolima,73461,Murillo
			601,Región Eje Cafetero - Antioquia,5,Antioquia,5475,Murindó
			602,Región Eje Cafetero - Antioquia,5,Antioquia,5480,Mutatá
			603,Región Centro Oriente,54,Norte de Santander,54480,Mutiscua
			604,Región Centro Oriente,15,Boyacá,15480,Muzo
			605,Región Eje Cafetero - Antioquia,5,Antioquia,5483,Nariño
			606,Región Centro Oriente,25,Cundinamarca,25483,Nariño
			607,Región Pacífico,52,Nariño,52480,Nariño
			608,Región Centro Sur,41,Huila,41483,Nátaga
			609,Región Centro Sur,73,Tolima,73483,Natagaima
			610,Región Eje Cafetero - Antioquia,5,Antioquia,5495,Nechí
			611,Región Eje Cafetero - Antioquia,5,Antioquia,5490,Necoclí
			612,Región Eje Cafetero - Antioquia,17,Caldas,17486,Neira
			613,Región Centro Sur,41,Huila,41001,Neiva
			614,Región Centro Oriente,25,Cundinamarca,25486,Nemocón
			615,Región Centro Oriente,25,Cundinamarca,25488,Nilo
			616,Región Centro Oriente,25,Cundinamarca,25489,Nimaima
			617,Región Centro Oriente,15,Boyacá,15491,Nobsa
			618,Región Centro Oriente,25,Cundinamarca,25491,Nocaima
			619,Región Eje Cafetero - Antioquia,17,Caldas,17495,Norcasia
			620,Región Caribe,13,Bolívar,13490,Norosí
			621,Región Pacífico,27,Chocó,27491,Nóvita
			622,Región Caribe,47,Magdalena,47460,Nueva Granada
			623,Región Centro Oriente,15,Boyacá,15494,Nuevo Colón
			624,Región Llano,85,Casanare,85225,Nunchía
			625,Región Pacífico,27,Chocó,27495,Nuquí
			626,Región Pacífico,76,Valle del Cauca,76497,Obando
			627,Región Centro Oriente,68,Santander,68498,Ocamonte
			628,Región Centro Oriente,54,Norte de Santander,54498,Ocaña
			629,Región Centro Oriente,68,Santander,68500,Oiba
			630,Región Centro Oriente,15,Boyacá,15500,Oicatá
			631,Región Eje Cafetero - Antioquia,5,Antioquia,5501,Olaya
			632,Región Pacífico,52,Nariño,52490,Olaya Herrera
			633,Región Centro Oriente,68,Santander,68502,Onzaga
			634,Región Centro Sur,41,Huila,41503,Oporapa
			635,Región Centro Sur,86,Putumayo,86320,Orito
			636,Región Llano,85,Casanare,85230,Orocué
			637,Región Centro Sur,73,Tolima,73504,Ortega
			638,Región Pacífico,52,Nariño,52506,Ospina
			639,Región Centro Oriente,15,Boyacá,15507,Otanche
			640,Región Caribe,70,Sucre,70508,Ovejas
			641,Región Centro Oriente,15,Boyacá,15511,Pachavita
			642,Región Centro Oriente,25,Cundinamarca,25513,Pacho
			643,Región Llano,97,Vaupés,97511,Pacoa
			644,Región Eje Cafetero - Antioquia,17,Caldas,17513,Pácora
			645,Región Pacífico,19,Cauca,19513,Padilla
			646,Región Centro Oriente,15,Boyacá,15514,Páez
			647,Región Pacífico,19,Cauca,19517,Páez
			648,Región Centro Sur,41,Huila,41518,Paicol
			649,Región Caribe,20,Cesar,20517,Pailitas
			650,Región Centro Oriente,25,Cundinamarca,25518,Paime
			651,Región Centro Oriente,15,Boyacá,15516,Paipa
			652,Región Centro Oriente,15,Boyacá,15518,Pajarito
			653,Región Centro Sur,41,Huila,41524,Palermo
			654,Región Eje Cafetero - Antioquia,17,Caldas,17524,Palestina
			655,Región Centro Sur,41,Huila,41530,Palestina
			656,Región Centro Oriente,68,Santander,68522,Palmar
			657,Región Caribe,8,Atlántico,8520,Palmar de Varela
			658,Región Centro Oriente,68,Santander,68524,Palmas del Socorro
			659,Región Pacífico,76,Valle del Cauca,76520,Palmira
			660,Región Caribe,70,Sucre,70523,Palmito
			661,Región Centro Sur,73,Tolima,73520,Palocabildo
			662,Región Centro Oriente,54,Norte de Santander,54518,Pamplona
			663,Región Centro Oriente,54,Norte de Santander,54520,Pamplonita
			664,Región Llano,94,Guainía,94887,Pana Pana
			665,Región Centro Oriente,25,Cundinamarca,25524,Pandi
			666,Región Centro Oriente,15,Boyacá,15522,Panqueba
			667,Región Llano,97,Vaupés,97777,Papunaua
			668,Región Centro Oriente,68,Santander,68533,Páramo
			669,Región Centro Oriente,25,Cundinamarca,25530,Paratebueno
			670,Región Centro Oriente,25,Cundinamarca,25535,Pasca
			671,Región Pacífico,52,Nariño,52001,Pasto
			672,Región Pacífico,19,Cauca,19532,Patía
			673,Región Centro Oriente,15,Boyacá,15531,Pauna
			674,Región Centro Oriente,15,Boyacá,15533,Paya
			675,Región Llano,85,Casanare,85250,Paz de Ariporo
			676,Región Centro Oriente,15,Boyacá,15537,Paz de Río
			677,Región Caribe,47,Magdalena,47541,Pedraza
			678,Región Caribe,20,Cesar,20550,Pelaya
			679,Región Eje Cafetero - Antioquia,17,Caldas,17541,Pensilvania
			680,Región Eje Cafetero - Antioquia,5,Antioquia,5541,Peñol
			681,Región Eje Cafetero - Antioquia,5,Antioquia,5543,Peque
			682,Región Eje Cafetero - Antioquia,66,Risaralda,66001,Pereira
			683,Región Centro Oriente,15,Boyacá,15542,Pesca
			684,Región Pacífico,19,Cauca,19533,Piamonte
			685,Región Centro Oriente,68,Santander,68547,Piedecuesta
			686,Región Centro Sur,73,Tolima,73547,Piedras
			687,Región Pacífico,19,Cauca,19548,Piendamó
			688,Región Eje Cafetero - Antioquia,63,Quindío,63548,Pijao
			689,Región Caribe,47,Magdalena,47545,Pijiño del Carmen
			690,Región Centro Oriente,68,Santander,68549,Pinchote
			691,Región Caribe,13,Bolívar,13549,Pinillos
			692,Región Caribe,8,Atlántico,8549,Piojó
			693,Región Centro Oriente,15,Boyacá,15550,Pisba
			694,Región Centro Sur,41,Huila,41548,Pital
			695,Región Centro Sur,41,Huila,41551,Pitalito
			696,Región Caribe,47,Magdalena,47551,Pivijay
			697,Región Centro Sur,73,Tolima,73555,Planadas
			698,Región Caribe,23,Córdoba,23555,Planeta Rica
			699,Región Caribe,47,Magdalena,47555,Plato
			700,Región Pacífico,52,Nariño,52540,Policarpa
			701,Región Caribe,8,Atlántico,8558,Polonuevo
			702,Región Caribe,8,Atlántico,8560,Ponedera
			703,Región Pacífico,19,Cauca,19001,Popayán
			704,Región Llano,85,Casanare,85263,Pore
			705,Región Pacífico,52,Nariño,52560,Potosí
			706,Región Pacífico,76,Valle del Cauca,76563,Pradera
			707,Región Centro Sur,73,Tolima,73563,Prado
			708,Región Pacífico,52,Nariño,52565,Providencia
			709,Región Caribe,88,"Archipiélago de San Andrés, Providencia y Santa Catalina",88564,Providencia
			710,Región Caribe,20,Cesar,20570,Pueblo Bello
			711,Región Caribe,23,Córdoba,23570,Pueblo Nuevo
			712,Región Eje Cafetero - Antioquia,66,Risaralda,66572,Pueblo Rico
			713,Región Caribe,47,Magdalena,47570,Pueblo Viejo
			714,Región Eje Cafetero - Antioquia,5,Antioquia,5576,Pueblorrico
			715,Región Centro Oriente,68,Santander,68572,Puente Nacional
			716,Región Pacífico,52,Nariño,52573,Puerres
			717,Región Centro Sur,91,Amazonas,91530,Puerto Alegría
			718,Región Centro Sur,91,Amazonas,91536,Puerto Arica
			719,Región Centro Sur,86,Putumayo,86568,Puerto Asís
			720,Región Eje Cafetero - Antioquia,5,Antioquia,5579,Puerto Berrío
			721,Región Centro Oriente,15,Boyacá,15572,Puerto Boyacá
			722,Región Centro Sur,86,Putumayo,86569,Puerto Caicedo
			723,Región Llano,99,Vichada,99001,Puerto Carreño
			724,Región Caribe,8,Atlántico,8573,Puerto Colombia
			725,Región Llano,94,Guainía,94884,Puerto Colombia
			726,Región Llano,50,Meta,50450,Puerto Concordia
			727,Región Caribe,23,Córdoba,23574,Puerto Escondido
			728,Región Llano,50,Meta,50568,Puerto Gaitán
			729,Región Centro Sur,86,Putumayo,86571,Puerto Guzmán
			730,Región Caribe,23,Córdoba,23580,Puerto Libertador
			731,Región Llano,50,Meta,50577,Puerto Lleras
			732,Región Llano,50,Meta,50573,Puerto López
			733,Región Eje Cafetero - Antioquia,5,Antioquia,5585,Puerto Nare
			734,Región Centro Sur,91,Amazonas,91540,Puerto Nariño
			735,Región Centro Oriente,68,Santander,68573,Puerto Parra
			736,Región Centro Sur,18,Caquetá,18592,Puerto Rico
			737,Región Llano,50,Meta,50590,Puerto Rico
			738,Región Llano,81,Arauca,81591,Puerto Rondón
			739,Región Centro Oriente,25,Cundinamarca,25572,Puerto Salgar
			740,Región Centro Oriente,54,Norte de Santander,54553,Puerto Santander
			741,Región Centro Sur,91,Amazonas,91669,Puerto Santander
			742,Región Pacífico,19,Cauca,19573,Puerto Tejada
			743,Región Eje Cafetero - Antioquia,5,Antioquia,5591,Puerto Triunfo
			744,Región Centro Oriente,68,Santander,68575,Puerto Wilches
			745,Región Centro Oriente,25,Cundinamarca,25580,Pulí
			746,Región Pacífico,52,Nariño,52585,Pupiales
			747,Región Pacífico,19,Cauca,19585,Puracé
			748,Región Centro Sur,73,Tolima,73585,Purificación
			749,Región Caribe,23,Córdoba,23586,Purísima
			750,Región Centro Oriente,25,Cundinamarca,25592,Quebradanegra
			751,Región Centro Oriente,25,Cundinamarca,25594,Quetame
			752,Región Pacífico,27,Chocó,27001,Quibdó
			753,Región Eje Cafetero - Antioquia,63,Quindío,63594,Quimbaya
			754,Región Eje Cafetero - Antioquia,66,Risaralda,66594,Quinchía
			755,Región Centro Oriente,15,Boyacá,15580,Quípama
			756,Región Centro Oriente,25,Cundinamarca,25596,Quipile
			757,Región Centro Oriente,54,Norte de Santander,54599,Ragonvalia
			758,Región Centro Oriente,15,Boyacá,15599,Ramiriquí
			759,Región Centro Oriente,15,Boyacá,15600,Ráquira
			760,Región Llano,85,Casanare,85279,Recetor
			761,Región Caribe,13,Bolívar,13580,Regidor
			762,Región Eje Cafetero - Antioquia,5,Antioquia,5604,Remedios
			763,Región Caribe,47,Magdalena,47605,Remolino
			764,Región Caribe,8,Atlántico,8606,Repelón
			765,Región Llano,50,Meta,50606,Restrepo
			766,Región Pacífico,76,Valle del Cauca,76606,Restrepo
			767,Región Eje Cafetero - Antioquia,5,Antioquia,5607,Retiro
			768,Región Centro Oriente,25,Cundinamarca,25612,Ricaurte
			769,Región Pacífico,52,Nariño,52612,Ricaurte
			770,Región Centro Sur,73,Tolima,73616,Rio Blanco
			771,Región Caribe,20,Cesar,20614,Río de Oro
			772,Región Pacífico,27,Chocó,27580,Río Iro
			773,Región Pacífico,27,Chocó,27600,Río Quito
			774,Región Caribe,13,Bolívar,13600,Río Viejo
			775,Región Pacífico,76,Valle del Cauca,76616,Riofrío
			776,Región Caribe,44,La Guajira,44001,Riohacha
			777,Región Eje Cafetero - Antioquia,5,Antioquia,5615,Rionegro
			778,Región Centro Oriente,68,Santander,68615,Rionegro
			779,Región Eje Cafetero - Antioquia,17,Caldas,17614,Riosucio
			780,Región Pacífico,27,Chocó,27615,Riosucio
			781,Región Eje Cafetero - Antioquia,17,Caldas,17616,Risaralda
			782,Región Centro Sur,41,Huila,41615,Rivera
			783,Región Pacífico,52,Nariño,52621,Roberto Payán
			784,Región Pacífico,76,Valle del Cauca,76622,Roldanillo
			785,Región Centro Sur,73,Tolima,73622,Roncesvalles
			786,Región Centro Oriente,15,Boyacá,15621,Rondón
			787,Región Pacífico,19,Cauca,19622,Rosas
			788,Región Centro Sur,73,Tolima,73624,Rovira
			789,Región Centro Oriente,68,Santander,68655,Sabana de Torres
			790,Región Caribe,8,Atlántico,8634,Sabanagrande
			791,Región Eje Cafetero - Antioquia,5,Antioquia,5628,Sabanalarga
			792,Región Caribe,8,Atlántico,8638,Sabanalarga
			793,Región Llano,85,Casanare,85300,Sabanalarga
			794,Región Caribe,47,Magdalena,47660,Sabanas de San Angel
			795,Región Eje Cafetero - Antioquia,5,Antioquia,5631,Sabaneta
			796,Región Centro Oriente,15,Boyacá,15632,Saboyá
			797,Región Llano,85,Casanare,85315,Sácama
			798,Región Centro Oriente,15,Boyacá,15638,Sáchica
			799,Región Caribe,23,Córdoba,23660,Sahagún
			800,Región Centro Sur,41,Huila,41660,Saladoblanco
			801,Región Eje Cafetero - Antioquia,17,Caldas,17653,Salamina
			802,Región Caribe,47,Magdalena,47675,Salamina
			803,Región Centro Oriente,54,Norte de Santander,54660,Salazar
			804,Región Centro Sur,73,Tolima,73671,Saldaña
			805,Región Eje Cafetero - Antioquia,63,Quindío,63690,Salento
			806,Región Eje Cafetero - Antioquia,5,Antioquia,5642,Salgar
			807,Región Centro Oriente,15,Boyacá,15646,Samacá
			808,Región Eje Cafetero - Antioquia,17,Caldas,17662,Samaná
			809,Región Pacífico,52,Nariño,52678,Samaniego
			810,Región Caribe,70,Sucre,70670,Sampués
			811,Región Centro Sur,41,Huila,41668,San Agustín
			812,Región Caribe,20,Cesar,20710,San Alberto
			813,Región Centro Oriente,68,Santander,68669,San Andrés
			814,Región Caribe,88,"Archipiélago de San Andrés, Providencia y Santa Catalina",88001,San Andrés
			815,Región Eje Cafetero - Antioquia,5,Antioquia,5647,San Andrés de Cuerquía
			816,Región Pacífico,52,Nariño,52835,San Andrés de Tumaco
			817,Región Caribe,23,Córdoba,23670,San Andrés Sotavento
			818,Región Caribe,23,Córdoba,23672,San Antero
			819,Región Centro Sur,73,Tolima,73675,San Antonio
			820,Región Centro Oriente,25,Cundinamarca,25645,San Antonio del Tequendama
			821,Región Centro Oriente,68,Santander,68673,San Benito
			822,Región Caribe,70,Sucre,70678,San Benito Abad
			823,Región Centro Oriente,25,Cundinamarca,25649,San Bernardo
			824,Región Pacífico,52,Nariño,52685,San Bernardo
			825,Región Caribe,23,Córdoba,23675,San Bernardo del Viento
			826,Región Centro Oriente,54,Norte de Santander,54670,San Calixto
			827,Región Eje Cafetero - Antioquia,5,Antioquia,5649,San Carlos
			828,Región Caribe,23,Córdoba,23678,San Carlos
			829,Región Llano,50,Meta,50680,San Carlos de Guaroa
			830,Región Centro Oriente,25,Cundinamarca,25653,San Cayetano
			831,Región Centro Oriente,54,Norte de Santander,54673,San Cayetano
			832,Región Caribe,13,Bolívar,13620,San Cristóbal
			833,Región Caribe,20,Cesar,20750,San Diego
			834,Región Centro Oriente,15,Boyacá,15660,San Eduardo
			835,Región Caribe,13,Bolívar,13647,San Estanislao
			836,Región Llano,94,Guainía,94883,San Felipe
			837,Región Caribe,13,Bolívar,13650,San Fernando
			838,Región Eje Cafetero - Antioquia,5,Antioquia,5652,San Francisco
			839,Región Centro Oriente,25,Cundinamarca,25658,San Francisco
			840,Región Centro Sur,86,Putumayo,86755,San Francisco
			841,Región Centro Oriente,68,Santander,68679,San Gil
			842,Región Caribe,13,Bolívar,13654,San Jacinto
			843,Región Caribe,13,Bolívar,13655,San Jacinto del Cauca
			844,Región Eje Cafetero - Antioquia,5,Antioquia,5656,San Jerónimo
			845,Región Centro Oriente,68,Santander,68682,San Joaquín
			846,Región Eje Cafetero - Antioquia,17,Caldas,17665,San José
			847,Región Eje Cafetero - Antioquia,5,Antioquia,5658,San José de La Montaña
			848,Región Centro Oriente,68,Santander,68684,San José de Miranda
			849,Región Centro Oriente,15,Boyacá,15664,San José de Pare
			850,Región Caribe,23,Córdoba,23682,San José de Uré
			851,Región Centro Sur,18,Caquetá,18610,San José del Fragua
			852,Región Llano,95,Guaviare,95001,San José del Guaviare
			853,Región Pacífico,27,Chocó,27660,San José del Palmar
			854,Región Llano,50,Meta,50683,San Juan de Arama
			855,Región Caribe,70,Sucre,70702,San Juan de Betulia
			856,Región Centro Oriente,25,Cundinamarca,25662,San Juan de Río Seco
			857,Región Eje Cafetero - Antioquia,5,Antioquia,5659,San Juan de Urabá
			858,Región Caribe,44,La Guajira,44650,San Juan del Cesar
			859,Región Caribe,13,Bolívar,13657,San Juan Nepomuceno
			860,Región Llano,50,Meta,50686,San Juanito
			861,Región Pacífico,52,Nariño,52687,San Lorenzo
			862,Región Eje Cafetero - Antioquia,5,Antioquia,5660,San Luis
			863,Región Centro Sur,73,Tolima,73678,San Luis
			864,Región Centro Oriente,15,Boyacá,15667,San Luis de Gaceno
			865,Región Llano,85,Casanare,85325,San Luis de Gaceno
			866,Región Caribe,70,Sucre,70742,San Luis de Sincé
			867,Región Caribe,70,Sucre,70708,San Marcos
			868,Región Caribe,20,Cesar,20770,San Martín
			869,Región Llano,50,Meta,50689,San Martín
			870,Región Caribe,13,Bolívar,13667,San Martín de Loba
			871,Región Centro Oriente,15,Boyacá,15673,San Mateo
			872,Región Centro Oriente,68,Santander,68686,San Miguel
			873,Región Centro Sur,86,Putumayo,86757,San Miguel
			874,Región Centro Oriente,15,Boyacá,15676,San Miguel de Sema
			875,Región Caribe,70,Sucre,70713,San Onofre
			876,Región Pacífico,52,Nariño,52693,San Pablo
			877,Región Caribe,13,Bolívar,13670,San Pablo de Borbur
			878,Región Centro Oriente,15,Boyacá,15681,San Pablo de Borbur
			879,Región Eje Cafetero - Antioquia,5,Antioquia,5664,San Pedro
			880,Región Caribe,70,Sucre,70717,San Pedro
			881,Región Pacífico,76,Valle del Cauca,76670,San Pedro
			882,Región Pacífico,52,Nariño,52694,San Pedro de Cartago
			883,Región Eje Cafetero - Antioquia,5,Antioquia,5665,San Pedro de Uraba
			884,Región Caribe,23,Córdoba,23686,San Pelayo
			885,Región Eje Cafetero - Antioquia,5,Antioquia,5667,San Rafael
			886,Región Eje Cafetero - Antioquia,5,Antioquia,5670,San Roque
			887,Región Pacífico,19,Cauca,19693,San Sebastián
			888,Región Caribe,47,Magdalena,47692,San Sebastián de Buenavista
			889,Región Eje Cafetero - Antioquia,5,Antioquia,5674,San Vicente
			890,Región Centro Oriente,68,Santander,68689,San Vicente de Chucurí
			891,Región Centro Sur,18,Caquetá,18753,San Vicente del Caguán
			892,Región Caribe,47,Magdalena,47703,San Zenón
			893,Región Pacífico,52,Nariño,52683,Sandoná
			894,Región Caribe,47,Magdalena,47707,Santa Ana
			895,Región Eje Cafetero - Antioquia,5,Antioquia,5679,Santa Bárbara
			896,Región Pacífico,52,Nariño,52696,Santa Bárbara
			897,Región Centro Oriente,68,Santander,68705,Santa Bárbara
			898,Región Caribe,47,Magdalena,47720,Santa Bárbara de Pinto
			899,Región Caribe,13,Bolívar,13673,Santa Catalina
			900,Región Centro Oriente,15,Boyacá,15757,Socha
			901,Región Centro Oriente,68,Santander,68720,Santa Helena del Opón
			902,Región Centro Sur,73,Tolima,73686,Santa Isabel
			903,Región Caribe,8,Atlántico,8675,Santa Lucía
			904,Región Centro Oriente,15,Boyacá,15690,Santa María
			905,Región Centro Sur,41,Huila,41676,Santa María
			906,Región Caribe,47,Magdalena,47001,Santa Marta
			907,Región Caribe,13,Bolívar,13683,Santa Rosa
			908,Región Pacífico,19,Cauca,19701,Santa Rosa
			909,Región Eje Cafetero - Antioquia,66,Risaralda,66682,Santa Rosa de Cabal
			910,Región Eje Cafetero - Antioquia,5,Antioquia,5686,Santa Rosa de Osos
			911,Región Centro Oriente,15,Boyacá,15693,Santa Rosa de Viterbo
			912,Región Caribe,13,Bolívar,13688,Santa Rosa del Sur
			913,Región Llano,99,Vichada,99624,Santa Rosalía
			914,Región Centro Oriente,15,Boyacá,15696,Santa Sofía
			915,Región Pacífico,52,Nariño,52699,Santacruz
			916,Región Eje Cafetero - Antioquia,5,Antioquia,5042,Santafé de Antioquia
			917,Región Centro Oriente,15,Boyacá,15686,Santana
			918,Región Pacífico,19,Cauca,19698,Santander de Quilichao
			919,Región Centro Oriente,54,Norte de Santander,54680,Santiago
			920,Región Centro Sur,86,Putumayo,86760,Santiago
			921,Región Caribe,70,Sucre,70820,Santiago de Tolú
			922,Región Eje Cafetero - Antioquia,5,Antioquia,5690,Santo Domingo
			923,Región Caribe,8,Atlántico,8685,Santo Tomás
			924,Región Eje Cafetero - Antioquia,66,Risaralda,66687,Santuario
			925,Región Pacífico,52,Nariño,52720,Sapuyes
			926,Región Llano,81,Arauca,81736,Saravena
			927,Región Centro Oriente,54,Norte de Santander,54720,Sardinata
			928,Región Centro Oriente,25,Cundinamarca,25718,Sasaima
			929,Región Centro Oriente,15,Boyacá,15720,Sativanorte
			930,Región Centro Oriente,15,Boyacá,15723,Sativasur
			931,Región Eje Cafetero - Antioquia,5,Antioquia,5736,Segovia
			932,Región Centro Oriente,25,Cundinamarca,25736,Sesquilé
			933,Región Pacífico,76,Valle del Cauca,76736,Sevilla
			934,Región Centro Oriente,15,Boyacá,15740,Siachoque
			935,Región Centro Oriente,25,Cundinamarca,25740,Sibaté
			936,Región Centro Sur,86,Putumayo,86749,Sibundoy
			937,Región Centro Oriente,54,Norte de Santander,54743,Silos
			938,Región Centro Oriente,25,Cundinamarca,25743,Silvania
			939,Región Pacífico,19,Cauca,19743,Silvia
			940,Región Centro Oriente,68,Santander,68745,Simacota
			941,Región Centro Oriente,25,Cundinamarca,25745,Simijaca
			942,Región Caribe,13,Bolívar,13744,Simití
			943,Región Caribe,70,Sucre,70001,Sincelejo
			944,Región Pacífico,27,Chocó,27745,Sipí
			945,Región Caribe,47,Magdalena,47745,Sitionuevo
			946,Región Centro Oriente,25,Cundinamarca,25754,Soacha
			947,Región Centro Oriente,15,Boyacá,15753,Soatá
			948,Región Centro Oriente,68,Santander,68755,Socorro
			949,Región Centro Oriente,15,Boyacá,15755,Socotá
			950,Región Centro Oriente,15,Boyacá,15759,Sogamoso
			951,Región Centro Sur,18,Caquetá,18756,Solano
			952,Región Caribe,8,Atlántico,8758,Soledad
			953,Región Centro Sur,18,Caquetá,18785,Solita
			954,Región Centro Oriente,15,Boyacá,15761,Somondoco
			955,Región Eje Cafetero - Antioquia,5,Antioquia,5756,Sonsón
			956,Región Eje Cafetero - Antioquia,5,Antioquia,5761,Sopetrán
			957,Región Caribe,13,Bolívar,13760,Soplaviento
			958,Región Centro Oriente,25,Cundinamarca,25758,Sopó
			959,Región Centro Oriente,15,Boyacá,15762,Sora
			960,Región Centro Oriente,15,Boyacá,15764,Soracá
			961,Región Centro Oriente,15,Boyacá,15763,Sotaquirá
			962,Región Pacífico,19,Cauca,19760,Sotara
			963,Región Centro Oriente,68,Santander,68770,Suaita
			964,Región Caribe,8,Atlántico,8770,Suan
			965,Región Pacífico,19,Cauca,19780,Suárez
			966,Región Centro Sur,73,Tolima,73770,Suárez
			967,Región Centro Sur,41,Huila,41770,Suaza
			968,Región Centro Oriente,25,Cundinamarca,25769,Subachoque
			969,Región Pacífico,19,Cauca,19785,Sucre
			970,Región Centro Oriente,68,Santander,68773,Sucre
			971,Región Caribe,70,Sucre,70771,Sucre
			972,Región Centro Oriente,25,Cundinamarca,25772,Suesca
			973,Región Centro Oriente,25,Cundinamarca,25777,Supatá
			974,Región Eje Cafetero - Antioquia,17,Caldas,17777,Supía
			975,Región Centro Oriente,68,Santander,68780,Suratá
			976,Región Centro Oriente,25,Cundinamarca,25779,Susa
			977,Región Centro Oriente,15,Boyacá,15774,Susacón
			978,Región Centro Oriente,15,Boyacá,15776,Sutamarchán
			979,Región Centro Oriente,25,Cundinamarca,25781,Sutatausa
			980,Región Centro Oriente,15,Boyacá,15778,Sutatenza
			981,Región Centro Oriente,25,Cundinamarca,25785,Tabio
			982,Región Pacífico,27,Chocó,27787,Tadó
			983,Región Caribe,13,Bolívar,13780,Talaigua Nuevo
			984,Región Caribe,20,Cesar,20787,Tamalameque
			985,Región Llano,85,Casanare,85400,Támara
			986,Región Llano,81,Arauca,81794,Tame
			987,Región Eje Cafetero - Antioquia,5,Antioquia,5789,Támesis
			988,Región Pacífico,52,Nariño,52786,Taminango
			989,Región Pacífico,52,Nariño,52788,Tangua
			990,Región Llano,97,Vaupés,97666,Taraira
			991,Región Centro Sur,91,Amazonas,91798,Tarapacá
			992,Región Eje Cafetero - Antioquia,5,Antioquia,5790,Tarazá
			993,Región Centro Sur,41,Huila,41791,Tarqui
			994,Región Eje Cafetero - Antioquia,5,Antioquia,5792,Tarso
			995,Región Centro Oriente,15,Boyacá,15790,Tasco
			996,Región Llano,85,Casanare,85410,Tauramena
			997,Región Centro Oriente,25,Cundinamarca,25793,Tausa
			998,Región Centro Sur,41,Huila,41799,Tello
			999,Región Centro Oriente,25,Cundinamarca,25797,Tena
			1000,Región Caribe,47,Magdalena,47798,Tenerife
			1001,Región Centro Oriente,25,Cundinamarca,25799,Tenjo
			1002,Región Centro Oriente,15,Boyacá,15798,Tenza
			1003,Región Centro Oriente,54,Norte de Santander,54800,Teorama
			1004,Región Centro Sur,41,Huila,41801,Teruel
			1005,Región Centro Sur,41,Huila,41797,Tesalia
			1006,Región Centro Oriente,25,Cundinamarca,25805,Tibacuy
			1007,Región Centro Oriente,15,Boyacá,15804,Tibaná
			1008,Región Centro Oriente,15,Boyacá,15806,Tibasosa
			1009,Región Centro Oriente,25,Cundinamarca,25807,Tibirita
			1010,Región Centro Oriente,54,Norte de Santander,54810,Tibú
			1011,Región Caribe,23,Córdoba,23807,Tierralta
			1012,Región Centro Sur,41,Huila,41807,Timaná
			1013,Región Pacífico,19,Cauca,19807,Timbío
			1014,Región Pacífico,19,Cauca,19809,Timbiquí
			1015,Región Centro Oriente,15,Boyacá,15808,Tinjacá
			1016,Región Centro Oriente,15,Boyacá,15810,Tipacoque
			1017,Región Caribe,13,Bolívar,13810,Tiquisio
			1018,Región Eje Cafetero - Antioquia,5,Antioquia,5809,Titiribí
			1019,Región Centro Oriente,15,Boyacá,15814,Toca
			1020,Región Centro Oriente,25,Cundinamarca,25815,Tocaima
			1021,Región Centro Oriente,25,Cundinamarca,25817,Tocancipá
			1022,Región Centro Oriente,15,Boyacá,15816,Togüí
			1023,Región Eje Cafetero - Antioquia,5,Antioquia,5819,Toledo
			1024,Región Centro Oriente,54,Norte de Santander,54820,Toledo
			1025,Región Caribe,70,Sucre,70823,Tolú Viejo
			1026,Región Centro Oriente,68,Santander,68820,Tona
			1027,Región Centro Oriente,15,Boyacá,15820,Tópaga
			1028,Región Centro Oriente,25,Cundinamarca,25823,Topaipí
			1029,Región Pacífico,19,Cauca,19821,Toribio
			1030,Región Pacífico,76,Valle del Cauca,76823,Toro
			1031,Región Centro Oriente,15,Boyacá,15822,Tota
			1032,Región Pacífico,19,Cauca,19824,Totoró
			1033,Región Llano,85,Casanare,85430,Trinidad
			1034,Región Pacífico,76,Valle del Cauca,76828,Trujillo
			1035,Región Caribe,8,Atlántico,8832,Tubará
			1036,Región Caribe,23,Córdoba,23815,Tuchín
			1037,Región Pacífico,76,Valle del Cauca,76834,Tuluá
			1038,Región Centro Oriente,15,Boyacá,15001,Tunja
			1039,Región Centro Oriente,15,Boyacá,15832,Tununguá
			1040,Región Pacífico,52,Nariño,52838,Túquerres
			1041,Región Caribe,13,Bolívar,13836,Turbaco
			1042,Región Caribe,13,Bolívar,13838,Turbaná
			1043,Región Eje Cafetero - Antioquia,5,Antioquia,5837,Turbo
			1044,Región Centro Oriente,15,Boyacá,15835,Turmequé
			1045,Región Centro Oriente,15,Boyacá,15837,Tuta
			1046,Región Centro Oriente,15,Boyacá,15839,Tutazá
			1047,Región Centro Oriente,25,Cundinamarca,25839,Ubalá
			1048,Región Centro Oriente,25,Cundinamarca,25841,Ubaque
			1049,Región Pacífico,76,Valle del Cauca,76845,Ulloa
			1050,Región Centro Oriente,15,Boyacá,15842,Umbita
			1051,Región Centro Oriente,25,Cundinamarca,25845,Une
			1052,Región Pacífico,27,Chocó,27800,Unguía
			1053,Región Pacífico,27,Chocó,27810,Unión Panamericana
			1054,Región Eje Cafetero - Antioquia,5,Antioquia,5842,Uramita
			1055,Región Llano,50,Meta,50370,Uribe
			1056,Región Caribe,44,La Guajira,44847,Uribia
			1057,Región Eje Cafetero - Antioquia,5,Antioquia,5847,Urrao
			1058,Región Caribe,44,La Guajira,44855,Urumita
			1059,Región Caribe,8,Atlántico,8849,Usiacurí
			1060,Región Centro Oriente,25,Cundinamarca,25851,Útica
			1061,Región Eje Cafetero - Antioquia,5,Antioquia,5854,Valdivia
			1062,Región Caribe,23,Córdoba,23855,Valencia
			1063,Región Centro Sur,86,Putumayo,86865,Valle de Guamez
			1064,Región Centro Oriente,68,Santander,68855,Valle de San José
			1065,Región Centro Sur,73,Tolima,73854,Valle de San Juan
			1066,Región Caribe,20,Cesar,20001,Valledupar
			1067,Región Eje Cafetero - Antioquia,5,Antioquia,5856,Valparaíso
			1068,Región Centro Sur,18,Caquetá,18860,Valparaíso
			1069,Región Eje Cafetero - Antioquia,5,Antioquia,5858,Vegachí
			1070,Región Centro Oriente,68,Santander,68861,Vélez
			1071,Región Centro Sur,73,Tolima,73861,Venadillo
			1072,Región Eje Cafetero - Antioquia,5,Antioquia,5861,Venecia
			1073,Región Centro Oriente,25,Cundinamarca,25506,Venecia
			1074,Región Centro Oriente,15,Boyacá,15861,Ventaquemada
			1075,Región Centro Oriente,25,Cundinamarca,25862,Vergara
			1076,Región Pacífico,76,Valle del Cauca,76863,Versalles
			1077,Región Centro Oriente,68,Santander,68867,Vetas
			1078,Región Centro Oriente,25,Cundinamarca,25867,Vianí
			1079,Región Eje Cafetero - Antioquia,17,Caldas,17867,Victoria
			1080,Región Eje Cafetero - Antioquia,5,Antioquia,5873,Vigía del Fuerte
			1081,Región Pacífico,76,Valle del Cauca,76869,Vijes
			1082,Región Centro Oriente,54,Norte de Santander,54871,Villa Caro
			1083,Región Centro Oriente,15,Boyacá,15407,Villa de Leyva
			1084,Región Centro Oriente,25,Cundinamarca,25843,Villa de San Diego de Ubate
			1085,Región Centro Oriente,54,Norte de Santander,54874,Villa del Rosario
			1086,Región Pacífico,19,Cauca,19845,Villa Rica
			1087,Región Centro Sur,86,Putumayo,86885,Villagarzón
			1088,Región Centro Oriente,25,Cundinamarca,25871,Villagómez
			1089,Región Centro Sur,73,Tolima,73870,Villahermosa
			1090,Región Eje Cafetero - Antioquia,17,Caldas,17873,Villamaría
			1091,Región Caribe,13,Bolívar,13873,Villanueva
			1092,Región Caribe,44,La Guajira,44874,Villanueva
			1093,Región Centro Oriente,68,Santander,68872,Villanueva
			1094,Región Llano,85,Casanare,85440,Villanueva
			1095,Región Centro Oriente,25,Cundinamarca,25873,Villapinzón
			1096,Región Centro Sur,73,Tolima,73873,Villarrica
			1097,Región Llano,50,Meta,50001,Villavicencio
			1098,Región Centro Sur,41,Huila,41872,Villavieja
			1099,Región Centro Oriente,25,Cundinamarca,25875,Villeta
			1100,Región Centro Oriente,25,Cundinamarca,25878,Viotá
			1101,Región Centro Oriente,15,Boyacá,15879,Viracachá
			1102,Región Llano,50,Meta,50711,Vista Hermosa
			1103,Región Eje Cafetero - Antioquia,17,Caldas,17877,Viterbo
			1104,Región Centro Oriente,25,Cundinamarca,25885,Yacopí
			1105,Región Pacífico,52,Nariño,52885,Yacuanquer
			1106,Región Centro Sur,41,Huila,41885,Yaguará
			1107,Región Eje Cafetero - Antioquia,5,Antioquia,5885,Yalí
			1108,Región Eje Cafetero - Antioquia,5,Antioquia,5887,Yarumal
			1109,Región Llano,97,Vaupés,97889,Yavaraté
			1110,Región Eje Cafetero - Antioquia,5,Antioquia,5890,Yolombó
			1111,Región Eje Cafetero - Antioquia,5,Antioquia,5893,Yondó
			1112,Región Llano,85,Casanare,85001,Yopal
			1113,Región Pacífico,76,Valle del Cauca,76890,Yotoco
			1114,Región Pacífico,76,Valle del Cauca,76892,Yumbo
			1115,Región Caribe,13,Bolívar,13894,Zambrano
			1116,Región Centro Oriente,68,Santander,68895,Zapatoca
			1117,Región Caribe,47,Magdalena,47960,Zapayán
			1118,Región Eje Cafetero - Antioquia,5,Antioquia,5895,Zaragoza
			1119,Región Pacífico,76,Valle del Cauca,76895,Zarzal
			1120,Región Centro Oriente,15,Boyacá,15897,Zetaquira
			1121,Región Centro Oriente,25,Cundinamarca,25898,Zipacón
			1122,Región Centro Oriente,25,Cundinamarca,25899,Zipaquirá
			1123,Región Caribe,47,Magdalena,47980,Zona Bananera`;

		let lineas = cadenacsv.split("\n");
		let json = [];
		let cabeceras = ['id', 'region', 'iddepartamento', 'departamento', 'idciudad', 'ciudad'];

		for (let i = 0; i < lineas.length; i++) {
			let objeto = {}
			let lineaActual = lineas[i].split(",");
			for (let j = 0; j < cabeceras.length; j++) {
				objeto[cabeceras[j]] = lineaActual[j];
			}
			json.push(objeto)
		}
		this.ciudades = json
	}
	addRecaptchaScript() {
		window['grecaptchaCallback'] = () => {
			this.renderReCaptcha();
		}
		(function (d, s, id, obj) {
			var js, fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) { return; }
			js = d.createElement(s); js.id = id;
			js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
			fjs.parentNode.insertBefore(js, fjs);
		}(document, 'script', 'recaptcha-jssdk', this));

	}
	renderReCaptcha() {
		window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
			'sitekey': '6LfAkhoaAAAAABTbyWODa_6m58zqawfcrIhVm_CE',
			'callback': (response) => {
				this.http.post(this.globals.backServiciosECommerce + 'captcha.php', { response }).subscribe((res: any) => {
					if (res.success === true) {
						this.validado = true;
						setInterval(function () {
							this.validado = false
						}, 120000);
					}
				})
			}
		});
	}

}
