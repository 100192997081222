import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';

@Component({
	selector: 'app-destacados',
	templateUrl: './destacados.component.html',
	styleUrls: ['./destacados.component.scss']
})
export class DestacadosComponent {
	categorias = []
	
	constructor(
		private https: HttpClient
	){
		this.getCategorias()
	}

	getCategorias(){
		this.https.get('https://ie-vie.com:3000/api/v2/v2-categorias').subscribe( (resData:any)=> {
			this.categorias = resData.data
		})
	}
}
