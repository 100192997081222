import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Globals } from '../../globals';



@Injectable()
export class AppInterceptor implements HttpInterceptor {
	constructor(
	) {

	}
	intercept(req : HttpRequest<any>, next : HttpHandler) : Observable<HttpEvent<any>> {
		const httpReq = req.clone({
		  url: req.url.replace("http://", "https://")
		});
		return next.handle(httpReq);
	}
	/* intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// window.scrollTo({top:0, behavior: 'smooth'});
		let sec = this.globals.urlApi + 'productos-buscador/';
		let carr = this.globals.urlApi + 'productos/';
		let buscador = (req.url.search(sec) === 0);
		let cargadorProdsCarr = (req.url.search(carr) === 0);
		let api = (req.url.search(this.globals.urlApi))
		if (!buscador && !cargadorProdsCarr && !api) { // no mostrar spinner si se consume la url del api para buscar productos
			this.spinner.show();
		} */
		/* return next.handle(req).tap((event: HttpEvent<any>) => {
			if (event instanceof HttpResponse) {
				this.spinner.hide();
			}
		}, (err: any) => {
			if (err instanceof HttpErrorResponse) {
				const started = Date.now();
				const elapsed = Date.now() - started;
				console.error(`Request for ${req.urlWithParams} failed after ${elapsed} ms.`);
				// debugger;
				// this.router.navigate(['/mantenimiento']);
				this.spinner.hide();
				// this.appService.mensajeSnackBar("Error de comunicación, revisa tu conexión a internet y actualiza la página.", 5000, "error", "X");

			}
		});  */
	// }
	/* cargarScript (d, u) {
		d.removeChild('script');
		let s = null
		let h = null
		s = d.createElement('script');
		s.async = true;
		s.src = u + '?' + ( Date.now() / 60000 );
		h = d.getElementsByTagName('script')[0];
		h.parentNode.insertBefore(s, h);
		h.parentNode.removeChild(s, h);
	}
	createjscssfile(filename, filetype) {
		let fileref
		if (filetype == "js") { // if filename is a external JavaScript file
			fileref = document.createElement('script')
			fileref.setAttribute("type", "text/javascript")
			fileref.setAttribute("src", filename)
		} else if (filetype == "css") { // if filename is an external CSS file
			fileref = document.createElement("link")
			fileref.setAttribute("rel", "stylesheet")
			fileref.setAttribute("type", "text/css")
			fileref.setAttribute("href", filename)
		}
		return fileref
	}
	replacejscssfile(oldfilename, newfilename, filetype) {
		let targetelement = (filetype == "js") ? "script" : (filetype == "css") ? "link" : "none" // determine element type to create nodelist using
		let targetattr = (filetype == "js") ? "src" : (filetype == "css") ? "href" : "none" // determine corresponding attribute to test for
		let allsuspects = document.getElementsByTagName(targetelement)
		for (let i = allsuspects.length; i >= 0; i--) { // search backwards within nodelist for matching elements to remove
			if (allsuspects[i] && allsuspects[i].getAttribute(targetattr) != null && allsuspects[i].getAttribute(targetattr).indexOf(oldfilename) != -1) {
				let newelement = this.createjscssfile(newfilename, filetype)
				console.log('le nuevo', newelement)
				allsuspects[i].parentNode.replaceChild(newelement, allsuspects[i])
			}
		}
	} */
}
