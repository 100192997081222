import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'brandSearchPipe', pure: false })
export class BrandSearchPipe implements PipeTransform {
	transform(brands, args?): Array<any> {
		const searchText = new RegExp(args, 'ig');
		if (brands) {
			return brands.filter(brand => {
				if (brand.nombre) {
					return brand.nombre.search(searchText) !== -1;
				}
			});
		}
	}
}
