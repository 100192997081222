import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { emailValidator } from '../../theme/utils/app-validators';
import { ProductoService } from '../../services/producto.service';
import { Globals } from '../../globals';
import { Router } from '@angular/router';
import { AppService } from '../../app.service';

@Component({
	selector: 'app-calculadora-solar',
	templateUrl: './calculadora-solar.component.html',
	styleUrls: ['./calculadora-solar.component.scss']
})
export class CalculadoraSolarComponent implements OnInit {
	ciudades = [
		{
			id: 1,
			nombre: "Armenia",
			rad_prom: 3930,
			horas_sol_pico: 3.93
		},{
			id: 2,
			nombre: "Barranquilla",
			rad_prom: 6080,
			horas_sol_pico: 6.08
		},{
			id: 3,
			nombre: "Bogotá",
			rad_prom: 3694,
			horas_sol_pico: 3.694
		},{
			id: 4,
			nombre: "Cali",
			rad_prom: 4332,
			horas_sol_pico: 4.332
		},{
			id: 5,
			nombre: "Cúcuta",
			rad_prom: 4904,
			horas_sol_pico: 4.904
		},{
			id: 6,
			nombre: "Florencia",
			rad_prom: 3631,
			horas_sol_pico: 3.631
		},{
			id: 7,
			nombre: "Ibagué",
			rad_prom: 4609,
			horas_sol_pico: 4.609
		},{
			id: 8,
			nombre: "Manizales",
			rad_prom: 3767,
			horas_sol_pico: 3.767
		},{
			id: 9,
			nombre: "Medellín",
			rad_prom: 4329,
			horas_sol_pico: 4.329
		},{
			id: 10,
			nombre: "Mocoa",
			rad_prom: 3271,
			horas_sol_pico: 3.271
		},{
			id: 11,
			nombre: "Montería",
			rad_prom: 4237,
			horas_sol_pico: 4.237
		},{
			id: 12,
			nombre: "Pasto",
			rad_prom: 3742,
			horas_sol_pico: 3.742
		},{
			id: 13,
			nombre: "Pereira",
			rad_prom: 3676,
			horas_sol_pico: 3.676
		},{
			id: 14,
			nombre: "Providencia",
			rad_prom: 4556,
			horas_sol_pico: 4.556
		},{
			id: 15,
			nombre: "Puerto inírida",
			rad_prom: 4035,
			horas_sol_pico: 4.035
		},{
			id: 16,
			nombre: "Riohacha",
			rad_prom: 5094,
			horas_sol_pico: 5.094
		},{
			id: 17,
			nombre: "San andrés",
			rad_prom: 5194,
			horas_sol_pico: 5.194
		},{
			id: 18,
			nombre: "San José del guaviare",
			rad_prom: 2604,
			horas_sol_pico: 2.604
		},{
			id: 19,
			nombre: "Santa Marta",
			rad_prom: 5408,
			horas_sol_pico: 5.408
		},{
			id: 20,
			nombre: "Sincelejo",
			rad_prom: 4368,
			horas_sol_pico: 4.368
		},{
			id: 21,
			nombre: "Valledupar",
			rad_prom: 5274,
			horas_sol_pico: 5.274
		},{
			id: 22,
			nombre: "Villavicencio",
			rad_prom: 4598,
			horas_sol_pico: 4.598
		},{
			id: 23,
			nombre: "Yopal",
			rad_prom: 4835,
			horas_sol_pico: 4.835
		}
	];
	tipos_consumo = [
		{
			id: 1,
			nombre: "Consumo residencial 2 personas",
			factor_ahorro: 0.1,
			porcentaje_ahorro: 10
		},{
			id: 2,
			nombre: "Consumo residencial 4 personas",
			factor_ahorro: 0.15,
			porcentaje_ahorro: 15
		},{
			id: 3,
			nombre: "Consumo residencial 4 personas con servicio",
			factor_ahorro: 0.2,
			porcentaje_ahorro: 20
		},{
			id: 4,
			nombre: "Consumo residencial de alto consumo",
			factor_ahorro: 0.25,
			porcentaje_ahorro: 25
		},{
			id: 5,
			nombre: "Consumo comercial con hora de descanso al medio día",
			factor_ahorro: 0.35,
			porcentaje_ahorro: 35
		},{
			id: 6,
			nombre: "Consumo comercial jornada continua",
			factor_ahorro: 0.5,
			porcentaje_ahorro: 50
		},{
			id: 7,
			nombre: "Consumo industrial pequeña empresa",
			factor_ahorro: 0.4,
			porcentaje_ahorro: 40
		},{
			id: 8,
			nombre: "Consumo industrial mediana empresa",
			factor_ahorro: 0.5,
			porcentaje_ahorro: 50
		},{
			id: 9,
			nombre: "Consumo industrial",
			factor_ahorro: 0.3,
			porcentaje_ahorro: 30
		}
	];
	pppf = [
		{
			id: 1,
			valor: 250
		},{
			id: 2,
			valor: 270
		},{
			id: 3,
			valor: 280
		},{
			id: 4,
			valor: 300
		},{
			id: 5,
			valor: 320
		},{
			id: 6,
			valor: 330
		},{
			id: 7,
			valor: 345
		},{
			id: 8,
			valor: 365
		},{
			id: 9,
			valor: 385
		},{
			id: 10,
			valor: 395
		},{
			id: 11,
			valor: 400
		}
	];
	imagen_ppal = "";
	arreglo_imagenes = [
		"../../../assets/images/calculadora/carrusel-1.jpg",
		"../../../assets/images/calculadora/carrusel-2.jpg",
		"../../../assets/images/calculadora/carrusel-3.jpg",
		"../../../assets/images/calculadora/carrusel-4.jpg",
	]
	productosIluminacionSolar = []
	

	calculadoraForm:  UntypedFormGroup
	ciudad
	porcentaje_ahorro
	ppp_f = this.pppf[4];
	consumo_mensual_energia
	rad_promedio = 4293
	horas_sol_pico_prom = 4.293
	valor_kwh = 0
	eficiencia_panel_solar = 0.81
	eficiencia_inversor = 0.92

	// resultados
	consumo_diario
	potencia_fotovoltaica_requerida
	numero_paneles_solares
	potencia_fotovoltaica_neta
	area_minima_requerida
	generacion_fotovoltaica_mensual
	generacion_fotovoltaica_anual
	ahorro_minimo_esperado_mensual = 0
	ahorro_minimo_esperado_anual = 0
	valor_cuota = 0

	costo_proyecto = 0

	//spinner
	progreso = 0
	public verprogreso:boolean = false
	public calculo_completado:boolean = false

	// captcha
	@ViewChild('recaptcha', {static: false }) recaptchaElement: ElementRef
	validado: boolean = false;

	constructor(
		public formBuilder: UntypedFormBuilder,
		public productoService: ProductoService,
		public http: HttpClient,
		public globals: Globals,
		public _router: Router,
		private appService: AppService
	) {
		
	}

	ngOnInit(): void {
		this.getProductosIluminacionSolar();
		this.calculadoraForm = this.formBuilder.group({
			nombre: ['', Validators.required],
			email: ['', Validators.compose([Validators.required, emailValidator])],
			telefono: [''],
			ubicacion_cercana : [this.ciudad, Validators.required],
			valor_recibo_luz: [''],
			consumo_energia: [this.consumo_mensual_energia, Validators.required],
			costo_kw_pesos: ['', Validators.required],
			tipo_perfil: ['', Validators.required],
			potencia_panel: [this.ppp_f]
		})
		this.imagen_ppal =  this.arreglo_imagenes[0];
		
	}
	ngAfterViewInit() {
		this.renderReCaptcha()
	}
	public getProductosIluminacionSolar() {
		this.productoService.getProductosCategoria(11150000, 1, 12, 'bogota').subscribe(res => {
			this.productosIluminacionSolar = res.productos;
		});
	}
	calcularSistemaFotovoltaico (valores) {
		
		this.consumo_diario = (valores.consumo_energia/30)*1000;
		
		this.potencia_fotovoltaica_requerida = (this.consumo_diario * valores.tipo_perfil.factor_ahorro) / (valores.ubicacion_cercana.horas_sol_pico * this.eficiencia_panel_solar * this.eficiencia_inversor);
		this.numero_paneles_solares = Math.round(this.potencia_fotovoltaica_requerida / valores.potencia_panel.valor);
		
		this.potencia_fotovoltaica_neta = this.numero_paneles_solares * valores.potencia_panel.valor;
		
		if(this.ppp_f.valor <= 300){
			this.area_minima_requerida = 1.65 * this.numero_paneles_solares
		} else if (this.ppp_f.valor > 300){
			this.area_minima_requerida = 2 * this.numero_paneles_solares
		} else {
			this.area_minima_requerida = "No determinado"
		}

		this.generacion_fotovoltaica_mensual = this.potencia_fotovoltaica_neta * this.eficiencia_panel_solar * this.eficiencia_inversor * valores.ubicacion_cercana.horas_sol_pico * 30;
		this.generacion_fotovoltaica_anual = ( this.potencia_fotovoltaica_neta * this.eficiencia_panel_solar * this.eficiencia_inversor * valores.ubicacion_cercana.horas_sol_pico * 365 ) / 1000;

		this.ahorro_minimo_esperado_mensual = (this.generacion_fotovoltaica_mensual / 1000) * valores.costo_kw_pesos;
		this.ahorro_minimo_esperado_anual = (this.generacion_fotovoltaica_anual * valores.costo_kw_pesos);

		if (this.potencia_fotovoltaica_neta <= 3200) {
			this.costo_proyecto = (6500 * this.potencia_fotovoltaica_neta)
		} else if (this.potencia_fotovoltaica_neta > 3200 && this.potencia_fotovoltaica_neta <= 8100) {
			this.costo_proyecto = (6000 * this.potencia_fotovoltaica_neta)
		} else if (this.potencia_fotovoltaica_neta > 8100 && this.potencia_fotovoltaica_neta <= 13500) {
			this.costo_proyecto = (5500 * this.potencia_fotovoltaica_neta)
		} else if (this.potencia_fotovoltaica_neta > 13500 && this.potencia_fotovoltaica_neta <= 21600) {
			this.costo_proyecto = (5000 * this.potencia_fotovoltaica_neta)
		} else if (this.potencia_fotovoltaica_neta > 21600 && this.potencia_fotovoltaica_neta <= 27000) {
			this.costo_proyecto = (4000 * this.potencia_fotovoltaica_neta)
		} else if (this.potencia_fotovoltaica_neta > 27000){
			this.costo_proyecto = (3500 * this.potencia_fotovoltaica_neta)
		} else {
			this.costo_proyecto = -1
		}
		this.valor_cuota = (((this.costo_proyecto/1000000) * 50100) / 30);
		let datos_correo = {
			"valor_cuota": this.valor_cuota,
			"correo_usuario": valores.email,
			"nombre_usuario": valores.nombre,
			"telefono_usuario": valores.telefono,
			"ubicacion_cercana": valores.ubicacion_cercana.nombre,
			"valor_recibo_mensual_luz": valores.valor_recibo_luz,
			"consumo_energia": valores.consumo_energia,
			"costo_kw_pesos": valores.costo_kw_pesos,
			"tipo_perfil": valores.tipo_perfil.nombre,
			"potencia_panel": valores.potencia_panel.valor,
			"valor_proyecto": this.costo_proyecto,
			"valor_ahorro_mensual_min_esperado": this.ahorro_minimo_esperado_mensual,
			"valor_ahorro_anual_min_esperado": this.ahorro_minimo_esperado_anual
		}

		if(this.valor_cuota === 0){
			this.appService.mensajeSnackBar("Los datos ingresados no arrojan un calculo real, por favor verifique los valores.", 3000, 'error', 'cerrar')
		} else {
			this.animarCirculo()
			this.enviarCorreo(datos_correo);
		}
		
	}
	seleccionarImagen (posicion) {
		this.imagen_ppal = this.arreglo_imagenes[posicion]
	}
	enviarCorreo(datos) {
		this.http.post(this.globals.backServiciosECommerce +'calculadora/correo_calculadora.php', datos).subscribe((respuesta:any) =>{
			console.log("respuesta ? ", respuesta)
			if(respuesta.status == 'ok'){
				console.log(respuesta.mensaje)
				this.appService.mensajeSnackBar('Hemos enviado un correo con la información indicada', 3000, 'success', 'cerrar')
			}
		})
	}
	animarCirculo (){
		this.verprogreso = true
		this.progreso ++;
		if(this.progreso < 100){
			setTimeout(() => {
				this.animarCirculo()
			}, 10);
		} else {
			this.calculo_completado = true
			this.verprogreso = false
		}
	}
	soloNumeros (event) {
		if(event.key === "," || event.key === "." || event.key === "e"){
			return false;
		}
	}
	addRecaptchaScript() {
		window['grecaptchaCallback'] = () => {
			this.renderReCaptcha();
		}
		(function(d, s, id, obj){
			var js, fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) { return;}
			js = d.createElement(s); js.id = id;
			js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
			fjs.parentNode.insertBefore(js, fjs);
		}(document, 'script', 'recaptcha-jssdk', this));
	   
	}
	renderReCaptcha() {
		window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
		  'sitekey' : '6LfAkhoaAAAAABTbyWODa_6m58zqawfcrIhVm_CE',
		  'callback': (response) => {
			  // console.log(response);
			  this.http.post(this.globals.backServiciosECommerce + 'captcha.php', {response}).subscribe((res:any) =>{
				  // console.log("res captcha ==> ", res)
				  	if(res.success === true) {
						
						this.validado = true;
						
					  	setInterval(function(){
						
							this.validado = false
						
						}, 120000);
				  	}
			  })
		  }
		});
	}

}
