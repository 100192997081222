import { Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
	selector: 'app-subir-archivo',
	templateUrl: './subir-archivo.component.html',
	styleUrls: ['./subir-archivo.component.scss']
})
export class SubirArchivoComponent implements OnInit {
	@Input('orientacion') orientacion: string;
	@Input('ancho') ancho: number;
	@Input('alto') alto: number;
	@Input('tipo') tipo: string;

	@ViewChild('grupo', {static: true}) tipoArchivo: string;

	eleccionRealizada:boolean = false;
	vistaPreviaImagen: any;
	carga:number = 0;

	hayError: boolean = false
	dimentionsFileValid = false
	mensajeError: string = ''

	constructor() {
	}

	ngOnInit(): void {
		if (this.tipo) {
			// imagen, video, pdf, excel
			if((this.tipo != 'imagen') && (this.tipo != 'video') && (this.tipo != 'pdf') && (this.tipo != 'excel') ){
				this.hayError = true;
				this.mensajeError = "Tipo de archivo no válido en la configuración";
			} else {
				if (this.tipo == 'imagen') {

				}

			}
			
			// si imagen => pedir dimensiones
			// si otro => pedir maximo tamaño del archivo

		} else {
			this.hayError = true;
			this.mensajeError = "Hace falta el parámetro de tipo en la configuración";
			// obligar a pedir tipo o mostrar todos ?
		}
		
		
	}

	elegirTipoArchivo  (event) {
		console.log("Elegido", event)
	}
	onChangeInput(event) {
		event.preventDefault();
		let archivoElegido: FileList = event.target.files;
		let lector = new FileReader();
		let imagenTemp = new Image()
		lector.readAsDataURL(archivoElegido.item(0));
		lector.onload = (_event) => {
			this.carga = _event.loaded
			this.eleccionRealizada = true
			
			this.vistaPreviaImagen =  lector.result.toString()
			imagenTemp.src = this.vistaPreviaImagen;
			imagenTemp.onload = (data: any) => {
				let ancho =data.path[0].width
				let alto =data.path[0].height
				console.log("Obtenidos", ancho, alto)
				
				if (ancho == this.ancho && alto == this.alto) {
					this.dimentionsFileValid = true
					this.hayError = false
					this.mensajeError = "";
				} else {
					this.dimentionsFileValid = false
					this.hayError = true
					this.mensajeError = "dimensiones no validas, requeridas " + this.ancho + " x " + this.alto ;
					this.carga = 0
					this.vistaPreviaImagen = null
				}
			}
		}


		/* this.archivoElegidoBanner = event.target.files;
		

		lector.readAsDataURL(this.archivoElegidoBanner.item(0));
		lector.onload = (_event) => {
			this.imagenTemporal.onload = (data: any) => {
				let anchoi = data.path[0].width
				let altoi = data.path[0].height
			
				if ((anchoi != 1200) || (altoi != 250)) {
					this.imagenCategoriaBanner = null
					this.snackBar.open("Dimensiones no permitidas, la imagen debe ser de 1200x250", 'x', {duration: 50000, panelClass: 'error', verticalPosition: 'top'} )
					// no permitir la carga
					// error con globalEventHandler al poner algo acá
				} else {
					this.imagenCategoriaBanner = lector.result.toString();
					this.modoBanner = false
				}
			};
			this.imagenTemporal.src = lector.result.toString(); 
		} */
	}

}
