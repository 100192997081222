/* import { WINDOW } from '@ng-toolkit/universal'; */
import { Component, OnInit, ViewChild, Inject, Output, EventEmitter } from '@angular/core';
import { Settings, AppSettings } from './../../app.settings';
import { Category } from '../../app.models';
import { Producto } from '../../models/producto';
import { Sede } from '../../models/sede';
import { UntypedFormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { AppService } from '../../app.service';
import { SidenavMenuService } from '../../../app/theme/components/sidenav-menu/sidenav-menu.service';
import { Router } from '@angular/router';
import { ProductoService } from '../../../app/services/producto.service';
import { SedeService } from '../../../app/services/sede.service';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../../../app/globals';
import { BuscadorService } from '../../../app/services/buscador.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MarcaService } from '../../../app/services/marca.service';

@Component({
	selector: 'app-buscador-productos',
	templateUrl: './buscador-productos.component.html',
	styleUrls: ['./buscador-productos.component.scss']
})
export class BuscadorProductosComponent implements OnInit {
	logo = './assets/images/others/logo-ieR.png';
	showBackToTop = false;
	categories: Category[];
	category: Category;
	sidenavMenuItems: Array<any>;
	productos: Producto[];
	sedes: Sede[];
	@ViewChild(MatAutocompleteTrigger) autocomplete: any;
	@Output() productoSeleccionado = new EventEmitter();
	leProducto
	loggedIn: boolean
	veces = 0
	buscador: UntypedFormControl;
	buscadorResponsive: UntypedFormControl;
	opciones: Producto[] = [];
	filteredOptions: Observable<Producto[]>;
	largocadena: number = 0;
	marcas: any;
	leOS = '';
	tecla
	settings: Settings;
	cargandoProductosBuscador: boolean = false;
	imagenDefecto: string = '../../../assets/images/others/producto-default.png';

	constructor(
		/* @Inject(WINDOW) private window: Window, */
		public appSettings: AppSettings,
		public appService: AppService,
		public sidenavMenuService: SidenavMenuService,
		public router: Router,
		public productoService: ProductoService,
		public sedeService: SedeService,
		private http: HttpClient,
		private globals: Globals,
		private buscadorService: BuscadorService,
		private marcasService: MarcaService
	) { }

	ngOnInit() {
		this.buscador = new UntypedFormControl();
		let cadenaTemp = ''
		this.buscador.valueChanges.subscribe( async (cadenaBuscada) => {
			let cadenaaux = this.buscador.value
			await setTimeout( () => {
				this.cargandoProductosBuscador = true;
				this.filteredOptions = null;
				this.largocadena = cadenaBuscada ? cadenaBuscada.length : 0;
				if (this.largocadena > 2 && cadenaaux == cadenaTemp) {
					this.buscarCadena(cadenaBuscada.replace('/', '%2F')).subscribe((productsFound: any[]) => {
						productsFound.forEach(async element => {
							element['show'] = false;
							await this.marcaProducto(element.idmarca).then(res => {
								// console.log('dddd', res);
								if (res) {
									element['marca'] = res[0]
								}

							})
						})
						this.filteredOptions = of(productsFound)
						this.cargandoProductosBuscador = false;
					});
				} else {
					this.cargandoProductosBuscador = false;
				}
			}, 500);
			cadenaTemp = cadenaBuscada
		});
	}
	ngAfterViewInit(): void {
		this.marcasService.getMarcas().subscribe(respuesta => {
			this.marcas = respuesta
		})
	}
	async marcaProducto(idsap) {
		return await this.marcas.filter(marca => marca.idsap === idsap.toString())
	}

	ejecutarBusqueda() {
		let cadenaBuscada = this.buscador.value
		this.largocadena = cadenaBuscada.length
		if (cadenaBuscada.length > 2) {
			this.filteredOptions = null;
			this.largocadena = 0;
			this.buscarCadena(cadenaBuscada).subscribe((productsFound: any[]) => {
				productsFound.forEach(element => {
					element['show'] = false
				})
				this.filteredOptions = of(productsFound)
			});
		}
	}
	verProductosResultados() {
		this.filteredOptions.subscribe((res: any) => {
			this.opciones = res;
			this.buscadorService.resultadosBuscador = true;
			this.buscadorService.opcionesResultados = this.opciones;
			this.filteredOptions = null;
			this.router.navigateByUrl('/chat', { skipLocationChange: true }).then((paso) => {
				if(paso) {
					this.router.navigate(['/productos/1/12/resultados']);
					this.largocadena = 0;
				}
			});
			this.buscador.setValue('');
			this.cargandoProductosBuscador = false;
		})
	}
	buscarCadena(cadena: string) {
		const url = `${this.globals.urlApi}productos-buscador/${cadena}`;
		return this.http.get(url);
	}
	verTodosEnter(event) {
		if (this.largocadena > 2 && (event.keyCode === 13 || event.key == 'Enter')) {
			this.verProductosResultados();
		}
	}
	seleccionarProducto( producto ) {
		this.productoSeleccionado.emit(producto);
	}

}
